import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';

const HomePageStyled = makeStyles((theme) => ({

    root: {
        position:'relative',
        width:'100vw',
        height:'100vh',
        margin:0,
        padding:0,
        color:'#fff',
        background:'#000',
        fontFamily:'Poppins',
    },
    homeSlider:{
        '& > .slick-list > .slick-track > .slick-slide > div':{
            width:'100vw !important',
            height:'100vh',
            // display:'flex !important',
            // justifyContent:'center',
            // alignItems:'center',
        }
    },
    dots:{
        position:'absolute',
        top:0,
        right:40,
        display:'flex',
        height:'inherit',
        alignItems:'center',
        '& > ul':{
            margin:0,
            padding:0,
            '& > li':{
                display:'flex',
                marginBottom:28,
                listStyle:'none',
                alignItems: 'center',
                justifyContent: 'space-between',
                fontWeight:500,
                fontSize:14,
                lineHeight:'21px',
                cursor:'pointer',
                opacity:'0.5',
                '& > div':{
                    width:16,
                    height:16,
                    marginLeft:12,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    '& > div':{
                        width:4,
                        height:4,
                        background:'#fff',
                        borderRadius:30
                    }
                }
            },
            '& li.active':{
                opacity:'1',
                '& > div ':{
                    border:'1px solid #fff',
                    borderRadius:30
                },
            },

        }
    }
}));

function getHomePageStyled(){
    const _homepageStyled = HomePageStyled();
    return _homepageStyled;
}

export {getHomePageStyled}