import React, {useEffect, useRef, useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useStores} from "../../common/store";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import SettingsIcon from '@material-ui/icons/Settings';
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import api from "../../common/api";
import LabelText from "../../components/LabelText";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import _ from "lodash";
import IconButton from "@mui/material/IconButton";
import Box from "@material-ui/core/Box";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'inline-block',
        [theme.breakpoints.between('xs', 'md')]: {
            overflowX: 'scroll'
        }
    },
    cardRoot: {
        display: 'inline-block',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        cursor: 'pointer',
        background: theme.palette.background.paper,
        '& :hover ': {
            background: '#d0d0d0',
        },
        '& > div': {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(4),
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(1),
            }
        }
    }
}));

const dialogStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paperWidthMd': {
            width: 530,
            height: 544,
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #171E31',
            borderRadius: 5,
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.white,
        height: 60,

    },
    dialogTitle: {
        height: 60,
        backgroundColor: theme.palette.secondary.main,
        color: '#fff',
        "& > *": {
            ...theme.typography.h5,
            fontSize: "1.125rem"
        }
    },
    closeButton: {
        color: '#fff !important',
        width: 48,
        height: 48
    },
    dialogContent: {
        backgroundColor: '#fff',
        padding: "26px 30px 0px 30px",
    },
    dialogButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        backgroundColor: '#fff',
        padding: "8px 30px 30px 30px",
        '& > *': {
            width: 130,
            height: 40,
            borderRadius: 5,
            fontSize: '0.75rem',
            fontWeight: 700
        },
        '& > :first-child': {
            border: '1px solid #C7CBD1',
            backgroundColor: 'transparent',
            color: '#81808A',
            "&:hover": {
                border: '1px solid #3C39CB',
                backgroundColor: 'transparent',
                color: theme.palette.primary.main
            }
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            marginLeft: 6,
            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
            }
        }
    },
    logContainer: {
        marginTop: 0,
        marginBottom: 13,
        minWidth: 490,
        [theme.breakpoints.between('xs', 'md')]: {
            minWidth: 350
        },
        '& > dt': {
            marginBottom: 10,
            '& > span': {
                ...theme.typography.button,
                fontWeight: 700,
                color: theme.palette.secondary.main
            }
        },
        '& > dd': {
            marginLeft: 0,
        },
        '& .MuiToggleButtonGroup-root': {
            width: 470,
            height: 50,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            backgroundColor: theme.palette.background.contrast,
            '& > button': {
                width: 90,
                height: 36,
                border: 'none',
                '&:hover': {
                    color: '#81808A',
                    backgroundColor: '#E3E1EC'
                }
            }
        },
        '& .MuiToggleButton-root.Mui-selected': {
            color: theme.palette.primary.main,
            fontWeight: 700,
            backgroundColor: '#fff',
            border: '1px solid #3C39CB',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
            borderRadius: '5px',
            zIndex: 1,
            '&:hover': {
                color: theme.palette.primary.main,
                backgroundColor: '#fff !important'
            }
        }
    }

}));


export default function LogsPage(props) {
    const {history} = props;
    const {t} = useTranslation();
    const {ds} = useStores();
    const classes = useStyles();
    const dialogStyle = dialogStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [openType, setOpenType] = useState(null);
    const [response, setResponse] = useState(true);

    const openHandler = () => {
        setIsOpen(prevState => !prevState);
    }

    const cardEvents = (type) => {
        if (type === "LOG") {
            setOpenType("LOG");
        } else {
            setOpenType("WORKING");
            setIsOpen(false);
            ds.showErrorAlert("Is Working...");
        }

        if (openType !== "WORKING") {
            setIsOpen(true);
        }
    }

    const settingContent = useRef(
        {
            logStorage: [],
        }
    );

    const settingClone = useRef(
        {
            logStorage: [],
        }
    );

    const allData = useRef(
        {
            value: "",
            errorCnt: 0,
            warnCnt: 0,
            infoCnt: 0,
            debugCnt: 0,
            traceCnt: 0,
        }
    );

    useEffect(() => {
        api.getLogList().then((data) => {
            settingContent.current.logStorage = data.data;
            settingClone.current = _.cloneDeep(settingContent.current);
            logAllCheck();
            setResponse(!response);
        });
    }, []);

    const cancelClick = () => {
        settingContent.current = _.cloneDeep(settingClone.current);
        logAllCheck();
        setResponse(!response);
    }

    const logAllCheck = () => {
        if (settingContent.current.logStorage !== undefined) {
            const len = settingContent.current.logStorage.length;

            settingContent.current.logStorage.map((data, index) => {
                if ("ERROR" === data.value) {
                    allData.current.errorCnt += 1;
                } else if ("WARN" === data.value) {
                    allData.current.warnCnt += 1;
                } else if ("INFO" === data.value) {
                    allData.current.infoCnt += 1;
                } else if ("DEBUG" === data.value) {
                    allData.current.debugCnt += 1;
                } else if ("TRACE" === data.value) {
                    allData.current.traceCnt += 1;
                }
            });

            if (allData.current.errorCnt === len) {
                allData.current.value = "ERROR";
            } else if (allData.current.warnCnt === len) {
                allData.current.value = "WARN";
            } else if (allData.current.infoCnt === len) {
                allData.current.value = "INFO";
            } else if (allData.current.debugCnt === len) {
                allData.current.value = "DEBUG";
            } else if (allData.current.traceCnt === len) {
                allData.current.value = "TRACE";
            } else {
                allData.current.value = ""
            }

            allData.current.errorCnt = 0;
            allData.current.warnCnt = 0;
            allData.current.infoCnt = 0;
            allData.current.debugCnt = 0;
            allData.current.traceCnt = 0;
        }
    }

    const okClick = () => {
        settingClone.current = _.cloneDeep(settingContent.current);
        api.setLogModify(settingContent.current.logStorage)
            .then((data) => {
                if (data.rtCode === 0) {
                    ds.showSuccessAlert(t('Logs.SaveSuccess'));
                } else {
                    ds.showErrorAlert(t('Logs.SaveFail'));
                }
            })
            .catch(err => {
                ds.showErrorAlert(t('Logs.SaveFail'));
            });
    }

    const handleAllAlignment = (newAlignment) => {
        if (newAlignment !== null) {
            allData.current.value = newAlignment;
            settingContent.current.logStorage.map((data, index) => (data.value = newAlignment));
            setResponse(!response);
        }
    }

    const handleAlignment = (seq, newAlignment) => {
        if (newAlignment !== null) {
            seq = seq - 1;
            settingContent.current.logStorage[seq].value = newAlignment;
            logAllCheck();
            setResponse(!response);
        }
    };

    return (
        <div className={classes.root}>
            <React.Fragment>
                <dl className={dialogStyle.logContainer}>
                    <dt>
                        <LabelText
                            label={'ALL'}
                            translation={false}
                        />
                    </dt>
                    <dd>
                        <ToggleButtonGroup
                            value={allData.current.value}
                            onChange={(event, newAlignment) => handleAllAlignment(newAlignment)}
                            exclusive
                        >
                            <ToggleButton value="ERROR" aria-label="log level Error">Error</ToggleButton>
                            <ToggleButton value="WARN" aria-label="log level Warn">Warn</ToggleButton>
                            <ToggleButton value="INFO" aria-label="log level Info">Info</ToggleButton>
                            <ToggleButton value="DEBUG" aria-label="log level Debug">Debug</ToggleButton>
                            <ToggleButton value="TRACE" aria-label="log level Trace">Trace</ToggleButton>
                        </ToggleButtonGroup>
                    </dd>
                </dl>
                {settingContent.current.logStorage !== undefined &&
                    settingContent.current.logStorage.map((data, index) => (
                        <dl className={dialogStyle.logContainer} key={index}>
                            <dt>
                                <LabelText
                                    label={data.name}
                                    translation={false}
                                />
                            </dt>
                            <dd>
                                <ToggleButtonGroup
                                    value={data.value}
                                    onChange={(event, newAlignment) => handleAlignment(data.seq, newAlignment)}
                                    exclusive
                                >
                                    <ToggleButton value="ERROR" aria-label="log level Error">Error</ToggleButton>
                                    <ToggleButton value="WARN" aria-label="log level Warn">Warn</ToggleButton>
                                    <ToggleButton value="INFO" aria-label="log level Info">Info</ToggleButton>
                                    <ToggleButton value="DEBUG" aria-label="log level Debug">Debug</ToggleButton>
                                    <ToggleButton value="TRACE" aria-label="log level Trace">Trace</ToggleButton>
                                </ToggleButtonGroup>
                            </dd>
                        </dl>
                    ))}
            </React.Fragment>
            <Box className={dialogStyle.dialogButtons}>
                <Button onClick={cancelClick}>{t("CommonButton.CancelBtn")}</Button>
                <Button onClick={okClick}>{t("CommonButton.OkBtn")}</Button>
            </Box>
        </div>
    )
}


// function SettingDialog({open, onClose, openType}) {
//     const classes = dialogStyles();
//     const {t} = useTranslation();
//     const {ds} = useStores();
//     const [response, setResponse] = useState([]);
//
//     const settingContent = useRef(
//         {
//             logStorage: {},
//         }
//     );
//
//     const settingClone = useRef(
//         {
//             logStorage: {},
//         }
//     );
//
//     const allData = useRef(
//         {
//             value: "",
//             errorCnt: 0,
//             warnCnt: 0,
//             infoCnt: 0,
//             debugCnt: 0,
//             traceCnt: 0,
//         }
//     );
//
//     useEffect(() => {
//         api.getLogList().then((data) => {
//             settingContent.current.logStorage = data.data;
//             settingClone.current = _.cloneDeep(settingContent.current);
//             logAllCheck();
//             setResponse(data);
//         });
//     }, []);
//
//     const cancelClick = () => {
//         settingContent.current = settingClone.current;
//         setResponse(settingContent);
//         onClose(false);
//     }
//
//     const logAllCheck = () => {
//         if (settingContent.current.logStorage !== undefined) {
//             const len = settingContent.current.logStorage.length;
//
//             settingContent.current.logStorage.map((data, index) => {
//                 if ("ERROR" === data.value) {
//                     allData.current.errorCnt += 1;
//                 } else if ("WARN" === data.value) {
//                     allData.current.warnCnt += 1;
//                 } else if ("INFO" === data.value) {
//                     allData.current.infoCnt += 1;
//                 } else if ("DEBUG" === data.value) {
//                     allData.current.debugCnt += 1;
//                 } else if ("TRACE" === data.value) {
//                     allData.current.traceCnt += 1;
//                 }
//             });
//
//             if (allData.current.errorCnt === len) {
//                 allData.current.value = "ERROR";
//             } else if (allData.current.warnCnt === len) {
//                 allData.current.value = "WARN";
//             } else if (allData.current.infoCnt === len) {
//                 allData.current.value = "INFO";
//             } else if (allData.current.debugCnt === len) {
//                 allData.current.value = "DEBUG";
//             } else if (allData.current.traceCnt === len) {
//                 allData.current.value = "TRACE";
//             } else {
//                 allData.current.value = ""
//             }
//
//             allData.current.errorCnt = 0;
//             allData.current.warnCnt = 0;
//             allData.current.infoCnt = 0;
//             allData.current.debugCnt = 0;
//             allData.current.traceCnt = 0;
//         }
//     }
//
//     const okClick = () => {
//         onClose(false);
//
//         if (openType === "LOG") {
//
//             api.setLogModify(settingContent.current.logStorage)
//                 .then((data) => {
//                     if (data.rtCode === 0) {
//                         ds.showSuccessAlert(t('Logs.SaveSuccess'));
//                     } else {
//                         ds.showErrorAlert(t('Logs.SaveFail'));
//                     }
//                 })
//                 .catch(err => {
//                     ds.showErrorAlert(t('Logs.SaveFail'));
//                 });
//         }
//
//     }
//
//     const titles = () => {
//         if (openType === "LOG") {
//             return (t('Logs.Title'))
//         } else {
//             return ('-')
//         }
//     }
//
//     const contents = () => {
//         if (openType === "LOG") {
//
//             const handleAllAlignment = (newAlignment) => {
//                 if (newAlignment !== null) {
//                     allData.current.value = newAlignment;
//                     settingContent.current.logStorage.map((data, index) => (data.value = newAlignment));
//                     setResponse(newAlignment);
//                 }
//             }
//
//             const handleAlignment = (seq, newAlignment) => {
//                 if (newAlignment !== null) {
//                     seq = seq - 1;
//                     settingContent.current.logStorage[seq].value = newAlignment;
//                     logAllCheck();
//                     setResponse(seq + newAlignment);
//                 }
//             };
//
//             return (
//                 <React.Fragment>
//                     <dl className={classes.logContainer}>
//                         <dt>
//                             <LabelText
//                                 label={'ALL'}
//                                 translation={false}
//                             />
//                         </dt>
//                         <dd>
//                             <ToggleButtonGroup
//                                 value={allData.current.value}
//                                 onChange={(event, newAlignment) => handleAllAlignment(newAlignment)}
//                                 exclusive
//                             >
//                                 <ToggleButton value="ERROR" aria-label="log level Error">Error</ToggleButton>
//                                 <ToggleButton value="WARN" aria-label="log level Warn">Warn</ToggleButton>
//                                 <ToggleButton value="INFO" aria-label="log level Info">Info</ToggleButton>
//                                 <ToggleButton value="DEBUG" aria-label="log level Debug">Debug</ToggleButton>
//                                 <ToggleButton value="TRACE" aria-label="log level Trace">Trace</ToggleButton>
//                             </ToggleButtonGroup>
//                         </dd>
//                     </dl>
//                     {settingContent.current.logStorage.map((data, index) => {
//                         return (
//                             <dl className={classes.logContainer} key={index}>
//                                 <dt>
//                                     <LabelText
//                                         label={data.name}
//                                         translation={false}
//                                     />
//                                 </dt>
//                                 <dd>
//                                     <ToggleButtonGroup
//                                         value={data.value}
//                                         onChange={(event, newAlignment) => handleAlignment(data.seq, newAlignment)}
//                                         exclusive
//                                     >
//                                         <ToggleButton value="ERROR" aria-label="log level Error">Error</ToggleButton>
//                                         <ToggleButton value="WARN" aria-label="log level Warn">Warn</ToggleButton>
//                                         <ToggleButton value="INFO" aria-label="log level Info">Info</ToggleButton>
//                                         <ToggleButton value="DEBUG" aria-label="log level Debug">Debug</ToggleButton>
//                                         <ToggleButton value="TRACE" aria-label="log level Trace">Trace</ToggleButton>
//                                     </ToggleButtonGroup>
//                                 </dd>
//                             </dl>
//                         )
//                     })}
//                 </React.Fragment>
//             )
//         } else {
//             return ('-')
//         }
//     }
//
//     return (
//         <Dialog
//             open={open}
//             onClose={onClose}
//             maxWidth='md'
//             className={classes.dialog}
//         >
//             <Box className={classes.header}>
//                 <DialogTitle id={`${openType}_dialog_id`} className={classes.dialogTitle}>
//                     {titles()}
//                 </DialogTitle>
//                 {onClose &&
//                 <DialogActions>
//                     <IconButton onClick={onClose} className={classes.closeButton}>
//                         <ClearIcon/>
//                     </IconButton>
//                 </DialogActions>
//                 }
//             </Box>
//             <DialogContent className={classes.dialogContent}>
//                 <Box id="alert-dialog-description">
//                     {contents()}
//                 </Box>
//             </DialogContent>
//             <DialogActions className={classes.dialogButtons}>
//                 <Button onClick={cancelClick}>{t("CommonButton.CancelBtn")}</Button>
//                 <Button onClick={okClick}>{t("CommonButton.OkBtn")}</Button>
//             </DialogActions>
//         </Dialog>
//     );
// }
