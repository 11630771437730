import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import api from "../../common/api";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator
} from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import {Slide, Typography} from "@material-ui/core";
import {Scrollbars} from "react-custom-scrollbars";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {AuthResult, CommonCodeValue} from "../../common/types";

const timelineStyles = makeStyles((theme) => ({
    scrollBar: {
        zIndex: 5,
        width: 365,
        height: 'calc(100vh - 11.876rem) !important',
        paddingRight: '10px',
        '& > div': {
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            height: '100% !important',
            overflowX: 'hidden !important'
        }
    },
    track: {
        position: 'absolute',
        width: '7px',
        height: "850px",
        right: '2px',
        bottom: '2px',
        borderRadius: '3px',
        paddingRight: '2px',
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'none',
        },
    },
    authBar: {
        width: 365,
        minWidth: 365,
        overflow: 'hidden'
    },
    authTitle: {
        borderLeft: '1px solid #E3E2EB',
        padding: '50px 0 25px 20px',
        marginLeft: 4.5,
        fontSize: '1.125rem',
        color: "#3A3A3B",
        lineHeight: '20.7px'
    },
    timeline: {
        marginTop: 0,
        marginBottom: 0,
        padding: '0 16px 0 0',
        '& > *': {
            width: 360,
            marginLeft: 4.5,
            '&:hover': {
                backgroundColor: '#F2F1F8',
                cursor: 'default',
                '& .MuiTimelineDot-defaultGrey': {
                    zIndex: 10,
                    border: '2px solid #3C39CB',
                },
                '& .MuiTimelineConnector-root': {
                    zIndex: 10,
                    backgroundColor: theme.palette.primary.main,
                }
            }
        },
        '& .MuiTimelineItem-missingOppositeContent:before': {
            display: "none"
        },
        '& .MuiTimelineDot-root': {
            boxShadow: 'none'
        },
        '& .MuiTimelineDot-defaultGrey': {
            backgroundColor: '#fff',
            width: 10, height: 10,
            border: '2px solid #E3E2EB',
            marginBottom: "0px",
            marginTop: "0px",
            marginLeft: "-4.5px",
            padding: 3
        },
        '& .MuiTimelineConnector-root': {
            marginLeft: "-4.5px",
            backgroundColor: '#E3E2EB',
            height: 45,
            width: '1px'
        }
    },
    timelineContent: {
        display: 'flex',
        '& > :first-child': {
            width: 220,
            '& > :first-child': {
                paddingTop: 25,
                paddingBottom: 10,
                color: '#8A8894',
                lineHeight: '12.65px'
            },
            '& > :last-child': {
                fontSize: '0.938rem',
                fontWeight: 500,
                color: '#3A3A3B',
                lineHeight: '17.25px'
            }
        }
    },
    typeTag: {
        display: "flex",
        alignItems: "center",
    },
    type1: {
        textAlign: 'center',
        ...theme.typography.tag,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.tag.success,
        minWidth: 80,
        minHeight: 25,
        padding: '7px 14px 7px 14px',
        borderRadius: '12.5px'
    },
    type2: {
        textAlign: 'center',
        ...theme.typography.tag,
        backgroundColor: theme.palette.tag.fail,
        color: '#C82525',
        minWidth: 80,
        minHeight: 25,
        padding: '7px 14px 7px 14px',
        borderRadius: '12.5px'
    },
}));

export default function AuthTimeline(props) {

    const classes = timelineStyles();
    const {t} = useTranslation();
    const page = 0;
    const size = 15;

    const [authData, setAuthData] = useState(null);
    const [clients, setClients] = useState([]);

    const getClientNameByKey = (key) => {
        let name = null;
        if (clients !== null) {
            clients.filter(c => c.clientKey === key).map(client => {
                name = client.clientName
            })
        }
        return name;
    }

    const fetchData = ({page, size}) => {
        api.getMyAuthHistoryList(null, page, size, 'REG_DT,DESC')
            .then(data => {
                setAuthData(data.data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        api.getClientCodes()
            .then((data) => {
                setClients(data);
            });
        fetchData({page, size});
    }, []);

    const [pageNo, setPageNo] = useState(0);
    const [update, setUpdate] = useState(false);
    const [prevPage, setPrevPage] = useState(0);

    const scrollbar = useRef(null);
    const fadeValue = useRef(
        {
            old: false,
            new: false
        }
    );

    const renderTrackVertical = () => {
        return (<div className={classes.track}/>)
    }

    const handleScrollDown = () => {
        const {scrollTop, scrollHeight, clientHeight} = scrollbar.current.getValues();
        fadeValue.current.old = fadeValue.current.new;
        fadeValue.current.new = scrollTop > 200;
        const pad = 10;
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        if (t > 1 && pageNo < 3) {          // limit to load history records up until 3rd page only (60개)
            setPageNo(pageNo + 1);
            setUpdate(!update);
        }
    }

    const scrollBack = () => {
        if (!scrollbar || !scrollbar.current) {
            return;
        }
        const {scrollHeight} = scrollbar.current.getValues();
        let topValue = 0;
        if (pageNo !== 0) {
            topValue = scrollHeight - 700
        }
        scrollbar.current.view.scroll({
            top: topValue,
            behavior: 'smooth',
        });
    }

    useEffect(() => {
        scrollBack();
    }, [update]);

    function convertDate(data, dataType, format) {
        if (data === undefined || data === null) {
            return null;
        }
        if (dataType === 'date') {
            let dateFormat = 'YYYY-MM-DD';
            if (format !== undefined) {
                dateFormat = format;
            }
            if (moment(data, 'YYYY-MM-DD hh:mm:ss Z').isSame(moment(), 'day')) {
                return "TODAY"
            }
            return moment(data, 'YYYY-MM-DD hh:mm:ss Z').utc(false).format(dateFormat);
        } else if (dataType === 'number') {
            if (_.isNumber(data)) {
                return new Intl.NumberFormat().format(data);
            }
        }
        return data;
    }

    function AuthTimelineContent() {

        const fetchMoreData = () => {
            api.getMyAuthHistoryList(null, pageNo, size, 'REG_DT,DESC')
                .then(data => {
                    if (pageNo > prevPage) {
                        setPrevPage(pageNo);
                        setAuthData(authData.concat(data.data));
                    }
                })
                .catch(err => {
                    return err;
                });
        }

        useEffect(() => {
            if (pageNo !== 0) {
                fetchMoreData();
            }
        }, [update]);

        return (
            <Timeline className={classes.timeline} position="right">
                {authData !== null &&
                    authData.map((item, idx) => {
                        return (
                            <TimelineItem key={idx}>
                                <TimelineSeparator>
                                    <TimelineConnector/>
                                    <TimelineDot/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent className={classes.timelineContent}>
                                    <Box>
                                        <Typography
                                            variant='subtitle2'>{item.regDt ? convertDate(item.regDt, 'date', 'MMM DD, yyyy / A hh:mm') : '-'}</Typography>
                                        <Typography>{item.clientName ? item.clientName : item.clientKey ? getClientNameByKey(item.clientKey) : ''}</Typography>
                                    </Box>
                                    <Box className={classes.typeTag}>
                                        <Typography
                                            className={item.status === AuthResult.AuthSuccess ? classes.type1 : classes.type2}
                                            display='block'>
                                            {t('Home.' + CommonCodeValue.getText(item.status))}
                                        </Typography>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })
                }
            </Timeline>
        )
    }

    return (
        props.isOpen ?
            <Slide direction='left' in={true} timeout={400}>
                <Box className={classes.authBar}>
                    <Typography variant='h1' className={classes.authTitle}>{t('Home.AuthHistory')}</Typography>
                    <Scrollbars className={classes.scrollBar}
                                ref={scrollbar}
                                onScrollStop={handleScrollDown}
                                autoHide
                                renderTrackVertical={renderTrackVertical}
                                renderTrackHorizontal={props => <div {...props} style={{display: 'none'}}
                                                                     className="track-horizontal"/>}
                    >
                        <AuthTimelineContent/>
                    </Scrollbars>
                </Box>
            </Slide> : ''
    )
}