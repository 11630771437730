import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {Button} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Typography from "@material-ui/core/Typography";
import {Collapse, Dialog, DialogContent} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment";
import { StaticDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, {Keyboard, Mousewheel} from "swiper";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    dialog: {
        padding: 0,
        '& .MuiDialog-paper': {
            border: '1px solid #1E1D30',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.3)',
            borderRadius: 5,
            overflow: 'hidden'
        },
        '& .MuiDialogContent-root': {
            minWidth: 371,
            maxHeight: 502,
            background: '#FAFAFC',
            padding: 30,
            borderRadius: 5,
            overflow: 'hidden'
        },
        '& *': {
            fontFamily: 'Poppins'
        }
    },
    dateButton: {
        width: '100%',
        height: 45,
        padding: '12px 15px',
        marginBottom: 5,
        backgroundColor: '#fff',
        color: '#6C758A',
        border: '1px solid #C1C9DB',
        justifyContent: 'flex-start',
        textTransform: 'none',
        '&[aria-selected="true"]': {
            border: '1px solid #4046BC',
            '& .MuiButton-endIcon > svg': {
                color: '#4046BC'
            }
        },
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&:last-of-type': {
            marginTop: 5
        },
        '& .MuiButton-label': {
            display: 'flex',
            '& > p': {
                flex: 1,
                textAlign: 'start',
                color: '#4046BC',
                fontSize: 14
            }
        },
        '& .MuiButton-endIcon > svg': {
            fontSize: 30,
            color: '#6C758A',
            stroke: '#fff',
            strokeWidth: 0.7
        }
    },
    collapseArea: {
        '& .MuiCollapse-wrapperInner > div': {
            background: '#fff',
            border: '1px solid #4046BC',
            borderRadius: 5
        }
    },
    todayBtn: {
        width: 45,
        height: 25,
        minWidth: 25,
        fontSize: 11,
        fontWeight: 600,
        borderRadius: 5,
        color: '#4046BC',
        backgroundColor: '#EAE6FF',
        border: 'none',
        position: 'absolute',
        marginLeft: 240,
        marginTop: 17,
        textTransform: 'none',
        lineHeight: '16px',
        '&:hover': {
            backgroundColor: '#EAE6FF'
        },
        '&[disabled]': {
            backgroundColor: '#E7E6EE',
            color: '#8E8C99'
        }
    },
    calendarBox: {
        width: '100%',
        minHeight: 272,
        marginBottom: 5,
        "& .MuiCalendarPicker-root": {
            overflow: "hidden",
            maxWidth: '100%',
        },
        '& .MuiPickerStaticWrapper-content': {
            minWidth: 306,
            borderRadius: 5,
            '& .css-epd502': {
                width: 306
            }
        },
        "& .MuiCalendarPicker-root > :first-child > :first-child": {
            marginRight: 0,
            "& > :not(:last-child)": {           // header month, header year
                color: '#3A3A3B',
                fontSize: 16,
                fontWeight: 600
            },
            '& > :last-child': {
                display: 'none'
            }
        },
        "& .PrivatePickersSlideTransition-root": {              // calendar body
            minHeight: 200,
            '& div[role=row]': {
                margin: '5px 0'
            },
            "& > * > * > *": {          // all grid cells (days)
                width: 40,
                textAlign: 'center'
            }
        },
        "& .MuiTypography-root": {      // weekdays
            color: '#3A3A3B',
            fontWeight: 600,
            width: 40,
            height: 33,
            fontSize: 13,
            margin: 0
        },
        "& .MuiPickersDay-root": {      // day button style
            color: '#3A3A3B',
            width: 25,
            height: 25,
            fontSize: 12,
            lineHeight: '12px',
            borderRadius: 5,
            "&:hover": {
                border: '1px solid #3C39CB !important',
                backgroundColor: '#fff !important',
                color: '#4046BC !important'
            }
        },
        "& .MuiPickersDay-root.Mui-selected": {
            color: '#fff',
            fontWeight: 600,
            backgroundColor: '#4046BC',
            transition: 'none',
            '&:focus': {
                color: '#fff',
                backgroundColor: '#4046BC'
            }
        },
        '& .MuiPickersDay-today': {
            color: '#4046BC',
            backgroundColor: '#EAE6FF',
            border: 'none !important',
            fontWeight: 600,
            '&:hover': {
                color: '#4046BC !important',
                backgroundColor: '#EAE6FF !important'
            }
        },
        '& .MuiPickersDay-dayOutsideMonth': {
            color: '#B9BDC5'
        },
        "& .MuiPickersDay-root.Mui-disabled": {
            color: '#C9CDD6',
        },
        "& .MuiPickersArrowSwitcher-root": {          // arrow buttons < >
            "& > button": {
                color: '#3A3A3B',
                width: 17,
                "&:disabled": {
                    color: '#C9CDD6',
                },
                "& .MuiSvgIcon-root": {
                    fontSize: 20
                },
                '&:hover': {
                    background: 'transparent'
                }
            },
            "& > :nth-child(2)": {
                width: 5
            }
        },
        '& .MuiDialogActions-root': {
            display: 'none'
        },
        '& .PrivatePickersYear-yearButton Mui-selected': {
            backgroundColor: '#4046BC',
            '&:hover': {
                border: '1px solid #3C39CB !important',
                backgroundColor: '#fff !important',
                color: '#4046BC !important'
            }
        }
    },
    actionBtn: {
        marginTop: 15,
        columnGap: 5,
        '& > button': {
            width: 153,
            height: 45,
            borderRadius: 5,
            textTransform: 'none',
            fontSize: 13,
            color: '#FFF'
        },
        '& > :first-child': {
            backgroundColor: '#BABACE'
        },
        '& > :last-child': {
            backgroundColor: '#4046BC'
        }
    },

    clockBox: {
        width: '100%',
        minHeight: 277,
        padding: '15px 25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            fontSize: 18,
            fontWeight: 600,
            textAlign: 'center',
            color: '#3A3A3B',
            height: 35
        }
    },
    timeFrame: {
        position: 'absolute',
        display: 'flex',
        columnGap: 25,
        '& > div': {
            height: 50,
            width: 70,
            borderTop: '1px solid #C1C9DB',
            borderBottom: '1px solid #C1C9DB',
            backgroundColor: 'transparent'
        },
        '& .hour > span': {
            transform: 'translate(80px, 9px)',
            fontSize: 18,
            fontWeight: 600,
            position: 'absolute'
        }
    },

    slideTimePicker: {
        display: 'flex',
        columnGap: 25,
        alignItems: 'center'
    },
    container: {
        height: 250,
        width: 70,
        '& .swiper-slide': {
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 18,
            fontWeight: 600,
            color: '#BBBBBB'
        },
        '& .swiper-slide-active': {
            color: '#3A3A3B'
        }
    },
    containerMasked: {
        maskImage: 'linear-gradient(transparent, black 20%, black 80%, transparent 100%)'
    }

}))

/****
 * 날짜와 시간을 선택하기 위한 모달 다이얼로그
 * @param open : boolean
 * @param onClose : function
 * @param minDate : string date format 'YYYY-MM-DD'
 * @param maxDate : string date format 'YYYY-MM-DD'
 * @param onSubmit : function passes a string containing selected date and time values, date format => "'YYYY-MM-DD HH:mm'"
 * @param dateValue : string|date default date
 * @param timeValue : object default time => {hour : number, min : number}
 ****/
export default function DateTimeDialog({open, onClose, onSubmit, minDate, maxDate, dateValue, timeValue}) {

    const classes = useStyles();
    const [openCalendar, setOpenCalendar] = useState(true);
    const {t} = useTranslation();

    const [date, setDate] = useState(null);
    const [dateStr, setDateStr] = useState(null);

    const currentMonth = new Date().getMonth();
    const today = new Date();

    const [time, setTime] = useState('AM 12 : 00');

    const handleDateTimeSwitch = () => {
        setOpenCalendar(!openCalendar)
    }

    const onChangeDate = (date) => {
        if (date) {
            setDate(date);
            setDateStr(moment(date).format('YYYY.MM.DD'));
        }
    }

    function selectToday() {
        onChangeDate(today)
    }

    const onApply = (date) => {
        onSubmit(moment(date, 'YYYY.MM.DD A HH : mm').format('YYYY-MM-DD HH:mm'));
        onClose();
    }

    useEffect(() => {
        if (!openCalendar) setOpenCalendar(true);
        if (dateValue) {
            setDate(moment(dateValue).format('YYYY-MM-DD'));
            setDateStr(moment(dateValue).format('YYYY.MM.DD'));
        }
    }, [open]);


    let isOutOfRange = false;
    if (minDate !== null && maxDate !== null) isOutOfRange = !(moment(new Date, 'YYYY-MM-DD').isBetween(moment(minDate, 'YYYY-MM-DD'), moment(maxDate, 'YYYY-MM-DD'), 'day', '[]'));
    if (minDate === null && maxDate !== null) isOutOfRange = !(moment(new Date, 'YYYY-MM-DD').isSameOrAfter(moment(minDate, 'YYYY-MM-DD')));
    if (minDate !== null && maxDate === null) isOutOfRange = !(moment(new Date, 'YYYY-MM-DD').isSameOrBefore(moment(maxDate, 'YYYY-MM-DD')));
    if (minDate === undefined && maxDate === undefined) isOutOfRange = false;

    return (
        <Dialog open={open} onClose={onClose} className={classes.dialog}>
            <DialogContent>
                <Button className={classes.dateButton}
                        startIcon={<DateRangeIcon/>}
                        endIcon={<ExpandMoreIcon/>}
                        disableRipple
                        aria-selected={openCalendar}
                        onClick={handleDateTimeSwitch}>
                    <Typography>{dateStr}</Typography>
                </Button>

                <Collapse in={openCalendar} className={classes.collapseArea}>

                    {openCalendar &&
                        <Button className={classes.todayBtn}
                                disabled={isOutOfRange}
                                onClick={selectToday}>Today</Button> }

                    <Box className={classes.calendarBox}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <StaticDatePicker value={date}
                                              openPicker={true}
                                              rawValue={null}
                                              onChange={onChangeDate}
                                              defaultCalendarMonth={currentMonth}
                                              showDaysOutsideCurrentMonth
                                              showToolbar={false}
                                              minDate={minDate ? Date.parse(minDate) : undefined}
                                              maxDate={maxDate ? Date.parse(maxDate) : undefined}
                                              renderInput={() => {}}
                                              disableFuture/>
                        </LocalizationProvider>
                    </Box>
                </Collapse>

                <Button className={classes.dateButton}
                        startIcon={<AccessTimeIcon/>}
                        endIcon={<ExpandMoreIcon/>}
                        disableRipple
                        aria-selected={!openCalendar}
                        onClick={handleDateTimeSwitch}>
                    <Typography>{time}</Typography>
                </Button>

                <Collapse in={!openCalendar} className={classes.collapseArea}>
                    <Box className={classes.clockBox}>

                        <Box className={classes.timeFrame} >
                            <Box className={'hour'}>
                                <span>:</span>
                            </Box>
                            <Box/>
                            <Box/>
                        </Box>

                        <SlideTimePicker onChangeTime={(value) => setTime(value)} timeValue={timeValue} />

                    </Box>
                </Collapse>

                <Box display='flex' className={classes.actionBtn}>
                    <Button onClick={onClose} disableRipple>{t("EventHistory.DateTime.Cancel")}</Button>
                    <Button onClick={() => onApply(dateStr + ' ' + time)} disableRipple>{t("EventHistory.DateTime.Apply")}</Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

SwiperCore.use([Keyboard, Mousewheel]);

function SlideTimePicker({onChangeTime, timeValue}) {

    const classes = useStyles();

    const [hourIdx, setHourIdx] = useState(0);
    const [minIdx, setMinIdx] = useState(0);
    const [periodIdx, setPeriodIdx] = useState(0);

    const hours = Array.from(Array(12), (_, index) => String(index + 1).padStart(2, '0'));
    const minutes = Array.from(Array(60), (_, index) => String(index).padStart(2, '0'));
    const periods = ['AM', 'PM']

    const swiperSettings = {
        slidesPerView: 'auto',
        allowTouchMove: true,
        simulateTouch: true,
        centeredSlides: true,
        direction: 'vertical',
        grabCursor: true,
        speed: 500,
        slideToClickedSlide: true
    }

    const onSlideChange = (timeType) => (swiper) => {

        if (timeType === 'hour') setHourIdx(swiper.realIndex);
        if (timeType === 'minute') setMinIdx(swiper.realIndex);
        if (timeType === 'period') setPeriodIdx(swiper.realIndex);

        swiper.slideToClosest(500);
    }

    useEffect(() => {

        let time = periods[periodIdx] + ' ' + hours[hourIdx] + ' : ' + minutes[minIdx];
        onChangeTime(time);

    }, [hourIdx, minIdx, periodIdx]);


    return (
        <Box className={classes.slideTimePicker}>

            <Swiper className={`${classes.container} ${classes.containerMasked}`}
                    {...swiperSettings}
                    loop={true}
                    freeMode={{
                        enabled: true,
                        momentum: false,
                        sticky: true
                    }}
                    initialSlide={timeValue ? (timeValue.hour % 12) - 1 : 11}
                    mousewheel={{ sensitivity: 0.5 }}
                    onActiveIndexChange={onSlideChange('hour')}
            >
                { hours.map(((hour, idx) =>
                        <SwiperSlide key={idx}>{hour}</SwiperSlide>
                ))}
            </Swiper>

            <Swiper className={`${classes.container} ${classes.containerMasked}`}
                    {...swiperSettings}
                    loop={true}
                    initialSlide={timeValue ? timeValue.min : 0}
                    freeMode={{
                        enabled: true,
                        momentum: false,
                        sticky: true
                    }}
                    mousewheel={{ sensitivity: 0.5 }}
                    onActiveIndexChange={onSlideChange('minute')}
            >
                { minutes.map(((min, idx) =>
                        <SwiperSlide key={idx}>{min}</SwiperSlide>
                ))}
            </Swiper>

            <Swiper className={classes.container}
                    {...swiperSettings}
                    mousewheel={{ sensitivity: 0.5 }}
                    initialSlide={timeValue && timeValue.hour > 12 ? 1 : 0}
                    onActiveIndexChange={onSlideChange('period')}
            >
                { periods.map(((period, idx) =>
                        <SwiperSlide key={idx}>{period}</SwiperSlide>
                ))}
            </Swiper>

        </Box>
    )
}