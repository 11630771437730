import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {Typography} from "@material-ui/core";
import SelectBox from "./SelectBox";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    select: {
        minWidth: 250,
        '& .MuiSelect-select': {
            maxHeight: 36,
            minHeight: 36,
            height: 36,
            color: '#565568',
            fontSize: 12
        },
        '& .MuiOutlineInput-input' : {
            color: '#565568',
            fontSize: 12
        },
        '& > p': {
            color: '#121212',
            fontSize: 13,
            fontWeight: 500
        }
    },
    selectBox: {
        ...theme.select,
        minWidth: 250,
        maxWidth: '100%',
        width: 'auto',
        padding: '0px !important',
        '& .MuiSelect-select': {
            height: '26px !important',
            '& > div > div > svg': {
                color: '#8D96AB'
            },
            '&:hover > div > div > svg': {
                color: '#3C39CB'
            }
        },
        '& > .MuiSvgIcon-root': {
            width: 0,
            height: 0
        },
        '& > .Mui-disabled': {
            backgroundColor: '#F5F6FA',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#C1C9DB !important'
            }
        },
        '& .MuiInputBase-root': {
            height: "36px !important"
        },
        '& fieldset': {
            height: "36px !important",
            top: 0,
            '& legend': {
                display: 'none'
            }
        }
    }
}));


export default function FilterSelectBox({option}) {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Box className={classes.select} display='flex' flexDirection='column'>
            <Typography>{t(option.label)}</Typography>
            <SelectBox className={classes.selectBox}
                       onChange={(e) => option.onChange(e.target.value)}
                       title={t('CommonCode.ALL')}
                       addAll={true}
                       disabled={option.disabled ?? false}
                       contents={option.items}
                       value={option.value}
            />
        </Box>
    )
}