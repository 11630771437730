import React, {useRef, useState} from 'react';
import {useStores} from "../common/store";
import Box from "@material-ui/core/Box";
import ScrollView from "./ScrollView";
import {makeStyles} from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import {MenuLogo} from "./Logo";
import GroupItems from "./GroupItems";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
    sidePanel       : {
        position       : 'relative',
        display        : 'flex',
        flexDirection  : 'column',
        height         : '100vh',
        backgroundColor: '#1E1D30',
        transition     : 'width 0.4s ease 0s',
        width          : 100,
        overflowY      : 'none',
        '&.active'     : {
            width          : 265,
            backgroundColor: '#1E1E2D',
            justifyContent : 'normal',
            '& .logoBox'   : {
                display  : 'block',
                alignSelf: 'flex-start',
            },
            '& .copyBox'   : {
                display: 'flex',
            },
            '& .toggleBtn' : {
                height  : 65,
                minWidth: 20,
                position: 'fixed',
                zIndex  : 100,
                // transition: 'all 0ms 50ms',
                transform: 'translate(260px, 0)',
                '& svg'  : {
                    transform: 'rotate(180deg)'
                }
            }
        }
    },
    logoBox         : {
        margin : (theme.type==='gccs' ? '40px 0  20px 15px' : '40px 0 20px 20px'),
        display: 'none',
    },
    toggleMenuButton: {
        padding                       : 0,
        height                        : 125,
        fontSize                      : 29,
        borderRadius                  : 0,
        color                         : theme.palette.text.gray,
        '&:hover'                     : {
            backgroundColor: 'transparent',
            color          : '#FFF'
        },
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    copyBox         : {
        position     : 'absolute',
        bottom       : 0,
        width        : '100%',
        display      : 'none',
        flexDirection: 'column'
    },
    dividingNavLine : {
        width          : '100%',
        height         : '1px',
        backgroundColor: '#333247',
        border         : 'none',
        margin         : '2rem 0'
    },
    copyright       : {
        display       : 'flex',
        justifyContent: 'center',
        marginTop     : '1rem',
        marginBottom  : '2.5rem',
        '& > p'       : {
            color     : '#6E6E8E',
            fontFamily: 'Poppins',
            fontSize  : '0.75rem',
            fontWeight: 600,
            minWidth  : 180
        }
    }
}));

/** 왼쪽 메뉴: 화면 선택 **/
export default function SideMenu({item, openItem, isOpen}) {
    const styles = useStyles();

    const [openedPanel, setOpenedPanel] = useState(false);

    const toggleOpenPanel = () => {
        setOpenedPanel(!openedPanel);
        isOpen(!openedPanel)
    }

    return (
        <Box className={`${styles.sidePanel} ${openedPanel ? 'active' : ''}`}>
            <ScrollView isUpdate={true}>

                <Box className={`${styles.logoBox} logoBox`}>
                    <MenuLogo/>
                </Box>

                <Button className={`${styles.toggleMenuButton} toggleBtn`}
                        disableRipple
                        onClick={toggleOpenPanel}
                >
                    <ChevronRightIcon fontSize='inherit'/>
                </Button>

                <GroupItems items={item} openItem={openItem} openedPanel={openedPanel}/>

                <Box className={`${styles.copyBox} copyBox`}>
                    <hr className={`${styles.dividingNavLine} `}/>
                    <Box className={`${styles.copyright} `}>
                        <Typography variant='body2' align='left'>
                            {'Copyright © '}
                            <Link color='inherit' href='https://fnsvalue.co.kr' target='_blank'>FNSVALUE Co Ltd.</Link>
                            <br/>
                            {' All Rights Reserved.'}
                        </Typography>
                    </Box>
                </Box>
            </ScrollView>
        </Box>
    )
}
