import React, {useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";

export default function ImageGrid() {
    const [hoveredInfo, setHoveredInfo] = useState({hoveredKey: 0, isHovered: false});
    const theme = useTheme();

    const gridStyle = makeStyles((theme) => ({
        imageGrid: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        gridBox: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)', // 3열
            gridTemplateRows: 'repeat(2, 1fr)', // 2행
            gap: '10px', // 이미지 간격
        },
        gridContent: {
            display: 'flex',
            justifyContent: 'center', // 가로 중앙 배치
            alignItems: 'center', // 세로 중앙 배치
            position: 'relative'
        },
        gridImg: { // 부모 요소의 크기를 초과하지 않도록 조절
            maxWidth: '100%',
            maxHeight: '100%',
            width: 60,
            height: 'auto'
        }
    }));

// 가상의 이미지 데이터
    const imageData = [
        { id: 1, url: `${require('../images/fnsv/privacyPolicy/privacy_3_2.png')}`, subTitle: '일반 개인정보 수집', desc: '수집하는 개인정보는 다음과 같습니다.', items: ["이름", "로그인ID", "휴대전화번호", "이메일", "기기정보"] },
        { id: 2, url: `${require('../images/fnsv/privacyPolicy/privacy_1.png')}`, subTitle: '개인정보 처리목적', desc: '다음의 목적을 위해 개인정보를 처리합니다.', items: ["BSA 서비스"] },
        { id: 3, url: `${require('../images/fnsv/privacyPolicy/privacy_2.png')}`, subTitle: '개인정보의 보유기간', desc: '보유기간은 다음과 같습니다.', items: ["회원 탈퇴 시까지"] },
        { id: 4, url: `${require('../images/fnsv/privacyPolicy/privacy_4.png')}`, subTitle: '개인정보의 제3자 제공', desc: '다음과 같은 경우에만 제3자 제공을 하고 있습니다.', items: ["재난", "감염병", "이외의 기타 긴급상황"] },
        { id: 5, url: `${require('../images/fnsv/privacyPolicy/privacy_5.png')}`, subTitle: '처리위탁', desc: '회사는 현재 개인정보 처리업무를 위탁하고 있지 않습니다.', items: [] },
        { id: 6, url: `${require('../images/fnsv/privacyPolicy/privacy_call.png')}`, subTitle: '고충처리부서', desc: '고충처리부서에 대한 안내입니다.', items: ["부서명: 솔루션사업부", "연락처: 02-303-3885"] },
    ];

    const classes = gridStyle();

    return (
        <div className={classes.imageGrid}>
            <div style={{fontSize: '16px',textAlign: 'center'}}>
                <b>[ 주요 개인정보 처리 표시(라벨링) ]</b>
            </div>

            <div className={classes.gridBox}>
                {imageData.map(image => (
                    <div key={image.id} className={classes.gridContent}>
                        <div style={{textAlign: 'center'}}
                             onMouseEnter={() => setHoveredInfo({hoveredKey: image.id, isHovered: true})}
                             onMouseLeave={() => setHoveredInfo({hoveredKey: image.id, isHovered: false})}>
                            <img src={image.url} alt=""/>
                            <div>
                                {image.subTitle}
                            </div>
                            {hoveredInfo.isHovered && hoveredInfo.hoveredKey == image.id && (
                                <div style={{
                                    position: 'absolute',
                                    backgroundColor: '#FFF',
                                    border: '1px solid #000',
                                    borderRadius: '5px',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    minHeight: '100%',
                                    zIndex: 99
                                }}>
                                    <div style={{width:'100%', fontSize:'16px', marginTop: '10px'}}><b>{image.subTitle}</b></div>
                                    <div style={{marginLeft: '30px', textAlign: 'left'}}>
                                        <p>{image.desc}</p>
                                        {image.items.length > 0 && <ul>
                                            {image.items.map((item, index) =>
                                                <li key={index}>• {item}</li>)}
                                            </ul>
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>

            <div style={{fontSize: '13px', margin: '5px 0px 30px 0px'}}>
                ※ 기호에 마우스 커서를 대시면 세부 사항을 확인할 수 있으며, 자세한 내용은 아래의 개인정보 처리방침을 확인하시기 바랍니다.
            </div>
        </div>
    );
};