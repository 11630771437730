import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'mobx-react';
import {ThemeProvider} from '@material-ui/styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './common/i18n'; // 지우지 말것, 다국어지원 관련 초기화 코드 로딩됨
import store from './common/store';
import CssBaseline from '@material-ui/core/CssBaseline';

ReactDOM.render(
    <React.StrictMode>
        <Provider ds={store}>
            <ThemeProvider theme={store.theme}>
                <CssBaseline/>
                <App/>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
