import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ClearIcon from '@material-ui/icons/Clear';

import {ReactComponent as SearchIcon} from '../images/search_icon.svg';
import {InputAdornment, Paper} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    searchBox: {
        display:'flex',
        minWidth: 280,
        backgroundColor: '#F1F2F6',
        [theme.breakpoints.down('sm')]: {
            minWidth: 200
        }
    },
    searchIconButton:  {
        paddingLeft: '1.25rem',
        paddingRight: '0.938rem',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '0.75rem'
        }
    },
    searchIcon: {
        [theme.breakpoints.down('sm')]: {
            width: 18
        }
    },
    inputContainer: {
        flex:1,
        color: theme.palette.text.secondary,
        '& > input::placeholder, & > input::-webkit-input-placeholder': {
            opacity: 1
        }
    },
    input: {
        color: `${theme.palette.text.secondary} !important`
    },
    clearButton: {
        color: theme.palette.text.secondary
    }
}));

function EndAdornment({onClick, text}) {
    if (!text) {
        return null
    }

    const classes = useStyles();

    return (
        <InputAdornment position={"end"}>
            <IconButton onClick={onClick}>
                <ClearIcon className={classes.clearButton}/>
            </IconButton>
        </InputAdornment>
    );
}

export default function SearchBox({value, defaultValue, onChange, doQuery, placeholder, className}) {
    if(!value){
        value = '' ;
    }

    const classes = useStyles();
    const [text, setText] = useState(value);

    const onKeyPress = (evt) => {
        if (evt.key === 'Enter') {
            doQuery();
            evt.preventDefault();
        }
    }
    const onChangeEvent = (event) => {
        if(onChange != undefined) {
            onChange(event.target.value);
        }
        setText(event.target.value);
    }

    useEffect(() => {
        setText(value);
    }, [value])

    const onClear = () => {
        if(onChange != undefined) {
            onChange('');
        }
        setText('');
    }

    return (
        <Paper component="form" elevation={0} className={`${classes.searchBox} ${className}`}>
            <IconButton className={classes.searchIconButton} aria-label="search button" onClick={doQuery}>
                <SearchIcon className={classes.searchIcon} />
            </IconButton>
            <InputBase
                className={classes.inputContainer}
                placeholder={placeholder}
                defaultValue={defaultValue}
                value={text}
                onChange={onChangeEvent}
                onKeyPress={onKeyPress}
                type='text'
                endAdornment={<EndAdornment onClick={onClear} text={text}/>}
            />
        </Paper>
    )
}
