import React, {useEffect, useState, useRef} from 'react';
import OtpInput from 'react-otp-input';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import api from "../../common/api";
import {OtpType, ErrCode} from "../../common/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

const dialogStyle = makeStyles((theme) => ({
    root: {
        padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
    },
    title: {},
    height: {
        height: '3.5rem'
    },
    label: {
        color: theme.palette.text.secondary,
    },
    content: {
        minWidth: 450,
        marginTop: -theme.spacing(2),
        marginBottom: -theme.spacing(1),
        '& > *': {
            margin: theme.spacing(1),
            justifyContent: 'center'
        }
    },
    userInfoForm: {
        '& > *': {
            display: "flex",
            width: '-webkit-fill-available',
            margin: theme.spacing(1)
        }
    },
    countryNumField: {
        marginRight: theme.spacing(1),
        height: '-webkit-fill-available'
    },
    submitBtn: {
        fontSize: '1rem',
        fontWeight: 'normal',
        height: '3.5rem',
        minWidth: '49%'
    },
    otpInput: {
        '& > input': {
            width: `${theme.spacing(5)}px !important`,
            height: theme.spacing(5),
            margin: theme.spacing(1),
            backgroundColor: 'transparent',
            border: '1px solid rgba(34, 34, 34, 0.23)',
            borderRadius: theme.spacing(1),
            color: 'rgb(34,34, 34)',
        },
        '& > input:focus-visible': {
            outline: 'none',
            border: '1px solid rgba(34, 34, 34)'
        }
    },
    otpError: {
        border: '1px solid #f44336 !important',
    },
    errMsg: {
        color: '#f44336',
        fontWeight: "bold"
    },
    progressMsg: {
        color: '#2e6ec6',
        fontWeight: "bold"
    }
}));

function StatusAlert({status, onClose}) {
    return (
        <Alert severity="error" onClose={onClose}>{status}</Alert>
    );
}

function ProgressAlert({status}) {
    return (
        <Alert severity="info">{status}</Alert>
    );
}

export default function OtpPage({otpType, handedUserKey = null, open, onClose, onSuccess, numInputs}) {
    const dialogStyles = dialogStyle();
    const {t} = useTranslation();
    const masterClientKey = api.MASTER_CLIENT_KEY;

    const [otp, setOtp] = useState(null);
    const [otpErr, setOtpErr] = React.useState(false);
    const [totpUserKey, setTotpUserKey] = useState(null);
    const otpSeq = React.useRef(null);

    const [errMsg, setErrMsg] = React.useState(null);
    const [progressMsg, setProgressMsg] = React.useState(null);

    const userInfo = useRef(
        {
            userKey: handedUserKey,
            name: "",
            mail: "",
            phone: "",
        }
    );

    const isError = errMsg != null;
    const isProcess = (progressMsg != null) && (!isError);

    const onErrorHandler = () => {
        setOtpErr(false);
        setErrMsg(null);
        setProgressMsg(null);
    }

    const setValue = (target) => {
        setOtp(target);
        if (target) {
            setOtpErr(false);
        }
    }

    const onTotpUserKeyChange = (event) => {
        setTotpUserKey(event.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (otpType === OtpType.DeviceTOTP && !totpUserKey) {
            setErrMsg(t('OtpPage.NoUserKey'));
            return;
        }

        if (!otp || otp.length != numInputs) {
            setOtpErr(true);
            setErrMsg(t('OtpPage.NoOtp'));
            return;
        }

        if (otpType === OtpType.DeviceOTP) {
            // OTP 검증
            if (handedUserKey) {
                api.postOtpUserVerify(masterClientKey, handedUserKey, otp)
                    .then(res => {
                        if (res.rtCode === ErrCode.RT_SUCCESS) {
                            setOtpErr(false);
                            setErrMsg(null);
                            setProgressMsg(t('AuthStatus.AuthCompleted'));
                            onSuccess({token: res.data});
                        }
                    })
                    .catch(err => {
                        setOtpErr(true);
                        setErrMsg(err.rtMsg);
                    });
            } else {
                api.getOtpVerify(masterClientKey, otp)
                    .then(res => {
                        if (res.rtCode === ErrCode.RT_SUCCESS) {
                            onSuccess({otpUserKey: res.data});
                            onClose();
                        }
                    })
                    .catch(err => {
                        setOtpErr(true);
                        setErrMsg(err.rtMsg);
                    });
            }
        } else if (otpType === OtpType.DeviceTOTP) {
            // TOTP 검증
            api.postTotpUserVerify(masterClientKey, totpUserKey, otp)
                .then(res => {
                    if (res.rtCode === ErrCode.RT_SUCCESS) {
                        setOtpErr(false);
                        setErrMsg(null);
                        setProgressMsg(t('AuthStatus.AuthCompleted'));
                        onSuccess({token: res.data});
                    }
                })
                .catch(err => {
                    setOtpErr(true);
                    setErrMsg(err.rtMsg);
                });
        } else {
            if (!otpSeq.current) {
                setErrMsg(t('OtpPage.NoDeviceMessage'));
            }
        }
    }

    useEffect(() => {
        if (open) {
            setTotpUserKey(handedUserKey);
            userInfo.current.userKey = handedUserKey;
        } else {
            setOtp(null);
            setOtpErr(false);
            setErrMsg(null);
            setProgressMsg(null);
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`OTP dialog`}
            className={dialogStyles.root}
            maxWidth='md'
        >
            <DialogTitle id={`OTP_dialog_id`} className={dialogStyles.title}>
                {otpType === OtpType.DeviceTOTP ? t('OtpPage.Title.Totp') : t('OtpPage.Title.Basic')}
            </DialogTitle>
            <form>
                <DialogContent className={dialogStyles.content}>
                    {otpType === OtpType.DeviceTOTP &&
                        <Box className={dialogStyles.userInfoForm}>
                            <TextField variant="outlined"
                                       required
                                       name="userKey"
                                       label="User"
                                       value={totpUserKey}
                                       disabled={isProcess}
                                       onChange={onTotpUserKeyChange}
                                       onKeyPress={(e) => {
                                           e.key === 'Enter' && onSubmit(e)
                                       }}
                            />
                        </Box>
                    }

                    {OtpType.isDeviceOTP(otpType) && <Typography variant={'subtitle1'}>{t('OtpPage.Message')}</Typography>}
                    <OtpInput
                        className={dialogStyles.otpInput}
                        value={otp}
                        onChange={setValue}
                        numInputs={numInputs}
                        separator={<span>-</span>}
                        isInputNum={true}
                        hasErrored={otpErr}
                        errorStyle={dialogStyles.otpError}
                        shouldAutoFocus={true}
                    />

                    <Box mt={3} hidden={!isError} className={dialogStyles.height}>
                        <StatusAlert status={errMsg} onClose={onErrorHandler}/>
                    </Box>
                    <Box mt={3} hidden={!isProcess} className={dialogStyles.height}>
                        <ProgressAlert status={progressMsg}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type={"submit"} onClick={onSubmit} color='primary'>{t("Agreement.Detail.OkBtn")}</Button>
                    <Button onClick={onClose}>{t("Agreement.Detail.CancelBtn")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )

}
