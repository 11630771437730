import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {ListItem} from "@material-ui/core";
import {Box, Collapse} from "@mui/material";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import api from "../common/api";

const useStyles = makeStyles((theme) => ({
    menuFullList: {
        [theme.breakpoints.down('xs')]: {
            maxHeight: 400,
            overflowY: 'overlay'
        }
    },
    menuNav: {
        width                                           : '100%',
        color                                           : '#6E6E8E',
        padding                                         : 0,
        '& > li:first-of-type > div > svg:first-of-type': {
            width : 14,
            height: 14,
        },
    },
}));
const menuItemStyles = makeStyles((theme) => ({
    menuGroup: {
        display       : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        alignItems    : 'initial',
        padding       : '0',
        margin        : '10px 0',
        cursor        : 'pointer',
        '& svg'       : {
            width     : 16,
            height    : 16,
            '& > path': {
                fill: '#6E6E8E'
            },
            '& > rect': {
                fill: '#6E6E8E'
            }
        },
        '&.menuFold > div > .MuiListItemText-root,&.menuFold > div > .MuiSvgIcon-root, &.menuFold > div > ul, &.menuFold .MuiCollapse-container':{
            display:'none',
        },
        '&.menuFold > .selectedMenu':{
            borderLeft : 'none',
            paddingLeft: 30,
        },
    },
    menuItem : {
        minHeight       : 40,
        display         : 'flex',
        justifyContent  : 'center',
        alignItems      : 'center',
        padding         : '0 30px',
        '& span'        : {
            fontFamily: 'Poppins',
            fontSize  : '0.875rem',
            minWidth  : 150
        },
        '&.selectedMenu': {
            borderLeft : '6px solid #4046BC',
            paddingLeft: 24,
            color      : '#fff',
            '& > svg'  : {
                '& > path': {
                    fill: '#fff'
                },
                '& > rect:first-of-type': {
                    fill: '#6575E7'
                },
                '& > rect': {
                    fill: '#4046BC'
                }
            },
        },

    },
    menuText : {
        margin    : 0,
        marginLeft: '1.25rem'
    },
    subMenu  : {
        alignSelf: 'center',
        '& ul'   : {
            margin: '15px 0 0 40px',
        },
        '& li'   : {
            height   : 30,
            '&:hover': {
                cursor: 'pointer'
            },
            '& p'    : {
                fontSize  : 13,
                fontFamily: 'Poppins',
                minWidth  : 150
            }
        }
    },
}));


function MenuItem({keys, item, selected, openItem, openedPanel, pathname}) {
    const {t} = useTranslation();
    const history = useHistory();
    const classes = menuItemStyles();
    const {handleClick} = openItem;
    const [openTabIdx, setOpenTabIdx] = useState(0);
    const [openSubMenu, setOpenSubMenu] = useState(selected);

    useEffect(() => {
        if (item.pages && item.url === pathname && openTabIdx !== 0) {
            setOpenTabIdx(0)
        }
        if (item.pages && item.pages.findIndex(u => pathname === u.url) !== -1) {
            setOpenTabIdx(item.pages.findIndex(u => pathname === u.url));
        }
    }, [pathname]);

    useEffect(() => {
        if (openSubMenu !== selected) setOpenSubMenu(selected)
    }, [selected]);

    const onMenuItemClick = (e) => {
        setOpenSubMenu(!openSubMenu);
        if (handleClick) {
            handleClick(e, openItem);
        }
        if (Array.isArray(item.url)) {
            if (item.pages && history.location.state) {
                history.push({
                    pathname: item.url[0],
                    state: api.encodeParams({[history.location.state.split("=")[0]] : item.pages[0].label})
                });
            } else {
                history.push(item.url[0]);
            }
        } else {
            history.push(item.url);
        }
    };

    const onClickSub = (index) => {
        setOpenTabIdx(index);
        history.push({
            pathname: item.pages[index].url
        });
    }

    return (
        <React.Fragment>
            {
                !item.hidden && item.menuAuth &&
                <ListItem disableGutters
                          key={keys}
                          className={`${classes.menuGroup} ${openedPanel ? '' : 'menuFold'}`}
                >
                    <Box display='flex' onClick={onMenuItemClick}
                         className={`${classes.menuItem} ${selected ? 'selectedMenu' : ''}`}>
                        {item?.iconImg?.render()}
                        <ListItemText className={`${classes.menuText}`} primary={t(item.menuName)}/>
                        {item.pages === undefined ?
                            <ChevronRightIcon/>
                            :
                            <ChevronRightIcon style={openSubMenu ? {transform: 'rotate(-90deg)'} : {transform: 'rotate(90deg)'}}/>
                        }
                    </Box>

                    {
                        (item.pages !== undefined ) &&
                        <Collapse  in={openSubMenu && openedPanel} easing={{exit: 'ease-out'}}>
                            <Box className={`${classes.subMenu}`}>
                                <ul>
                                    {item.pages.map((item, idx) => (
                                        <li key={idx}
                                            onClick={() => onClickSub(idx)}
                                            style={{color: openTabIdx === idx ? '#fff' : '#6E6E8E'}}>
                                            <Typography>{t(item.label)}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        </Collapse>
                    }

                </ListItem>

            }
        </React.Fragment>
    )
}

function GroupItem({group, openItem, openedPanel}) {
    const groupItemStyles = useStyles();

    const {pathname} = useLocation();

    let selectedIdx = -1;

    group.items.forEach((item) => {
        if (_.isArray(item.url)) {
            if (_.includes(item.url, pathname)) {
                selectedIdx = item.menuIndex;
            }
        } else {
            if (pathname.includes(item.url)) {
                selectedIdx = item.menuIndex;
            }
        }
        if (pathname.includes(item.url)) {
            selectedIdx = item.menuIndex;
        }
    });

    return (
        <List component="nav" className={groupItemStyles.menuNav}>
            {group?.items.map((item, idx) =>
                <MenuItem
                    key={idx}
                    keys={idx.toString()}
                    item={item}
                    selected={!item.hidden && item.menuIndex === selectedIdx}
                    openItem={openItem}
                    openedPanel={openedPanel}
                    pathname={pathname}
                />
            )}
        </List>
    )
}

export default function GroupItems(props) {
    const {items, openItem, openedPanel} = props;
    const classes = useStyles();

    return (
        <Box className={classes.menuFullList}>
            {items.map((group, idx) =>
                <GroupItem key={idx} group={group} openItem={openItem} openedPanel={openedPanel}/>
            )}
        </Box>
    )
}
