import React from 'react';
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import {Dialog, DialogContent, DialogTitle, DialogActions} from "@mui/material";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const dialogStyles = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #171E31',
            borderRadius: 5,
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)'
        },
        '& .MuiDialogActions-root > :not(:first-of-type)': {
            marginLeft: 6
        }
    },
    dialog_title: {
        backgroundColor: theme.palette.secondary.main,
        height: 60,
        '& .css-i4bv87-MuiSvgIcon-root': {
            width: 27,
            height: 25
        },
        '& > :first-child': {
            color: theme.palette.text.white,
            ...theme.typography.h5,
            fontSize: "1.125rem"
        }
    },
    dialog_content: {
        height: 100,
        width: 350,
        padding: 30,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: '#fff',
        '& > :first-child': {
            margin: '20px 0 0 0',
            fontSize: '1rem',
            fontWeight: 400,
        }
    },
    dialog_buttons: {
        '& > *': {
            width: 120,
            height: 40
        },
        '& > :first-child': {
            ...theme.buttons.secondary,
        },
        '& > :last-child': {
            ...theme.buttons.primary
        }
    }
}));


export default function ConfirmDialog (props) {
    const classes = dialogStyles();

    const {open, onClose, onOk, title, content} = props;


    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
            className={classes.dialog}
        >
            <DialogTitle id={`${title}_dialog_id`} className={classes.dialog_title}>
                <Typography>{t(title===undefined?'ConfirmDialog.Title':title)}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#fff'
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent className={classes.dialog_content}>
                <Typography variant='subtitle1'>{t(content===undefined?'ConfirmDialog.Content':content)}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialog_buttons}>
                <Button onClick={onClose}>{t("ConfirmDialog.CancelBtn")}</Button>
                <Button onClick={onOk} color="primary" autoFocus>{t("ConfirmDialog.OkBtn")}</Button>
            </DialogActions>
        </Dialog>
    )
}
