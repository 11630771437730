import React, {useState} from 'react';
import TabPanels from "../../components/TabPanels";
import InfoPage from "./InfoPage";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import MySitePage from "./MySitePage";
import {useHistory} from 'react-router';
import UserHistoryPage from "../events/UserHistoryPage";
import api from "../../common/api";
import AuthHistoryPage from "../events/AuthHistoryPage";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position: 'relative',
    },
    tabContent: {
        margin: '40px 170px 60px 170px',
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20
        }
    },
}));

export default function MyPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const [tabIndex, setTabIndex] = useState(props.tabIdx ? props.tabIdx : 0);

    let today = moment(new Date()).format('YYYY-MM-DD');
    const todayStart = today + " 00:00";
    const todayEnd = today + " 23:59";

    let items = [];
    if (props.pages !== undefined) {
        items = props.pages;
    }
    if (props.parentItem && props.parentItem.pages !== undefined) {
        items = props.parentItem.pages
    }

    const onTabChange = (selectedIndex) => {
        setTabIndex(selectedIndex)

        if (selectedIndex === 1 || selectedIndex === 2) {
            history.push({
                pathname: items[selectedIndex].url,
                search: "?" + api.encodeParams({startDt: todayStart, endDt: todayEnd}, true) })
        } else {
            history.push({
                pathname: items[selectedIndex].url
            })
        }
    }

    return (
        <React.Fragment>
            <TabPanels items={items} selected={tabIndex} changeHandler={onTabChange} />
            <Box className={classes.tabContent}>
                {switchTab(tabIndex)}
            </Box>
        </React.Fragment>
    );


    function switchTab(index) {
        switch (index) {
            case 0 :
                return <InfoPage/>
            case 1 :
                return <AuthHistoryPage {...props} pageType={'myHistory'}/>
            case 2 :
                return <UserHistoryPage {...props} pageType={'myHistory'}/>
            case 3 :
                return <MySitePage />
            default:
                return null
        }
    }
}