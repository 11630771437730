import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@mui/material";
import React from "react";

const arrowStyles = makeStyles((theme) => ({
    arrows     : {
        fontSize             : 0
        , lineHeight         : 0
        , position           : 'absolute'
        , top                : '50%'
        , display            : 'block'
        , padding            : 0
        , '-webkit-transform': 'scale(1.0) translateY(-50%)'
        , transform          : 'scale(1.0) translateY(-50%)'
        , cursor             : 'pointer'
        , color              : 'transparent'
        , border             : 'none'
        , outline            : 'none'
        , zIndex             : 2
        , width              : 31
        , height             : 57
        , [theme.breakpoints.down('md')]: {
            height             : 37
        }
    }
    , prevArrow  : {
        background    : 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec5/ico_arrow_up.png) no-repeat 50% 50%'
        , marginBottom: 20
        , cursor      : 'pointer'

    }
    ,nextArrow    : {
        background : 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec5/ico_arrow_down.png) no-repeat 50% 50%'
        , marginTop: 20
        , cursor   : 'pointer'
    }
    , leftArrow  : {
        left        : '-97px'
        , background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec4/ico_arrow_left.png) no-repeat 0 50%'
        , [theme.breakpoints.down('md')]: {
            left        : '10px'
        }

    }
    , rightArrow: {
        right       : '-97px'
        , background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec4/ico_arrow_right.png) no-repeat 0 50%'
        , [theme.breakpoints.down('md')]: {
            right        : '10px'
        }
    }
}));

function UpArrow({forwardRef, clazz, onClick}) {
    const arrowStyle = arrowStyles();
    return (
        <Box className={`${arrowStyle.arrows} ${arrowStyle.prevArrow} ${clazz?clazz:''}`} ref={forwardRef} onClick={onClick} >Previous</Box>
    )
}
function DownArrow({forwardRef, clazz, onClick}) {
    const arrowStyle = arrowStyles();
    return (
        <Box className={`${arrowStyle.arrows} ${arrowStyle.nextArrow} ${clazz?clazz:''}`} ref={forwardRef} onClick={onClick}>Next</Box>
    )
}

function LeftArrow({forwardRef, clazz, onClick, left}) {
    const arrowStyle = arrowStyles();
    return (
        <Box className={`${arrowStyle.arrows} ${arrowStyle.leftArrow} ${clazz?clazz:''}`} style={{left: left !== undefined ? left : ''}} ref={forwardRef} onClick={onClick} >Previous</Box>
    )
}
function RightArrow({forwardRef, clazz, onClick , right}) {
    const arrowStyle = arrowStyles();
    return (
        <Box className={`${arrowStyle.arrows} ${arrowStyle.rightArrow} ${clazz?clazz:''}`} style={{right: right !== undefined ? right : ''}} ref={forwardRef} onClick={onClick}>Next</Box>
    )
}

function Arrows({ arrowDirection, forwardRef, clazz, onClick}) {
    if( arrowDirection === 'up' ){ return ( <UpArrow forwardRef={forwardRef} clazz={clazz} onClick={onClick} /> ) }
    else if( arrowDirection === 'right' ){ return ( <RightArrow forwardRef={forwardRef} clazz={clazz} onClick={onClick} /> ) }
    else if( arrowDirection === 'down' ){ return ( <DownArrow forwardRef={forwardRef} clazz={clazz} onClick={onClick} /> ) }
    else if( arrowDirection === 'left' ){ return ( <LeftArrow forwardRef={forwardRef} clazz={clazz} onClick={onClick} /> ) }
}

function SimpleArrows({ arrowDirection, forwardRef, clazz, onClick}) {
    const arrowStyle = arrowStyles();
    return (
        <Box className={`${arrowStyle.arrows} ${clazz?clazz:''}`} ref={forwardRef} onClick={onClick}>Arrow</Box>
    )
}

export {UpArrow,RightArrow, DownArrow ,LeftArrow, Arrows, SimpleArrows}