import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import _ from "lodash";
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from "@material-ui/core/Box";
import SearchBox from "./SearchBox";
import Button from "@material-ui/core/Button";
import ClearIcon from '@material-ui/icons/Clear';
import {DialogActions, ListItem} from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {ReactComponent as AddIcon} from '../images/add_icon.svg';
import List from "@material-ui/core/List";
import DashboardPage from "../pages/home/DashboardPage";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    headerWrapper: {
        display: 'flex',
        background: '#FFF',
        width: '100%',
        height: '125px',
        padding: '30px 178px',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            padding: '30px 30px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '1.875rem',
            paddingBottom: '1.563rem',
            paddingLeft: '1.875rem',
            paddingRight: '1.875rem'
        },
        '& > div': {
            width: '100%'
        }
    },
    headerBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& > h1': {
            color: theme.palette.text.title,
            fontSize: "1.313rem",
            fontWeight: 600,
            marginBottom: 5
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: '1.25rem',
            '& > h1': {
                marginBottom: '0.938rem'
            }
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0.938rem',
            '& > h1': {
                marginBottom: '0.75rem'
            }
        }
    },
    navs: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        '& > a, p': {
            lineHeight: '12px',
            textDecoration: 'none',
            color: '#7E7E88',
            fontSize: '0.75rem',
            marginBlockStart: 0,
            marginBlockEnd: 0
        },
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    },
    splitter: {
        fontSize: 15,
        marginLeft: 8,
        marginRight: 8,
        color: '#7E7E88',
        transform: 'translate(0px, 1px)',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            marginLeft: 6,
            marginRight: 6,
        }
    },
    controlList: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: 0,
        '& > li': {
            padding: 0,
            marginRight: '0.938rem'
        },
        '& > li:last-child': {
            marginRight: 0
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '& > li:nth-child(2)': {
                marginBottom: 0
            }
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& > li': {
                marginRight: 0,
                marginBottom: '0.625rem'
            }
        }
    },
    searchBox: {
        width: '20rem',
        height: 40,
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '3.5rem'
        },
        [theme.breakpoints.down('sm')]: {
            height: '2.375rem'
        }
    },
    createBox: {
        minWidth: '12.5rem',
        height: 40,
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '3.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            height: '2.375rem'
        }
    },
    createIcon: {
        width: 13,
        [theme.breakpoints.down('sm')]: {
            width: 11
        }
    },
    createDescription: {
        fontSize: '0.813rem',
        fontWeight: 700,
        marginLeft: '0.75rem',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.688rem',
            marginLeft: '0.625rem'
        }
    },
    hide:{
        display:'none'
    }
}));

const dialogStyle = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '100%',
        '& .MuiDialog-paperWidthMd': {
            width: '650px',
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #171E31',
            borderRadius: 5,
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)'
        }
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.text.white,
        height: 60,

    },
    closeButton: {
        color: theme.palette.text.white
    },
    title: {
        '& > h2': {
            fontSize: '1.125rem',
            fontWeight: 500
        }
    },
    content: {
        padding: 0,
        backgroundColor: theme.palette.background.paper
    }
}));

// 새로운 아이템(클라이언트, 약관 등) 추가하기 위한 modal dialog
// 생성 form의 콘텐츠를 wrapping 한다
function CreateDialog({title, content, open, onClose}) {
    const dialogStyles = dialogStyle();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${title} dialog`}
            maxWidth='lg'
            scroll={'paper'}
            className={dialogStyles.root}
        >
            <Box className={dialogStyles.header}>
                <DialogTitle id={`${title}_dialog_id`} className={dialogStyles.title}>{title}</DialogTitle>
                {onClose &&
                <DialogActions>
                    <IconButton onClick={onClose} className={dialogStyles.closeButton}>
                        <ClearIcon/>
                    </IconButton>
                </DialogActions>
                }
            </Box>
            <DialogContent className={dialogStyles.content}>
                {content({onClose, open})}
            </DialogContent>
        </Dialog>
    );
}

function Header(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {location, search, create , headerHidden } = props;
    const [createOpen, setCreateOpen] = useState(false);

    const label = props.label;
    const navs = props.tabItem ? props.tabItem.navs : props.navs;
    const navUrl = props.tabItem ? props.tabItem.navUrl : props.navUrl;

    // 페이지 title에 변수가 들어있다면 query나 state에서 변수값을 불러와서 label 값에 넣습니다
    const menuNames = (label) => {
        if (_.includes(label, ":")) {
            let title = "";
            const querySearch = queryString.parse(location.search);
            const queryState = queryString.parse(location.state);
            const param = label.replace(":", "");
            if (querySearch[param] !== undefined) {
                title = querySearch[param];
            } else if (queryState[param] !== undefined) {
                title = queryState[param];
            } else {
                title = label;
            }
            return t(title);
        } else {
            return t(label);
        }
    }

    const menuUrl = (url) => {
        if (_.includes(url, ":")) {
            const querySearch = queryString.parse(location.search);
            const queryState = queryString.parse(location.state);
            const param = url.replace(":", "");
            if (querySearch[param] !== undefined) {
                return querySearch[param];
            } else if (queryState[param] !== undefined) {
                return queryState[param];
            } else {
                return '/';
            }
        } else {
            return url;
        }
    }

    const createClickEvent = () => {
        setCreateOpen(prevState => !prevState);
    }

    return (
        <header className={`${classes.headerWrapper} ${headerHidden && classes.hide}`}>
            <Box className={classes.headerBox}>
                <Box className={classes.titleBox}>
                    <Typography variant='h1'>{menuNames(label)}</Typography>
                    <Box className={classes.navs}>
                        {navs?.map((nav, idx) => (
                            <React.Fragment key={idx.toString()}>
                                {(navs.length - 1) !== idx ? <Link to={menuUrl(navUrl[idx])}>{menuNames(nav)}</Link> : <p>{menuNames(nav)}</p> }
                                {(navs.length - 1) !== idx && <ChevronRightIcon className={classes.splitter}/>}
                            </React.Fragment>
                        ))}
                    </Box>
                </Box>
                {(props.searchData || props.createContent) &&
                    <List className={classes.controlList}>
                        {props.searchData &&
                            <ListItem key={'search-block'}>
                                <SearchBox
                                    className={classes.searchBox}
                                    value={search.value}
                                    defaultValue={search.defaultValue}
                                    placeholder={search.placeholder}
                                    doQuery={search.doQuery}
                                    onChange={search.onChange}
                                />
                            </ListItem>
                        }
                        {props.createContent && props.createAuth &&
                            <ListItem key={'create-block'}>
                                <Button className={classes.createBox}   // 권한 있는 사용자에게만 보이는 버튼
                                        variant="contained"
                                        color='primary'
                                        size='large'
                                        onClick={createClickEvent}
                                >
                                    <AddIcon className={classes.createIcon}/>
                                    <Typography className={classes.createDescription}
                                                variant="button">{create.description}</Typography>
                                </Button>
                                <CreateDialog
                                    title={create.dialogTitle}
                                    content={props.createPage}
                                    open={createOpen}
                                    onClose={createClickEvent}
                                />
                            </ListItem>
                        }
                    </List>
                }
            </Box>
        </header>
    );
}

/**
 * Page는 왼쪽메뉴와 상단 내비게이션바 밖에 위치하는 영역의 wrapper component입니다.
 * 대시보드 화면에 헤더가 없기 때문에 대시보드 페이지는 따로 관리된다.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Page(props) {

    const [search, setSearch] = useState(
        {
            value: undefined,
            defaultValue: undefined,
            placeholder: undefined,
            doQuery: undefined,
            onChange: undefined
        }
    );

    const [create, setCreate] = useState(
        {
            description: undefined,
            dialogTitle: undefined,
            dialogContent: undefined
        }
    );

    return (
        <React.Fragment>
            {props.label === 'Menu.dashboard' ?
                <DashboardPage sideMenuOpen={props.isOpen}/>
                :
                <React.Fragment>
                    <Header {...props} search={search} create={create}/>
                    <Box>
                        {props.page({...props, setSearch, setCreate})}
                    </Box>
                </React.Fragment>
            }
        </React.Fragment>
    );
}
