import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    copyright: {
        color: '#606675',
        fontSize: "11px",
        minWidth: 200
    }
}));

export default function Copyright(props, {enterLine = false}) {
    const { _clazz } = props;
    const classes = useStyles();
    return (
        <Typography variant='body2' align='left' className={`${_clazz !== undefined ? _clazz : classes.copyright}`}>
            {'Copyright © '}
            <Link color='inherit' href='https://fnsvalue.co.kr' target='_blank'>
                FNSVALUE Co Ltd.
            </Link>
            {enterLine && <br/>}
            {' All Rights Reserved.'}
        </Typography>
    );
}