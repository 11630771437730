import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {useStores} from '../../common/store';
import {useObserver} from 'mobx-react-lite';
import {Redirect} from 'react-router-dom';
import LoginMain from "./LoginMain";
import LoginLayout from "./LoginLayout";


export default function LoginPage(props) {
    const {ds} = useStores();
    const theme = useTheme();

    return useObserver(() => {
        if (ds.isLogin) {
            const {location} = props;
            const {from} = location.state || {from: {pathname: '/'}};
            return <Redirect to={from}/>;
        } else {
            if( theme?.type === 'gccs'){
                return <LoginLayout {...props}/>;     // FNSValue Homepage 버전
            } else {
                return <LoginMain {...props} />;     // https://webbsa.tabgdc.com/ TM POC => BSA 버전 (FNSV 홈페이지 화면(뉴스, 특허 등)들이 없음)
            }
        }
    });
}