import {useTranslation} from "react-i18next";
import {useObserver} from "mobx-react-lite";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LabelText from "./LabelText";
import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {ReactComponent as NoticeOne} from "../images/notice_1.svg";
import {ReactComponent as NoticeTwo} from "../images/notice_2.svg";
import {useMediaQuery} from "@material-ui/core";
import imgDoc from '../images/file_doc.png';
import imgPpt from '../images/file_ppt.png';
import imgPdf from '../images/file_pdf.png';
import imgHwp from '../images/file_hwp.png';
import imgUnknown from '../images/file_unknown.png';

//커스텀 style 지정
const detailContentStyle = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        '& .css-1jh78f5-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody': {
            width: 600,
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #171E31',
            borderRadius: 5,
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)'
        },
    },
    dialogHeader: {
        backgroundColor: theme.palette.secondary.main,
        height: 60,
        '& .css-i4bv87-MuiSvgIcon-root': {
            width: 27,
            height: 25
        }
    },
    dialog_content: {
        padding: "15px 30px 30px 30px !important",
        backgroundColor: '#fff'
    },
    title: {
        color: theme.palette.text.primary,
        ...theme.typography.h5,
        "& > *": {
            color: theme.palette.text.white,
            fontSize: "1.125rem"
        }
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingBottom: 10,
        "& > *": {
            color: theme.palette.text.primary,
        },
        '& > div:first-child': {
            minWidth: 130
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'scroll'
        }
    },
    headerInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        flexFlow: 'wrap',
        borderBottom: '1px solid #DBDADA',
        '& > div': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            minHeight: 50,
            borderBottom: '1px solid #DBDADA',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                borderBottom: 'none',
            },
            '& > dl': {
                display: 'flex',
                alignItems: 'center',
                minWidth: 295,
                margin: "8px 0",
                '& > *': {
                    fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif']
                },
                '& > dt': {
                    color: theme.palette.primary.main,
                    fontSize: '0.688rem',
                    fontWeight: 700
                },
                '& > dd': {
                    fontSize: '0.813rem',
                    color: '#555',
                    maxWidth: 410,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    marginRight: theme.spacing(1),
                    marginInlineStart: theme.spacing(1),
                },
                [theme.breakpoints.down('xs')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    borderBottom: '1px solid #DBDADA',
                    padding: '8px 0',
                    margin: 0,
                    minWidth: 100,
                    '& span': {
                        fontSize: '0.75rem'
                    },
                    '& > dd': {
                        marginInlineStart: 0
                    }
                }
            }
        },
        '& > div.noticeFileBox': {
            flexDirection: 'column',
            '& > dl' : {
                flexDirection: 'column',
                alignItems: 'flex-start',
                rowGap: '10px'
            },
            '& > dl > dd': {
                textAlign: "left"
            },
            '& > dl > dd > a': {
                display: 'flex',
                alignItems: 'center',
                columnGap: '5px',
                textOverflow: 'ellipsis'
            },
            '& > dl > dd > a > img': {
                height:'20px'
            }
        },
        '& > :last-child': {
            borderBottom: 'none',
        }
    },
    bodyContainer: {
        flexGrow: 1,
        flexBasis: 0,
        borderRadius: 5,
        marginTop: 7,
        padding: '1.5rem',
        minHeight: 250,
        border: '1px solid #DBDADA',
        backgroundColor: theme.palette.background.contrast,
    },
    commentArea: {
        display: 'flex',
        flex: 1,
        flexDirection: "column",
        "& > :first-child": {
            color: theme.palette.primary.main,
            fontWeight: 700,
            marginBottom: theme.spacing(1)
        },
        "& > :last-child": {
            color: "#555",
            fontSize: '0.813rem'
        }
    },
    noticeContentBox: {
        fontSize: '14px',
        whiteSpace: 'break-spaces',
        margin: '10px 0px'
    },
    noticeImageBox: {
        textAlign: "center",
        '& > img': {
            width: '100%',
            height: 'auto'
        }
    },
}));

/*
    상세 dialog 구성
    open : dialog 호출 여부
    oncloseEvent : 닫기 이벤트
    content : 테이블 row 값
 */
export default function NoticeDetailDialog({open, onCloseEvent, content}) {
    
    //호출 여부가 false면 열리지 않는다.
    if (!open) {
        return null;
    }
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));  //mobile 여부
    const {t} = useTranslation();
    const classes = detailContentStyle();                                   //커스텀 Style

    /*
    테스트 시 필요 가로 세로 값들
    let wid = isMobile ? "100%" : "532";                    

    let wid2 = isMobile ? "100%" : "595";
    let ht2 = isMobile ? "100%" : "1016";
    let no = content.current.no;*/

    let ht = isMobile ? "100%" : "763";

    return useObserver(() =>
        <Dialog className={classes.dialog} open={open} onClose={onCloseEvent} maxWidth='md'>
            <DialogTitle className={classes.dialogHeader}>
                <Typography className={classes.title}>
                    <span>공지사항</span>
                </Typography>
                {onCloseEvent ? (
                    <IconButton
                        aria-label="close"
                        onClick={onCloseEvent}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#fff'
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent className={classes.dialog_content}>
                <div className={classes.headerContainer}>
                    <div className={classes.headerInfo}>
                        <div style={{justifyContent:'flex-start'}}>
                            <dl>
                                <dt>제목</dt>
                                <dd><span>{content.current.title}</span></dd>
                            </dl>
                            <dl style={{marginLeft:'auto'}}>
                                <dt>공시일자</dt>
                                <dd><span>{content.current.eventDate}</span></dd>
                            </dl>
                        </div>

                        {content.current.files && content.current.files.length > 0 &&
                            <div className="noticeFileBox">
                                <dl>
                                    <dt>첨부파일</dt>
                                    {content.current.files.map((file, idx) => (
                                        <dd key={idx}>
                                            <a href={file.encodingSrc} download={file.originalName}>
                                                <img className="preview" src={file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(doc|docx)$/) ? imgDoc :
                                                    (file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(ppt|pptx)$/) ? imgPpt :
                                                            (file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(pdf)$/) ? imgPdf :
                                                                    (file.originalName.substring(file.originalName.lastIndexOf('.') + 1).match(/^(hwp)$/) ? imgHwp :
                                                                            imgUnknown
                                                                    )
                                                            )
                                                    )} alt="file"/>
                                                {file.originalName}
                                            </a>
                                        </dd>
                                    ))}
                                </dl>
                            </div>
                        }

                        {content.current.content ?<div className={classes.noticeContentBox}>
                            {content.current.content}
                        </div>: ""}
                        {content.current.url ?<div>
                            <dl>
                                <dt>URL</dt>
                                <dd><span>{content.current.url}</span></dd>
                            </dl>
                        </div>: ""}
                    </div>
                </div>

                {content.current.images.length > 0 &&
                    content.current.images.map((image, idx) => (
                        <div className={classes.noticeImageBox} key={idx}>
                            <img src={image.encodingSrc} height={ht}/>
                        </div>
                    ))
                }

            </DialogContent>
        </Dialog>
    )
}