import React from 'react';
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next';
import LabelText from "./LabelText";
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 'auto',
        padding: theme.spacing(4),
        background: theme.palette.background.panel,
        position:'relative',
        '&.MuiPaper-elevation1':{
            boxShadow:'none',
        },
        '&.MuiPaper-rounded':{
            borderRadius:theme.spacing(1),
        }
    },
    title: {
        margin: 0,
        marginBottom: theme.spacing(3),
        padding: 0,
        color: theme.palette.text.title,
        '& span': {fontSize: '1rem'}
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        '& > dl': {
            display: 'flex',
            flex: 1,
            margin: 0,
            minHeight: theme.spacing(4),
            marginBottom: theme.spacing(2),
            alignItems: 'center',
            '& > dt': {
                flex: 1,
                color: theme.palette.text.gray,
            },
            '& > dd': {
                wordBreak: 'break-all',
            }
        },
        [theme.breakpoints.between('xs', 'xs')]: {
            '& > dl': {
                flexDirection: 'column',
                alignItems: 'flex-start',
                '& > dt': {
                    alignSelf: 'flex-start',
                    width:'100%'
                },
                '& > dd': {
                    wordBreak: 'break-all',
                    width:'100%'
                }
            },
        }
    },
    defaultDd: {
        flex: 2,
        margin: 0,
        color: theme.palette.text.primary,
        [theme.breakpoints.between('xs', 'xs')]: {
            flex: 1,
        }
    },
    cardType1: {
        paddingLeft: 0,
        fontWeight: 'bold',
        color: theme.palette.text.gray,
    },
    cardType2: {
        padding:0,
        margin: 0,
        flex: 1,
    },
    cardType3: {
        paddingLeft: theme.spacing(2),
        [theme.breakpoints.between('xs', 'xs')]: {
            paddingLeft: theme.spacing(1),
        }
    },
    cardType3Dd:{
        flex: 2,
        margin: 0,
        color: theme.palette.text.primary,
        [theme.breakpoints.between('xs', 'xs')]: {
            paddingLeft: theme.spacing(1),
        }
    },
    htmlTitle:{
        alignSelf: 'flex-start',
        paddingTop: 15
    },
    Divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    }

}));


export function CardTypes(props) {
    const classes = useStyles();
    const {cardType, label, component, rules, translation = false} = props;

    const required = _.includes(rules, "required");

    if (cardType === 'type1') {
        return (
            <dt className={classes.cardType1}>
                <LabelText translation={false} label={label}/>
            </dt>
        )
    } else if (cardType === 'type2') {
        return (
            <dd className={classes.cardType2}>
                {(component !== undefined && component(props))}
            </dd>
        )
    } else if (cardType === 'type3') {
        return (
            <React.Fragment>
                <dt className={(cardType === 'type3' ? classes.cardType3 : null)}>
                    <LabelText translation={translation} label={label} required={required}/>
                </dt>
                <dd className={classes.cardType3Dd}>
                    {(component !== undefined && component(props))}
                </dd>
            </React.Fragment>
        )
    } else if (cardType === 'content') {
        return (
            <dt>
                {component !== undefined ? component : null}
            </dt>
        );
    } else if (cardType === 'blank') {
        return (<dt></dt>);
    } else if (cardType === 'bottom') {
        return (
            <dt>
                {component !== undefined ? component : null}
            </dt>
        );
    } else if (cardType === 'html') {
        return (
            <React.Fragment>
                <dt className={classes.htmlTitle}>
                    <LabelText translation={translation} label={label} required={required}/>
                </dt>
                <dd className={classes.defaultDd}>
                    <Typography color='textPrimary'
                                dangerouslySetInnerHTML={{__html: props.value}}
                    />
                </dd>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <dt>
                    <LabelText translation={translation} label={label} required={required}/>
                </dt>
                <dd className={classes.defaultDd}>
                    {(component !== undefined && component(props))}
                </dd>
            </React.Fragment>
        );
    }
}



export function ExcludeMode(props){
    const {cardType, component, index} = props;

    if( 'divider' === cardType) {
        return (
            <Divider key={index} className={classes.Divider}/>
        )
    } else if( 'expireAlert' === cardType) {
        return (
            component !== undefined ? component : null
        )
    }

}

export default function Cards(props) {
    const {components, form} = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const excludeType = ['divider','expireAlert'];




    return (
        <Card className={classes.root}>
            <CardHeader title={(t(props.title))} className={classes.title}/>
            <CardContent className={classes.cardContent}>
                {components.map((data, index) => (
                    (
                        _.includes(excludeType,data.cardType)
                        ? <ExcludeMode {...data} key={index}/>
                        : (
                            <dl key={index}>
                                <CardTypes {...data} form={form}/>
                            </dl>
                          )
                    )
                ))}
            </CardContent>
        </Card>
    );
}
