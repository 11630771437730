import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@mui/material/Select';
import {useStores} from '../common/store';
import {useObserver} from 'mobx-react-lite';
import PersonOutline from '@material-ui/icons/PersonOutline';
import {CommonCodeValue} from '../common/types';
import validatorjs from "validatorjs";
import {useMediaQuery} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import storage, {StorageKey as SK} from '../common/storage';
import Box from "@material-ui/core/Box";
import {HorizontalListBox} from "./HorizontalListBox";
import {Link} from "react-router-dom";
import clsx from 'clsx';

import {ReactComponent as LogoutIcon} from '../images/topBar/logout_icon.svg';
import {ReactComponent as LanguageIcon} from '../images/topBar/language_icon.svg';
import {ReactComponent as MyIcon} from '../images/topBar/my_icon.svg';
import {ReactComponent as MenuIcon} from '../images/topBar/menu_icon.svg';
import {adminMenuFunc} from "../common/menu";

function UserInfo() {
    const classes = useStyles();
    const {ds} = useStores();
    const {name, userType} = ds.user;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const {t} = useTranslation();

    const userTypeName = CommonCodeValue.getText(userType);
    const [anchorEl, setAnchorEl] = React.useState(null);
    let personStyles = "";

    if (isMobile) {
        personStyles = {fontSize: 24};
    } else {
        personStyles = {fontSize: 24, marginRight: 13};
    }

    const userInfoPop = (event) => {
        if (isMobile) {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const userInfoPopId = "user-info-popover";

    const logoutHandler = () => {
        storage.removeSession(SK.ACCESS_TOKEN);
        window.location.reload(false);
    }

    return useObserver(() => (
        <React.Fragment>
            <div className={classes.logoutBox} onClick={logoutHandler}>
                <ExitToAppIcon/>
                <Typography variant="body1">
                    {t('Menu.Logout')}
                </Typography>
            </div>
            <div className={classes.userInfo} onClick={userInfoPop} aria-describedby={userInfoPopId}>
                <PersonOutline style={personStyles}/>
                <Typography variant="body1">
                    {name} ({userTypeName})
                </Typography>
            </div>
            <Popover
                id={userInfoPopId}
                className={classes.userPop}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical  : 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical  : 'top',
                    horizontal: 'left',
                }}
            >
                <List component="nav" aria-label="member logout">
                    {isMobile ? (
                        <ListItem>
                            <ListItemIcon>
                                <PersonIcon/>
                            </ListItemIcon>
                            <ListItemText primary={`${name} (${userTypeName})`}/>
                        </ListItem>
                    ) : null}
                    <ListItem button onClick={logoutHandler}>
                        <ListItemIcon>
                            <ExitToAppIcon/>
                        </ListItemIcon>
                        <ListItemText primary={t('Menu.Logout')}/>
                    </ListItem>
                </List>
            </Popover>
        </React.Fragment>
    ));
}

const useStyles = makeStyles((theme) => ({
    header          : {
        display                       : 'flex',
        width                         : '100%',
        height                        : '4.063rem',
        zIndex                        : 2,
        backgroundColor               : '#1E1D30',
        backgroundSize                : 'contain',
        [theme.breakpoints.down('md')]: {
            backgroundImage: 'none',
            height         : '5.625rem'
        },
        [theme.breakpoints.down('sm')]: {
            height: '3.5rem'
        },
    },
    headerMenuBox   : {
        display                                : 'flex',
        justifyContent                         : 'flex-end',
        alignItems                             : 'center',
        flexGrow                               : 1,
        padding                                : '0 1.563rem',
        color                                  : '#71798F',
        [theme.breakpoints.between('xs', 'md')]: {
            justifyContent: 'space-between',
            paddingRight  : '0.938rem',
        }
    },
    sideMenuBtn     : {
        color                                  : '#fff',
        marginLeft                             : '1.563rem',
        padding                                : 0,
        [theme.breakpoints.down('sm')]         : {
            marginLeft: '0.313rem'
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            display: 'none'
        }
    },
    sideMenuIcon    : {
        [theme.breakpoints.down('sm')]: {
            width : '1.5rem',
            height: '0.875rem'
        }
    },
    logoutMenu      : {
        display   : 'flex',
        alignItems: 'center',
        cursor    : 'pointer'
    },
    langMenu        : {
        display   : 'flex',
        alignItems: 'center',
    },
    langMenuTitle   : {
        alignSelf: 'center',
        color    : '#71798F'
    },
    langMobMenuTitle: {
        fontWeight                    : 700,
        fontSize                      : '0.75rem',
        color                         : '#fff',
        backgroundColor               : '#171E31',
        lineHeight                    : 0.7,
        position                      : 'absolute',
        height                        : 12,
        bottom                        : -2,
        left                          : 10,
        paddingTop                    : 1,
        paddingLeft                   : 1,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.625rem',
            height  : 10,
            bottom  : 0,
        }
    },
    langSelector    : {
        display                                                          : 'flex',
        alignItems                                                       : 'center',
        '& .MuiOutlinedInput-notchedOutline'                             : {
            border: 'none'
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },
        '& .MuiOutlinedInput-root:hover'                                 : {
            color: '#71798F',
        },
        '& .MuiSelect-icon'                                              : {
            color     : '#71798F',
            right     : 0,
            top       : 'calc(50% - 0.25rem)',
            marginLeft: '0.5rem'
        },
    },
    langSelect      : {
        color    : '#71798F',
        '& > div': {
            padding     : '0 !important',
            // paddingRight: '1.188rem !important',
            '&:focus'   : {
                backgroundColor: 'transparent'
            }
        }
    },
    langMobSelector : {
        display : 'flex',
        '& > .MuiInput-underline:before, & > .MuiInput-underline:after, & > .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        }
    },
    langMobSelect   : {
        '& > :first-child'                    : {
            paddingRight: '0px !important'
        },
        '& .MuiOutlinedInput-notchedOutline'  : {
            border: 'none'
        },
        '& .MuiSelect-select.MuiSelect-select': {
            padding  : 0,
            '&:focus': {
                backgroundColor: 'transparent'
            }
        },
        '& .MuiSvgIcon-root.MuiSelect-icon'   : {
            display: 'none',
        }
    },
    myMenuLink      : {
        backgroundColor               : theme.palette.primary.main,
        width                         : '2.188rem',
        height                        : '2.188rem',
        flexShrink                    : 0,
        borderRadius                  : 5,
        display                       : 'flex',
        alignItems                    : 'center',
        justifyContent                : 'center',
        // [theme.breakpoints.only('md')]: {
        //     width : '5.625rem',
        //     height: '5.625rem'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width : '3.5rem',
        //     height: '3.5rem'
        // },
    },
    subLinkHeader:{
        [theme.breakpoints.down('md')]:{
            display:'none'
        }
    },
    linkBtn:{
        color:'#6E6E8E',
        fontSize:'0.813rem',
        lineHeight:'1.313rem',
        fontWeight:600,
        textDecoration:'none',
        textAlign:'center',
        fontFamily: 'Poppins',
        '&.active':{
            color:'#fff'
        },
        '&.menuLink': {
            fontWeight: 500
        }
    },
    linkSelectBox:{
        textAlign:'center'
        ,'& .MuiSelect-select':{
            padding:'0 !important'
        }
        ,'& > .MuiInput-underline:before, & > .MuiInput-underline:after, & > .MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: 'none',
        }
        ,'&.active':{
            color:'#fff',
            '& p':{
                color:'#fff',
                fontSize:'0.813rem',
                lineHeight:'1.313rem',
                fontFamily: 'Poppins',
                fontWeight: 600,
                height:39,
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            },
        }
    },
    linkSelectTitle:{
        '& > p':{
            color:'#6E6E8E',
            fontSize:'0.813rem',
            fontFamily: 'Poppins',
            lineHeight:'1.313rem',
            fontWeight:600,
        }
    }
}));

function MenuButton({openItem}) {

    const classes = useStyles();

    const {handleClick} = openItem

    function onClick(e) {
        if (handleClick) {
            handleClick(e, openItem);
        }
    }

    return (

        <IconButton onClick={onClick} className={classes.sideMenuBtn}>
            <MenuIcon className={classes.sideMenuIcon}/>
        </IconButton>

    );

}

function LogoutMenu() {
    const classes = useStyles();

    const logoutHandler = () => {
        storage.removeSession(SK.ACCESS_TOKEN);
        window.location.reload(false);
    }

    return (
        <Box className={classes.logoutMenu} onClick={logoutHandler}>
            <LogoutIcon width={20} height={20}/>
        </Box>
    )
}

function LanguageSelector() {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const onChange = (evt) => {
        i18n.changeLanguage(evt.target.value);
        validatorjs.useLang(evt.target.value);
    };

    const onRender = () => {
        return (
            <Box className={classes.langMenu}>
                <LanguageIcon width={22} height={22}/>
                {isMobile && <Typography className={classes.langMobMenuTitle}>{t("Header.lang")}</Typography>}
            </Box>
        )
    };

    useEffect(() => {
        validatorjs.useLang(i18n.language ? i18n.language : 'en');
    }, [])

    return (
        <Box className={(isMobile ? classes.langMobSelector : classes.langSelector)}>
            <Select className={(isMobile ? classes.langMobSelect : classes.langSelect )}
                    value={i18n.language}
                    onChange={onChange}
                    renderValue={onRender}
                    IconComponent={ () => null}
                    MenuProps={{
                        anchorOrigin   : { vertical  : "top",  horizontal: "left" },
                        transformOrigin: { vertical  : "top",  horizontal: "left" }
                    }}
            >
                <MenuItem value="ko">한국어</MenuItem>
                <MenuItem value="en">English</MenuItem>
            </Select>
        </Box>
    )
}

/** 상단 메뉴:
 * 홈페이지 화면 선택
 * 언어 변경 버튼
 * 로그아웃 버튼
 * 나의 페이지로 이동하기
 * **/
export default function TopBar({openItem, location}) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const [subLink, setSubLink] = useState('Company');
    const _pathName = location.pathname;
    const {ds} = useStores();

    const onLinkRender = () => {
        return (
            <Box className={classes.linkSelectTitle}>
                <Typography>{subLink}</Typography>
            </Box>
        )
    }
    const onLinkChange = ({target}) => {
        setSubLink(target.value);
    }

    return (
        <Box className={classes.header}>
            { theme?.type === 'gccs' &&
                <HorizontalListBox
                    className={classes.subLinkHeader}
                    borderWidth={0}
                    space={0.9}>
                    <Link className={`${classes.linkBtn } ${adminMenuFunc(ds.user)[1].items.filter(p => p.url === _pathName)[0] === undefined && 'active' || _pathName === '/' && 'active' }`} to={'/dashboard'}>Dashboard</Link>
                    <Link className={`${classes.linkBtn } ${_pathName === '/solution' && 'active' }`} to={'/solution'}>Solution</Link>
                    <Link className={`${classes.linkBtn } ${_pathName === '/news' && 'active' }`} to={'/news'}>News</Link>
                    <Link className={`${classes.linkBtn } ${_pathName === '/video' && 'active' }`} to={'/video'}>Video</Link>

                    <Box className={`${classes.linkSelectBox} ${ ((_pathName === '/patent' ) || (_pathName === '/history') || (_pathName === '/contact')) && 'active' }`}>
                        <Select value={subLink}
                                variant={'standard'}
                                onChange={onLinkChange}
                                renderValue={onLinkRender}
                                IconComponent={ () => null}
                                MenuProps={{
                                    anchorOrigin   : { vertical  : "bottom",  horizontal: "left" },
                                    transformOrigin: { vertical  : "top",  horizontal: "left" }
                                }}
                        >
                            <MenuItem value="Company" style={{display: 'none'}} />
                            <MenuItem value="Patent"><Link className={`${classes.linkBtn} ${"menuLink"}`} to={'/patent'}>Patent</Link></MenuItem>
                            <MenuItem value="History"><Link className={`${classes.linkBtn} ${"menuLink"}`} to={'/history'}>History</Link></MenuItem>
                            <MenuItem value="Contact"><Link className={`${classes.linkBtn} ${"menuLink"}`} to={'/contact'}>Contact</Link></MenuItem>
                        </Select>
                    </Box>
                </HorizontalListBox>
            }
            <Box className={classes.headerMenuBox}>
                <MenuButton openItem={openItem}/>

                <HorizontalListBox
                    borderWidth={0}
                    space={isMobile ? 0.938 : 0.625}>
                    <LanguageSelector/>
                    <LogoutMenu/>
                    <Link className={classes.myMenuLink} to={'/mypage/info'}>
                        <MyIcon width={21} height={20}/>
                    </Link>
                </HorizontalListBox>
            </Box>
        </Box>
    )
}
