import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PrivacyPolicyForm from "./PrivacyPolicyForm";
import SecurityPolicyForm from "./SecurityPolicyForm"
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        width:'100%',
        height:'100%',
        color:'#fff',
        [theme.breakpoints.down('md')] :{
            width:'initial !important',
            height:'calc(100vh - 52px) !important',
        }
    },
    contact: {
    display:'flex',
        flex:1,
        height:'inherit',
        alignItems:'center',
        justifyContent:'center',
        background:'#0F1014',
        backgroundSize:'cover'
    }
    ,securityPolicyBox: {
        width                         : 'calc(50% - 30px)',
        height                        : 'calc(90% - 79px)',
        textAlign                     : 'left',
        marginLeft                    : 70,
        '& > h2'                      : {
            fontWeight  : '700',
            fontSize    : '32px',
            lineHeight  : '68px',
            marginBottom: 20,
        },
        '& > img'                     : {
            display     : 'inline-block',
            maxWidth    : '90%',
            maxHeight   : '90%',
            width       : 'auto',
            height      : 'auto'
        },
        [theme.breakpoints.down('md')]: {
            margin        : '0',
            padding       : '0 20px',
            display       : 'flex',
            flexDirection : 'column',
            flex          : 1,
            justifyContent: 'center',
            textAlign     : 'left',
            width         : 'calc(50% - 20px)',
            height        : 'calc(80% - 73px)',
            '& > h2'      : {
                fontSize  : '30px',
                lineHeight: '48px',
            },
            '& > img'                     : {
                display     : 'inline-block',
                alignSelf   : 'flex-end',
                maxWidth    : '70%',
                maxHeight   : '70%',
                width       : 'auto',
                height      : 'auto'
            },
        }
    }
    ,privacyPolicyBox     : {
        marginLeft: 70,
        width: 'calc(50% - 30px)',
        height: 'calc(90% - 79px)',
        textAlign: 'left',
        '& > h2': {
            fontWeight: '700',
            fontSize: '32px',
            lineHeight: '68px',
            marginBottom: 20,
        },
        [theme.breakpoints.down('md')]: {
            margin: '0',
            padding: '0 20px',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'center',
            textAlign: 'left',
            width: 'calc(50% - 20px)',
            height: 'calc(80% - 73px)',
            '& > h2': {
                fontSize: '30px',
                lineHeight: '48px',
            },
        }
    }
}));

const PrivacyPolicyPage = ({ forwardedRef }) => {
    const classes = useStyles();
    const innerRef = useRef();
    const outerRef = forwardedRef;
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const innerRefCurrent = innerRef.current

        const handleClick = (e) => {
            setOpenDialog(true)
        }

        innerRefCurrent?.addEventListener("click", handleClick);

        return () => {
            innerRefCurrent?.removeEventListener("click", handleClick);
        }
    });

    const onCloseDialog = () => {
        setOpenDialog(false)
    }
    const _innerFlag = name !== undefined;

    return (
        <div className={`${classes.contact} ${_innerFlag && classes.container}`}>
            <Box className={classes.securityPolicyBox}>
                <SecurityPolicyForm />
            </Box>
            <Box className={classes.privacyPolicyBox}>
                <PrivacyPolicyForm />
            </Box>
        </div>
    );
};

export default PrivacyPolicyPage;
