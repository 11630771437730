import React, {useEffect, useState} from 'react';
import TabPanels from "../../components/TabPanels";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AgreementHistoryPage from "./AgreementHistoryPage";
import ClientHistoryPage from "./ClientHistoryPage";
import QuestionHistoryPage from "./QuestionHistoryPage";
import UserHistoryPage from "./UserHistoryPage";
import LicenseHistoryPage from "./LicenseHistoryPage";
import api from "../../common/api";
import {UserType} from "../../common/types";
import {useStores} from "../../common/store";
import {useHistory} from 'react-router';
import AuthHistoryPage from "./AuthHistoryPage";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position: 'relative',
    },
    tabContent: {
        margin: '40px 170px 60px 170px',
        // margin: '40px 50px 60px 50px',
        [theme.breakpoints.down('md')]: {
            margin: 20
        }
    },
}));

export default function EventHistoryPage(props) {

    const classes = useStyles();
    const {ds} = useStores();
    const history = useHistory();
    const [tabIndex, setTabIndex] = useState(props.tabIdx ? props.tabIdx : 0);

    const [values, setValues] = useState([]);
    const [dateRange, setDateRange] = useState(null);

    let today = moment(new Date()).format('YYYY-MM-DD');
    const todayStart = today + " 00:00";
    const todayEnd = today + " 23:59";

    let items = [];
    if (props.pages !== undefined) {
        items = props.pages;
    }
    if (props.parentItem && props.parentItem.pages !== undefined) {
        items = props.parentItem.pages
    }

    const clients = [];
    if (ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null) {
        if (clients.length === 0) {
            let clientIndex = 0;
            for (let key in ds.user.accessibleClient) {
                if (UserType.isManager(ds.user.accessibleClient[key].userType)) {
                    clients[clientIndex++] = {
                        value: ds.user.accessibleClient[key].clientKey,
                        text: ds.user.accessibleClient[key].clientName
                    };
                }
            }
        }
    }

    const onTabChange = (selectedIndex) => {
        setTabIndex(selectedIndex)
        history.push({
            pathname: items[selectedIndex].url,
            search: "?" + api.encodeParams({startDt: todayStart, endDt: todayEnd}, true) })
    }

    useEffect(() => {
        api.getHistoryValueList()
            .then((res) => {
                setValues(res.data.categories);
                setDateRange(res.data.dateRange)
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            })
    }, []);

    return (
        <React.Fragment>
            <TabPanels items={items} selected={tabIndex} changeHandler={onTabChange} />
            <Box className={classes.tabContent}>
                {switchTab(tabIndex)}
            </Box>
        </React.Fragment>
    );

    function switchTab(index) {
        switch (index) {
            case 0 :
                return <ClientHistoryPage {...props} valueList={values} clientArray={clients} dateRange={dateRange}/>
            case 1 :
                return <UserHistoryPage {...props} pageType={'all'} valueList={values} dateRange={dateRange}/>
            case 2 :
                return <AuthHistoryPage  {...props} pageType={'all'} />
            case 3 :
                return <AgreementHistoryPage {...props} valueList={values} clientArray={clients} dateRange={dateRange}/>
            // case 4 :
            //     return <LicenseHistoryPage  {...props} valueList={values} clientArray={clients} dateRange={dateRange}/>
            case 4 :
                return <QuestionHistoryPage {...props}  valueList={values} clientArray={clients} dateRange={dateRange}/>
            default:
                return <ClientHistoryPage {...props} valueList={values} clientArray={clients} dateRange={dateRange}/>
        }
    }
}