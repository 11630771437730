import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../../components/DataTable';
import api from "../../common/api";
import {Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {
    AgreementType,
    CommonCodeValue,
    LanguageCode,
    UseStatusCode,
    UserType
} from "../../common/types";
import queryString from 'query-string';
import {useStores} from "../../common/store";
import moment from "moment";
import _ from "lodash";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SelectBox from "../../components/SelectBox";

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: '40px 170px 60px 170px',
        padding: '35px 40px',
        backgroundColor: '#FFF',
        borderRadius: 6,
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20,
        }
    },
    conditionDiv: {
        ...theme.typography.body1,
        display: 'flex',
        marginBottom: theme.spacing(2),
        gap: theme.spacing(1),
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            '& > *': {
                flex: 1,
                maxWidth: '100% !important'
            }
        }
    },
    inputBox: {
        ...theme.typography.body1,
        ...theme.select,
        minWidth: '200px'
    },
    typeTag: {
        display: "flex",
        justifyContent: "center",
        '& > p': {
            fontWeight: 500,
            height: 34,
            padding: '0 20px',
            lineHeight: '34px',
            borderRadius: '17px'
        }
    },
    type1: {
        color: theme.palette.tag.blue,
        backgroundColor: theme.palette.tag.lightBlue,
    },
    type2: {
        color: theme.palette.tag.green,
        backgroundColor: theme.palette.tag.lightGreen,
    },
    type3: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.tag.purple,
    },
    table: {
        '& .MuiTableRow-root': {
            '& > *': {
                wordBreak: 'break-all',
                [theme.breakpoints.between('xs', 'md')]: {
                    wordBreak: 'keep-all'
                }
            },
            '& > :nth-child(1)': {
                width: 270,
                minWidth: 240
            },
            '& > :nth-child(2)': {
                width: 270
            },
            '& > :nth-child(3)': {
                width: 200
            },
            '& > :nth-child(4)': {
                width: 140
            },
            '& > :nth-child(5)': {
                width: 140
            },
            '& > :nth-child(6)': {
                width: 230
            },
            '& > :nth-child(7)': {
                width: 130
            }
        }
    }
}));

export default function AgreementListPage(props) {
    const pageSize = 5;
    const {location, setSearch, setCreate} = props;
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const query = queryString.parse(location.search);
    let page = query['page'] !== undefined ? Number(query['page']) : 1;
    let lang = query['lang'] !== undefined ? query['lang'] : null;
    let useStatus = query['useStatus'] !== undefined ? query['useStatus'] : null;
    let type = query['type'] !== undefined ? query['type'] : null;
    let client = query['client'] !== undefined ? query['client'] : null;
    let searchWord = query['searchWord'] !== undefined ? query['searchWord'] : null;

    const searchParams = useRef(
        {
            lang: lang,
            useStatus: useStatus,
            type: type,
            client: client
        }
    );

    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);
    /********************** USE STATE EVENT **********************/
    const changedSearchKeywordEvent = (value) => {
        searchWord = value;
    }

    const onChangeSelect = (type, value) => {
        if (type === 'language') {
            searchParams.current.lang = value;
        } else if (type === 'useStatus') {
            searchParams.current.useStatus = value;
        } else if (type === 'type') {
            searchParams.current.type = value;
        } else if (type === 'client') {
            searchParams.current.client = value;
        }
        search(null, 1);
    }

    /********************** USE STATE EVENT **********************/

    const rowClickEvent = (row) => (event) => {
        setEvent({type: 'detail', agreementSeq: row[7]?.content});
    }

    const search = (event, page = 1) => {
        const query = {
            lang: searchParams.current.lang,
            useStatus: searchParams.current.useStatus,
            type: searchParams.current.type,
            client: searchParams.current.client,
            searchWord: searchWord,
            page: page
        };
        props.history.push('/admin/agreements?' + api.encodeParams(query, true));
    }

    const fetchData = (query) => {
        const lang = query.lang !== 'ALL' ? query.lang : null;
        const useStatus = query.useStatus !== 'ALL' ? query.useStatus : null;
        const type = query.type !== 'ALL' ? query.type : null;
        const client = query.client !== 'ALL' ? query.client : null;

        api.getAgreementList(lang, useStatus, type, client, query.searchWord, query.page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        fetchData({lang, useStatus, type, client, searchWord, page});
        if (setSearch) {
            setSearch(
                {
                    placeholder: t('Agreement.SearchPlaceholder'),
                    doQuery: search,
                    onChange: changedSearchKeywordEvent
                }
            );
        }
        if (setCreate) {
            setCreate(
                {
                    description: t('Agreement.NewAgreement'),
                    dialogTitle: t('Agreement.CreateDialog.Title')
                }
            );
        }
    }, [location.search, i18n.language]);


    if (event?.type === 'detail') {
        return (
            <Redirect push to={{
                pathname: '/admin/agreements/detail',
                search: "?" + api.encodeParams({agreementSeq: event.agreementSeq}),
            }}/>
        );
    }
    return (
        <Box className={classes.pageContent}>
            <ConditionPanel searchParams={searchParams}
                            onChangeSelect={onChangeSelect}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={page}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
        </Box>
    );
}

function ConditionPanel({searchParams, onChangeSelect}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();

    const langArray = [];

    if (langArray.length === 0) {
        Object.keys(LanguageCode).map((key, index) => {
            langArray[index] = {
                key: key,
                value: LanguageCode[key],
                text: t(CommonCodeValue.translateKey + '.' + LanguageCode[key])
            };
        })
    }

    const useStatusArray = [];

    if (useStatusArray.length === 0) {
        Object.keys(UseStatusCode).map((key, index) => {
            useStatusArray[index] = {
                key: key,
                value: UseStatusCode[key],
                text: t(CommonCodeValue.translateKey + '.' + UseStatusCode[key])
            };
        })
    }

    const typeArray = [];

    if (typeArray.length === 0) {
        Object.keys(AgreementType).map((key, index) => {
            typeArray[index] = {
                key: key,
                value: AgreementType[key],
                text: t(CommonCodeValue.translateKey + '.' + AgreementType[key])
            };
        })
    }

    const clientArray = [];

    if (ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null) {
        if (clientArray.length === 0) {
            let clientIndex = 0;
            for (let key in ds.user.accessibleClient) {
                if (UserType.isManager(ds.user.accessibleClient[key].userType)) {
                    clientArray[clientIndex++] = {
                        value: ds.user.accessibleClient[key].clientKey,
                        text: ds.user.accessibleClient[key].clientName
                    };
                }
            }
        }
    }


    const onChangeLanguage = (event) => {
        if (onChangeSelect) {
            onChangeSelect('language', event.target.value);
        }
    }

    const onChangeUseStatus = (event) => {
        if (onChangeSelect) {
            onChangeSelect('useStatus', event.target.value);
        }
    }

    const onChangeType = (event) => {
        if (onChangeSelect) {
            onChangeSelect('type', event.target.value);
        }
    }

    const onChangeClient = (event) => {
        if (onChangeSelect) {
            onChangeSelect('client', event.target.value);
        }
    }

    return (
        <div className={classes.conditionDiv}>
            <SelectBox className={classes.inputBox}
                       title={t('Agreement.Lang')}
                       value={searchParams.current.lang}
                       contents={langArray}
                       onChange={onChangeLanguage}
                       addAll={true}
            />
            <SelectBox className={classes.inputBox}
                       title={t('Agreement.UseStatus')}
                       value={searchParams.current.useStatus}
                       contents={useStatusArray}
                       onChange={onChangeUseStatus}
                       addAll={true}
            />
            <SelectBox className={classes.inputBox}
                       title={t('Agreement.Type')}
                       value={searchParams.current.type}
                       contents={typeArray}
                       onChange={onChangeType}
                       addAll={true}
            />
            <SelectBox className={classes.inputBox}
                       title={t('Agreement.Client')}
                       value={searchParams.current.client}
                       contents={clientArray}
                       onChange={onChangeClient}
                       addAll={true}
            />
        </div>
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage, pageSize}) {

    const classes = useStyles();
    const {t} = useTranslation();
    const body = [];
    const head = [
        [
            {content: t('Agreement.Type')},
            {content: t('Agreement.Title')},
            {content: t('Agreement.Client')},
            {content: t('Agreement.Lang')},
            {content: t('Agreement.UseStatus')},
            {content: t('Agreement.UserId')},
            {content: t('Agreement.Date')},
        ]
    ];

    if (response !== null) {

        response.data.map((data, idx) => {
            let title = data.title ? data.title : '-';
            let clientName = data.clientName && data.clientName!=="NOT FOUND" ? data.clientName : '-';
            let type = data.type ? t(CommonCodeValue.translateKey + '.' + data.type) : '-';
            let lang = data.lang ? t(CommonCodeValue.translateKey + '.' + data.lang) : '-';
            let useStatus = data.useStatus ? t(CommonCodeValue.translateKey + '.' + data.useStatus) : '-';
            let regUserKey = data.regUserKey ? data.regUserKey : '-';
            let regDt = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD') : '-';

            body[idx] = [
                {
                    id: 'type', content: (
                        <Box className={classes.typeTag}>
                            <Typography
                                className={
                                    type === t("CommonCode.CMMAGR001") ? classes.type1 :
                                        type === t("CommonCode.CMMAGR002") ? classes.type2 : classes.type3
                                }
                                variant="body1">
                                {type}
                            </Typography>
                        </Box>
                    )
                },
                {id: 'title', content: title, align: 'left'},
                {id: 'clientName', content: clientName, align: 'center'},
                {id: 'lang', content: lang},
                {id: 'useStatus', content: useStatus},
                {id: 'regUserKey', content: regUserKey},
                {id: 'regDt', content: regDt},
                {id: 'agreementSeq', content: data.seq},
            ];

        });
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   style={classes}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}


function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}