import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router';
import TabPanels from "../../components/TabPanels";
import queryString from 'query-string';
import _ from "lodash";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import {useStores} from "../../common/store";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position: 'relative',
    },
    tabContent: {
        margin: '40px 170px 60px 170px',
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20,
        }
    }
}));


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box>{children}</Box>)}
        </div>
    );
}

export default function ClientDetailsPage(props) {
    const classes = useStyles();
    const {location} = props;
    const query = queryString.parse(location.search);
    const queryState = queryString.parse(location.state);
    const clientKey = query.clientKey;
    const licenseSeq = query.licenseSeq ?? null;
    const clientName = queryState.clientName;
    const {ds} = useStores();
    const {t} = useTranslation();
    const history = useHistory();

    const [isEditable, setIsEditable] = React.useState(false);
    const [tabIndex, setTabIndex] = React.useState(props.tabItem ? props.tabItem.tabIdx : 0);

    const items = props.tabs ? props.tabs : [];

    const changeTabIndex = (value) => {
        if (tabIndex !== value) {
            setTabIndex(value)
            history.push({
                pathname: items[value].url,
                search: location.search,
                state: location.state
            })
            setIsEditable(false);
        }
    }

    const toggleClickHandler = (value) => {
        setIsEditable(value);
    }

    useEffect(() => {
        if (_.includes(props.url, "detail")) {
            if (clientKey === undefined) {
                ds.showErrorAlert(t('Error.WRONG_APPROACH'));
                history.push('/admin/clients');
            }
        } else {
            history.push({
                pathname: items[tabIndex].url,
                search: location.search,
                state: location.state
            })
        }
    }, []);

    return (
        <React.Fragment>
            <TabPanels items={items} selected={tabIndex} changeHandler={changeTabIndex}/>
            <Grid container>
                <Grid item xs={12} className={classes.tabContainer}>
                    <div className={classes.tabContent}>
                        {items.map((data, index) => (
                            (data.page !== undefined) &&
                            <TabPanel key={index.toString()} value={tabIndex} index={index}>
                                <data.page {...props} clientKey={clientKey} clientName={clientName} licenseSeq={licenseSeq}
                                           isEditable={isEditable} setIsEditable={toggleClickHandler} formId={data.formId}/>
                            </TabPanel>
                        ))}
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

