import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import {Container, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "./Pagination";

const tableStyles = makeStyles((theme) => ({
    defaultTable: {
        marginBottom: theme.spacing(1),
        borderRadius: 10,
        borderCollapse: 'separate !important',
        borderSpacing: '0px 10px !important'
    },
    defaultHeadRow: {
        '& > :nth-child(1)': {
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px"
        },
        '& > :nth-last-child(1)': {
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px"
        }
    },
    defaultHeadCell: {
        ...theme.typography.body1,
        fontWeight: 500,
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.table.head,
        border: 'none',
    },
    defaultRow: {
        height: '5.625rem',
        cursor: 'pointer',
        border: theme.palette.border.gray,
        backgroundColor: 'transparent',
        borderRadius: "5px",
        "&:hover": {
            boxShadow: theme.palette.shadow.primary,
            border: theme.palette.border.secondary,
            '& > :nth-child(1)': {
                borderLeft: theme.palette.border.secondary,
            },
            '& > *': {
                borderBottom: theme.palette.border.secondary,
                borderTop: theme.palette.border.secondary,
            },
            '& > :nth-last-child(1)': {
                borderRight: theme.palette.border.secondary,
            },
        },
        '& > :nth-child(1)': {
            borderLeft: theme.palette.border.gray,
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
        },
        '& > :nth-last-child(1)': {
            borderRight: theme.palette.border.gray,
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px"
        }
    },
    defaultBodyCell: {
        ...theme.typography,
        color: theme.palette.text.primary,
        borderBottom: theme.palette.border.gray,
        borderTop: theme.palette.border.gray,
    },
    emptyRow: {
        cursor: 'initial',
        height: '5.625rem',
        border: theme.palette.border.gray,
        backgroundColor: 'transparent',
        '& > :nth-child(1)': {
            borderLeft: theme.palette.border.gray,
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
        },
        '& > :nth-last-child(1)': {
            borderRight: theme.palette.border.gray,
            borderTopRightRadius: "5px",
            borderBottomRightRadius: "5px"
        }
    },
    emptyCell: {
        width: 0,
        padding: 0,
        display: "none"
    }
}));

/**
 * DataTable Component
 * @param style : 테이블에 적용 할 스타일
 * @param component : HTML element 종류
 * @param size : Cell 크기
 * @param padding : Cell 페딩
 * @param headContent : 테이블 컬럼명
 * @param bodyContent : 테이블 내용
 * @param oncClickRowEvent : 테이블 Row 클릭 시 호출 이벤트
 * @param currentPage : 현재 페이지
 * @param totalPage : 전체 페이지 개수
 * @param enablePaging : 페이징에 표시 여부
 * @param isFirstButton : 페이징에 처음으로 가기 버튼표기 여부
 * @param isLastButton : 페이징에 처음으로 가기 버튼표기 여부
 * @param onChangePageEvent : 페이지 변경 이벤트 발생 시 호출할 함수
 * @returns {JSX.Element} : <DataTable ../>
 * @constructor
 */
export default function DataTable({
                                      style,
                                      component,
                                      size,
                                      padding,
                                      headContent,
                                      bodyContent,
                                      oncClickRowEvent,
                                      currentPage,
                                      totalPage,
                                      enablePaging,
                                      isFirstButton,
                                      isLastButton,
                                      onChangePageEvent,
                                      isSort,
                                      isAlwaysVisibleSort,
                                      changeSortEvent,
                                      emptyText,
                                      minHeight
                                  }) {

    const tableStyle = tableStyles();
    if (style === undefined) {
        style = {};
    }
    const headCount = headContent !== undefined ? headContent[headContent.length - 1].length : 0;
    const [sort, setSort] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    let sortTarget = null;

    const onClickSortLabel = (id) => (event) => {
        if (sortTarget === null || sortTarget !== id) {
            sortTarget = id;
            setSort('asc');
        } else {
            setSort(sort ? (sort === 'asc' ? 'desc' : false) : 'asc');
        }

        const changeContent = changeSortEvent(bodyContent, sortTarget, sort);
        bodyContent = changeContent;
    }

    const pageChanged = (event, pageIndex) => {
        const searchContent = onChangePageEvent(event, pageIndex, bodyContent);
        if (searchContent) {
            bodyContent = searchContent;
        }
    };

    if (bodyContent === undefined || bodyContent.length < 1) {
        let lastHeadCount = 5;
        if (headContent !== undefined && headContent.length > 0) {
            lastHeadCount = headContent[headContent.length - 1].length;
        }
        bodyContent = [
            [{colspan: lastHeadCount, content: emptyText}]
        ];

        style.emptyRow = (style.emptyRow ? style.emptyRow : tableStyle.emptyRow);
        oncClickRowEvent = undefined;
        enablePaging = false;
    } else {
        style.bodyRow = (style.bodyRow ? style.bodyRow : tableStyle.defaultRow);
    }

    let tableBoxStyle = "";
    if (isMobile) {
        tableBoxStyle = {minHeight: minHeight + 'px', overflowX: 'scroll'};
    } else {
        tableBoxStyle = {minHeight: minHeight + 'px'};
    }

    return (
        <React.Fragment>
            <div style={tableBoxStyle}>
                <Table component={component}
                       className={style.table ? `${style.table} ${tableStyle.defaultTable}` : tableStyle.defaultTable}
                       size={size}
                       padding={padding}
                >
                    <TableHead>
                        <DataTableRows style={style ? style : null}
                                       rowDataList={headContent}
                                       variant={'head'}
                                       isSort={isSort}
                                       sort={sort}
                                       sortTarget={sortTarget}
                                       isAlwaysVisibleSort={isAlwaysVisibleSort}
                                       onClickSortLabel={onClickSortLabel}

                        />
                    </TableHead>

                    <TableBody>
                        <DataTableRows style={style ? style : null}
                                       rowDataList={bodyContent} variant={'body'}
                                       limitCol={headCount}
                                       oncClickRowEvent={oncClickRowEvent}
                        />
                    </TableBody>
                </Table>
            </div>
            {
                enablePaging ?
                    (
                        <Container>
                            <Pagination currentPage={currentPage}
                                         totalPage={totalPage}
                                         onChangeEvent={pageChanged}
                            />
                        </Container>
                    ) : null
            }
        </React.Fragment>
    );
}


DataTable.defaultProps = {
    size: 'medium',
    padding: 'normal',
    enablePaging: true,
    isSort: false,
    isAlwaysVisibleSort: false,
    emptyText: 'No Data',
    minHeight: 373
}

DataTable.propTypes = {
    headContent: PropTypes.arrayOf(PropTypes.array),
    bodyContent: PropTypes.arrayOf(PropTypes.array),
    totalPage: PropTypes.number,
    currentPage: PropTypes.number,
    size: PropTypes.oneOf(['small', 'medium']),
    padding: PropTypes.oneOf(['normal', 'checkbox', 'none']),
    enablePaging: PropTypes.bool,
    isSort: PropTypes.bool,
    isAlwaysVisibleSort: PropTypes.bool,
    emptyText: PropTypes.string,
    minHeight: PropTypes.number,
}

/**
 * DataTable Rows
 * @param style : Row 들에 일괄 적용할 스타일
 * @param rowDataList : 출력할 데이터
 * @param variant : Cell 유형
 * @param limitCol : 화면에 표기 할 Cell 수, 넘어갈 경우 Cell을 화면에 출력하지 않음
 * @param oncClickRowEvent : Row 클릭 이벤트 발생 시 호출하는 함수
 * @param sort : 데이터 순서 정렬 방식
 * @param sortTarget : 데이터 순서 정렬 대상
 * @returns {*|null} : ...<DataTableRow ../>
 * @constructor
 */
function DataTableRows({
                           style,
                           rowDataList,
                           variant,
                           limitCol,
                           oncClickRowEvent,
                           isSort,
                           sort,
                           sortTarget,
                           isAlwaysVisibleSort,
                           onClickSortLabel
                       }) {
    let lastIndex = rowDataList.length - 1;
    return (
        rowDataList ? rowDataList.map((row, index) =>
                <DataTableRow key={index.toString()}
                              id={variant + '_' + index}
                              style={style}
                              rowContent={row}
                              variant={variant}
                              limitCol={limitCol}
                              oncClickRowEvent={oncClickRowEvent}
                              isSort={index === lastIndex ? isSort : false}
                              sort={sort}
                              sortTarget={sortTarget}
                              isAlwaysVisibleSort={isAlwaysVisibleSort}
                              onClickSortLabel={onClickSortLabel}
                />
            ) :
            null
    )
        ;
}

DataTableRows.defaultProps = {
    limitCol: -1,
}

DataTableRows.propTypes = {
    limitCol: PropTypes.number,
    rowDataList: PropTypes.array
}

/**
 * DataTable Row
 * @param style : 스타일
 * @param rowContent : 출력할 데이터
 * @param variant : Cell 유형
 * @param colspan : Cell 의 열 확장 개수
 * @param limitCol : 화면에 표기 할 Cell 수, 넘어갈 경우 Cell을 화면에 출력하지 않음
 * @param oncClickRowEvent : Row 클릭 이벤트 발생 시 호출하는 함수
 * @returns {JSX.Element|null} <DataTableRow ../>
 * @constructor
 */
function DataTableRow({
                          id,
                          style,
                          rowContent,
                          variant,
                          limitCol,
                          oncClickRowEvent,
                          isSort,
                          sort,
                          sortTarget,
                          isAlwaysVisibleSort,
                          onClickSortLabel
                      }) {
    const tableStyle = tableStyles();
    let rowStyle = null;
    let cellStyle = null;

    let emptyStyle = null;

    if (variant === 'head') {
        rowStyle = style.headRow ? style.headRow : tableStyle.defaultHeadRow;
        cellStyle = style.headCell ? style.headCell : tableStyle.defaultHeadCell;
    } else {
        rowStyle = style.bodyRow ? style.bodyRow : tableStyle.defaultRow;
        cellStyle = style.bodyCell ? style.bodyCell : tableStyle.defaultBodyCell;
    }

    if (rowContent.length === 1 && rowContent[0].content === "No Data") {
        emptyStyle = style.emptyRow;
        rowStyle = undefined;
    }

    return (
        rowContent !== undefined ?
            <TableRow key={id}
                      className={`${emptyStyle} ${rowStyle}`}
                      onClick={oncClickRowEvent !== undefined ? oncClickRowEvent(rowContent) : null}
            >
                {
                    rowContent.map((cell, idx) => {
                            return (
                                (limitCol < 0 || limitCol > idx) ?
                                    (<DataTableCell key={id + '_' + idx}
                                                    id={id + '_' + idx}
                                                    style={cellStyle}
                                                    cellData={cell.content}
                                                    align={cell.align}
                                                    colspan={cell.colspan}
                                                    variant={variant}
                                                    isSort={isSort}
                                                    sort={sort}
                                                    sortTarget={sortTarget}
                                                    sortLabelVisible={isAlwaysVisibleSort}
                                                    onClickSortLabel={onClickSortLabel}
                                        />
                                    ) : null
                            );
                        }
                    )}
            </TableRow>
            : null
    );
}

DataTableRow.propTypes = {
    rowContent: PropTypes.array
}


/**
 * DataTable Cell
 * @param style : 스타일
 * @param cellData : 출력 할 데이터
 * @param padding : padding
 * @param align : Cell 정렬
 * @param size : 크기
 * @param variant : 유형
 * @param colspan : 열 확장 개수
 * @param sortDirection : 데이터 순서 정렬 방식
 * @returns {JSX.Element} : <DataTableCell ../>
 * @constructor
 */
function DataTableCell({
                           id,
                           style,
                           cellData,
                           padding,
                           align,
                           size,
                           variant,
                           colspan,
                           isSort,
                           sort,
                           sortTarget,
                           sortLabelVisible,
                           onClickSortLabel
                       }) {
    const classes = tableStyles();
    return (
        isSort ?
            <DataTableSortCell id={id}
                               style={style}
                               padding={padding}
                               align={align}
                               size={size}
                               variant={variant}
                               cellData={cellData}
                               colSpan={colspan}
                               sort={sort}
                               sortTarget={sortTarget}
                               sortLabelVisible={sortLabelVisible}
                               onClickSortLabel={onClickSortLabel}
            />
            : <TableCell key={id}
                         className={cellData !== undefined ? style : classes.emptyCell}
                         padding={padding}
                         align={align}
                         size={size}
                         variant={variant}
                         colSpan={colspan}
            >
                {cellData}
            </TableCell>
    );
}

DataTableCell.defaultProps = {
    padding: 'normal',
    align: 'center',
    size: 'medium',
    colspan: 1,
    sort: false,
    sortLabelVisible: false,
}

DataTableCell.prototype = {
    colspan: PropTypes.number,
    padding: PropTypes.oneOf(['normal', 'checkbox', 'none']),
    align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
    size: PropTypes.oneOf(['medium', 'small']),
    variant: PropTypes.oneOf(['body', 'footer', 'head']),
    sortLabelVisible: PropTypes.bool,
}


function DataTableSortCell({
                               id,
                               style,
                               padding,
                               align,
                               size,
                               variant,
                               colspan,
                               cellData,
                               sortLabelVisible,
                               sort,
                               sortTarget,
                               onClickSortLabel
                           }) {
    const key = id;
    const classes = tableStyles();
    let isTarget = key === sortTarget;
    return (
        <TableCell key={key}
                   className={cellData !== undefined ? style : classes.emptyCell}
                   padding={padding}
                   align={align}
                   size={size}
                   variant={variant}
                   colSpan={colspan}
                   sortDirection={isTarget ? sort : false}
        >
            <TableSortLabel active={sortLabelVisible ? true : isTarget}
                            direction={isTarget ? (sort ? sort : 'asc') : 'asc'}
                            onClick={onClickSortLabel(id)}
            >
                {cellData}
            </TableSortLabel>
        </TableCell>
    );
}
