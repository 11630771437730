import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
import {CommonCodeValue} from "../common/types";
import {dataFormat} from "../common/utils/StringFormatUtils";

const useStyles = makeStyles((theme) => ({
    text: {},
}));

export default function LabelText(props) {
    const {t} = useTranslation();

    const {label, translation, required, value, dataType, format, className} = props;
    const requiredKey = " *";
    let labelText = "";

    if( value === undefined ) {
        if (label === undefined || label === "") {
            labelText = "-";
        } else {
            if (translation === false) {
                labelText = label;
            } else {
                labelText = t(label);
            }

            if (required) {
                labelText += requiredKey;
            }
        }
    } else {
        if( dataType === 'commonCode'){
            labelText = t('CommonCode.'+value);
        } else {
            labelText = dataFormat(value, dataType, format);
        }
    }

    return (
        <Typography variant={"inherit"} className={className!==undefined?className:null}>{labelText}</Typography>
    )
}
