import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PrivacyPolicyDialog from '../../components/PrivacyPolicyDialog';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            width: 'initial !important',
            height: 'calc(100vh - 52px) !important',
        },
    },
    contact: {
        display: 'flex',
        flex: 1,
        height: 'inherit',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#0F1014',
        backgroundSize: 'cover',
    },
    privacyPolicyBox: {
        marginLeft: 70,
        width: '100%',
        height: '100%',
        textAlign: 'left',
        '& > h2': {
            fontWeight: '700',
            fontSize: '32px',
            lineHeight: '68px',
            marginBottom: 20,
        },
        [theme.breakpoints.down('md')]: {
            margin: '0',
            padding: '0 20px',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center', // Center align content on mobile
            justifyContent: 'center', // Center align content on mobile
            textAlign: 'center', // Center align content on mobile
            width: 'calc(100% - 40px)', // Adjust width to center align on mobile
            height: 'calc(80% - 73px)',
            '& > h2': {
                fontSize: '30px',
                lineHeight: '48px',
                marginTop: 0, // Adjust the margin top to center align on mobile
            },
        },
    },
}));

const PrivacyPolicyForm = ({ forwardedRef }) => {
    const classes = useStyles();
    const innerRef = useRef();
    const outerRef = forwardedRef;
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const innerRefCurrent = innerRef.current;

        const handleClick = (e) => {
            setOpenDialog(true);
        };

        innerRefCurrent?.addEventListener('click', handleClick);

        return () => {
            innerRefCurrent?.removeEventListener('click', handleClick);
        };
    }, []);

    const onCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className={`${classes.contact} ${classes.container}`}>
            <Box className={classes.privacyPolicyBox}>
                <h2>개인정보처리방침</h2>
                <div>
                    <div>
                        ㈜에프엔에스벨류의 개인정보처리방침은 아래의 링크에서 시행일자별로 확인하실 수 있습니다.
                    </div>
                    <div
                        ref={innerRef}
                        style={{
                            display: 'inline-block',
                            marginTop: 30,
                            cursor: 'pointer',
                            background: '#555',
                            borderRadius: '5px',
                            padding: '5px',
                        }}
                    >
                        • 2023.08.01 이후 적용되는 개인정보처리방침
                    </div>
                    <PrivacyPolicyDialog open={openDialog} onCloseEvent={onCloseDialog} />
                </div>
            </Box>
        </div>
    );
};

export default PrivacyPolicyForm;
