import React from 'react';
import {action, observable} from 'mobx';
import {MobXProviderContext} from 'mobx-react';
import theme from './theme';

class Store {
    @observable
    isLogin = false;

    @observable
    token = null;

    @observable
    user = null;

    theme = theme;

    isMobileDevice = Boolean(navigator.userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    ));

    alertFunc = null;

    @action
    setLogin = (isLogin) => {
        this.isLogin = isLogin;
    };

    @action
    setToken = (token) => {
        this.token = token;
    };

    @action
    setUser = (user) => {
        this.user = user;
    };

    showAlert(severity, message) {
        this.alertFunc(severity, message);
    }

    @action
    showSuccessAlert(message) {
        this.showAlert("success", message);
    }

    @action
    showWarningAlert(message) {
        this.showAlert("warning", message);
    }

    @action
    showErrorAlert(message) {
        this.showAlert("error", message);
    }

    @action
    showInfoAlert(message) {
        this.showAlert("info", message);
    }
}

export default new Store();

export function useStores() {
    return React.useContext(MobXProviderContext);
}