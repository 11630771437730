import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const EventHistoryStyled = makeStyles((theme) => ({
    root: {
        '& *': {
            fontFamily: 'Poppins !important'
        }
    },
    filters: {
        backgroundColor: '#fff',
        border: '1px solid #515151',
        borderBottom: '1px solid #9e9fa3',
        borderRadius: 5,
        marginBottom: 30,
        '& > :first-child': {
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            padding: '30px 30px 20px 30px',
            gap: '20px 35px'
        },
        '& > :last-child': {
            minHeight: 90,
            backgroundColor: '#ECEDF550',
            borderTop: '1px solid #C1C9DB',
            borderRadius: '0px 0px 4px 4px',
            padding: '20px 30px',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                rowGap: 20
            }
        },
        '& > :nth-last-child(2)': {
            paddingBottom: 36
        },
        '& > * .MuiOutlinedInput-notchedOutline': {
            borderColor: '#C1C9DB'
        }
    },
    keywords: {
        minHeight: 30,
        '& p': {
            wordBreak: 'break-all',
            overflow: 'hidden',
            maxHeight: 32,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 250
        }
    },
    deleteIcon: {
        width: 7,
        height: 7,
        padding: 0,
        '& > svg': {
            fontSize: '16px'
        }
    },
    actionBtn: {
        columnGap: 8,
        marginLeft: 20,
        '& > button': {
            width: 159,
            height: 47,
            borderRadius: 5,
            fontSize: 14,
            fontWeight: 600
        },
        '& > :first-child': {
            backgroundColor: '#4046BC',
            color: '#fff'
        },
        '& > :last-child': {
            backgroundColor: '#fff',
            color: '#565568',
            border: '1px solid #B7BECD'
        }
    },
    inputDate: {
        '& > p': {
            color: '#121212',
            fontSize: 13,
            fontWeight: 500
        },
        '& span': {
            color: '#EF346C',
            fontSize: 13,
            fontWeight: 500
        }
    },
    dateSelectBtn: {
        height: 36,
        minWidth: 250,
        maxWidth: '100%',
        width: 'auto',
        border: '1px solid #C1C9DB',
        borderRadius: 5,
        justifyContent: 'flex-start',
        padding: '8px 15px',
        transitionDuration: 0,
        '& .MuiButton-label': {
            color: '#565568',
            fontSize: 12,
            width: '100%',
            lineHeight: '20px'
        },
        '& .MuiButton-endIcon': {
            marginLeft: 'auto',
            color: '#8D96AB',
            '& > svg': {
                width: 20,
                height: 20
            }
        },
        '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid #3C39CB',
            '& .MuiButton-label': {
                color: '#222',
            },
            '& .MuiButton-endIcon': {
                color: '#3C39CB'
            },
        }
    },
    error: {
        borderColor: '#EF346C',
        backgroundColor: '#FFEEF3'
    },
    pageControl: {
        marginTop: 40,
        '& > :nth-child(2)': {
            justifySelf: 'flex-end',
            marginLeft: 'auto'
        }
    },
    pagination: {           // 새로 나온 디자인
        '& ul > li' : {
            '& .MuiPaginationItem-previousNext': {
                backgroundColor: '#fff !important',
                '& svg > path': {
                    fill: '#8E8DAB'
                }
            },
            '& .MuiPaginationItem-root': {
                backgroundColor: 'transparent',
                border: 'none',
                color: '#8D96AB',
                fontWeight: 700,
                fontSize: 13
            },
            '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: '#4046BC',
                color: '#fff'
            }
        },
        '& ul > li:first-child > .MuiButtonBase-root.Mui-disabled': {
            opacity: 1,
            '& svg > path': {
                fill: '#8E8DAB50'
            }
        }
    },
    tableArea: {
        background: '#FFF',
        padding: '35px 40px',
        borderRadius: 5,
        '& div:first-child': {
            overflowX: 'scroll'
        },
        '& thead .timeSort': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '& tbody > tr > td': {
            maxWidth: 200,
            wordBreak: 'keep-word',
            '&:not(:first-child)': {
                minWidth: 100,
                whiteSpace: 'pre-line'
            },
            '&:nth-child(3)': {
                maxWidth: 200,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            '& > p': {
                maxHeight: 50,
                fontSize: '14px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                '& > :first-child': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                },
                '& ol, ul': {
                    marginBlockEnd: 0,
                    marginBlockStart: 0,
                    display: '-webkit-box',
                    '-webkit-line-clamp': 1,
                    '-webkit-box-orient': 'vertical'
                }
            }
        }
    },
    sortIcon: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 6,
        transform: 'translate(0px, 3px)',
        '& .MuiSvgIcon-root': {
            fontSize: 15
        },
        '& > [aria-selected="true"]': {
            fill: '#565568'
        }
    },
    licenseSearch: {
        padding: '0px 30px 36px 30px',
        minHeight: 36,
        height: 'auto',
        '& .MuiTextField-root': {
            minHeight: 36,
            height: 'auto',
        },
        '& .MuiOutlinedInput-multiline': {
            padding: '9px 15px'
        },
        '& .MuiOutlinedInput-input': {
            padding: 0,
            minHeight: 18
        }
    },
    filterLabel: {
        color: '#121212',
        fontSize: 13,
        fontWeight: 500
    },
    inputSearch: {
        height: 36,
        minWidth: 250,
        maxWidth: '100%',
        width: 'auto',
        '& .MuiInputBase-formControl': {
            maxHeight: 36,
            paddingRight: 7
        },
        '& .MuiOutlinedInput-input': {
            height: 18,
            padding: '9px 15px',
        },
        '& .MuiSvgIcon-root': {
            color: '#8D96AB'
        },
        '&:hover .MuiSvgIcon-root': {
            color: '#3C39CB'
        },
        '& input::placeholder': {
            color: '#565568',
            opacity: 1
        },
        '& > .Mui-disabled': {
            backgroundColor: '#F5F6FA',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#C1C9DB !important'
            },
            '&:hover .MuiSvgIcon-root': {
                color: '#8D96AB'
            }
        }
    },
    menuList: {
        maxHeight: 500,
        '& .MuiPaper-root': {
            border: '1px solid #3C39CB'
        },
        '& .MuiMenuItem-root': {
            height: 40,
            margin: '0 5px 0 5px',
            padding: '6.5px 10px 6.5px 10px !important',
            borderRadius: 2,
            fontSize: 12,
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            '&:hover': {
                color: '#3C39CB',
                backgroundColor: '#F2F1F8'
            }
        }
    }

}));


function getEventHistoryStyled() {
    return EventHistoryStyled();
}

export {getEventHistoryStyled}