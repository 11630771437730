import React from 'react';
import Box from '@material-ui/core/Box';
import {useTheme} from "@material-ui/core";

export function LogoGifImage(props) {
    const theme = useTheme();
    const w = props.width ? props.width : '2.1875rem';
    const h = props.height ? props.height : '2.5rem';
    const logo = require(theme.type === 'gccs' ? '../images/gccs/logo_img.gif' : '../images/bsa/logo_img_dark.png')
    return (
        <img src={logo} width={w} height={h} alt='Guardian-CCS logo'/>
    );
}

export function LogoImage(props) {
    const theme = useTheme();
    const w = props.width ? props.width : '2.1875rem';
    const h = props.height ? props.height : '2.5rem';
    return (
        <img src={`${require('../images/bsa/BSA_logo_main.png')}`}
             width={w} height={h} alt='BSA logo'
             className={props?.className && props.className}
        />
    );
}

export function FnsvLogoImage(props) {
    const theme = useTheme();
    const w = props.width ? props.width : '2.1875rem';
    const h = props.height ? props.height : '2.5rem';
    return (
        <img src={`${require('../images/fnsv/fnsv_logo.png')}`} width={w} height={h} alt='FNS Value logo'/>
    );
}

export function SmallLogoImage() {
    return (
        <img src={`${require('../images/gccs/logo_small.png')}`}/>
    );
}

export function BigLogoImage() {
    return (
        <img src={`${require('../images/gccs/logo_big.png')}`}/>
    );
}

export function QrLogoImageSrc() {
    return `${require('../images/qr_logo.svg')}`;
}

export function LogoTitle(props) {
    const theme = useTheme();
    const w = props.width ? props.width : '10.1875rem';
    const h = props.height ? props.height : '1.5rem';
    const c = props.color ? props.color : theme.palette.text.primary;
    return (
        <Box {...props}>
            <svg viewBox="0 0 317.89 43.76" width={w} height={h}>
                <path fill={c}
                      d="M28,2.11V6.69H9.85c-3.75,0-5.63,1.83-5.63,5.51V33.43c0,2.11.45,3.6,1.36,4.46a4.82,4.82,0,0,0,3.47,1.29H24.4v-14H15.54V20.47h13.2V43.76H10q-5.34,0-7.65-2.64T0,32.85V12.43Q0,7.11,2.79,4.6c1.85-1.66,4.68-2.49,8.47-2.49Z"/>
                <path fill={c} d="M39.41,13.31V33.9q0,5.28,5,5.28H54.49V13.31h4.1V43.76H45q-9.8,0-9.8-10.5V13.31Z"/>
                <path fill={c}
                      d="M77.6,13.31q5,0,7.21,2.85A12.41,12.41,0,0,1,87,23.87V43.76H71.73a8.52,8.52,0,0,1-6.19-2.26q-2.31-2.27-2.31-6.84a8.16,8.16,0,0,1,2.37-6.33,12.31,12.31,0,0,1,6.6-2.82L82.64,24v-.53a6.73,6.73,0,0,0-1.08-4.19q-1.09-1.38-3.9-1.38H67.57V13.31Zm5,25.87V28.56L71.67,30a5.18,5.18,0,0,0-3,1.44,4.39,4.39,0,0,0-1.11,3.19,4.43,4.43,0,0,0,1.2,3.44,4.19,4.19,0,0,0,2.9,1.08Z"/>
                <path fill={c}
                      d="M107.22,13.31v4.58h-5.4c-3.28,0-4.93,1.76-4.93,5.28V43.76H92.67V23.87q0-10.56,9.8-10.56Z"/>
                <path fill={c}
                      d="M133.32,0V43.76h-13.9q-8.92,0-8.92-9.57V23.46q0-10.14,8.86-10.15h9.85V0Zm-18.6,34.84q0,4.33,4.23,4.34h10.14V17.89h-9.26q-2.82,0-3.9,1.47a7.62,7.62,0,0,0-1.21,4.28Z"/>
                <path fill={c} d="M143.17,0V5.63H139V0Zm0,13.31V43.76H139V13.31Z"/>
                <path fill={c}
                      d="M162.35,13.31c3.36,0,5.77.95,7.21,2.85a12.41,12.41,0,0,1,2.17,7.71V43.76H156.48a8.48,8.48,0,0,1-6.18-2.26Q148,39.24,148,34.66a8.16,8.16,0,0,1,2.37-6.33,12.31,12.31,0,0,1,6.6-2.82L167.39,24v-.53a6.73,6.73,0,0,0-1.08-4.19q-1.09-1.38-3.9-1.38H152.32V13.31Zm5,25.87V28.56l-11,1.47a5.21,5.21,0,0,0-3,1.44,4.39,4.39,0,0,0-1.11,3.19,4.43,4.43,0,0,0,1.2,3.44,4.19,4.19,0,0,0,2.9,1.08Z"/>
                <path fill={c}
                      d="M191.91,13.31q9.85,0,9.85,10.56V43.76h-4.22V23.17q0-5.28-5-5.28H181.88V43.76h-4.11V13.31Z"/>
                <path fill={c} d="M220.65,23.58V28h-12.2V23.58Z"/>
                <path fill={c}
                      d="M254.73,2.11v4.4H238.54q-6.1,0-6.1,5.75V32.55a7.66,7.66,0,0,0,1.38,4.93,4.72,4.72,0,0,0,3.9,1.7h16.71v4.58H239c-3.84,0-6.58-1-8.24-2.94s-2.5-5-2.5-9.15V13.08q0-5.8,2.64-8.39t8.39-2.58Z"/>
                <path fill={c}
                      d="M286.34,2.11v4.4H270.15q-6.11,0-6.1,5.75V32.55a7.66,7.66,0,0,0,1.38,4.93,4.75,4.75,0,0,0,3.9,1.7h16.72v4.58H270.56q-5.74,0-8.24-2.94t-2.49-9.15V13.08q0-5.8,2.64-8.39t8.38-2.58Z"/>
                <path fill={c}
                      d="M315.66,2.11V6.69H301.18a7,7,0,0,0-4.81,1.55c-1.18,1-1.76,2.67-1.76,4.9q0,3.69,1.38,5.45a4.75,4.75,0,0,0,4,1.76h7.86q5.34,0,7.69,3t2.34,9.09q0,11.26-11.84,11.27H291.62V39.18h15.66a6.42,6.42,0,0,0,4.63-1.58c1.1-1.06,1.64-2.82,1.64-5.28a16.79,16.79,0,0,0-.32-3.58,5.32,5.32,0,0,0-1-2.26,3.77,3.77,0,0,0-1.79-1.2,8.1,8.1,0,0,0-2.55-.35h-7.21q-5.34,0-7.8-2.91t-2.46-8.94c0-3.68.95-6.42,2.87-8.24s4.94-2.73,9.09-2.73Z"/>
            </svg>
        </Box>
    );
}

export function MenuLogo(props) {

    const theme = useTheme();
    let w = props.width ? props.width : '12.938rem';
    let h = props.height ? props.height : '4.125rem';

    if (theme.type === 'bsa') {
        w = props.width ? props.width : '9.25rem';
        h = props.height ? props.height : '4.063rem';
    }

    return (
        <img style={{width: w, height: h}}
             src={`${require('../images/bsa/menu_logo.png')}`}
             alt={'BSA Menu Logo Image'}/>
    );
}