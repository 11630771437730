import {createTheme} from '@material-ui/core/styles';
import '../css/fonts.css'
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

/*
* type: Login, Menu Image Theme ( gccs, bsa )
* storeType: Login Page Store Link (vip, azure, poc, prod )
*            vip : lab.fnsvalue.co.kr
*            azure : azlab.fnsvalue.co.kr
*            poc : api.tabgdc.co.my
*            prod: api.onebsa.com.my
* */

const breakpoints = createBreakpoints({});

export default createTheme({
    type : process.env.REACT_APP_THEME_TYPE,
    storeType:process.env.REACT_APP_STORE_TYPE,

    palette: {
        primary: {
            light: '#6698ff',
            main: '#3C39CB',
            dark: '#3532C1',
            contrastText: '#fff',
        },
        secondary: {
            light: '#22293E',
            main: '#171E31',
            dark: '#009bcc',
            contrastText: '#fff'
        },
        background: {
            panel:'#e7e7e7',
            paper: '#fff',
            default: '#F1F2F6',
            sideMenu:'#fff',
            scroll:'#b8bbbeb3',
            blackBox:'#21262e',
            tab:'#FFF',
            contrast: '#F2F1F8'
        },
        text: {
            primary: '#5C5C62',
            secondary: '#7E7E88',
            disabled: 'rgb(170, 170, 170)',
            hint: 'rgb(170, 170, 170)',
            divider: 'rgb(215, 215, 215)',
            title:'#3A3A3B',
            gray:'#838992',
            black: '#222',
            white:'#fff',
        },
        border: {
            primary:'#DBDADA',
            secondary: "1px solid #3C39CB",
            gray: '1px solid #E6E6E6',
        },
        table: {
            head: '#7386AD'
        },
        tag: {
            purple: '#EBEAFD',
            green: '#237B6C',
            lightGreen: '#E7F8F5',
            blue: '#005BBD',
            lightBlue: '#E5F2FF',
            success: '#D7D7F5',
            fail: '#FBDBDB'
        },
        shadow: {
            primary: "0px 2px 4px rgba(0, 0, 0, 0.2)"
        },
        button: {
            background: '#3D39CC',
            hover: '#3C58E9'
        },
        type: 'dark'
    },
    typography: {
        fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 700,
            fontSize: '1.75rem',
            lineHeight: 1.167,
            letterSpacing: '-0.01562em',
            [breakpoints.down('sm')]: {
                fontSize: '1.313rem',
            }
        },
        h2: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 300,
            fontSize: '1.625rem',
            lineHeight: 1.167,
            letterSpacing: '-0.00833em'
        },
        h3: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.5rem',
            lineHeight: 1.167,
            letterSpacing: '0em'
        },
        h4: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 400,
            fontSize: '1.375rem',
            lineHeight: 1.235,
            letterSpacing: '0.00735em'
        },
        h5: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        h6: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 300,
            fontSize: '1rem',
            lineHeight: 1.334,
            letterSpacing: '0.0075em'
        },
        subtitle1: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em',
            [breakpoints.down('sm')]: {
                fontSize: '0.563rem',
            }
        },
        subtitle2: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.688rem',
            lineHeight: 1.57,
            letterSpacing: '0.00714em'
        },
        body1: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.75rem',
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            [breakpoints.down('sm')]: {
                fontSize: '0.688rem'
            }
        },
        body2: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.688rem',
            lineHeight: 1.43,
            letterSpacing: '0.01071em'
        },
        button: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.75rem',
            lineHeight: 1.75,
            letterSpacing: '0.00938em',
            textTransform: 'uppercase',
            [breakpoints.down('sm')]: {
                fontSize: '0.688rem'
            }
        },
        tag: {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.563rem',
            lineHeight: '10.35px',
            letterSpacing: '0.00938em'
        }
    },
    buttons:{
        btn1 : {
            background : {
                hover:'#6a6d72',
                default: '#4c5159'
            },
            color:'#bbb',
        },
        primary : {
            backgroundColor: '#171E31',
            color: '#fff',
            "&:hover": {
                backgroundColor: '#3C39CB',
                color: '#fff',
            }
        },
        secondary : {
            border: '1.5px solid #C7CBD1',
            "&:hover": {
                border: '1px solid #3C39CB',
                backgroundColor: 'transparent',
                color: '#3C39CB'
            }
        }
    },
    select: {
        // Select box style
        maxWidth: 200,
        width: 200,
        '&:hover .MuiInputLabel-outlined': {
            color: '#222'
        },
        "& .MuiSelect-outlined.MuiSelect-outlined":{
            height: 27,
            lineHeight: '23px',
            padding: "8px 15px 8px 15px !important",
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 500,
            fontSize: '0.75rem',
            color: '#5C5C62'
        },
        "& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined": {
            width: 0,
            height: 0
        },
        '& .MuiListItem-button:hover': {
            backgroundColor: '#F2F1F8',
            color: '#3C39CB'
        },
        '& .MuiList-root.MuiMenu-list.MuiList-padding': {
            '& > *': {
                height: '40px',
                margin: '0 5px 0 5px',
                padding: "6.5px 10px 6.5px 10px !important",
                borderRadius: '2px'
            }
        },
        '& .MuiMenuItem-root': {
            minHeight: 40
        },
        '& .MuiInputBase-root': {
            height: '40px',
            paddingRight: 0,
            '& > div > div > *': {
                '& .MuiSvgIcon-root': {
                    color: '#8D96AB'
                }
            },
            '&:hover > div > div > *': {
                '& .MuiSvgIcon-root': {
                    color: '#3C39CB'
                }
            },
        },
        '& .Mui-disabled': {
            '&:hover > div > div > *': {
                '& .MuiSvgIcon-root': {
                    color: '#8D96AB'
                }
            },
        },
        '& .MuiOutlinedInput-root:hover': {
            '& > fieldset': {
                border: "1px solid #3C39CB"
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                color: '#222'
            }
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C39CB"
        }
    },
    picker: {
        // DatePicker style
        maxWidth: 200,
        width: 200,
        '&:hover .MuiInputLabel-outlined': {
            color: '#222'
        },
        '& .MuiInputBase-root': {
            height: '40px',
            paddingRight: 0,
            '& > input': {
                "&::placeholder": {
                    color: '#5C5C62',
                    opacity: 1
                },
                "&:hover": {
                    "&::placeholder": {
                        color: 'black',
                        opacity: 1
                    },
                }
            }
        },
        '& .MuiSvgIcon-root': {
            color: '#8D96AB'
        },
        '&:hover .MuiSvgIcon-root': {
            color: '#3C39CB'
        }
    },
    props: {
        MuiTypography: {}
    },
    // default component style customizing
    overrides: {
        MuiOutlinedInput: {
            root: {
                "&:hover": {
                    color: '#222'
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #DBDADA"
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #3C39CB",
                    color: '#222'
                },
            }
        },
        MuiToggleButton: {
            root: {
                color: 'rgba(0, 0, 0, 0.38)',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                '&.Mui-selected': {
                    color: 'rgba(0, 0, 0, 0.54)',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
                '&.Mui-selected:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.15) !important'
                },
            },
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                color: '#3C39CB'
            }
        },
        MuiCssBaseline: {
            "@global": {
                body: {

                    // globally customizing the scrollbar (전체 포탈에 씬 커스텀 스크롤바 적용)
                    overflow: 'overlay',
                    scrollbarColor: 'rgba(184, 187, 190, 0.7) transparent', // firefox
                    scrollbarWidth: 'thin',                                 // firefox
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: "transparent",
                        width: "7px",
                        height: "7px"
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: "3px",
                        backgroundColor: "rgba(184, 187, 190, 0.3)",
                        border: '1px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: "rgba(184, 187, 190, 0.7)",
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: "rgba(184, 187, 190, 0.7)",
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgba(184, 187, 190, 0.7)",
                    },
                }
            }
        }
    }
});
