import LabelText from "../../components/LabelText";
import Grid from "@material-ui/core/Grid";
import Cards from "../../components/Cards";
import {useStores} from "../../common/store";
import {makeStyles} from "@material-ui/core/styles";
import {PhoneNumberFormat} from "../../common/utils/StringFormatUtils";
import {useObserver} from "mobx-react-lite";
import React from "react";
import {useTranslation} from "react-i18next";

const infoPageStyle = makeStyles((theme) => ({
        form: {
            '& > div > .MuiGrid-item': {
                display: 'flex',
                '& > *': {
                    backgroundColor: '#FFF'
                }
            }
        },
    })
);

export default function InfoPage() {
    const classes = infoPageStyle();
    const {ds} = useStores();

    return useObserver(() =>
        <form noValidate autoComplete="off" className={classes.form}>
            <Grid container spacing={5}>
                <Grid item md={6} sm={12} xs={12}>
                    <InfoCard info={ds.user}/>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <StatusCard info={ds.user}/>
                </Grid>
            </Grid>
        </form>
    );
}

function InfoCard({info}) {
    const {t} = useTranslation();
    const phoneNumber = PhoneNumberFormat(info?.phoneNum);
    const userInfo = [
        {label: t('MyPage.MyInfo.Info.Client'), component: LabelText, value: info !== null ? info.clientName : '-'},
        {label: 'Type ', component: LabelText, value: info !== null ? info.userType : '-' , dataType: 'commonCode'},
        {label: t('MyPage.MyInfo.Info.UserId'), component: LabelText, value: info !== null ? info.userKey : '-'},
        {label: t('MyPage.MyInfo.Info.Name'), component: LabelText, value: info !== null ? info.name : '-'},
        {label: t('MyPage.MyInfo.Info.Email'), component: LabelText, value: info !== null ? info.email : '-'},
        {label: t('MyPage.MyInfo.Info.Telno'), component: LabelText, value: phoneNumber},
    ];
    return (
        <Cards title={'MyPage.MyInfo.Info.Title'} components={userInfo}/>
    );
}


function StatusCard({info}) {
    const {t} = useTranslation();
    const userStatus = [
        {label: t('MyPage.MyInfo.Status.Status'), component: LabelText, value: info !== null ? info.userStatus : '-', dataType: 'commonCode'},
        {label: t('MyPage.MyInfo.Status.RegDate'), component: LabelText, value: info !== null ? info.regDt : '-', dataType: 'date', format: 'YYYY/MM/DD'},
        {label: t('MyPage.MyInfo.Status.DeviceDate'), component: LabelText, value: info !== null ? info.regDt : '-', dataType: 'date', format: 'YYYY/MM/DD'},
        // {label: t('MyPage.MyInfo.Status.DeviceDate'), component: LabelText, value: info !== null ? info.deviceRegDt : '-', dataType: 'date', format: 'YYYY/MM/DD'},
    ];
    return (
        <Cards title={'MyPage.MyInfo.Status.Title'} components={userStatus}/>
    );
}

