import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import store, {useStores} from '../../common/store';
import {useObserver} from 'mobx-react-lite';
import {Redirect} from 'react-router-dom';
import Homepage from "../fnsv/Homepage";
import LoginForm from "./LoginForm";
import {Slide, useMediaQuery} from "@material-ui/core";
import {FnsvLogoImage} from "../../components/Logo";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "../../components/LanguageSelector";
import {Provider} from 'mobx-react';
import {ReactComponent as MenuIcon} from "../../images/topBar/menu_icon.svg";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LoginMain from "./LoginMain";

const useStyles = makeStyles((theme) => ({
    root:{
        position:'relative',
        zIndex:1,
        display:'flex',
        flex:1,
        width:'100vw',
        height:'100vh',
        maxWidth:'initial',
        margin:0,
        padding:0,
        overflow:'hidden'
    },
    header:{
        width:'100vw',
        position:'absolute',
        top:0,
        display:'flex',
        flex:1,
        alignItems:'center',
        justifyContent:'space-between',
        padding:'40px 40px 0 40px',
        zIndex:1,
        [theme.breakpoints.between('xs', 'md')]: {
            padding:'15px',
        }
    },
    logoBox:{
        cursor:'pointer'
    },
    buttonContainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    loginShowBtn: {
        fontWeight: 700,
        fontSize: '0.75rem',
        width: 120,
        height: 36,
        borderRadius: "19.5px",
        backgroundColor: theme.palette.button.background,
        '&:hover': {
            backgroundColor: theme.palette.button.hover,
        },
        color: theme.palette.primary.contrastText,
    }
    ,loginBtn: {
        fontWeight: 700,
        fontSize: '0.75rem',
        width: 60,
        height: 36,
        color: theme.palette.primary.contrastText,
    },
    sideMenuBtn: {
        color: '#fff',
        marginLeft: '1.563rem',
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0.313rem'
        },
        [theme.breakpoints.between('lg', 'xl')]: {
            display: 'none'
        }
    },
    sideMenuIcon: {
        [theme.breakpoints.down('sm')]: {
            width: '1.5rem',
            height: '0.875rem'
        }
    },
    menu:{
        width:'100vw'
        ,height:'100vh'
        ,position: 'absolute'
        ,display: "flex"
        ,flexDirection:'column'
        ,background: 'rgba(17, 17, 22, 0.95);'
        ,zIndex:3
        ,padding:20
        ,color:'#fff'
        ,'& p, & span':{
            fontFamily:'Poppins'
        }
    }
    ,menuHeader:{
        display:'flex'
        ,justifyContent:'space-between'
        ,alignItems:'center'
    }
    ,closeIconBtn:{
        width:50
        ,height:50
    }
    ,menuListBox:{
        display:'flex'
        ,flex:1
        ,alignItems:'center'
        ,justifyContent:'center'
    }
    ,menuList:{
        width:150
        ,alignSelf:'center'
        ,margin:0
        ,padding:0
        ,listStyleType:'none'
        ,'& li':{
            display:'flex'
            ,marginBottom:28
            ,listStyle:'none'
            ,alignItems: 'center'
            ,justifyContent: 'space-between'
            ,fontWeight:500
            ,fontSize:14
            ,lineHeight:'21px'
            ,cursor:'pointer'
            ,opacity:'0.5'
        }
        ,'& li.active':{
            opacity:'1'
        }
        ,'& .subMenuTitle':{
            marginBottom:0,
        }
        ,'& .subMenu':{
            marginTop:5
            ,marginBottom:0
            ,marginLeft:10
        }
    }
}));

const MenuLayout = (dotRef, menuPanelHandler, loginPanelHandler) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [idx, setIdx] = useState(0);

    useEffect( () => {
        setIdx( dotRef.current?.sliderIdx );
    },[dotRef.current?.sliderIdx])

    const onMenuClick = (value) => {
        setIdx(value);
        dotRef.current?.slickSliderGoTo(value);
        menuPanelHandler(false);
    }
    const onLanguageClick = (e) => {
        menuPanelHandler(false);
    }

    return (
        <Box className={classes.menu}>
            <Box className={classes.menuHeader}>
                <IconButton onClick={() => menuPanelHandler(false)} className={classes.closeIconBtn} >
                    <CloseIcon />
                </IconButton>

                <LanguageSelector onClickHandler={onLanguageClick}/>
            </Box>

            <Box className={classes.menuListBox}>
                <ul className={classes.menuList}>
                    <li onClick={() => onMenuClick(0)} className={`${idx === 0 && 'active'}`}>MAIN</li>
                    <li onClick={() => onMenuClick(1)} className={`${idx === 1 && 'active'}`}>ABOUT</li>
                    <li onClick={() => onMenuClick(2)} className={`${idx === 2 && 'active'}`}>SOLUTION</li>
                    <li onClick={() => onMenuClick(3)} className={`${idx === 3 && 'active'}`}>NEWS</li>
                    <li onClick={() => onMenuClick(4)} className={`${idx === 4 && 'active'}`}>VIDEO</li>
                    <li onClick={() => onMenuClick(5)} className={`subMenuTitle ${idx === 5 && 'active'}`}>COMPANY</li>
                    <li onClick={() => onMenuClick(5)} className={`subMenu ${idx === 5 && 'active'}`}>Patent</li>
                    <li onClick={() => onMenuClick(6)} className={`subMenu ${idx === 6 && 'active'}`}>History</li>
                    <li onClick={() => onMenuClick(7)} className={`subMenu ${idx === 7 && 'active'}`}>Notice</li>
                    <li onClick={() => onMenuClick(8)} className={`subMenu ${idx === 8 && 'active'}`}>PrivacyPolicy</li>
                    <li onClick={() => onMenuClick(9)} className={`subMenu ${idx === 9 && 'active'} ${idx === 10 && 'active'}`}>Contact US</li>
                </ul>
            </Box>
        </Box>
    )
}

/** 로그인 화면: FNSV 홈페이지 + 왼쪽 로그인 form **/
export default function LoginLayout(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const [loginShow, setLoginShow] = useState(false);
    const [menuShow, setMenuShow] = useState(false);

    const loginPanelHandler = (prev) => { setLoginShow( prev ); }
    const menuPanelHandler = ( prev ) => { setMenuShow( prev); }

    const loginHandler = (e) => {
        menuPanelHandler(false);
        loginPanelHandler(true);
    }
    const dotRef = useRef();

    const onLogoClick = (value) => {
        dotRef.current?.slickSliderGoTo( value );
    }

    return (

        <Container component="main" className={classes.root}>
            <Slide direction='right' in={loginShow} timeout={{enter: 500, exit: 300}} onClose={() => loginHandler(false)}>
                {LoginForm( props, loginPanelHandler )}
            </Slide>

            <Slide direction='left' in={menuShow} timeout={{enter: 500, exit: 300}}>
                {MenuLayout(dotRef, menuPanelHandler, loginPanelHandler)}
            </Slide>


            <header className={classes.header}>

                <Box className={classes.logoBox} onClick={() => onLogoClick(0)}>
                    <FnsvLogoImage width={isMobile ? 153 : 204} height={'auto'}/>
                </Box>

                { isMobile
                    ?
                    <Box className={classes.buttonContainer}>

                        <Button className={classes.loginBtn} onClick={loginHandler}> {t('LoginPage.BtnLogin')} </Button>

                        <IconButton onClick={()=>menuPanelHandler(true)} className={classes.sideMenuBtn}>
                            <MenuIcon className={classes.sideMenuIcon}/>
                        </IconButton>
                    </Box>
                    :
                    <Box className={classes.buttonContainer}>
                        { !loginShow && <Button className={classes.loginShowBtn} onClick={() => loginPanelHandler(true)}> {t('LoginPage.BtnLogin')} </Button> }
                        <LanguageSelector />
                    </Box>
                }

            </header>

            <Homepage forwardRef={dotRef} loginPanelHandler={loginPanelHandler} />

        </Container>
    );
}
