import {useTranslation} from "react-i18next";
import {Bar} from "react-chartjs-2";
import React, {useEffect, useState} from "react";
import api from "../../common/api";
import {TimeUnit} from "../../common/types";

export default function LoginChart({type}) {

    const {t} = useTranslation();
    const [monthlyStats, setMonthlyStats] = useState([]);
    const [weeklyStats, setWeeklyStats] = useState([]);

    useEffect(() => {
        let monthViewCount = 7;
        let weekdayViewCount = 7;

        api.getMyAuthHistoryStats(TimeUnit.Month, monthViewCount)
            .then(data => {
                const responseArray = [];
                data.map((data, idx) => {
                    responseArray[idx] = {
                        dateStr: data.dateStr,
                        successCount: data.successCount,
                        failCount: data.failCount
                    }
                })
                setMonthlyStats(responseArray);
            })
            .catch(err => {
                console.log(err);
            });

        api.getMyAuthHistoryStats(TimeUnit.Day, weekdayViewCount)
            .then(data => {
                const responseArray = [];
                data.map((data, idx) => {
                    responseArray[idx] = {
                        dateStr: data.dateStr,
                        successCount: data.successCount,
                        failCount: data.failCount
                    }
                })
                setWeeklyStats(responseArray);
            })
            .catch(err => {
                console.log(err);
            });

    }, [])

    const getStepSize = (authHistoryStat) => {
        const maxCount = Math.max(...authHistoryStat.map(item => item.successCount), ...authHistoryStat.map(item => item.failCount));
        return maxCount < 10 ? 1 : 20;
    }

    const monthlyStep = getStepSize(monthlyStats);
    const weeklyStep = getStepSize(weeklyStats);

    const getLoginData = (authHistoryStat, labels) => {
        return {
            labels: labels,
            datasets: [
                {
                    label: 'Success',
                    backgroundColor: '#595FDB',
                    data: authHistoryStat.map(item => item.successCount),
                    maxBarThickness: 5,
                    barThickness: 9,
                    borderRadius: 10,
                    hoverBackgroundColor: '#595FDB'
                },
                {
                    label: 'Fail',
                    backgroundColor: '#4FAAFF',
                    data: authHistoryStat.map(item => item.failCount),
                    maxBarThickness: 5,
                    barThickness: 9,
                    borderRadius: 10,
                    hoverBackgroundColor: '#4FAAFF'
                },
            ],
        }
    }

    const monthlyLabels = monthlyStats.map(item => {
        const date = new Date(item.dateStr);
        const month = date.toLocaleString('en', {month: 'short'});
        const translationName = 'Home.Month.' + month;
        return t(translationName);
    });
    const monthlyData = getLoginData(monthlyStats, monthlyLabels);

    const weeklyLabels = weeklyStats.map(item => {
        const date = new Date(item.dateStr);
        const weekday = date.toLocaleString('en', {weekday: 'short'});
        const translationName = 'Home.Weekday.' + weekday;
        return t(translationName);
    });
    const weeklyData = getLoginData(weeklyStats, weeklyLabels);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 1700
        },
        scales: {
            y: {
                display: true,
                title: {
                    display: false,
                },
                grid: {
                    color: '#5A70A0',
                    drawBorder: false,
                    borderDash: [5],
                    lineWidth: 1,
                    drawTicks: false,
                },
                min: 0,
                ticks: {
                    font: {
                        family: 'Gmarket Sans',
                        size: 12,
                        weight: 400,
                    },
                    crossAlign: 'far',
                    padding: 10,
                    color: '#5A70A0',
                    stepSize: type === 'monthly' ? monthlyStep : weeklyStep,
                },
            },
            x: {
                grid: {
                    display: false,
                    drawBorder: true,
                    borderColor: '#5A70A0',
                },
                ticks: {
                    color: "#fff",
                    font: {
                        family: 'Gmarket Sans',
                        size: 12,
                        weight: 400,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            }
        }
    }

    return (
        <Bar data={type === 'monthly' ? monthlyData : weeklyData}
             options={options}
             type='bar'
        />
    );
}