import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import Slider from "react-slick";
import fnsvAPI from "./fnsvAPI";
import {dataFormat} from "../../common/utils/StringFormatUtils";
import {useTranslation} from "react-i18next";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {LeftArrow, RightArrow} from "./FNSVCommon";

const useStyles = makeStyles((theme) => ({
    container    : {
        width                         : 'calc(100vw - 120px) !important',
        height                        : 'calc(100vh - 61px) !important',
        color                         : '#fff',
        [theme.breakpoints.down('md')]: {
            width : 'initial !important',
            height: 'calc(100vh - 52px) !important',
        }
    },
    root         : {
        display         : 'flex'
        , flex          : 1
        , flexDirection : 'column'
        , alignItems    : 'center'
        , justifyContent: 'center'
        , height        : 'inherit'
        , background    : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec4/bg.png) no-repeat`
    }
    , titleBox   : {
        textAlign                       : 'center'
        , marginBottom                  : 60
        , zIndex                        : 1
        , '& > h2'                      : {
            fontSize    : 45
            , fontWeight: 'bold'
            , lineHeight: '68px'
            , margin    : 0
        }
        , '& > p'                       : {
            fontSize       : 21
            , lineHeight   : '32px'
            , letterSpacing: '-0.04em'
            , margin       : 0
        }
        , [theme.breakpoints.down('md')]: {
            marginBottom: 40
            , '& > h2'  : {
                fontSize    : 38
                , lineHeight: '48px'
            }
            , '& > p'   : {
                fontSize    : 12
                , lineHeight: '20px'
            }
        }
    }
    , contentBox : {
        width                           : 1200
        , [theme.breakpoints.down('md')]: {
            width     : '70%'
            , maxWidth: 400
        }
    }
    , itemBox    : {
        width                           : 400
        , background                    : '#fff'
        , margin                        : 0
        , padding                       : 0
        , '&:hover'                     : {
            '& a'                             : {
                color        : '#4046BC'
                , borderColor: '#4046BC'
            }
            , '& > div > div > p:nth-child(1)': {
                color: '#4046BC'
            }
        }
        , '& > img'                     : {
            maxWidth   : 400
            , height   : 250
            , margin   : '0 auto'
            , objectFit: 'cover'
        }
        , [theme.breakpoints.down('md')]: {
            '& > img': {
                width      : '100%'
                , height   : '100%'
                , maxWidth : 263
                , maxHeight: 164
            }
        }

    },
    itemAlign    : {
        height                          : 324
        , display                       : 'flex'
        , flexDirection                 : 'column'
        , justifyContent                : 'space-between'
        , paddingTop                    : 35
        , paddingLeft                   : 30
        , paddingRight                  : 30
        , paddingBottom                 : 44
        , color                         : '#222'
        , '& > a'                       : {
            textDecoration : 'none'
            , color        : '#888'
            , border       : '1px solid #888'
            , borderRadius : 20
            , fontWeight   : 600
            , fontSize     : 14
            , lineHeight   : '21px'
            , textAlign    : 'center'
            , letterSpacing: '-0.04em'
            , padding      : '5px 35px'
            , alignSelf    : 'baseline'
        }
        , [theme.breakpoints.down('md')]: {
            height         : 'initial'
            , paddingTop   : 25
            , paddingLeft  : 20
            , paddingRight : 20
            , paddingBottom: 34
        }
    }
    , itemContent: {
        '& p'                           : {
            margin        : 0
            , marginBottom: 15
        }
        , '& > p:nth-child(1)'          : {
            fontWeight     : 600
            , fontSize     : 16
            , lineHeight   : '24px'
            , letterSpacing: '0.04em'
        }
        , '& > p:nth-child(2)'          : {
            fontWeight     : 500
            , fontSize     : 21
            , lineHeight   : '28px'
            , letterSpacing: '-0.04em'

            , display             : '-webkit-box'
            , '-webkit-box-orient': 'vertical'
            , '-webkit-line-clamp': 3
            , wordWrap            : 'break-word'
            , textOverflow        : 'ellipsis'
            , height              : 85
            , overflow            : 'hidden'
        }
        , '& > div:nth-child(3)'        : {
            display               : '-webkit-box'
            , '-webkit-box-orient': 'vertical'
            , '-webkit-line-clamp': 3
            , wordWrap            : 'break-word'
            , textOverflow        : 'ellipsis'
            , height              : 45
            , overflow            : 'hidden'
            , '& p'               : {
                fontWeight     : 500
                , fontSize     : 15
                , lineHeight   : '24px'
                , letterSpacing: '-0.04em'
                , color        : '#888'
                , margin       : 0
            }
        }
        , [theme.breakpoints.down('md')]: {
            '& > p:nth-child(1)'    : {
                fontSize    : 12
                , lineHeight: '18px'
            }
            , '& > p:nth-child(2)'  : {
                fontSize              : 17
                , lineHeight          : '20px'
                , display             : '-webkit-box'
                , '-webkit-box-orient': 'vertical'
                , '-webkit-line-clamp': 3
                , wordWrap            : 'break-word'
                , textOverflow        : 'ellipsis'
                , height              : 60
                , overflow            : 'hidden'
            }
            , '& > div:nth-child(3)': {
                display: 'none'
            }
        }
    }

}));


export default function NewsPage({name}) {

    const _innerFlag = name !== undefined;

    const classes = useStyles();
    const [newsData, setNewsData] = useState();
    const newsSliderRef = useRef();
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    useEffect(() => {
        let language = i18n.language === 'ko' ? "CMMLNG001" : "CMMLNG002";
        fnsvAPI.getHomepageData(95, language)
            .then(data => {
                setNewsData(data.data);
            })
            .catch(err => {
                return err;
            });
    }, [i18n.language])


    const settings = {
        infinite     : true,
        lazyLoad     : true,
        draggable    : false,
        slidesToShow : (isMobile ? 1 : 3),
        autoPlay     : true,
        autoplaySpeed: 2500,
        prevArrow    : <LeftArrow left={isMobile && '-40px'}/>,
        nextArrow    : <RightArrow right={isMobile && '-40px'}/>
    };

    return (
        <section className={`${classes.root} ${_innerFlag && classes.container}`}>
            <Box className={classes.titleBox}>
                <h2>{t('Fnsv.News.Title')}</h2>
                <p>{t('Fnsv.News.Description')}</p>
            </Box>

            <Box className={classes.contentBox}>
                <Slider ref={newsSliderRef} {...settings}>
                    {
                        newsData?.map((item, index) => (
                            <Box className={classes.itemBox} key={index}>
                                <img src={item.images[0].encodingSrc} alt={item.images[0].originalName}/>
                                <Box className={classes.itemAlign}>
                                    <Box className={classes.itemContent}>
                                        <p>{dataFormat(new Date(item.eventDate), 'date')}</p>
                                        <p>{item.title}</p>
                                        <Box dangerouslySetInnerHTML={{__html: item.content}}/>
                                    </Box>
                                    <a href={item.url} target="_blank"
                                       alt={t('Fnsv.News.ViewBtn')}>{t('Fnsv.News.ViewBtn')}</a>
                                </Box>
                            </Box>
                        ))
                    }

                </Slider>
            </Box>

        </section>
    )
}