import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useStores} from "../../common/store";
import api from "../../common/api";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import {CardActions, useMediaQuery} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {dataFormat, StringNvl} from "../../common/utils/StringFormatUtils";
import {CommonCodeValue} from "../../common/types";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../../components/ConfirmDialog";
import Pagination from "../../components/Pagination";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from "@material-ui/core/Typography";
import LinkOffIcon from '@mui/icons-material/LinkOff';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 20,
        [theme.breakpoints.between('xs', 'md')]: {
            gridTemplateColumns: '1fr'
        }
    },
    cardRoot: {
        display: 'flex',
        minWidth: 355,
        minHeight: 'max-content',
        flexDirection: 'column',
        padding: 30,
        background: theme.palette.background.paper,
        '&.MuiPaper-elevation1': {
            boxShadow: 'none',
        },
        '&.MuiPaper-rounded': {
            borderRadius: 5,
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin: `0 0 ${theme.spacing(2)}px 0`,
            minWidth: 300
        }
    },
    cardHeader: {
        padding: '0 0 20px 0',
        borderBottom: '1px solid #E3E4ED',
        '& .MuiCardHeader-content': {
            '& > :first-child': {
                fontSize: 15,
                color: '#121212',
                fontWeight: 600,
                marginBottom: 5
            },
            '& > :last-child': {
                fontSize: 12,
                color: '#8E8DAB',
                fontWeight: 300
            }
        }
    },
    avatar: {
        backgroundColor: '#E9ECFF',
        borderRadius: 5,
        width: 52,
        height: 52,
        '& > p': {
            fontSize: '0.938rem',
            fontWeight: 600,
            color: '#4046BC',
            textTransform: 'uppercase'
        }
    },
    cardContent: {
        padding: '20px 0',
        '& > dl': {
            display: 'flex',
            justifyContent: 'space-between',
            '& > dt': {
                color: '#9E9DBB',
                fontSize: 12
            },
            '& > dd': {
                margin: 0,
                color: '#565568',
                fontSize: 12
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                flexDirection: 'column'
            }
        },
    },
    cardAction: {
        padding: 0,
        '& > button': {
            flex: 1,
            minHeight: 45,
            textTransform: 'none',
            backgroundColor: '#FFF',
            color: '#B2B5C8',
            boxShadow: 'none',
            border: '1px solid #E2E4F0',
            '&:hover': {
                backgroundColor: '#4046BC',
                color: '#FFF',
                border: 'none'
            },
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(1)
            }
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'center',
            '& > button': {
                flex: 1
            }
        }
    },
    pageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    moreBtn: {
        flex: 1,
        height:theme.spacing(6),
    }
}));


export default function MySitePage(props) {
    const {t} = useTranslation();
    const {ds} = useStores();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [pageInfo, setPageInfo] = useState({page: 1, pageSize: 8});
    const classes = useStyles();

    const [response, setResponse] = useState(null);
    const [confirm, setConfirm] = useState(false);

    const deleteClientKey = useRef();



    const fetch = (page = 1, pageSize, delItem = false) => {
        api.getLinkClient(page - 1, pageSize)
            .then(res => {

                if (isMobile) {
                    if (response === null) {
                        setResponse(res);
                    } else {
                        res.data.forEach((item) => {
                            response.data?.push(item);
                        })
                    }

                    setResponse({...res, data: response.data});
                } else {

                    setResponse(res);
                }

                if( delItem ){
                    if( page > res?.page?.totalPages ) {
                        setPageInfo({...pageInfo, page: page -1 });
                    }
                }

            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    useEffect(() => {
        fetch(pageInfo.page, pageInfo.pageSize);
    }, [pageInfo]);

    const onUnLink = () => {

        api.deleteUnLink(deleteClientKey.current)
            .then(res => {
                console.log(" response => ", res);
                ds.showSuccessAlert(t('UserPage.Detail.Dialog.UnLinkResultMessage'));
                fetch(pageInfo.page, pageInfo.pageSize, true);
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });

        onCloseConfirm();
    }

    const onCloseConfirm = () => {
        deleteClientKey.current = undefined;
        setConfirm(false);
    }

    const onOpenConfirm = (deleteKey) => {

        deleteClientKey.current = deleteKey;
        setConfirm(true);
    }

    const onChangePage = (event, newPage) => {
        setPageInfo({...pageInfo, page: newPage});
    }

    const goNext = () => {
        setPageInfo({...pageInfo, page: pageInfo.page + 1});
    }

    return (
        <React.Fragment>
            <Box className={classes.root}>
                {response != undefined && response?.data?.map((data, index) => (
                        <Card className={classes.cardRoot} key={index}>
                            <CardHeader
                                className={classes.cardHeader}
                                avatar={
                                    <Box className={classes.avatar} display='flex' justifyContent='center' alignItems='center'>
                                        <Typography>{data.clientName.slice(0, 2)}</Typography>
                                    </Box>
                                }
                                title={data.clientName}
                                subheader={dataFormat(data.regDt, 'date', 'YYYY-MM-DD HH:mm:ss')}
                            />
                            <Divider light/>
                            <CardContent className={classes.cardContent}>
                                <dl>
                                    <dt>{t('MyPage.Site.Id')}</dt>
                                    <dd>{StringNvl(data.extraKey, ds.user.userKey )}</dd>
                                </dl>

                                <dl>
                                    <dt>{t('MyPage.Site.Type')}</dt>
                                    <dd>{StringNvl(t(CommonCodeValue.translateKey + '.' + data.userType), '-')}</dd>
                                </dl>

                                <dl>
                                    <dt>{t('MyPage.Site.VerifyType')}</dt>
                                    <dd>{StringNvl(data.verifyType, 'Default')}</dd>
                                </dl>

                                <dl>
                                    <dt>{t('MyPage.Site.Status')}</dt>
                                    <dd>{StringNvl(t(CommonCodeValue.translateKey + '.' + data.userStatus), '-')}</dd>
                                </dl>

                            </CardContent>
                            <CardActions className={classes.cardAction}>
                                {
                                    api.MASTER_CLIENT_KEY !== data.clientKey
                                    && <Button variant="contained" color="primary"
                                               onClick={() => onOpenConfirm(data.clientKey)}><LinkOffIcon/> {t('MyPage.Site.UnLink')} </Button>
                                }
                            </CardActions>
                        </Card>
                    )
                )}

                <ConfirmDialog open={confirm}
                               onClose={onCloseConfirm}
                               onOk={onUnLink}
                               title={t('MyPage.Site.MSG_01')}
                               content={t('MyPage.Site.MSG_02')}
                />
            </Box>
            <Box className={classes.pageContainer}>
                {isMobile
                    ? (
                        <Button className={classes.moreBtn}
                                variant={'outlined'}
                                onClick={goNext}
                                disabled={response?.page?.last}
                        >
                            { t('MyPage.Site.More')}
                            <ArrowDropDownIcon />
                        </Button>
                    ) : (
                        <Pagination currentPage={response?.page?.currentPage + 1}
                                    totalPage={response?.page?.totalPages}
                                    onChangeEvent={onChangePage}/>
                    )
                }
            </Box>
        </React.Fragment>
    )
}
