import React, {useEffect, useRef, useState} from 'react';
import Box from "@material-ui/core/Box";
import {useStores} from "../../common/store";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from 'react-router';
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import {AgreementType, LanguageCode, SubClientBehavior, UseStatusCode} from "../../common/types";
import api from "../../common/api";
import InputSelect from "../../components/InputSelect";
import AccessClientSelectBox from "../../components/AccessClientSelectBox";
import InputTexts from "../../components/InputTexts";
import TextEditor from "../../components/TextEditor";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: '1.875rem'
    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    content: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    selectContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiInputBase-root': {
            fontSize: '0.813rem',
            minWidth: 290,
            maxWidth: '100%',
            width: '100%',
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth: 'auto'
            }
        },
        '& > *': {
            ...theme.select,
            minWidth: 290,
            maxWidth: '100%',
            flex: 1,
            marginBottom: theme.spacing(1.25),
            '& .MuiFormControl-root': {
                width: '100%',
            },
            '& .MuiFormHelperText-contained': {
                margin: 0,
                fontSize: '0.438rem'
            },
            [theme.breakpoints.between('xs', 'md')]: {
                maxWidth: '100%'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                minWidth: 160,
            }
        },
        '& > div:first-child, div:nth-child(3)': { marginRight: theme.spacing(1) },
        '& > div:nth-child(4)': { marginRight: 0},
        '& > div:nth-child(5)': { maxWidth: '100%' },

        [theme.breakpoints.between('xs', 'sm')]: {
            flex: 1,
            flexDirection: 'column',
            marginBottom: 0,
            '& > div:first-child': {marginRight: 0},
            '& > div:nth-child(2)': {marginLeft: 0},
        }
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiOutlinedInput-input': {
            fontSize: '0.813rem !important'
        },
        '& > :first-child': {
            marginBottom: '11px'
        },
        '& > div:first-child': {
            '& .MuiOutlinedInput-root': {
                height: 40
            },
            '& .MuiFormHelperText-contained': {
                margin: 0,
                fontSize: '0.438rem'
            },
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth: 'initial',
                flex: 1,
            }
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            width: 130,
            height: 40,
            borderRadius: 5,
            fontSize: '0.75rem',
            fontWeight: 700
        },
        '& > :first-child': {
            border: '1px solid #C7CBD1',
            backgroundColor: 'transparent',
            color: '#81808A',
            marginRight: 6,
            "&:hover": {
                border: '1px solid #3C39CB',
                backgroundColor: 'transparent',
                color: theme.palette.primary.main
            }
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
            }
        }
    }
}));

function CommonCodeKey(dataList, data) {
    let dataKey = "";
    Object.keys(dataList).map((key, index) => {
        if (dataList[key] === data) {
            dataKey = dataList[key];
        }
    });
    return dataKey;
}

export default function AgreementCreate(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {t} = useTranslation();
    const history = useHistory();
    const {onClose, open} = props;

    const [openConfirm, setOpenConfirm] = useState(false);

    const agreementDetail = useRef(
        {
            regUserKey: ds.user.userKey,
            lang: "",
            useStatus: "",
            type: "",
            title: "",
            clientKey: "",
            content: "",
            subCltBhv: "",
        }
    );

    const onChangeClient = (event, value) => {
        agreementDetail.current.clientKey = value;
        form.$('clientKey').value = value;
    }

    const formId = "agreementForm";
    const form = new MobxReactForm({
        fields: [
            {
                name: 'lang',
                value: agreementDetail.current.lang,
                rules: 'required',
                label: t('Agreement.Lang')
            },
            {
                name: 'useStatus',
                value: agreementDetail.current.useStatus,
                rules: 'required',
                label: t('Agreement.UseStatus')
            },
            {
                name: 'type',
                value: agreementDetail.current.type,
                rules: 'required',
                label: t('Agreement.Type')
            },
            {
                name: 'title',
                value: agreementDetail.current.title,
                rules: 'required',
                label: t("Agreement.Title"),
            },
            {
                name: 'clientKey',
                value: agreementDetail.current.clientKey,
                label: t('Agreement.Client'),
                rules: 'required'
            },
            {
                name: 'subCltBhv',
                value: agreementDetail.current.subCltBhv,
                label: t('Agreement.SubClientBehavior'),
                rules: 'required'
            },
            {
                name: 'content',
                value: agreementDetail.current.content,
                rules: 'required',
                label: t("Agreement.Content"),
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                const values = form.values();
                Object.keys(values).map((key, index) => {
                    if (values[key] !== null && values[key] !== "") {
                        agreementDetail.current[key] = values[key];
                    }
                });
                setOpenConfirm(true);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map((data, index) => {
                    if (errorMsg === "") {
                        errorMsg = form.errors()[data];
                        if (errorMsg === null) {
                            errorMsg = "";
                        }
                    }
                });
                ds.showErrorAlert(errorMsg);
                console.log("onError", form.errors());
            }
        }
    });

    let langText = "";
    if (agreementDetail.current.lang !== undefined) {
        langText = CommonCodeKey(LanguageCode, agreementDetail.current.lang);
    }

    let statusText = "";
    if (agreementDetail.current.useStatus !== undefined) {
        statusText = CommonCodeKey(UseStatusCode, agreementDetail.current.useStatus);
    }

    let subClientBehaviorText = "";
    if (agreementDetail.current.subCltBhv !== undefined) {
        subClientBehaviorText = CommonCodeKey(SubClientBehavior, agreementDetail.current.subCltBhv);
    }

    let typeText = "";
    if (agreementDetail.current.type !== undefined) {
        typeText = CommonCodeKey(AgreementType, agreementDetail.current.type);
    }

    const search = (seq) => {
        history.push({
            pathname: '/admin/agreements/detail',
            search: "?" + api.encodeParams({agreementSeq: seq})
        });
    };

    const onClickConfirm = (isYes) => {
        if (isYes) {
            api.postAgreement(agreementDetail.current)
                .then((data) => {
                    search(data.seq);
                    ds.showInfoAlert(t('Agreement.RegistrationSuccess'));
                    onClose();
                })
                .catch(err => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                });
        }
        setOpenConfirm(false);
    }

    useEffect(() => {
        if (!open) {
            Object.keys(agreementDetail.current)
                .map((key) => {
                    if (key !== 'regUserKey') agreementDetail.current[key] = ""
            });
        }
    }, [open]);

    return (
        <React.Fragment>
            <Box maxWidth="660px" className={classes.dialogContent}>
                <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                    <div className={classes.selectContainer}>
                        <InputSelect
                            form={form}
                            name={'lang'}
                            placeholder={t('Agreement.Lang')}
                            selectType={LanguageCode}
                            value={agreementDetail.current.lang}
                            text={langText}
                            variant={'outlined'}
                        />
                        <InputSelect
                            form={form}
                            name={'useStatus'}
                            placeholder={t('Agreement.UseStatus')}
                            selectType={UseStatusCode}
                            value={agreementDetail.current.useStatus}
                            text={statusText}
                            variant={'outlined'}
                        />
                        <InputSelect
                            form={form}
                            name={'type'}
                            placeholder={t('Agreement.Type')}
                            selectType={AgreementType}
                            value={agreementDetail.current.type}
                            text={typeText}
                            variant={'outlined'}
                        />
                        <AccessClientSelectBox
                            title={t("Agreement.ClientSelect")}
                            defaultClientKey={""}
                            addAll={true}
                            onChangeSelect={onChangeClient}
                            required={true}
                            field={form.$('clientKey')}
                        />
                        <InputSelect
                            form={form}
                            name={'subCltBhv'}
                            placeholder={t('Agreement.SubClientBehavior')}
                            selectType={SubClientBehavior}
                            value={agreementDetail.current.subCltBhv}
                            text={t('CommonCode.' + subClientBehaviorText)}
                            variant={'outlined'}
                            required={true}
                        />
                    </div>
                    <div className={classes.bodyContainer}>
                        <div>
                            <InputTexts
                                form={form}
                                name={'title'}
                                placeholder={t('Agreement.Title')}
                                variant={'outlined'}
                            />
                        </div>
                        <div>
                            <TextEditor placeholder={t('Agreement.Content')} field={form.$("content")} height={300}/>
                        </div>
                    </div>
                </form>
                <div className={classes.buttonContainer}>
                    <Button type={'button'} form={formId} onClick={onClose}>{t('Agreement.CreateDialog.Cancel')}</Button>
                    <Button onClick={form.onSubmit}>{t('Agreement.CreateDialog.Save')}</Button>
                </div>
            </Box>
            <ConfirmDialog
                open={openConfirm}
                title={t('Agreement.CreateDialog.ConfirmTitle')}
                content={t('Agreement.CreateDialog.ConfirmContent')}
                onOk={() => onClickConfirm(true)}
                onClose={() => onClickConfirm(false)}
            />
        </React.Fragment>
    );
}