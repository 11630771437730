import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import Box from "@mui/material/Box";

import {Swiper, SwiperSlide} from 'swiper/react';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, {EffectCoverflow} from "swiper";
import {useTranslation} from "react-i18next";
import Slider from "react-slick";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {LeftArrow, RightArrow} from "./FNSVCommon";


const useStyles = makeStyles((theme) => ({
    root       : {
        position  : 'absolute',
        top       : 0,
        alignItems: 'center',
        width     : 'inherit',
        height    : 'inherit'
    },
    bgVideo    : {
        position                                 : 'absolute',
        top                                      : 0,
        width      : '100vw !important',
        height     : '100vh !important',
        '& > video': {
            objectFit: 'cover'

        }
    },
    contents   : {
        position                      : 'absolute'
        , top                         : 0
        , left                        : 0
        , zIndex                      : 1
        , width                       : 'inherit'
        , height                      : 'inherit'
        , display                     : 'flex'
        , flex                        : 1
        , flexDirection               : 'column'
        , alignItems                  : 'center'
        , justifyContent              : 'center'
        , [theme.breakpoints.up('lg')]: {
            '& .swiper-slide'         : {
                width             : 860,
                height            : 500,
                marginLeft        : 15,
                marginRight       : 15,
                backgroundPosition: 'center',
                backgroundSize    : 'cover',
                '& > div'         : {
                    width     : '100%',
                    height    : '100%',
                    display   : 'flex',
                    flex      : 1,
                    alignItems: 'end'
                }
            }
            , '& .swiper-slide-active': {
                transform: 'translate3d(0px, 0px, 0px) rotateX(0deg) rotateY(0deg) scale(1) !important'
            }
        }

    },
    titleBox   : {
        textAlign                       : 'center',
        marginBottom                    : 90,
        '& > :nth-child(1)'             : {
            fontSize  : '45px',
            lineHeight: '67px',
            fontWeight: 'bold',
            margin    : 0,
            whiteSpace: 'pre-wrap'
        },
        '& > :nth-child(2)'             : {
            fontSize  : '21px',
            lineHeight: '31px'
        }
        , [theme.breakpoints.down('md')]: {
            margin             : '0 20px 20px',
            '& > :nth-child(1)': {
                fontSize  : '32px',
                lineHeight: '35px',
            },
            '& > :nth-child(2)': {
                fontSize  : '17px',
                lineHeight: '20px'
            }
        }

    },
    aboutSlider: {
        [theme.breakpoints.up('lg')]    : {
            overflow                 : 'hidden'
            , width                  : 'inherit'
            , height                 : 500
            , '-webkit-transform'    : 'translateY(0)'
            , transform              : 'translateY(0)'
            , opacity                : 1
            , '-webkit-transition'   : 'all 1s 1000ms ease-in'
            , '& > .swiper-container': {
                position              : 'absolute'
                , bottom              : 0
                , left                : 0
                , '-webkit-transition': 'all 0.1s ease-out'
                , transition          : 'all 0.1s ease-out'
                , transform           : 'translate3d(-3045px, 0px, 0px)'
            }
        }
        , [theme.breakpoints.down('md')]: {
            position: 'relative'
            , width : '70%'
        }
        , [theme.breakpoints.down('xs')]: {
            width: '90%'
        }

    },
    txtBox     : {
        marginLeft                      : 60,
        marginBottom                    : 60,
        '& > *'                         : {
            marginTop   : 10,
            marginBottom: 0,
        },
        '& > :nth-child(1)'             : {
            fontSize  : 48,
            lineHeight: '72px',
            fontWeight: '800'
        },
        '& > :nth-child(2)'             : {
            fontSize  : 19,
            lineHeight: '31px',
            fontWeight: '300',
            opacity   : '0.65'
        }
        , [theme.breakpoints.down('md')]: {
            marginLeft         : 20,
            marginBottom       : 20,
            '& > :nth-child(1)': {
                fontSize  : 32,
                lineHeight: '30px',
            },
            '& > :nth-child(2)': {
                fontSize  : 13,
                lineHeight: '20px',
            }
        }
    },
    easy       : {
        background          : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec2/bg1.jpg) no-repeat`
        , backgroundSize    : 'cover'
        , backgroundPosition: 'center'
    },
    safe       : {
        background          : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec2/bg3.jpg) no-repeat`
        , backgroundSize    : 'cover'
        , backgroundPosition: 'center'
    },
    fast       : {
        background          : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec2/bg2.jpg) no-repeat`
        , backgroundSize    : 'cover'
        , backgroundPosition: 'center'
    },
    slider     : {
        '& > .slick-list > .slick-track > .slick-slide > div': {
            // width           : '100vw !important'
            display                         : 'flex !important'
            , justifyContent                : 'center'
            , alignItems                    : 'center'
            , '& > div'                     : {
                width       : '100%'
                , height    : '100%'
                , display   : 'flex !important'
                , flex      : 1
                , alignItems: 'end'
            }
            , [theme.breakpoints.down('md')]: {
                height: 400
            }
            , [theme.breakpoints.down('xs')]: {
                height: 300
            }
        }
    },
}));

SwiperCore.use([EffectCoverflow]);

export default function AboutPage() {

    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const isKor = i18n.language == 'ko';

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const settings = {
        dots            : false
        , arrows        : true
        , infinite      : true
        , lazyLoad      : true
        , draggable     : false
        , speed         : 1000
        , slidesToShow  : 1
        , slidesToScroll: 1
        , prevArrow     : <LeftArrow/>
        , nextArrow     : <RightArrow/>
    };


    return (
        <section className={classes.root}>

            <ReactPlayer
                url='https://resources.fnsvalue.co.kr/fnsv/images/sec2/video2.mp4'
                className={classes.bgVideo}
                playing={true}
                controls={false}
                muted={true}
                loop={true}
            />

            <Box className={classes.contents}>

                <Box className={classes.titleBox}>
                    <p>{t('Fnsv.About.Title')}</p>
                    <p>{t('Fnsv.About.Description')}</p>
                </Box>

                <Box className={classes.aboutSlider}>


                    {isMobile
                        ?
                        <Slider {...settings} className={classes.slider}>
                            <Box className={classes.easy}>
                                <div className={classes.txtBox}>
                                    <p className="tit">{t('Fnsv.About.Easy.Title')}</p>
                                    <p className="sub">{t('Fnsv.About.Easy.Description')}</p>
                                    {isKor && <p className="eng">{t('Fnsv.About.Easy.SubDescription')}</p>}
                                </div>
                            </Box>
                            <Box className={classes.safe}>
                                <div className={classes.txtBox}>
                                    <p className="tit">{t('Fnsv.About.Safe.Title')}</p>
                                    <p className="sub">{t('Fnsv.About.Safe.Description')}</p>
                                    {isKor && <p className="eng">{t('Fnsv.About.Safe.SubDescription')}</p>}
                                </div>
                            </Box>
                            <Box className={classes.fast}>
                                <div className={classes.txtBox}>
                                    <p className="tit">{t('Fnsv.About.Fast.Title')}</p>
                                    <p className="sub">{t('Fnsv.About.Fast.Description')}</p>
                                    {isKor && <p className="eng">{t('Fnsv.About.Fast.SubDescription')}</p>}
                                </div>
                            </Box>
                        </Slider>
                        :
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            loop={true}
                            coverflowEffect={{
                                rotate      : 50,
                                stretch     : 0,
                                depth       : 100,
                                modifier    : 1,
                                slideShadows: false
                            }}
                            slidesPerView={"auto"}
                            centeredSlides={true}
                        >
                            <SwiperSlide>
                                <Box className={classes.easy}>
                                    {/*<img src="https://resources.fnsvalue.co.kr/fnsv/images/sec2/bg1.jpg"
                                     alt="EASY - Passwordless와 다양한 간편 인증 방식으로 사용자의 편의성을 제공합니다."/>*/}
                                    <div className={classes.txtBox}>
                                        <p className="tit">{t('Fnsv.About.Easy.Title')}</p>
                                        <p className="sub">{t('Fnsv.About.Easy.Description')}</p>
                                        {isKor && <p className="eng">{t('Fnsv.About.Easy.SubDescription')}</p>}
                                    </div>
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box className={classes.safe}>
                                    {/*<img src="https://resources.fnsvalue.co.kr/fnsv/images/sec2/bg3.jpg"*/}
                                    {/*     alt="SAFE - 특허 받은 인증 기술 방법을 통한 블록체인 기술 검증 방식으로 위변조와 해킹이 불가능합니다."/>*/}
                                    <div className={classes.txtBox}>
                                        <p className="tit">{t('Fnsv.About.Safe.Title')}</p>
                                        <p className="sub">{t('Fnsv.About.Safe.Description')}</p>
                                        {isKor && <p className="eng">{t('Fnsv.About.Safe.SubDescription')}</p>}
                                    </div>
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box className={classes.fast}>
                                    {/*<img src="https://resources.fnsvalue.co.kr/fnsv/images/sec2/bg2.jpg"*/}
                                    {/*     alt="FAST - 특허 받은 분산 검증 방식으로 처리 속도가 향상되어 빠르게 인증 할 수 있습니다."/>*/}
                                    <div className={classes.txtBox}>
                                        <p className="tit">{t('Fnsv.About.Fast.Title')}</p>
                                        <p className="sub">{t('Fnsv.About.Fast.Description')}</p>
                                        {isKor && <p className="eng">{t('Fnsv.About.Fast.SubDescription')}</p>}
                                    </div>
                                </Box>
                            </SwiperSlide>
                        </Swiper>
                    }

                </Box>

            </Box>

        </section>
    )
}