import axios from 'axios';
import i18next from 'i18next';
import {ErrCode} from "../../common/types";

/***
 * 관리자 포탈(Admin portal)에서 관리하는 콘텐츠 데이터를 불러오는 API
 ***/
class AdminAPI {
     //HOST = (process?.env?.REACT_APP_BASE_URL !== null ? 'http://192.168.0.162' : 'https://admin.fnsvalue.co.kr');
    HOST = "https://admin.fnsvalue.co.kr";
    api = null;

    constructor() {

        this.api = axios.create({
            baseURL: this.HOST + '/api/homepage',
            timeout: 1000 * 30,
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            headers: {}
        });

        this.api.defaults.withCredentials = true;

        this.api.interceptors.response.use(response => {
            const {rtCode} = response.data;
            if (rtCode !== ErrCode.RT_SUCCESS) {
                response.data.rtMsg = this.getErrMsg(rtCode);
                throw response.data;
            }

            return response;
        });
    }

    getErrMsg = (errCode) => {
        return i18next.t('Error.' + errCode);
    };

    getHomepageData = (seq, language) => {
        return new Promise((resolve, reject) => {
            this.api.get(`/category/${seq}?language=${language}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

}

export default new AdminAPI();
