import React, {useEffect, useState} from 'react';
import LoginPage from './pages/login/LoginPage';
import api from './common/api';
import AuthRoute from './components/AuthRoute';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useStores} from './common/store';
import 'mobx-react-lite/batchingForReactDom';
import Layout from './pages/Layout';
import {useObserver} from "mobx-react-lite";
import {SnackbarProvider} from 'notistack';

function App(props) {
    const {ds} = useStores();

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (api.hasToken()) {
            api.getMe()
                .then(() => {
                    ds.setLogin(true);
                })
                .catch(() => {
                    ds.setLogin(false);
                })
                .finally(() => {
                    setChecked(true);
                });
        } else {
            setChecked(true);
        }
    }, []);

    return useObserver(() => {
        return checked ?
            <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
                <Router>
                    <Switch>
                        <Route path='/login' component={LoginPage}/>
                        <AuthRoute path='/' component={Layout}/>
                    </Switch>
                </Router>
            </SnackbarProvider>
            : null;
    });
}

export default App;