import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {ReactComponent as GlobeIcon} from "../images/gccs/globe-icon.svg";
import {ClickAwayListener, MenuItem, MenuList, Paper, Popper} from "@material-ui/core";
import {Grow} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    // 언어 변경 버튼
    langAreaWidth: {
        width: 140,
        [theme.breakpoints.down('md')]: {
            width: 'auto'
        }
    },
    langBtn: {
        display: "flex",
        flexDirection: "row",
        alignSelf: "flex-end",
        width: 120,
        height: 36,
        borderRadius: "19.5px",
        backgroundColor: "rgba(206, 206, 206, 0.2)",
        color: "#838790",
        ...theme.typography.subtitle2,
        [theme.breakpoints.between('sm', 'md')]: {
            height: "45px",
            width: "140px",
            margin: 0,
            color: "#8A8A8A",
            ...theme.typography.button,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
        [theme.breakpoints.down("xs")]: {
            // height: "27px",
            // width: "85px",
            margin: 0,
            fontSize: "0.5rem",
            backgroundColor: "rgba(255, 255, 255, 0.1)"
        },
    },
    langMenuPaper: {
        borderRadius: "14.5px",
        marginTop: "5px",
        width: "120px",
        height: "70px",
        backgroundColor: 'rgba(206, 206, 206, 0.2)',
        [theme.breakpoints.down('sm', 'md')]: {
            width: "140px",
            height: "60px",
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
        },
        [theme.breakpoints.down("xs")]: {
            // width: "82px",
            // height: "60px",
            fontSize: "0.563rem",
            backgroundColor: 'rgba(255, 255, 255, 0.1)'
        }
    },
    langMenu: {
        ...theme.typography.subtitle2,
        color: "#838790",
        justifyContent: "center",
        minHeight: "16px",
        [theme.breakpoints.between('sm', 'md')]: {
            color: "#8A8A8A",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "3px 0 3px 0",
            fontSize: "0.5rem",
            minHeight: "14px",
        }
    },
    selectedLang: {
        backgroundColor: "transparent !important",
        color: theme.palette.primary.contrastText
    },
    globeSvg: {
        marginRight: "8px",
        [theme.breakpoints.down("xs")]: {
            marginRight: "2px",
            height: "14px"
        }
    },

}));

export function LanguageSelector({clickLang, onClickHandler}) {

    const classes = useStyles();

    const options = ['ENGLISH', 'KOREAN'];
    const {t, i18n} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    useEffect( () => {
        if(i18n.language === 'en'){
            setSelectedIndex(0);
        } else if(i18n.language === 'ko'){
            setSelectedIndex(1);
        }
    },[i18n.language]);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        index === 0 ? i18n.changeLanguage('en') : i18n.changeLanguage('ko');
        setOpen(false);

        if( onClickHandler !== undefined ) onClickHandler();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current?.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center" className={classes.langAreaWidth}>
            <Grid item xs={12} ref={clickLang}>
                <Box variant="contained" size="small" className={classes.langMenu} ref={anchorRef}
                     aria-label="split button">
                    <Button variant="text" className={classes.langBtn} onClick={handleToggle}>
                        <GlobeIcon className={classes.globeSvg}/>
                        <div>{options[selectedIndex]}</div>
                    </Button>
                </Box>
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-start">
                    {({TransitionProps, placement}) => (
                        <Grow {...TransitionProps} >
                            <Paper className={classes.langMenuPaper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                className={classes.langMenu}
                                                selected={index === selectedIndex}
                                                classes={{selected: classes.selectedLang}}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}