import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useMediaQuery, useTheme} from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MailForm from "./MailForm";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
    container: {
        width:'calc(100vw - 120px) !important',
        height:'calc(100vh - 61px) !important',
        color:'#fff',
        [theme.breakpoints.down('md')] :{
            width:'initial !important',
            height:'calc(100vh - 52px) !important',
        }
    },
    contact: {
        display:'flex',
        flex:1,
        height:'inherit',
        // flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        background:'#0F1014',
        backgroundSize:'cover'
    }
}));


export default function ContactPage({name}){
    const _innerFlag = name !== undefined;
    const classes = useStyles();

    return (
        <section className={`${classes.contact} ${_innerFlag && classes.container}`}>
            <ContactForm />
            <MailForm />
        </section>
    )
}

