import React, {useMemo,useEffect, useState} from 'react';
import Table from "../../components/Table";                     //Notice Page에 Table Component
import "../../css/table-styles.css";                            //Notice Page에 Table Style Css
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "@material-ui/core";
import fnsvAPI from "./fnsvAPI";                                //Notice Data Common API

//Notice Page Style 지정
const useStyles = makeStyles((theme) => ({
    container    : {
        color                         : '#fff',
        [theme.breakpoints.down('md')]: {
            width : 'initial !important',
            height: 'calc(100vh - 52px) !important',
        }
    },
    root         : {
        display         : 'flex'
        , flex          : 1
        , flexDirection : 'column'
        , alignItems    : 'center'
        , justifyContent: 'center'
        , height        : 'inherit'
        , background    : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec6/bg.jpg) no-repeat`
    }
    , titleBox   : {
        textAlign                       : 'center'
        , marginBottom                  : 60
        , zIndex                        : 1
        , '& > h2'                      : {
            fontSize    : 45
            , fontWeight: 'bold'
            , lineHeight: '68px'
            , margin    : 0
        }
        , '& > p'                       : {
            fontSize       : 21
            , lineHeight   : '32px'
            , letterSpacing: '-0.04em'
            , margin       : 0
        }
        , [theme.breakpoints.down('md')]: {
            marginBottom: 40
            , '& > h2'  : {
                fontSize    : 38
                , lineHeight: '48px'
            }
            , '& > p'   : {
                fontSize    : 12
                , lineHeight: '20px'
            }
        }
    }
    , contentBox : {
        width                           : 1200
        , [theme.breakpoints.down('md')]: {
            width     : '70%'
            , maxWidth: 400
        }
        , background                    : '#fff'
    }

    , itemBox    : {
        width                           : 400
        , background                    : '#fff'
        , margin                        : 0
        , padding                       : 0
        , '&:hover'                     : {
            '& a'                             : {
                color        : '#4046BC'
                , borderColor: '#4046BC'
            }
            , '& > div > div > p:nth-child(1)': {
                color: '#4046BC'
            }
        }
        , '& > img'                     : {
            maxWidth   : 400
            , height   : 250
            , margin   : '0 auto'
            , objectFit: 'cover'
        }
        , [theme.breakpoints.down('md')]: {
            '& > img': {
                width      : '100%'
                , height   : '100%'
                , maxWidth : 263
                , maxHeight: 164
            }
        }

    },
    itemAlign    : {
        height                          : 324
        , display                       : 'flex'
        , flexDirection                 : 'column'
        , justifyContent                : 'space-between'
        , paddingTop                    : 35
        , paddingLeft                   : 30
        , paddingRight                  : 30
        , paddingBottom                 : 44
        , color                         : '#222'
        , '& > a'                       : {
            textDecoration : 'none'
            , color        : '#888'
            , border       : '1px solid #888'
            , borderRadius : 20
            , fontWeight   : 600
            , fontSize     : 14
            , lineHeight   : '21px'
            , textAlign    : 'center'
            , letterSpacing: '-0.04em'
            , padding      : '5px 35px'
            , alignSelf    : 'baseline'
        }
        , [theme.breakpoints.down('md')]: {
            height         : 'initial'
            , paddingTop   : 25
            , paddingLeft  : 20
            , paddingRight : 20
            , paddingBottom: 34
        }
    }
    , itemContent: {
        '& p'                           : {
            margin        : 0
            , marginBottom: 15
        }
        , '& > p:nth-child(1)'          : {
            fontWeight     : 600
            , fontSize     : 16
            , lineHeight   : '24px'
            , letterSpacing: '0.04em'
        }
        , '& > p:nth-child(2)'          : {
            fontWeight     : 500
            , fontSize     : 21
            , lineHeight   : '28px'
            , letterSpacing: '-0.04em'

            , display             : '-webkit-box'
            , '-webkit-box-orient': 'vertical'
            , '-webkit-line-clamp': 3
            , wordWrap            : 'break-word'
            , textOverflow        : 'ellipsis'
            , height              : 55
            , overflow            : 'hidden'
        }
        , '& > div:nth-child(3)'        : {
            display               : '-webkit-box'
            , '-webkit-box-orient': 'vertical'
            , '-webkit-line-clamp': 3
            , wordWrap            : 'break-word'
            , textOverflow        : 'ellipsis'
            , height              : 45
            , overflow            : 'hidden'
            , '& p'               : {
                fontWeight     : 500
                , fontSize     : 15
                , lineHeight   : '24px'
                , letterSpacing: '-0.04em'
                , color        : '#888'
                , margin       : 0
            }
        }
        , [theme.breakpoints.down('md')]: {
            '& > p:nth-child(1)'    : {
                fontSize    : 12
                , lineHeight: '18px'
            }
            , '& > p:nth-child(2)'  : {
                fontSize              : 17
                , lineHeight          : '20px'
                , display             : '-webkit-box'
                , '-webkit-box-orient': 'vertical'
                , '-webkit-line-clamp': 3
                , wordWrap            : 'break-word'
                , textOverflow        : 'ellipsis'
                , height              : 60
                , overflow            : 'hidden'
            }
            , '& > div:nth-child(3)': {
                display: 'none'
            }
        }
    }

}));

//Notice Page 구성
export default function NoticePage(){
    const theme = useTheme();                                               //공통 theme 사용
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));  //mobile 여부
    const {t, i18n} = useTranslation();                                     //언어팩 사용
    const classes = useStyles();                                            //커스텀 Style
    const [newsData, setNewsData] = useState();                             //실제 Data
    //const dataArr = Array(15);                                            가짜 데이터 배열

    // Table의 컬럼 설정
    const columns = useMemo(
        () => [
            {
                accessor: "seq",
                Header: "순번",
            },
            {
                accessor: "title",
                Header: "제목",
            },
            {
                accessor: "eventDate",
                Header: "공시일자",
            },
            {
                accessor: "regUserKey",
                Header: "등록자",
            },
        ],
        []
    );

    //Hook 실질적인 데이터를 Fatch 하는 곳
    useEffect(() => {
        /*
            Fatch Data
            param1 : seq(게시판 등록 번호 98 : 공지사항)
            parma2 : 언어 조건 설정(CMMLNG001 : 한국어, CMMLNG002 : 영어)
        */
        fnsvAPI.getHomepageData(98, "CMMLNG001")
        //fnsvAPI.getHomepageData(48, "CMMLNG001")
            .then(data => {
                setNewsData(data.data);
            })
            .catch(err => {
                return err;
            });
    }, [i18n.language])

    /*
    * 테이블 데이터 구성
    * seq : 순번
    * title : 제목
    * regDt : 등록일자
    * regUserKey : 등록자 ID
    * images : 이미지
    * eventData : 이벤트 발생 일자
    * content : 내용
    * url : url (String)
    * */
    const data = Array(newsData?newsData.length:0)
        .fill()
        .map((item, index) =>(
            {seq : (newsData.length - index),
            title : newsData?(newsData[index]?newsData[index].title : "1") : "1",
            regDt : newsData?(newsData[index]?newsData[index].regDt : "1") : "1",
            regUserKey : newsData?(newsData[index]?newsData[index].regUserKey : "1") : "1",
                images : newsData?(newsData[index]?newsData[index].images : "1") : "1",
                eventDate : newsData?(newsData[index]?newsData[index].eventDate : "1") : "1",
                content : newsData?(newsData[index]?newsData[index].content : "1") : "1",
                url : newsData?(newsData[index]?newsData[index].url : "1") : "1",
                files : newsData?(newsData[index]?newsData[index].files : "1") : "1",
            }
        ));
    return (

        <section className={`${classes.root} ${classes.container}`}>
            <Box className={classes.titleBox}>
                <h2>FNSVALUE Notice</h2>
                <p>에프엔에스벨류 공지사항</p>
            </Box>
            <Box className={classes.contentBox}>
                {
                    /*
                     * 실질적인 테이블 구성
                     * Props : 1. columns, 2. data
                     * */
                }
                <Table columns={columns} data={data} />
            </Box>
        </section>


    );
}