import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router';
import queryString from 'query-string';
import TabPanels from "../../components/TabPanels";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position:'relative',
    },
    tabContent: {
        margin: '40px 170px 60px 170px',
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20
        }
    }
}));


export default function UserDetailsPage(props) {
    const classes = useStyles();
    const {location} = props;
    const {t} = useTranslation();
    const history = useHistory();

    const querySearch = queryString.parse(location.search);
    const queryState = queryString.parse(location.state);
    const clientKey = querySearch.clientKey;
    const userKey = querySearch.userKey;
    const clientName = queryState.clientName;

    const [tabIndex, setTabIndex] = React.useState(props.tabItem ? props.tabItem.tabIdx : 0);

    const items = props.tabs ? props.tabs : [];

    /********************** USE STATE EVENT **********************/
    const changeTabIndex = (value) => {
        setTabIndex(value)
        history.push({
            pathname: items[value].url,
            search: location.search,
            state: location.state
        })
    }
    /********************** USE STATE EVENT **********************/


    useEffect( () => {
        if( clientKey === undefined || userKey === undefined ) {
            alert(t("Error.WRONG_APPROACH"));
            history.push('/admin/users');
        } else {
            history.push({
                pathname: items[tabIndex].url,
                search: location.search,
                state: location.state
            })
        }

    }, []);

    return (
        <React.Fragment>

            <TabPanels items={items} selected={tabIndex} changeHandler={changeTabIndex} />

            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.tabContent}>
                        {items.map((data, index) => (
                            (data.page !== undefined) &&
                            <TabPanel key={index.toString()} value={tabIndex} index={index}>
                                <data.page params={{clientKey: clientKey, userKey: userKey, clientName: clientName, ...props}}/>
                            </TabPanel>
                        ))}
                    </div>
                </Grid>
        </Grid>

        </React.Fragment>
    )
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box>{children}</Box>)}
        </div>
    );
}
