import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {AppDownLink} from "../login/LoginFunc";


const useStyles = makeStyles((theme) => ({
    root     : {
        display                       : "flex",
        flex                          : 1,
        position                      : 'absolute',
        top                           : 0,
        left                          : 0,
        alignItems                    : 'center',
        width                         : 'inherit',
        height                        : 'inherit',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        }
    },
    mainVideo: {
        position   : 'absolute',
        top        : 0,
        width      : '100vw !important',
        height     : '100vh !important',
        '& > video': {
            [theme.breakpoints.up('md')]             : {
                width   : 'initial !important'
                , height: 'initial !important'
            }
            , [theme.breakpoints.between('xs', 'md')]: {
                objectFit: 'cover'
            }
        }
    },
    contents : {
        marginLeft                               : 260,
        zIndex                                   : 2,
        '& > *'                                  : {
            margin    : 0,
            padding   : 0,
            fontWeight: 500,
        },
        '& > :nth-child(1)'                      : {
            fontSize  : 25,
            lineHeight: '37px'
        },
        '& > :nth-child(2)'                      : {
            fontSize    : 52,
            fontWeight  : 300,
            lineHeight  : '70px',
            marginBottom: 40,
            whiteSpace  : 'pre-wrap',
        }
        , [theme.breakpoints.between('xs', 'md')]: {
            marginLeft           : 0
            , padding : '0 25px'
            , textAlign          : 'center'
            , '& > :nth-child(1)': {
                fontSize    : 17
                , lineHeight: '1.5em'
            }
            , '& > :nth-child(2)': {
                fontSize    : 28
                , lineHeight: '35px'
            }
        }
    },
    company  : {
        [theme.breakpoints.up('lg')]: {
            '& > a':{
                color         : '#fff',
                border        : '1px solid #fff',
                padding       : '10px 40px',
                borderRadius  : 30,
                textDecoration: 'none',
                '& > span'    : {
                    width: 200,
                }
            },
        },
        [theme.breakpoints.down('md')]: {
            marginLeft   : 'auto',
            marginRight  : 'auto',
            display      : 'block',
            textAlign:'center',
            width        : 200,
            background   : '#fff',
            padding      : '10px 0',
            borderRadius : 30,
            fontSize     : '11px',
            lineHeight   : '16.5px',
            fontWeight   : 700,
            letterSpacing: '-2%',
            '& > a'      : {
                color         : '#222',
                textDecoration: 'none',
            },
        }

    },
    store    : {
        display                       : 'none',
        [theme.breakpoints.down('md')]: {
            display    : 'block',
        }
    },
    storeBtn : {
        '& > a'       : {
            color         : '#fff',
            background    : 'rgba(206, 206, 206, 0.25)',
            padding       : '10px 40px',
            borderRadius  : 30,
            textDecoration: 'none',
            justifyContent: 'center',
            marginTop  : 15,
            marginLeft : 'auto',
            marginRight: 'auto',
            width      : 200
        }
    }


}));

export default function MainPage() {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <section className={classes.root}>

            <ReactPlayer
                url='https://resources.fnsvalue.co.kr/fnsv/images/sec1/video.mp4'
                className={classes.mainVideo}
                playing={true}
                controls={false}
                muted={true}
                loop={true}
            />

            <Box className={classes.contents}>
                <p>{t('Fnsv.Main.SubTitle')}</p>
                <p>
                    {t('Fnsv.Main.TitleDescription')}
                    <br/>
                    <strong>{t('Fnsv.Main.TitleKeyword')}</strong>
                </p>

                <Box className={classes.company}>
                    <a href={t('Fnsv.Main.IntroductionUrl')} target={"_blank"}
                       download title={t('Fnsv.Main.IntroductionBtn')}>
                        <span>{t('Fnsv.Main.IntroductionBtn')}</span>
                    </a>
                </Box>

                <Box className={classes.store}>
                    <AppDownLink clazz={classes.storeBtn}/>
                </Box>

            </Box>

        </section>
    )
}



















