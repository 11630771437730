import React, {useState} from 'react';
import TabPanels from "../../components/TabPanels";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import LogsPage from "./LogsPage";
import UserManagementPage from "./UserManagementPage";
import {useHistory} from 'react-router';

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        position: 'relative',
    },
    tabContent: {
        margin: '40px 170px 60px 170px',
        padding: '35px 40px',
        backgroundColor: '#FFF',
        borderRadius: 6,
        justifyContent: 'center',
        display: 'flex',
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20,
        }
    }
}));

export default function SettingsPage(props) {
    const classes = useStyles();
    const history = useHistory();
    const [tabIndex, setTabIndex] = useState(props.tabIdx ? props.tabIdx : 0);

    let items = [];
    if (props.pages !== undefined) {
        items = props.pages
    }
    if (props.parentItem && props.parentItem.pages !== undefined) {
        items = props.parentItem.pages
    }

    const onTabChange = (selectedIndex) => {
        setTabIndex(selectedIndex);
        history.push({
            pathname: items[selectedIndex].url
        })
    }

    return (
        <React.Fragment>
            <TabPanels items={items} selected={tabIndex} changeHandler={onTabChange} />
            <Box className={classes.tabContent}>
                {switchTab(tabIndex)}
            </Box>
        </React.Fragment>
    );


    function switchTab(index) {
        switch (index) {
            case 0 :
                return <UserManagementPage/>
            case 1 :
                return <LogsPage/>
            default:
                return null
        }
    }
}
