import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import LabelText from "./LabelText";
import moment from "moment";
import _ from "lodash";
import {useObserver} from "mobx-react";

const useStyles = makeStyles((theme) => ({
    inputText: {
        '& input':{
            fontFamily: theme.typography.body2.fontFamily,
            fontWeight: theme.typography.body2.fontWeight,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.typography.body2.lineHeight,
            letterSpacing: theme.typography.body2.letterSpacing,
            "&::placeholder": {
                color: '#5C5C62',
                opacity: 1
            },
            "&:hover": {
                "&::placeholder": {
                    color: 'black',
                    opacity: 1
                },
            }
        }
    },
}));

export default function InputTexts({
                                       form,
                                       name,
                                       style,
                                       placeholder,
                                       value,
                                       label,
                                       variant,
                                       dataType,
                                       format,
                                       isEditable = true,
                                       rows,
                                       disabled = false
                                   }) {
    const classes = useStyles();

    const field = form.$(name);
    value = value === undefined ? "" : value;
    const convertDefaultData = _dataFormat(value, dataType, format);
    let data = null;

    const onChangeEvent = (event) => {
        data = _dataFormat(event.target.value, dataType, format);
        field.value = data;
    }

    return useObserver( () => (
        <React.Fragment>
            {isEditable
                ?
                (
                    <TextField name={name}
                               placeholder={placeholder}
                               defaultValue={convertDefaultData}
                               onChange={onChangeEvent}
                               minRows={(rows===undefined?1:rows)}
                               multiline={(rows!==undefined)}
                               variant={variant!==undefined?variant:'outlined'}
                               label={variant!==undefined?label:''}
                               fullWidth
                               disabled={disabled}
                               className={`${classes.inputText} ${style ? style : null}`}
                               {...(field?.error ? {error: true, helperText: field.error} : {helperText: undefined})}
                    />
                )
                :
                (
                    <LabelText translation={false} label={data ? data : convertDefaultData} />
                )
            }
        </React.Fragment>
    ));

}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === "date") {
        let dateFormat = "YYYY-MM-DD";
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === "number") {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
