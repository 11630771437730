import React, {useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Box, Button, useMediaQuery} from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {TextField} from "@mui/material";
import {useFormik} from 'formik';
import * as yup from 'yup';
import api from "../../common/api";
import {ErrCode} from "../../common/types";
import {useTranslation} from "react-i18next";
import {useStores} from "../../common/store";

const useStyles = makeStyles((theme) => ({
    mailBox     : {
        marginLeft                    : 70,
        width                         : 670,
        '& > h2'                      : {
            fontWeight  : '700',
            fontSize    : '45px',
            lineHeight  : '68px',
            marginBottom: 20,
        },
        [theme.breakpoints.down('md')]: {
            margin        : '0',
            padding       : '0 20px',
            display       : 'flex',
            flexDirection : 'column',
            flex          : 1,
            justifyContent: 'center',
            textAlign     : 'center',
            width         : '100vw',
            height        : '100vh',
            '& > h2'      : {
                fontSize  : '32px',
                lineHeight: '48px',
            },
        }

    },
    inputBox1   : {
        display                       : 'flex',
        marginBottom                  : 10,
        '& > :nth-child(1)'           : {
            marginRight: 10
        },
        '& > :nth-child(2)'           : {
            flex: 1
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            '& > *'      : {
                margin: '5px 0 !important'
            },
        }
    },
    commonInput : {
        '& .MuiOutlinedInput-root': {
            background: '#26272A',
        },
        '& input, & textarea'     : {
            color: '#fff',
        },
        '& label'                 : {
            color: '#5E6066'
        },
    },
    nameInput   : {width: 220},
    mailInput   : {
        width      : 440,
        marginRight: '0px !important'
    },
    contentInput: {
        width: '100%'
    },
    submitBtn   : {
        display                       : 'flex',
        margin                        : '36px auto',
        cursor                        : 'pointer',
        padding                       : '10px 80px',
        borderRadius                  : '30px',
        color                         : '#fff',
        borderColor                   : '#ffffff4d',
        [theme.breakpoints.down('md')]: {
            margin: '10px auto',
        }
    }
}));

interface Values {
    name: string;
    mail: string;
    contents: string;
}


export default function MailForm(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();

    const [mail, setMail] = useState(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const validationSchema = yup.object({
        name   : yup
            .string('Name')
            .required('Email is required'),
        email  : yup
            .string('E-mail')
            .required('Email is required'),
        content: yup
            .string('Contents')
            .required('Contents is required')
    });

    const formik = useFormik({
        initialValues   : {
            name   : '',
            email  : '',
            content: ''
        },
        validationSchema: validationSchema,
        onSubmit        : (values, actions) => {

            api.postContactMail(values)
                .then(data => {

                    if (data.rtCode === ErrCode.RT_SUCCESS) {
                        actions.resetForm({
                            values: {
                                name   : '',
                                email  : '',
                                content: ''
                            }
                        });
                        ds.showSuccessAlert(t('Fnsv.Company.ContactUs.Contact.Success'));
                    }
                })
                .catch(err => {
                    return err;
                });
        },
    });

    return (
        <Box className={classes.mailBox}>
            <h2>{t('Fnsv.Company.ContactUs.Contact.Title')}</h2>
            <form onSubmit={formik.handleSubmit}>
                <Box className={classes.inputBox1}>
                    <TextField
                        id="name"
                        name="name"
                        label={t('Fnsv.Company.ContactUs.Contact.Name')}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        className={`${classes.commonInput}`}
                    />
                    <TextField
                        id="email"
                        name="email"
                        label={t('Fnsv.Company.ContactUs.Contact.Email')}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        className={`${classes.commonInput}`}
                    />
                </Box>
                <TextField
                    id="content"
                    name="content"
                    label={t('Fnsv.Company.ContactUs.Contact.Content')}
                    value={formik.values.content}
                    onChange={formik.handleChange}
                    error={formik.touched.content && Boolean(formik.errors.content)}
                    helperText={formik.touched.content && formik.errors.content}
                    className={`${classes.commonInput} ${classes.contentInput}`}
                    multiline
                    rows={(isMobile ? 10 : 19)}
                />

                <Button type="submit"
                        variant="outlined"
                        className={classes.submitBtn}
                >
                    {t('Fnsv.Company.ContactUs.Contact.SubmitBtn')}
                </Button>
            </form>
        </Box>
    )
}
