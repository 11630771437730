import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../../components/DataTable';
import api from "../../common/api";
import {Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {CommonCodeValue, UserType, LicenseStatus} from "../../common/types";
import queryString from 'query-string';
import {useStores} from "../../common/store";
import moment from "moment";
import _ from "lodash";
import Box from "@material-ui/core/Box";
import SelectBox from "../../components/SelectBox";


const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: '40px 170px 60px 170px',
        padding: '35px 40px',
        backgroundColor: '#FFF',
        borderRadius: 6,
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20,
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',

        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
        // [theme.breakpoints.down('sm')]: {
        //     overflowX: 'scroll',
        //     overflowY: 'hidden',
        //     height: 60
        // }
    },
    // selectContainer: {
    //     display: 'flex',
    //     height: 40,
    //     margin: 0,
    //     "& > *": {
    //         marginRight: 10
    //     }
    // },
    inputBox: {
        ...theme.select,
        minWidth: 200,
        [theme.breakpoints.down('xs')]: {
            flex: 1,
            maxWidth: '100% !important',
            width: '100% !important'
        }
    },
    table: {
        '& .MuiTableRow-root': {
            '& > :nth-child(1)': {
                width: 100
            },
            '& > :nth-child(2), :nth-child(3)': {
                width: 300
            },
            '& > :nth-child(4)': {
                width: 180
            },
            '& > :nth-child(5), :nth-child(6)': {
                width: 250
            },
        }
    }
}));


export default function LicenseListPage(props) {
    const pageSize = 5;
    const {location, setCreate} = props;
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const query = queryString.parse(location.search);
    let client = query['client'] !== undefined ? query['client'] : null;
    let vendor = query['vendor'] !== undefined ? query['vendor'] : null;
    let status = query['status'] !== undefined ? query['status'] : null;

    const searchParams = useRef(
        {
            client: client,
            vendor: vendor,
            status: status
        }
    );

    const [page, setPage] = React.useState(1);
    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);

    /********************** USE STATE EVENT **********************/

    const onChangeSelect = (type, value) => {
        if (type === 'client') {
            searchParams.current.client = value;
        } else if (type === 'vendor') {
            searchParams.current.vendor = value;
        } else if (type === 'status') {
            searchParams.current.status = value;
        }
        search(null, 1);
    }

    /********************** USE STATE EVENT **********************/

    const rowClickEvent = (row) => (event) => {
        setEvent({clientName: row[1].content, seq: row[6]?.content, clientKey: row[7].content});
    }

    const search = (event, page = 1) => {
        const query = {
            client: searchParams.current.client,
            vendor: searchParams.current.vendor,
            status: searchParams.current.status,
            page: page
        };
        setPage(page);
        fetchData(query, pageSize);
    }

    const fetchData = (query) => {
        const client = query.client !== 'ALL' ? query.client : null;
        const vendor = query.vendor !== 'ALL' ? query.vendor : null;
        const status = query.status !== 'ALL' ? query.status : null;

        api.getLicensePage(client, vendor, status, query.page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        fetchData({client, vendor, status, page});
        setCreate(
            {
                description: t('LicensePage.NewLicense'),
                dialogTitle: t('LicensePage.AddDialog.Title')
            }
        );
    }, [i18n.language]);


    if (event != null) {
        return (
            <Redirect push to={{
                pathname: '/admin/license/detail',
                search: "?" + api.encodeParams({seq: event.seq, clientKey: event.clientKey}),
                state: api.encodeParams({clientName: event.clientName})
            }}/>
        );
    }
    return (
        <Box className={classes.pageContent}>
            <ConditionPanel searchParams={searchParams}
                            search={search}
                            onChangeSelect={onChangeSelect}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={page}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
        </Box>
    );
}

function ConditionPanel({searchParams, search, onChangeSelect}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();

    const statusArray = [];
    if (statusArray.length === 0) {
        Object.keys(LicenseStatus)
            .map((key, index) => {
                statusArray[index] = {key: key, value: LicenseStatus[key], text: t(CommonCodeValue.translateKey + '.' + LicenseStatus[key])};
            })
    }

    const clientArray = [];
    const vendorArray = [];
    if (ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null) {
        if (clientArray.length === 0) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                    clientArray[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }

        if (vendorArray.length === 0) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isSuperManager( ds.user.accessibleClient[key].userType ) || UserType.isVendorManager( ds.user.accessibleClient[key].userType ) ) {
                    vendorArray[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }
    }

    const onChangeClient = (event) => {
        if (onChangeSelect) {
            onChangeSelect('client', event.target.value);
        }
    }
    const onChangeVendor = (event) => {
        if (onChangeSelect) {
            onChangeSelect('vendor', event.target.value);
        }
    }

    const onChangeStatus = (event) => {
        if (onChangeSelect) {
            onChangeSelect('status', event.target.value);
        }
    }


    return (
        <div className={classes.conditionDiv}>
            {/*<div className={classes.selectContainer}>*/}
                <SelectBox className={classes.inputBox}
                           title={t('LicensePage.ClientBox')}
                           value={searchParams.current.client}
                           contents={clientArray}
                           onChange={onChangeClient}
                           addAll={true}
                />
                <SelectBox className={classes.inputBox}
                           title={t('LicensePage.VendorBox')}
                           value={searchParams.current.lang}
                           contents={vendorArray}
                           onChange={onChangeVendor}
                           addAll={true}
                />
                <SelectBox className={classes.inputBox}
                           title={t('LicensePage.StatusBox')}
                           value={searchParams.current.lang}
                           contents={statusArray}
                           onChange={onChangeStatus}
                           addAll={true}
                />
            {/*</div>*/}
        </div>
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage, pageSize}) {
    const {t} = useTranslation();
    const style = useStyles();

    const body = [];
    const head = [
        [
            {content: 'No'},
            {content: t('LicensePage.Header.Client')},
            {content: t('LicensePage.Header.Vendor')},
            {content: t('LicensePage.Header.Status')},
            {content: t('LicensePage.Header.ValidityPeriod')},
            {content: t('LicensePage.Header.RegDate')}
        ]
    ];

    if (response !== null) {
        let num = response.page.totalElements - (response.page.currentPage * pageSize);

        response.data.map((data, idx) => {
            // let title = data.title ? data.title : '-';
            let clientName = data.clientName ? data.clientName : '-';
            let vendorClientName = data.vendorClientName ? data.vendorClientName : '-';
            let status = data.status ? t(CommonCodeValue.translateKey + '.' + data.status) : '-';
            let startDt = data.startDt ? _dataFormat(data.startDt, 'date', 'YYYY-MM-DD') : '-';
            let endDt = data.endDt ? _dataFormat(data.endDt, 'date', 'YYYY-MM-DD') : '-';
            let regDt = data.regDt ? _dataFormat(data.regDt, 'date', 'YYYY-MM-DD HH:mm') : '-';

            body[idx] = [
                {id: 'index', content: num--},
                {id: 'client', content: clientName},
                {id: 'vendor', content: vendorClientName},
                {id: 'status', content: status},
                {id: 'validityDate', content: startDt + ' ~ ' + endDt},
                {id: 'regDate', content: regDt},
                {id: 'seq', content: data.seq},
                {id: 'clientKey', content: data.clientKey}
            ];

        });
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   style={style}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}