import React, {useEffect, useRef, useState} from 'react';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from 'react-router';
import {useStores} from "../../common/store";
import {useTranslation} from "react-i18next";
import InputTexts from "../../components/InputTexts";
import Button from "@material-ui/core/Button";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import _ from "lodash";
import api from "../../common/api";
import ConfirmDialog from "../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: '1.875rem'
    },
    title: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    content: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > div': {
            marginBottom: theme.spacing(2),
        },
        '& > div:first-child': {
            minWidth: 650,
            [theme.breakpoints.between('xs', 'md')]: {
                minWidth: 'initial',
                flex: 1,
            }
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            width: 130,
            height: 40,
            borderRadius: 5,
            fontSize: '0.75rem',
            fontWeight: 700,
            boxShadow: 'none'
        },
        '& > :first-child': {
            border: '1px solid #C7CBD1',
            backgroundColor: 'transparent',
            color: '#81808A',
            marginRight: 6,
            "&:hover": {
                border: '1px solid #3C39CB',
                backgroundColor: 'transparent',
                color: theme.palette.primary.main
            }
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
            }
        }
    }

}));

export default function LicenseCreate(props) {
    const classes = useStyles();
    const {ds} = useStores();
    const {t} = useTranslation();
    const history = useHistory();
    const {onClose} = props;

    const [openConfirm, setOpenConfirm] = useState(false);

    const licenseKey = useRef();
    const cloneLicenseKey = useRef();

    const saveCancelClick = () => {
        licenseKey.current = _.clone(cloneLicenseKey.current);
    }

    const formId = "agreementForm";
    const form = new MobxReactForm({
        fields: [
            {
                name: 'content',
                value: licenseKey.current,
                rules: 'required',
                label: t('LicensePage.AddDialog.LicenseKey'),
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                const values = form.values();
                if (values['content'] !== null && values['content'] !== "") {
                    licenseKey.current = values['content'];
                }
                setOpenConfirm(true);
            },
            onError(form) {
                let errorMsg = "";
                Object.keys(form.errors()).map((data, index) => {
                    if (errorMsg === "") {
                        errorMsg = form.errors()[data];
                        if (errorMsg === null) {
                            errorMsg = "";
                        }
                    }
                });
                ds.showErrorAlert(errorMsg);
                console.log("onError", form.errors());
            }
        }
    });

    const search = (seq, clientKey, clientName) => {
        history.push({
            pathname: '/admin/license/detail',
            search: "?" + api.encodeParams({seq: seq, clientKey: clientKey}),
            state: api.encodeParams({clientName: clientName})
        });
    };


    const onClickBtn = (isYes) => {
        if (isYes) {
            api.postLicense({licenseKey: licenseKey.current, comment: ''})
                .then((data) => {
                    search(data.seq, data.clientKey, data.clientName);
                    ds.showInfoAlert(t('LicensePage.RegistrationSuccess'));
                })
                .catch(err => {
                    ds.showErrorAlert(api.getErrMsg(err.rtCode));
                });
        }
        setOpenConfirm(false);
    }

    return (
        <React.Fragment>
            <Box className={classes.dialogContent}>
                <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                    <div className={classes.bodyContainer}>
                        <div>
                            <InputTexts name={'content'}
                                        form={form}
                                        label={t('LicensePage.AddDialog.LicenseKey')}
                                        rows={10}
                                        variant={'outlined'}
                            />
                        </div>
                    </div>
                </form>
                <div className={classes.buttonContainer}>
                    <Button variant="contained"
                            type={'button'} form={formId}
                            onClick={onClose}
                    >
                        {t('LicensePage.AddDialog.Cancel')}
                    </Button>
                    <Button variant='contained'
                            size='large'
                            color='primary'
                            onClick={form.onSubmit}
                    >
                        {t('LicensePage.AddDialog.Save')}
                    </Button>
                </div>
            </Box>
            <ConfirmDialog
                open={openConfirm}
                title={t('LicensePage.AddDialog.ConfirmTitle')}
                content={t('LicensePage.AddDialog.ConfirmContent')}
                onOk={() => onClickBtn(true)}
                onClose={() => onClickBtn(false)}
            />
        </React.Fragment>
    );
}