import React, {useRef, useState} from 'react';
import { useTable, useGlobalFilter } from "react-table";
import NoticeDetailDialog from "../components/NoticeDetailDialog"
import moment from "moment";

/*
    테이블 구성
    columns : 앞서 구성한 컬럼
    data : 실질적인 데이터 값
 */
function Table({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
    } = useTable({ columns, data }, useGlobalFilter);

    const [openDialog, setOpenDialog] = useState(false);    //테이블 row 클릭 시 상세 Dialog 호출 할 수 있는 상태 값

    let dialogContent = useRef(null);                       //DialogContent 영역 구성

    let today = moment(new Date()).format('YYYY-MM-DD');    //현재 날짜(format : yyyy-mm-dd)

    /*
    * 테이블  row 클릭 이벤트
    * Dialog 값 true로 변경하여 호출
    * row의 값이 있어야 dialogueContent값에 row 값을 설정 할 수 있다.
    */
    const rowClickEvent = (row) =>  {
        setOpenDialog(true);
        if (row !== undefined) {
            dialogContent.current = row;
        }
    }

    /*
     * 상세 Dialog 닫기 이벤트
     * Dialog 값 false로 변경하여 닫는다.
     */
    const onCloseDialog = () => {
        setOpenDialog(false);
    }

    return (
        <>
            <div style={{marginRight : "10px" , textAlign : "right", color : "black"}}>{today}</div>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    return (
                        // onclick => rowClickEvent 호출 등록 값 : row 값
                        <tr {...row.getRowProps()} onClick={() => {rowClickEvent(row.original)} }>
                            {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
            <NoticeDetailDialog open={openDialog} content={dialogContent} onCloseEvent={onCloseDialog}/>
        </>


    );
}

export default Table;