import React, {useRef, useState} from 'react';
import Box from "@material-ui/core/Box";
import TextEditor from "../../components/TextEditor";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {useStores} from "../../common/store";
import {makeStyles} from "@material-ui/core/styles";
import {QuestionType, UserType} from "../../common/types";
import {useObserver} from "mobx-react-lite";
import FormControl from "@material-ui/core/FormControl";
import Select from '@mui/material/Select';

import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import MobxReactForm from "mobx-react-form";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import ConfirmDialog from "../../components/ConfirmDialog";
import api from "../../common/api";
import {useHistory} from 'react-router';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ListItemIcon} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    forms: {
        display: 'flex',
        [theme.breakpoints.between('xs', 'md')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
        }
    },
    inputSelect:{
        fontSize: '0.813rem',
        ...theme.select,
        minWidth: 200,
        width: '100%',
        maxWidth: '100%',
        marginRight: 10,
        '& legend': {
            display: 'none'
        },
        "&:hover > .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3C39CB !important",
        },
        [theme.breakpoints.between('xs', 'md')]: {
            minWidth: '100%'
        }
    },
    selectPaper: {
        maxWidth: '100%',
        border: theme.palette.border.secondary,
        boxShadow: theme.palette.shadow.primary,
        ...theme.select,
        '& > :first-child': {
            '& .MuiListItem-root.Mui-disabled': {
                opacity: 1
            },
        }
    },
    titleItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > :nth-child(2)': {
            minWidth: 'auto',
            '& > *': {
                color: theme.palette.primary.main
            }
        }
    },
    allItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > :first-child': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& > :nth-child(2)': {
            minWidth: 'auto',
            '& > *': {
                color: "transparent"
            }
        }
    },
    client: {
        minWidth: 200,
        [theme.breakpoints.between('xs', 'md')]: {
            flex: 1
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '0 !important'
        }
    },
    category: {
        minWidth: 200,
        [theme.breakpoints.between('xs', 'md')]: {
            flex: 1
        }
    },
    qna_summary: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'initial',
            "& > *": {
                marginBottom: theme.spacing(1),
            }
        }
    },
    dialog_content: {
        padding: '1.875rem'
    },
    dialog_forms: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        columnGap: theme.spacing(1),
        "& > div": {
            flexGrow: 1,
            minWidth: 320,
        },
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            marginTop: -theme.spacing(1),
            marginBottom: -theme.spacing(1),
            "& > div": {
                width: '100%',
                minWidth: 160,
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            }
        }
    },
    dialog_buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > *': {
            width: 130,
            height: 40,
            borderRadius: 5,
            fontSize: '0.75rem',
            fontWeight: 700,
            boxShadow: 'none'
        },
        '& > :first-child': {
            border: '1px solid #C7CBD1',
            backgroundColor: 'transparent',
            color: '#81808A',
            marginRight: 6,
            "&:hover": {
                border: '1px solid #3C39CB',
                backgroundColor: 'transparent',
                color: theme.palette.primary.main
            }
        },
        '& > :last-child': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
            "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: '#fff',
            }
        },
        [theme.breakpoints.between('xs', 'md')]: {
            justifyContent: 'center',
            marginRight: 0
        }
    },
}));

function ClientSelector({className, clients, value, onChange, field}) {
    const {t} = useTranslation();
    const {ds} = useStores();
    const classes = useStyles();

    const clientsArray = useRef([]);

    if( ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null ) {
        if( clientsArray.current.length === 0 ) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                    clientsArray.current[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }
    }

    return useObserver(() =>
        <FormControl variant="outlined" className={className} {...(field?.error ? {error: true} : null)}>
            <Select
                onChange={onChange}
                displayEmpty={true}
                labelWidth={80}
                label={t("QnaPage.ClientSelect")}
                {...field?.bind()}
                className={classes.inputSelect}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                    classes: {paper: classes.selectPaper},
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
            >
                <MenuItem value="">
                    <Box className={classes.titleItem}>
                        <div>{t("QnaPage.ClientSelect")}</div>
                        <ListItemIcon>
                            <ExpandMoreIcon/>
                        </ListItemIcon>
                    </Box>
                </MenuItem>
                {
                    clientsArray.current.map((content, idx) => <MenuItem key={idx} value={content.value}>{content.text}</MenuItem>)
                }
            </Select>
            {field ? <FormHelperText>{field.error ? field.error : " "}</FormHelperText> : null}
        </FormControl>
    );
}

function CategorySelector({className, value, onChange, field}) {
    const {t} = useTranslation();
    const classes = useStyles();

    return useObserver(() =>
        <FormControl variant="outlined" className={className} {...(field?.error ? {error: true} : null)}>
            <Select
                onChange={onChange}
                displayEmpty={true}
                className={classes.inputSelect}
                {...field?.bind()}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                    classes: {paper: classes.selectPaper},
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
            >
                <MenuItem value="">
                    <Box className={classes.titleItem}>
                        <div>{t("QnaPage.CategorySelect")}</div>
                        <ListItemIcon>
                            <ExpandMoreIcon/>
                        </ListItemIcon>
                    </Box>
                </MenuItem>
                <MenuItem value={QuestionType.License}>{QuestionType.getText(QuestionType.License)}</MenuItem>
                <MenuItem value={QuestionType.Auth}>{QuestionType.getText(QuestionType.Auth)}</MenuItem>
                <MenuItem value={QuestionType.Partner}>{QuestionType.getText(QuestionType.Partner)}</MenuItem>
                <MenuItem value={QuestionType.TechnicalSupport}>{QuestionType.getText(QuestionType.TechnicalSupport)}</MenuItem>
            </Select>
            {field ? <FormHelperText>{field.error ? field.error : " "}</FormHelperText> : null}
        </FormControl>
    );
}

function TextFieldWrapper(props) {
    const {field} = props;
    return useObserver(() =>
        <TextField
            {...props}
            {...field?.bind()}
            {...(field?.error ? {error: true, helperText: field.error} : {helperText: " "})}
        />
    );
}

export default function QuestionEdit({isEdit, qna, onClose}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const {ds} = useStores();
    const history = useHistory();

    const [clients, setClients] = useState([]);
    const [openConfirm, setOpenConfirm] = useState(false);

    const values = useRef({
        clientKey: qna?.clientKey ? qna.clientKey : ds.user.clientKey,
        type: qna?.type ? qna.type : "",
        title: qna?.title ? qna.title : "",
        content: qna?.content ? qna.content : "",
        questionSeq: qna?.questionSeq ? qna.questionSeq : null,
    });

    const formId = "questionForm";
    const form = new MobxReactForm({
        fields: [
            {name: 'clientKey', value: values.current.clientKey, rules: 'required', label: "Client"},
            {name: 'type', value: values.current.type, rules: 'required', label: "Category"},
            {
                name: 'title',
                value: values.current.title,
                rules: 'required',
                label: t("QnaPage.Dialog.TitleLabel"),
                placeholder: t("QnaPage.Dialog.TitlePlaceHolder")
            },
            {
                name: 'content',
                value: values.current.content,
                rules: 'required',
                label: t("QnaPage.Dialog.ContentLabel"),
                placeholder: t("QnaPage.Dialog.ContentPlaceHolder")
            },
        ]
    }, {
        plugins: {
            dvr: dvr(validatorjs)
        },
        hooks: {
            onSuccess(form) {
                values.current = form.values();
                if (isEdit && !form.isDirty) {
                    ds.showWarningAlert(t("QnaPage.NoChange"));
                    return;
                }
                setOpenConfirm(true);
            },
            onError(form) {
                console.log("onError", form.errors());
            }
        }
    });

    const f_client = form.$("clientKey");
    const f_category = form.$("type");
    const f_title = form.$("title");
    const f_content = form.$("content");

    const refresh = () => {
        history.push('/');
        history.replace('/admin/qna');
    };

    const onClickBtn = (isYes) => {
        if (isYes) {
            if (isEdit) {
                values.current["questionSeq"] = qna.questionSeq;

                api.putQuestion(values.current)
                    .then((data) => {
                        onClose(true);
                        ds.showInfoAlert(t("QnaPage.EditComplete"));
                    })
                    .catch(err => {
                        ds.showErrorAlert(api.getErrMsg(err.rtCode));
                    });
            } else {
                api.postQuestion(values.current)
                    .then((data) => {
                        onClose(true);
                        refresh();
                        ds.showInfoAlert(t("QnaPage.CreateComplete"));
                    })
                    .catch(err => {
                        ds.showErrorAlert(api.getErrMsg(err.rtCode));
                    });
            }
        }
        setOpenConfirm(false);
    }

    return (
        <React.Fragment>
            <Box className={classes.dialog_content}>
                <form noValidate autoComplete="off" onSubmit={form?.onSubmit} id={formId}>
                    <div className={classes.dialog_forms}>
                        <ClientSelector className={classes.client} clients={clients} field={f_client}/>
                        <CategorySelector className={classes.category} field={f_category}/>
                    </div>
                    <TextFieldWrapper
                        placeholder={t("QnaPage.Dialog.TitlePlaceHolder")}
                        variant="outlined"
                        fullWidth
                        field={f_title}
                    />
                    <TextEditor
                        placeholder={t("QnaPage.Dialog.ContentPlaceHolder")}
                        field={f_content}
                        height={300}
                    />
                </form>
                <div className={classes.dialog_buttons}>
                    <Button variant='contained' size='large' onClick={onClose}>{t("QnaPage.Dialog.CancelBtn")}</Button>
                    <Button variant='contained' size='large' color='primary'
                            onClick={form.onSubmit}>{isEdit ? t("QnaPage.Dialog.EditBtn") : t("QnaPage.Dialog.OkBtn")}</Button>
                </div>
            </Box>
            <ConfirmDialog
                open={openConfirm}
                title={isEdit ? t("QnaPage.Confirm.EditTitle") : t("QnaPage.Confirm.WriteTitle")}
                content={isEdit ? t("QnaPage.Confirm.EditMessage") : t("QnaPage.Confirm.WriteMessage")}
                onOk={() => onClickBtn(true)}
                onClose={() => onClickBtn(false)}
            />
        </React.Fragment>
    );
}