import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LabelText from "../../../components/LabelText";
import Grid from "@material-ui/core/Grid";
import {CardTypes, ExcludeMode} from "../../../components/Cards";
import {ErrCode, UserStatus} from "../../../common/types";
import api from "../../../common/api";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {PhoneNumberFormat} from "../../../common/utils/StringFormatUtils";
import {useStores} from "../../../common/store";
import {UserType} from "../../../common/types";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import ConfirmDialog from "../../../components/ConfirmDialog";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {useHistory} from "react-router-dom";
import {Grow} from "@mui/material";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > div':{
            padding:0,
            flexGrow: 1,
            '& > .MuiGrid-item > div': {
                backgroundColor: '#FFF'
            }
        },
        '& > div > div' :{
            display:'flex',
        }
    },
    wordStyle:{
        textAlign:'center',
    },
    unlinkContainer:{
        justifyContent:'center'
    },
    unlinkButton:{
        width:200
    },
    cardRoot: {
        display:'flex',
        flex:1,
        padding: theme.spacing(4),
        background: theme.palette.background.panel,
        position:'relative',
        '&.MuiPaper-elevation1':{
            boxShadow:'none',
        },
        '&.MuiPaper-rounded':{
            borderRadius:theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection:'column',
        }
    },
    title: {
        margin: 0,
        marginBottom: theme.spacing(3),
        padding: 0,
        color: theme.palette.text.title,
        '& span': {fontSize: '1rem'}
    },
    cardBox:{
        flex:1,
    },
    cardBoxCenter:{
        padding:'0 50px',
        margin:'0 50px',
        borderLeft:'1px solid #E3E4ED',
        borderRight:'1px solid #E3E4ED',
        [theme.breakpoints.between('xs', 'sm')]: {
            border:'none',
            borderTop:'1px solid #565656',
            borderBottom:'1px solid #565656',
            padding:`${theme.spacing(3)}px 0 0 0`,
            margin:`0 0 ${theme.spacing(3)}px 0`
        }
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        '& > dl': {
            display: 'flex',
            flex: 1,
            margin: 0,
            minHeight: theme.spacing(4),
            marginBottom: theme.spacing(2),
            alignItems: 'center',
            '& > dt': {
                flex: 1,
                color: theme.palette.text.gray,
            },
            '& > dd': {
                wordBreak: 'break-all',
            },
        },
        [theme.breakpoints.down('xs', 'xs')]: {
            '& > dl': {
                flexDirection: 'column',
                alignItems: 'flex-start',
                '& > dt': {
                    alignSelf: 'flex-start',
                    width:'100%'
                },
                '& > dd': {
                    wordBreak: 'break-all',
                    width:'100%'
                }
            },
        }
    },
    resetBtn: {
        backgroundColor: '#FFF',
        border: '1px solid #3C39CB',
        color: theme.palette.primary.main,
        boxShadow: 'none',
        '&:hover': {
            color: theme.palette.primary.contrastText,
            boxShadow: 'none'
        }
    }
}));

const buttonStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    grayBtn:{
        whiteSpace:'nowrap',
        marginLeft:theme.spacing(1),
        background:theme.buttons.btn1.background.default,
        color:theme.buttons.btn1.color,
        '&:hover':{
            background:theme.buttons.btn1.background.hover,
        }
    },
    avatarBox: {
        display: 'flex',
        '& > *': {
            marginRight: theme.spacing(1),
            padding:theme.spacing(1)
        },
    },
    small: {
        border:'1px solid #fff',
    },
    large: {
        border:'1px solid #fff',
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

const typeStyle = makeStyles((theme) => ({
    root : {
        display:'flex',
        alignItems:'center',
        background: '#ffffff',
        padding:theme.spacing(2),
        borderRadius:'5px',
        '& > h6':{
          fontSize:'0.813rem'
        },
        '& > img':{
            width:'1rem',
            height:'1rem',
            marginRight: '0.5rem'
        }
    }
}));

const dialogStyles = makeStyles((theme) => ({
        dialog_title: {
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 720,
            "& > *": {
                ...theme.typography.h5
            }
        },
        dialog_content: {
            marginTop: -theme.spacing(1),
            marginBottom: theme.spacing(1),
            minWidth: 400,
            maxWidth: 960,
        },
        dialog_buttons: {
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: theme.spacing(1),
            "& > *": {
                minWidth: 100,
            }
        }
    })
);
const statusStyles = makeStyles((theme) => ({
    container:{
        display:'flex',
        flex:1,
        alignItems:'center',
        justifyContent:'space-between'
    },
    buttonDown:{
        minWidth:40,
        padding:'4px 10px',
        borderRadius:`4px !important`,
        border: '1px solid #3C39CB',
        background: '#FFF',
        color: theme.palette.primary.main,
        '&:hover':{
            background: theme.palette.primary.main,
            color: '#FFF'
        }
    },
    popperContainer:{
        marginTop:5
    },
    buttonGroup:{
        [theme.breakpoints.between('xs', 'sm')]: {
            display:'flex',
            flexDirection:'column',
            flex:1,
            '& > *':{
                border:'1px solid rgba(255, 255, 255, 0.23) !important',
                borderRadius:`4px !important`
            }
        }
    }
}));

function AuthorityComponent(props) {

    const statusStyle = statusStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const { params, value, isEdit, onSuccess } = props;
    const options = props.options.current;

    const anchorRef = useRef(null);
    const preSelect = useRef(null);

    useEffect( () => {
        setSelectedIndex(options.indexOf(value));
    }, [value]);

    const handleMenuItemClick = (event, index) => {
        preSelect.current = selectedIndex;
        setSelectedIndex(index);
        setOpen(false);
        setOpenConfirm(true);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const onConfirmClose = () => {
        setSelectedIndex(preSelect.current);
        setOpenConfirm(false)
    }

    const changeAuthority = () => {
        api.changeAuthority(params.clientKey, params.userKey, options[selectedIndex])
            .then(data => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    ds.showInfoAlert(t('UserPage.Detail.Dialog.ChangeAuthoritySuccess'));
                    onSuccess();
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                console.log("err => ",err);
                return err;
            });
        setOpenConfirm(false);
    }

    return (
        <React.Fragment>
            <Box className={statusStyle.container} ref={anchorRef}>
                <LabelText value={value} dataType={"commonCode"}/>
                { isEdit &&
                    <Button className={statusStyle.buttonDown} onClick={handleToggle}>
                        <ArrowDropDownIcon/>
                    </Button>
                }
            </Box>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start" className={statusStyle.popperContainer}>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}  style={{transformOrigin: 'center top'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                    {options?.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            disabled={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {t('CommonCode.' + option)}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <ConfirmDialog open={openConfirm}
                           onClose={onConfirmClose}
                           onOk={changeAuthority}
                           title={'UserPage.Detail.Dialog.ConfirmTitle'}
                           content={'UserPage.Detail.Dialog.AuthorityConfirmMessage'}
            />
        </React.Fragment>
    )
}

function UserStatusComponent(props) {
    const statusStyle = statusStyles();
    const {params, value, onSuccess} = props;
    const {ds} = useStores();
    const {t} = useTranslation();
    const [enabledStatus, setEnabledStatus] = React.useState(false);
    const [groupOpen, setGroupOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const userStatus = React.useRef(null);

    //const enabledStatus = React.useRef(null);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setGroupOpen( (prevState) => !prevState);
    }

    const handleClose = () => {
        setGroupOpen( false );
    }

    const handleClick = (item) => {
        userStatus.current = item;
        setConfirmOpen( true );
        setGroupOpen(false);
    }

    const fetch = (clientKey, userKey) => {
        api.getEnabledStatus(clientKey, userKey)
            .then( res => {
                //console.log("res => ",res);
                if( res.rtCode === ErrCode.RT_SUCCESS ) {
                    //console.log("getEnabledStatus => ",res.data);
                    setEnabledStatus(res.data);
                }
            })
            .catch( err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                return err;
            })
    }

    const onConfirmOk = () => {
        setConfirmOpen( false );
        api.putEnabledStatus(params.clientKey, params.userKey, userStatus.current)
            .then( res => {
                if( res.rtCode === ErrCode.RT_SUCCESS ) {
                    onSuccess();
                    fetch(params.clientKey, params.userKey);
                }
            })
            .catch( err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                return err;
            })
    }

    useEffect( () => {

        fetch(params.clientKey, params.userKey);

    }, []);

    const StatusLayout = ({item}) => {

        return (
            <React.Fragment>
                { item?.length === 1 &&
                <Button
                    className={statusStyle.buttonDown}
                    color="primary"
                    size="small"
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleClick(item[0])}
                >
                    <LabelText value={item[0]} dataType={"commonCode"}/>
                </Button>
                }
                {
                    item?.length > 1 &&
                    <Button
                        className={statusStyle.buttonDown}
                        color="primary"
                        size="small"
                        aria-controls={groupOpen ? 'split-button-menu' : undefined}
                        aria-expanded={groupOpen ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Box className={statusStyle.container} ref={anchorRef}>
                <LabelText value={value} dataType={"commonCode"}/>
                <StatusLayout item={enabledStatus} />
            </Box>
            <Popper open={groupOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start" className={statusStyle.popperContainer}>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps}  style={{transformOrigin: 'center top'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <ButtonGroup className={statusStyle.buttonGroup}>
                                    {enabledStatus?.map((item, index) => (
                                        <Button key={index} onClick={()=>handleClick(item)}>{t('CommonCode.'+item)}</Button>
                                    ))}
                                </ButtonGroup>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

            <ConfirmDialog open={confirmOpen}
                           onClose={() => setConfirmOpen(false)}
                           onOk={onConfirmOk}
            />
        </React.Fragment>
    )
}

function BoxCards({titles, components, form, claszz}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const excludeType = ['divider','expireAlert'];

    return (
        <Box className={`${classes.cardBox} ${claszz} ` }>
            <CardHeader title={(t(titles))} className={classes.title}/>
            <CardContent className={classes.cardContent}>
                {components.map((data, index) => (
                    (
                        _.includes(excludeType, data.cardType)
                            ? <ExcludeMode {...data} key={index}/>
                            : (
                                <dl key={index}>
                                    <CardTypes {...data} form={form} />
                                </dl>
                            )
                    )
                ))}
            </CardContent>
        </Box>
    )
}

function authTypeBox({value}) {
    const typeClasses = typeStyle();
    const {t} = useTranslation();

        if( value !== null && value !== ""){
            let imgUrl = null;

            if( value === 1 ) {
                imgUrl = '../../images/auth_icon.png';
            } else if( value === 2 ) {
                imgUrl = '../../images/auth_fingerprint.png';
            } else if( value === 3 ) {
                imgUrl = '../../images/auth_fingerprint.png';
            } else if( value === 4 ) {
                imgUrl = '../../images/icon_passcode.png';
            }

            return (
                <div className={typeClasses.root}>
                    <img src={imgUrl}/>
                    <Typography variant='subtitle2' color='textSecondary'>{t('UserPage.Detail.AuthType'+value)}</Typography>
                </div>
            )
        }

    return ('')
}

export default function DetailPage(props) {
    const classes = useStyles();
    const { params } = props;
    const userDetail = useRef({});
    const options = useRef([UserType.User]);
    const {t} = useTranslation();
    const {ds} = useStores();
    const [response, setResponse] = useState([]);
    const [authConfirm, setAuthConfirm] = useState(false);
    const [eventStatus, setEventStatus] = useState(false);
    const [unLinkConfirm, setUnLinkConfirm] = useState(false);
    const history = useHistory();

    const unLinkKey = useRef({
        clientKey:'',
        userKey:''
    });

    /********************** USE STATE EVENT **********************/
    const changeAuthConfirm = () => {
        setAuthConfirm( (prevAuthConfirm) => !prevAuthConfirm);
    }
    /********************** USE STATE EVENT **********************/

    const fetch = (clientKey, userKey) => {
        api.getUserDetail(clientKey, userKey)
            .then(data => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    userDetail.current = data.data;
                    setResponse(data.data)
                    setEventStatus( (!(userDetail.current.client?.userStatus === UserStatus.Withdraw || userDetail.current.client?.userStatus === UserStatus.Wait)) )
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                console.log("err => ",err);
                return err;
            });

        api.getPermittedModifications(clientKey, userKey)
            .then(data => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    options.current= data.data.permittedUserTypes;
                }
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
                console.log("err => ",err);
                return err;
            });

    }
    useEffect( () => {
        fetch(params.clientKey, params.userKey);
    }, []);

    const nvl = (value) => {
        if (value === undefined) {
            return null;
        } else {
            return value;
        }
    }

    const onChangeStatus = () => {
        fetch(params.clientKey, params.userKey);
    }


    const onUnLink = () => {
        api.deleteAdminUnLink(unLinkKey.current)
            .then(res => {
                ds.showSuccessAlert(t('UserPage.Detail.Dialog.UnLinkResultMessage'));
                history.goBack();

            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });



        onCloseConfirm();
    }

    const onCloseConfirm = () => {
        unLinkKey.current.clientKey = '';
        unLinkKey.current.userKey = '';
        setUnLinkConfirm(false);
    }

    const onOpenConfirm = (d_clientKey, d_userKey) => {
        unLinkKey.current.clientKey = d_clientKey;
        unLinkKey.current.userKey = d_userKey;
        setUnLinkConfirm( true );
    }

    const phoneNumber = PhoneNumberFormat(userDetail.current.phoneNum);


    const userInfo = [
        {label:t('UserPage.Detail.Client'), component:LabelText, value:params.clientName},
        {label:t('UserPage.Detail.UserId'), component:LabelText, value:params.userKey},
        {label:t('UserPage.Detail.Name'),   component:LabelText, value:nvl(userDetail.current.name)},
        {label:t('UserPage.Detail.Email'),  component:LabelText, value:nvl(userDetail.current.email)},
        {label:t('UserPage.Detail.Telno'),  component:LabelText, value:phoneNumber},
    ];

    const userStatus = [
        {label:t('UserPage.Detail.Authority'),      component:AuthorityComponent,   value:nvl(userDetail.current.client?.userType), params: params, options: options, isEdit:eventStatus, onSuccess:onChangeStatus},
        {label:t('UserPage.Detail.Status'),         component:UserStatusComponent,  value:nvl(userDetail.current.client?.userStatus), params: params, onSuccess:onChangeStatus},
        {label:t('UserPage.Detail.RegDate'),        component:LabelText,            value:nvl(userDetail.current.regDt), dataType: 'date', format:'YYYY/MM/DD'},
        {label:t('UserPage.Detail.DeviceRegDate'),  component:LabelText,            value:nvl(userDetail.current.device?.deviceRegDt), dataType: 'date', format:'YYYY/MM/DD'},
        {label:t('UserPage.Detail.AprDate'),        component:LabelText,            value:nvl(userDetail.current.client?.approveDt), dataType: 'date', format:'YYYY/MM/DD'},
    ];

    const userAuth = [
        {label:t('UserPage.Detail.Type'),      component:LabelText,    cardType: 'type1'},
        {cardType:'type2',                     component:authTypeBox,  value:nvl(userDetail.current.authType) }
    ];

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={5}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card className={classes.cardRoot}>
                        <BoxCards titles={'UserPage.Detail.Information'} components={userInfo} />

                        <BoxCards claszz={classes.cardBoxCenter} titles={'UserPage.Detail.StatusInfo'} components={userStatus} />

                        <BoxCards titles={'UserPage.Detail.Authentication'} components={userAuth} />
                    </Card>
                </Grid>

                { api.MASTER_CLIENT_KEY !== params.clientKey &&
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.unlinkContainer}>
                        <Button className={classes.unlinkButton} variant="contained" color="primary" onClick={()=>onOpenConfirm(params.clientKey, params.userKey)}> {t('UserPage.Detail.UnLink')} </Button>
                    </Grid>
                }
            </Grid>

            <ConfirmDialog open={unLinkConfirm}
                           onClose={onCloseConfirm}
                           onOk={onUnLink}
                           title={'UserPage.Detail.Dialog.UnLinkTitle'}
                           content={'UserPage.Detail.Dialog.UnLinkMessage'}
            />

        </form>
    );
}
