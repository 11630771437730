import Box from "@material-ui/core/Box";
import {Doughnut} from "react-chartjs-2";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {useStores} from "../../common/store";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import api from "../../common/api";
import {useCustomTranslation} from "../../common/i18n";

const useStyles = makeStyles((theme) => ({
    chartBox: {
        flexDirection: 'row',
        "& > :first-child": {
            width: 197,
            height: 197,
            alignSelf: 'center',
            [theme.breakpoints.down("xs")]: {
                width: 160,
                height: 160
            }
        },
        [theme.breakpoints.down("xs")]: {
            paddingBottom: 10
        }
    },
    chartBoxMobile: {
        flexDirection: 'column',
        "& > :first-child": {
            width: 340,
            height: 230,
            alignSelf: 'center',
            marginTop: 20
        }
    }
}));

const tableStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        maxHeight: 275,
        margin: "0 25px 0 25px",
        alignSelf: 'center',
        [theme.breakpoints.down("xs")]: {
            margin: "0 0 0 15px"
        }
    },
    containerMobile: {
        width: '90%',
        height: 300,
        alignSelf: "center"
    },
    table: {
        '& .MuiTableRow-head': {
            '& > *': {
                height: 40,
                paddingBottom: 0,
                backgroundColor: '#fff',
                borderBottom: 0,
                fontWeight: 400,
                color: '#8A8894',
                fontSize: "0.875rem",
                fontFamily: 'Noto Sans KR',
                [theme.breakpoints.down("xs")]: {
                    fontSize: "0.75rem",
                    padding: 10
                }
            },
            "& > :nth-child(2)": {
                maxWidth: '70px'
            },
            "& > :last-child": {
                maxWidth: '70px'
            }
        },
    },
    bodyRow: {
        "& > :first-child": {
            display: 'flex',
            minHeight: 57,
            paddingRight: 5
        },
        "& > :nth-child(2)": {
            maxWidth: '70px'
        },
        '& > *': {
            height: 52,
            maxHeight: 52,
            color: '#000',
            fontSize: "0.875rem",
            fontWeight: 400,
            lineHeight: "22.2px",
            fontFamily: 'Noto Sans KR',
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.7rem",
                alignItems: 'center',
                padding: 10
            }
        },
        "& .MuiTableCell-root": {
            borderBottom: '2px solid #DFDFDF'
        }
    }
}));

export default function AuthChart() {

    const {t} = useCustomTranslation();
    const {ds} = useStores();
    const theme = useTheme();
    const classes = useStyles();
    const [response, setResponse] = useState(null);

    const isPhone = useMediaQuery(theme.breakpoints.down('xs')) && ds.isMobileDevice;

    useEffect(() => {
        api.getMyPlatformStats()
            .then(data => {
                let _unknown;
                data.platformStats.forEach( (data, index) => {
                    if( data.platform === "CMMAPF000" ) {
                        _unknown = data;
                    } else if( data.platform === "CMMAPF002" ) {
                        data.count += _unknown.count;
                        data.percent += _unknown.percent;
                    }
                });
                data.platformStats = data.platformStats.filter( item => _unknown !== item);

                setResponse(data);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    const platformData = [];

    if (response !== null) {
        response.platformStats.map((data, idx) => {
            platformData[idx] = {
                name: t('CommonCode.' + data.platform),
                percent: Math.round(data.percent * 100) / 100,
                count: data.count
            }
        });
    }

    const colors = ['#3C39CB', '#258BEA', '#35B8CF', '#FC756F'];

    const chartData = () => {
        return {
            labels: platformData.map(item => item.name),
            datasets: [
                {
                    label: "Authentication",
                    data: platformData.map(item => item.percent),
                    // data: [20, 20 , 40 , 10],
                    backgroundColor: colors,
                    borderWidth: 0
                },
            ],
        };
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '70%',
        animation: {
            duration: 1700
        },
        layout: {
            padding: isPhone ? {
                left: 80,
                right: 90,
                top: 10,
                bottom: 10
            } : 0
        },
        plugins: {
            legend: {
                display: false,
                position: "top",
                labels: {
                    font: {
                        size: 8,
                        lineHeight: 1.2,
                    },
                    boxWidth: 5,
                },
            },
            tooltip: {
                enabled: false,
                backgroundColor: "rgba(43,76,153, 0.6)",
                caretSize: 6,
                padding: 15,
                cornerRadius: 15,
                bodyFont: {
                    family: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
                    size: 12,
                },
                boxWidth: 8,
                boxHeight: 8,
                displayColors: true,
            },
            datalabels: {
                display: isPhone ? 'auto' : false,
                borderColor: colors,
                borderWidth: 1,
                borderRadius: 3,
                padding: {
                    left: 3,
                    right: 3,
                    top: 3,
                    bottom: 3
                },
                anchor: 'end',
                align: 'end',
                clamp: true,
                clip: false,
                offset: 6,
                font: {
                    family: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
                    size: 9,
                    weight: 'bold'
                },
                labels: {
                    value: {
                        color: colors
                    }
                },
                formatter: function(value, ctx) {
                    return t(ctx.chart.data.labels[ctx.dataIndex]);
                }
            }
        },
    };

    const AuthChartTable = ({data}) => {

        const classes = tableStyles();
        const theme = useTheme();

        const SquareIcon = ({color}) => {
            let margin = theme.breakpoints.down('xs') ? '10px' : '15px';
            const iconStyle = {
                width: '18px',
                height: '18px',
                marginRight: margin,
                backgroundColor: color
            };
            return (
                <Box style={iconStyle}/>
            )
        };

        return (
            <TableContainer component={Box} className={!isPhone ? classes.container : classes.containerMobile}>
                <Table stickyHeader className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Home.Platform')}</TableCell>
                            <TableCell align="center">{t('Home.Percentage')}</TableCell>
                            <TableCell align="center">{t('Home.Count')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data.map((item, idx) => {
                                return (
                                    <TableRow className={classes.bodyRow} key={idx}>
                                        <TableCell component="th" scope="row">
                                            <SquareIcon color={colors[idx]}/>
                                            {item.name}
                                        </TableCell>
                                        <TableCell align="center">{item.percent + "%"}</TableCell>
                                        <TableCell align="center">{item.count}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Box display='flex' justifyContent='center' className={isPhone ? classes.chartBoxMobile : classes.chartBox}>
            <Box>
                <Doughnut
                    data={chartData}
                    plugins={[ChartDataLabels]}
                    options={options}
                    type='doughnut'/>
            </Box>
            <AuthChartTable data={platformData} colors={colors}/>
        </Box>
    )
}