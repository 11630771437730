import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import {InputAdornment, TextField, Typography} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    search: {
        minWidth: 250,
        '& .MuiOutlineInput-input' : {
            color: '#565568',
            fontSize: 12
        },
        '& > p': {
            color: '#121212',
            fontSize: 13,
            fontWeight: 500
        }
    },
    inputSearch: {
        height: 36,
        minWidth: 250,
        maxWidth: '100%',
        width: 'auto',
        '& .MuiOutlinedInput-input': {
            height: 18,
            padding: '9px 15px',
        },
        '& .MuiSvgIcon-root': {
            color: '#8D96AB'
        },
        '&:hover .MuiSvgIcon-root': {
            color: '#3C39CB'
        },
        '& input::placeholder': {
            color: '#565568',
            opacity: 1
        },
        '& > .Mui-disabled': {
            backgroundColor: '#F5F6FA',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#C1C9DB !important'
            },
            '&:hover .MuiSvgIcon-root': {
                color: '#8D96AB'
            }
        }
    }
}));


export default function FilterSearchBox(props) {

    const classes = useStyles();
    const {t} = useTranslation();
    const option = props.option;

    return (
        <Box display='flex' flexDirection='column' className={`${classes.search} ${props.style && props.style}`}>
            <Typography>{t(option.label)}</Typography>
            <TextField className={classes.inputSearch}
                       variant="outlined"
                       disabled={option.disabled??false}
                       value={option.value}
                       rowsMax={props.rows?props.rows:1}
                       multiline={props.multiline?props.multiline:false}
                       onChange={(e) => option.onChange(e.target.value)}
                       onBlur={(e) => option.onChange(e.target.value.trim())}
                       InputProps={
                           option.value.length === 0 && {
                               endAdornment: (
                                   <InputAdornment position="end">
                                       <SearchIcon/>
                                   </InputAdornment>
                               )
                           }
                       }
            />
        </Box>
    )
}