import React, {useEffect, useState, useRef} from 'react';
import QRCode from 'qrcode.react';
import {QrLogoImageSrc} from '../../components/Logo';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {useStores} from '../../common/store';
import storage, {StorageKey as SK} from '../../common/storage';
import Button from "@material-ui/core/Button";
import api from '../../common/api';
import {AuthStatus, ErrCode} from '../../common/types';
import {ProgressAlert} from "./LoginFunc";

const dialogStyles = makeStyles((theme) => ({
        root: {
            position: 'relative',
            width: '100%',
        },
        title: {
            maxWidth: 720,
            "& > *": {
                ...theme.typography.h5
            }
        },
        content: {
            marginTop: -theme.spacing(2),
            marginBottom: -theme.spacing(2),
            minWidth: 250,
            maxWidth: 960,
            '& > *': {
                margin: theme.spacing(1)
            }
        },
        qrContentBox: {
            padding: 15,
            backgroundColor: 'white',
            width: 'fit-content',
            margin: 'auto'
        },
        qrCanvas: {
            display: 'flex'
        },
        height: {
            height: '3.5rem'
        },
        description: {
            fontSize: '0.9375rem',
            lineHeight: '1.5625rem',
            color: theme.palette.text.secondary,
            textAlign: 'right'
        },
    })
);

export function stopQrLogin(qrId, userKey, clientKey, onProgressMsg, onErrMsg) {
    api.deleteAuth(clientKey, userKey, qrId)
        .then(data => {
            if (data.rtCode === ErrCode.RT_SUCCESS) {
                onProgressMsg(null);
            }
        }).catch(err => {
        // onErrMsg(err.rtMsg);
    });
}

export default function QrPage({open, onClose, title, qrId, qrUrl, onProgressMsg, onErrMsg, handedUserKey = null}) {
    const dialogStyle = dialogStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const fncClearInterval = useRef(null);

    const [progressMsg, setProgressMsg] = useState(null);
    const [countDown, setCountDown] = useState(null);

    const userKey = useRef(null);
    const channelKey = useRef(null);

    const stompConnector = React.useRef(null);
    const isFinished = React.useRef(false);


    const customInterval = (time, onClose, timeoutMsg = null, ws = null) => {
        let count = time;

        const id = setInterval(() => {
            count = count - 1;
            if (count) {
                setCountDown(count);
            } else {
                clear(true);
            }
        }, 1000);


        const clear = (isTimeout) => {
            if (id !== null) {

                if( stompConnector.current !== null ) {
                    console.log("stompConnector.current.deactivate()");
                    stompConnector.current.deactivate();
                }

                clearInterval(id);

                if (isTimeout && timeoutMsg) {
                    isFinished.current = true;
                    console.log("timeoutMsg => " , timeoutMsg);
                    onErrMsg(timeoutMsg);
                }

                if (onClose) { onClose(); }

                setCountDown(null);
            }
        }

        return () => {
            clear(false)
        };
    }

    const isProcess = progressMsg != null;

    function qrCancel(isStop = false) {
        isFinished.current = true;
        if (handedUserKey !== '' && userKey.current === null) userKey.current = handedUserKey;
        if (onClose) {
            onClose(isStop, userKey.current);
        }
        fncClearInterval.current();
    }

    useEffect(() => {
        if (open) {
            isFinished.current = false;
            setProgressMsg(null);
            onErrMsg(null);

            const authTimeRemaining = 60000;

            //const stomp = api.getStompQrWS(qrId);
            stompConnector.current = api.getStompQrWS(qrId);

            // 타이머 시작
            fncClearInterval.current = customInterval((authTimeRemaining / 1000) + 1, onClose, t('LoginPage.LoginTimeout'));

            stompConnector.current.onConnect = ( data ) => {
                const onClose = () => {
                    subscribeStomp.unsubscribe();
                    fncClearInterval.current();
                }

                const subscribeCallBack = (message) => {
                    const data = JSON.parse(message.body);
                    const status = data.status;
                    const type = data.type;
                    const statusMsg = t('AuthStatus.' + status);
                    const clientKey = api.MASTER_CLIENT_KEY;

                    if (type !== undefined && type === "data") {
                        userKey.current = data.userKey;
                        channelKey.current = data.channelKey;
                    }

                    if (status === AuthStatus.RequestAuth) {

                        setTimeout( ()=> {setProgressMsg(t('AuthStatus.CreateChannel'));} , 1000);
                        setTimeout( ()=> {setProgressMsg(t('AuthStatus.SelectNodes'));} , 1000);

                    } else if (AuthStatus.isFinish(status)) {

                        subscribeStomp.unsubscribe();
                        isFinished.current = true;

                        open = false;
                        setProgressMsg(null);

                        if (AuthStatus.isSuccess(status)) {

                            api.getAuthResult(clientKey, userKey.current, channelKey.current, true)
                                .then(data => {
                                    if (data.rtCode === ErrCode.RT_SUCCESS) {

                                        if(fncClearInterval.current !== null) {
                                            fncClearInterval.current();
                                        }

                                        api.setToken(data.data);
                                        api.getMe()
                                            .then(data => {
                                                ds.setLogin(true);
                                                storage.setSession(SK.LOGIN_USER_INFO, {data});
                                            })
                                            .catch(err => {
                                                onErrMsg(err.rtMsg);
                                            });
                                    } else {
                                        onErrMsg(api.getErrMsg(data.rtCode));
                                        setProgressMsg(null);
                                    }

                                })
                                .catch(err => {
                                    onErrMsg(err.rtMsg);
                                });
                        } else {
                            onErrMsg(statusMsg);
                            if ( fncClearInterval.current !== null ) {
                                fncClearInterval.current();
                            }
                        }

                    } else {
                        setProgressMsg(statusMsg);
                    }
                }


                const subscribeStomp = stompConnector.current.subscribe('/user/queue', (message) => {
                    if (message.command === "MESSAGE") {
                        subscribeCallBack(message);
                    } else {
                        onProgressMsg(t('AuthStatus.AuthFailed'));
                    }
                });

                stompConnector.current.onWebSocketClose = () => {
                    console.log(" Close Web Socket Event Test");

                    if( !isFinished.current ) {
                        api.getAuthResult(api.MASTER_CLIENT_KEY, userKey.current, channelKey.current, true)
                            .then(data => {
                                if (data.rtCode === ErrCode.RT_SUCCESS) {

                                    if(fncClearInterval.current !== null) {
                                        fncClearInterval.current();
                                    }

                                    api.setToken(data.data);
                                    api.getMe()
                                        .then(data => {
                                            ds.setLogin(true);
                                            storage.setSession(SK.LOGIN_USER_INFO, {data});
                                        })
                                        .catch(err => {
                                            onErrMsg(err.rtMsg);
                                        });
                                } else {
                                    onErrMsg(api.getErrMsg(data.rtCode));
                                    setProgressMsg(null);
                                }

                            })
                            .catch(err => {
                                onErrMsg(err.rtMsg);
                            });
                    }

                };

                stompConnector.current.onDisconnect = () => {
                    console.log("close connect event test");
                }
            }
        }


    }, [open])

    return (
        <Dialog
            open={open}
            onClose={() => qrCancel(true)}
            aria-labelledby={`${title} dialog`}
            maxWidth='md'
            className={dialogStyle.root}
        >
            <DialogTitle id={`${title}_dialog_id`} className={dialogStyle.title}>{t(title)}</DialogTitle>
            <DialogContent className={dialogStyle.content}>
                <Typography variant={'subtitle1'}>{t('LoginPage.QrDialog.Message')}</Typography>
                <Box className={dialogStyle.qrContentBox}>
                    <QRCode className={dialogStyle.qrCanvas}
                            value={qrUrl}
                            size={128}
                            imageSettings={{
                                src: QrLogoImageSrc(),
                                width: 33,
                                height: 38,
                            }}
                    />
                </Box>
                {countDown == null ? null :
                    <Typography variant='body1' className={dialogStyle.description}>
                        {`${t('LoginPage.TimeRemain')} : ${countDown} (${t('LoginPage.Sec')})`}
                    </Typography>
                }
                <Box mt={3} hidden={!isProcess} className={dialogStyle.height}>
                    <ProgressAlert status={progressMsg} count={countDown}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => qrCancel(true)}>{t("ConfirmDialog.CancelBtn")}</Button>
            </DialogActions>
        </Dialog>
    )

}
