import React, {useEffect, useState} from 'react';
import Box from "@material-ui/core/Box";
import {Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import InfoIcon from '@mui/icons-material/Info';
import api from "../../common/api";
import queryString from 'query-string';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useStores} from "../../common/store";
import {EventActionType, isCommonCode, UserType} from "../../common/types";

const useStyles = makeStyles((theme) => ({
    root: {
        // margin: '40px 50px',
        margin: '40px 170px 60px 170px',
        '& *': {
            fontFamily: 'Poppins !important'
        },
        '& > div': {
            width: '100%',
            backgroundColor: '#fff',
            border: '1px solid #CCCFE5',
            borderRadius: 5
        },
        '& > p:first-child': {
            fontWeight: 600,
            fontSize: 20,
            color: '#121212',
            letterSpacing: '-0.04em'
        },
        [theme.breakpoints.down('sm')]: {
            margin: 20
        }
    },
    detailInfo: {
        minHeight: 190,
        margin: "20px 0",
        padding: 40,
        '& > dl': {
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '100px',
            rowGap: 20,
            '& > div': {
                display: 'flex',
                '& > *': {
                    fontSize: 14
                }
            },
        },
        '& dt': {
            width: 165,
            minWidth: 165,
            height: 23,
            color: '#565568'
        },
        '& dd': {
            width: 230,
            height: 23,
            marginInlineStart: 0,
            color: '#121212',
            letterSpacing: '0.05em'
        },
        '& .title': {
            flex: 1,
            display: 'flex',
            marginBottom: 20,
            '& > *': {
                fontSize: 14
            },
            '& > dd': {
                width: 'fit-content',
                height: 'max-content',
                wordBreak: 'break-word'
            }
        }
    },
    changeInfo: {
        padding: 40,
        minHeight: 290
    },
    type: {
        height: 24,
        width: 95,
        borderWidth: 1.5,
        borderRadius: 12,
        borderStyle: 'solid',
        fontSize: '0.75rem !important',
        fontWeight: '600 !important',
        lineHeight: '22px !important',
        textAlign: 'center'
    },
    typeAdd: {
        color: '#237B6C',
        borderColor: '#237B6C'
    },
    typeDelete: {
        color: '#EF346C',
        borderColor: '#EF346C'
    },
    typeModify: {
        color: '#1764D9',
        borderColor: '#1764D9'
    },
    infoTitle: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
        '& > :first-child': {
            borderRadius: '50%',
            backgroundColor: '#E9ECFF',
            width: 32,
            height: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 10
        },
        '& .MuiSvgIcon-root': {
            fill: '#4046BC'
        },
        '& > p': {
            fontSize: 16,
            color: '#121212',
            fontWeight: 600
        }
    },
    infoContent: {
        display: 'flex',
        columnGap: 55,
        '& > *': {
            flex: 1,
            '& > :first-child': {
                height: 36,
                borderRadius: 5,
                fontSize: 14,
                fontWeight: 600,
                padding: '8px 15px'
            },
            '& > div': {
                minHeight: 60,
                padding: 20,
                display: 'flex',
                alignItems: 'flex-start',
                borderBottom: '1px solid #E3E4ED',
                '& > *': {
                    fontSize: 14
                },
                '& > dt': {
                    width: 150,
                    color: '#565568'
                },
                '& > dd': {
                    width: 260,
                    marginInlineStart: 0,
                    color: '#121212',
                    fontWeight: 600
                }
            }
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    before: {
        '& > p:first-child': {
            backgroundColor: '#F2F3F9',
            color: '#565568',
        },
        '& > div > :first-child': {
            marginBlockStart: 0,
            margin: '0 9px 0 0'
        },
        '& > div > :nth-child(2)': {
            minWidth: 150,
            maxWidth: 150,
            paddingRight: 10,
            wordBreak: 'break-word'
        }
    },
    after: {
        '& > p:first-child': {
            backgroundColor: '#4046BC',
            color: '#FFF',
        },
        '& > div > :first-child': {
            marginBlockStart: 0,
            margin: '0 9px 0 0'
        },
        '& > div > :nth-child(2)': {
            minWidth: 150,
            maxWidth: 150,
            paddingRight: 10,
            wordBreak: 'break-word'
        }
    },
    changeText: {
        color: '#121212',
        fontSize: 14,
        fontWeight: 500,
        wordBreak: 'break-word',
        '& p':{
            color: '#121212',
            wordBreak: 'break-word',
            fontSize: 14,
            fontWeight: 500,
            marginBlockStart: '0px !important',
            marginBlockEnd: '0px !important'
        }
    }
}));


export default function EventDetailPage(props) {

    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();

    const eventSeq = queryString.parse(props.location.search).seq;
    const category = queryString.parse(props.location.search).category;
    const [eventData, setEventData] = useState(null);
    const [eventTitle, setEventTitle] = useState(null);
    const [eventTitleLabel, setEventTitleLabel] = useState(null);
    const [licenseKey, setLicenseKey] = useState(null);
    const [originalValue, setOriginalValue] = useState('-');
    const [changeValue, setChangeValue] = useState('-');

    const typeStyle = (type) => {
        if (type === 'CMMACT001') return `${classes.typeAdd} ${classes.type}`
        if (type === 'CMMACT002') return  `${classes.typeModify} ${classes.type}`
        if (type === 'CMMACT003') return  `${classes.typeDelete} ${classes.type}`
    }

    const clientArray = [];
    if (ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null) {
        if (clientArray.length === 0) {
            let clientIndex = 0;
            for (let key in ds.user.accessibleClient) {
                if (UserType.isManager(ds.user.accessibleClient[key].userType)) {
                    clientArray[clientIndex++] = {
                        value: ds.user.accessibleClient[key].clientKey,
                        text: ds.user.accessibleClient[key].clientName
                    };
                }
            }
        }
    }

    function getClientName(key) {
        if (clientArray.find(c => c.value === key)) {
            return clientArray.find(c => c.value === key).text;
        } else {
            return '-'
        }
    }

    const fetchData = () => {
        api.getEventHistoryDetail(category, eventSeq)
            .then((res) => {
                setEventData(res.data)
                if (res.data.agreementTitle !== undefined) {
                    res.data.agreementTitle !== '' ? setEventTitle(res.data.agreementTitle) : setEventTitle('-');
                    setEventTitleLabel(t('EventHistory.AgreementTitle'));
                }
                if (res.data.questionTitle !== undefined) {
                    res.data.questionTitle !== '' ? setEventTitle(res.data.questionTitle) : setEventTitle('-');
                    setEventTitleLabel(t('EventHistory.QuestionTitle'));
                }
                if (res.data.originalValue !== undefined && res.data.originalValue !== '') {
                    isCommonCode(res.data.originalValue) ? setOriginalValue(res.data.originalValue + " (" + t('CommonCode.' + res.data.originalValue) + ")") :
                        isDate(res.data.originalValue) ? setOriginalValue(moment(res.data.originalValue).format('YYYY-MM-DD HH:mm:ss')) : setOriginalValue(res.data.originalValue);
                }
                if (res.data.changeValue !== undefined && res.data.changeValue !== '') {
                    isCommonCode(res.data.changeValue) ? setChangeValue(res.data.changeValue + " (" + t('CommonCode.' + res.data.changeValue) + ")") :
                        isDate(res.data.changeValue) ? setChangeValue(moment(res.data.changeValue).format('YYYY-MM-DD HH:mm:ss')) : setChangeValue(res.data.changeValue);
                }
                if (res.data.licenseKey !== undefined) {
                    setLicenseKey(res.data.licenseKey)
                }
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            })
    }

    useEffect(() => {
        fetchData();
    }, []);

    function isDate(date) {
        return moment(date, 'yyyy-MM-DDTHH:mm:ss.sss', true).isValid()
    }

    return (
        <section className={classes.root}>

            <Typography>{t('CommonCode.' + category)} {t('EventHistory.EventDetail')}</Typography>

            <Box className={classes.detailInfo}>

                {eventTitle !== null &&
                    <div className={'title'}>
                        <dt>• {eventTitleLabel}</dt>
                        <dd>{eventTitle}</dd>
                    </div>
                }

                {licenseKey !== null &&
                    <div className={'title'}>
                        <dt>• {t("EventHistory.LicenseKey")}</dt>
                        <dd>{licenseKey}</dd>
                    </div>
                }

                {eventData !== null &&
                    <dl>
                        <div>
                            <dt>• {t('EventHistory.Table.Category')}</dt>
                            <dd>{t('CommonCode.' + category)}</dd>
                        </div>
                        <div>
                            <dt>• {t('EventHistory.Table.EventType')}</dt>
                            <dd>
                                <Typography className={typeStyle(eventData.eventType)}>
                                    {t('CommonCode.' + eventData.eventType)}
                                </Typography>
                            </dd>
                        </div>
                        {eventData.userKey !== undefined &&
                            <div>
                                <dt>• {t('EventHistory.Table.UserKey')}</dt>
                                <dd>{eventData.userKey}</dd>
                            </div>
                        }
                        {eventData.clientKey !== undefined &&
                            <div>
                                <dt>• {t('EventHistory.Table.Client')}</dt>
                                <dd>{getClientName(eventData.clientKey)}</dd>
                            </div>
                        }
                        {eventData.actionClientKey !== undefined &&
                            <div>
                                <dt>• {t('EventHistory.ActionClient')}</dt>
                                <dd>{getClientName(eventData.actionClientKey)}</dd>
                            </div>
                        }
                        <div>
                            <dt>• {t('EventHistory.ActionUserKey')}</dt>
                            <dd>{eventData.actionUserKey}</dd>
                        </div>
                        <div>
                            <dt>• {t('EventHistory.ActionUserType')}</dt>
                            <dd>{t('CommonCode.' + eventData.actionUserType)}</dd>
                        </div>
                        <div>
                            <dt>• {t('EventHistory.Table.IP')}</dt>
                            <dd>{eventData.actionIp}</dd>
                        </div>
                        <div>
                            <dt>• {t('EventHistory.Table.Time')}</dt>
                            <dd>{moment(eventData.regDt).format('YYYY-MM-DD HH:mm:ss')}</dd>
                        </div>

                    </dl>
                }
            </Box>
            {eventData !== null && eventData.eventType !== EventActionType.Delete &&
                (eventData.changeValue !== undefined || eventData.originalValue !== undefined) &&

                <Box className={classes.changeInfo}>

                    <div className={classes.infoTitle}>
                        <div>
                            <InfoIcon/>
                        </div>
                        <Typography>{t('EventHistory.DataChange')}</Typography>
                    </div>

                    <div className={classes.infoContent}>

                        <Box display='flex' flexDirection='column' className={classes.before}>
                            <Typography>{t('EventHistory.BeforeChange')}</Typography>
                            <Box display='flex'>
                                <p>•</p>
                                <Typography>{eventData.name ? t('EventHistory.ValueName.' + eventData.name) : t('EventHistory.Value')}</Typography>
                                <Typography className={classes.changeText} dangerouslySetInnerHTML={{__html: originalValue}}/>
                            </Box>
                        </Box>

                        <Box display='flex' flexDirection='column' className={classes.after}>
                            <Typography>{t('EventHistory.AfterChange')}</Typography>
                            <Box display='flex'>
                                <p>•</p>
                                <Typography>{eventData.name ? t('EventHistory.ValueName.' + eventData.name) : t('EventHistory.Value')}</Typography>
                                <Typography className={classes.changeText} dangerouslySetInnerHTML={{__html: changeValue}}/>
                            </Box>
                        </Box>

                    </div>

                </Box>
            }
        </section>
    )
}