import React, {useEffect, useState} from 'react';
import DataTable from '../../components/DataTable';
import api from "../../common/api";
import {Redirect} from "react-router-dom";
import {useTranslation} from "react-i18next"
import {CommonCodeValue} from "../../common/types";
import queryString from 'query-string';
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: '40px 170px 60px 170px',
        padding: '35px 40px',
        backgroundColor: '#FFF',
        borderRadius: 6,
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20
        }
    },
    table: {
        '& .MuiTableRow-root': {
            '& > :nth-child(1)': {
                width: 100
            },
            '& > :nth-child(2)': {
                width: 440
            },
            '& > :nth-child(n+3)': {
                width: 210
            }
        }
    }
}));

/**
 *  회원목록 조회 화면
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ClientListPage(props) {
    const dataArray = [];
    const pageSize = 5;
    const {location, setSearch, setCreate} = props;
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const query = queryString.parse(location.search);
    let page = query['page'] !== undefined ? Number(query['page']) : 1;
    let keyword = query['keyword'] !== undefined ? query['keyword'] : null;
    const [response, setResponse] = useState(null);

    const [clickEvent, setClickEvent] = useState(location.state ? location.state.clickEvent : null);

    const changedSearchKeywordEvent = (value) => {
        keyword = value;
    }

    const rowClickEvent = (row) => (event) => {
        if( row[6] !== undefined ) {
            setClickEvent({event: 'detail', clientKey: row[6]?.content, clientName: row[1]?.content, licenseSeq: row[7]?.content});
        }
    }

    const search = (event, page = 1) => {
        const query = {page, keyword};
        props.history.push('/admin/clients?' + api.encodeParams(query, true));
    }

    const fetchData = (query) => {
        api.getClientList(query.keyword, query.page - 1, pageSize)
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                return err;
            });
    }

    useEffect(() => {
        fetchData({keyword, page});
        if (setSearch) {
            setSearch(
                {
                    placeholder: t("ClientPage.SearchPlaceHolder"),
                    doQuery: search,
                    onChange: changedSearchKeywordEvent
                }
            );
        }
        if (setCreate) {
            setCreate(
                {
                    description: t("ClientPage.NewClient"),
                    dialogTitle: t("ClientPage.CreateDialog.Title")
                }
            );
        }
    }, [location.search, i18n.language]);


    if (response !== null) {
        let index = response.page.currentPage * pageSize;
        const numberFormat = new Intl.NumberFormat();
        response.data.map((data, idx) => {
            let clientName = data.clientName ? data.clientName : '-';
            let userCount = data.userCount !== undefined ? numberFormat.format(data.userCount) : '-';
            let authCount = data.authCount !== undefined ? numberFormat.format(data.authCount) : '-';
            let licenseStatus = data.licenseStatus !== undefined ? t(CommonCodeValue.translateKey + '.' + data.licenseStatus) : '-';
            let typeCodeStr = data.licenseType ? t(CommonCodeValue.translateKey + '.' + data.licenseType) : '-';

            dataArray[idx] = [
                {id: 'index', content: ++index},
                {id: 'clientName', align: 'left', content: clientName},
                {id: 'userCount', align: 'center', content: userCount},
                {id: 'authCount', align: 'center', content: authCount},
                {id: 'statusCodeStr', content: licenseStatus},
                {id: 'typeCodeStr', content: typeCodeStr},
                {id: 'clientKey', content: data.clientKey},
                {id: 'licenseSeq', content: data.licenseSeq ?? null}
            ];

        });
    }


    if (clickEvent && clickEvent !== null) {
        const eventType = clickEvent.event;
        if (eventType === 'detail') {
            return (
                <Redirect push to={
                    {
                        pathname: '/admin/client',
                        search: "?" + api.encodeParams({clientKey: clickEvent.clientKey, licenseSeq:clickEvent.licenseSeq}),
                        state: api.encodeParams({clientName: clickEvent.clientName})
                    }
                }/>
            );
        }
    } else {
        return (
            <Box className={classes.pageContent}>
                <ResultTable dataArray={dataArray}
                             search={search}
                             currentPage={page}
                             totalPage={response?.page.totalPages}
                             rowClickEvent={rowClickEvent}
                />
            </Box>
        );
    }
}

/**
 * 조회 결과 테이블
 * @param dataArray : 조회 결과 Array
 * @param search : 조회 이벤트 시 호출 할 조회 함수
 * @param rowClickEvent : Row 클릭 시 호출 할 이벤트 함수
 * @param currentPage : 현재 선택 된 페이지 번호
 * @param totalPage : 전체 페이지 개수
 * @returns {JSX.Element} : <DataTable ../>
 * @constructor
 */
function ResultTable({dataArray, search, rowClickEvent, currentPage, totalPage}) {

    const {t} = useTranslation();
    const style = useStyles();

    const head = [
        [
            {content: ''},
            {align: 'left', content: t('ClientPage.Client')},
            {content: t('ClientPage.User')},
            {content: t('ClientPage.Auth')},
            {content: t('ClientPage.LicenseStatus')},
            {content: t('ClientPage.LicenseType')}
        ]
    ];

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable headContent={head}
                   bodyContent={dataArray}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   style={style}
        />
    );
}