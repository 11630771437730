import React, {useEffect, useRef, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import TopBar from '../components/TopBar';
import SideMenu from '../components/SideMenu';
import {Route} from 'react-router-dom';
import Page from '../components/Page';
import {useMediaQuery} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import {useSnackbar} from 'notistack';
import {useStores} from "../common/store";
import Slide from "@material-ui/core/Slide";
import {useTranslation} from "react-i18next";
import {Scrollbars} from 'react-custom-scrollbars';
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import {adminMenuFunc} from "../common/menu";


const useStyles = makeStyles((theme) => ({
    track: {
        position: 'absolute',
        width: '7px',
        height: 'calc(100vh - 5.938rem)',
        right: '2px',
        top: '2px',
        borderRadius: '3px',
        paddingRight: '2px',
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'none',
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: '100vh',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    root2: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
        backgroundColor: '#F1F2F6',
        [theme.breakpoints.between('xs', 'md')]: {
            transition: theme.transitions.create(
                ['margin-left'],
                {duration: 500}
            )
        }
    },
    sideMenuIsWidth: {
        height: '100vh',
        display: 'flex',
        '& > .MuiBackdrop-root': {
            height: '100vh'
        },
        '& > .MuiDialog-container.MuiDialog-scrollPaper > .MuiPaper-root': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    mobileSideMenu: {
        height: '100vh',
    },
    main: {
        display: 'flex',
        flexDirection: 'column'
    },
    topBtn: {
        display: 'none',
        opacity: 0,
        position: 'fixed',
        bottom: theme.spacing(6),
        right: '5%',
        zIndex: '10',
        cursor: 'pointer',
        width: theme.spacing(8),
        height: theme.spacing(8),
        background: "#6f6f6f",
        fontSize: theme.typography.button.fontSize,
        color: '#fff',
        [theme.breakpoints.between('xs', 'md')]: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
    }
}));

export default function Layout(props) {
    const {ds} = useStores();
    const {t} = useTranslation();

    const classes = useStyles();
    const theme = useTheme();
    const isWidth = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [open, setOpen] = React.useState(false);
    const scrollbar = useRef(null);
    const fadeValue = useRef(
        {
            old: false,
            new: false
        }
    );
    const topBox = useRef(null);

    const renderTrackVertical = () => {
        return (<div className={classes.track}/>)
    }

    const fadeButton = () => {

        if (!topBox || !topBox.current) {
            return;
        }
        const {current} = topBox;
        if (fadeValue.current.new) {
            current.style.display = "flex";
            current.style.transition = 'opacity 0.6s 0.1s';
            setTimeout(() => {
                current.style.opacity = 1;
            }, 400);
        } else {
            current.style.transition = `opacity 0.3s 0.1s`;
            current.style.opacity = 0
            setTimeout(() => {
                current.style.display = "none";
            }, 300);
        }
    }

    const handleUpdate = (values) => {
        fadeValue.current.old = fadeValue.current.new;
        fadeValue.current.new = values.scrollTop > 200;

        if (fadeValue.current.old !== fadeValue.current.new) {
            fadeButton();
        }
    }

    const scrollThumb = () => {
        const thumbStyle = {
            height: '100vh',
            borderRadius: '5px',
            backgroundColor: '#b8bbbeb3'
        };
        return (
            <div style={thumbStyle}/>
        )
    }

    const handleClick = (e, props) => {
        setOpen(props.open);
    };

    const scrollToTop = () => {
        if (!scrollbar || !scrollbar.current) {
            return;
        }
        scrollbar.current.view.scroll({
            top: 0,
            behavior: 'smooth',
        });
    }

    const openItem = {open: !open, isWidth: isWidth, handleClick};

    const menuItems = adminMenuFunc(ds.user);

    const {enqueueSnackbar} = useSnackbar();

    const TopButton = () => {
        return (
            <Avatar ref={topBox} className={classes.topBtn} onClick={scrollToTop}>
                TOP
            </Avatar>
        )
    }

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const getIsOpen = (index) => {
        setMenuIsOpen(index);
    }

    useEffect(() => {
        ds.alertFunc = (variant, message) => {
            enqueueSnackbar(message, {variant});
        }

        /** URL AUTH CHECK **/
        const connectionUrl = props.location.pathname;
        let authCheck = false; // 권한 확인
        let urlCheck = true;   // url 확인

        menuItems.forEach((data) => {
            data.items.forEach((item) => {
                if (item.menuAuth === false) {
                    if (_.isArray(item.url)) {
                        authCheck = _.includes(item.url, connectionUrl);
                    }
                    if (item.pages) {
                        item.pages.forEach((item) => {
                            if (connectionUrl.includes(item.url)) authCheck = true
                        })
                    } else if (item.url === connectionUrl) {
                        authCheck = true;
                    }
                }

                if (_.isArray(item.url)) {
                    if (_.includes(item.url, connectionUrl)) {
                        urlCheck = false;
                    }
                }
                if (item.pages) {
                    item.pages.forEach((item) => {
                        if (connectionUrl.includes(item.url)) urlCheck = false
                    })
                }
                if (item.tabs) {
                    item.tabs.forEach((item) => {
                        if (connectionUrl.includes(item.url)) urlCheck = false
                    })
                }
                if (item.url === connectionUrl) {
                    urlCheck = false;
                }
            })
        });

        if (authCheck || urlCheck) {
            ds.showErrorAlert(t('Error.WRONG_APPROACH'));
            props.history.push('/');
        }
        /** URL AUTH CHECK **/
    }, [])

    return (
        <Box className={classes.root}>
            {
                isWidth ?
                    <Dialog
                        fullScreen
                        open={open}
                        onClose={(event) => handleClick(event, {open: false})}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={classes.sideMenuIsWidth}
                        BackdropComponent={Backdrop}
                        BackdropProps={{timeout: 500}}
                    >
                        <SideMenu item={menuItems} openItem={openItem} isOpen={getIsOpen}/>
                    </Dialog>
                    :
                    <SideMenu item={menuItems} openItem={openItem} isOpen={getIsOpen}/>
            }
            <Box className={classes.root2}>
                <TopBar openItem={openItem} location={props.location}/>
                <Scrollbars style={{height: '100vh'}}
                            ref={scrollbar}
                            onUpdate={handleUpdate}
                            autoHide
                            renderTrackVertical={renderTrackVertical}
                            renderThumbVertical={scrollThumb}>
                    <main className={classes.main}>
                        {menuItems.map(item => (
                            item.items.map((innerItem, index) => (
                                <Route exact key={index.toString()} path={innerItem.url}
                                       render={props => (<Page {...props} {...innerItem} isOpen={menuIsOpen}/>)}/>
                            ))
                        ))}

                        {/****** item.pages => 왼쪽 메뉴의 하위 메뉴에 나오는 sub 페이지
                                 item.tabs => 메인 화면에서 상세 화면으로 이동하면 페이지에 나오는 탭
                                 히스터리를 유지하기 위해 모든 탭은 경로가 있다  *******/}
                        {menuItems[0].items.map(item => (
                            item.pages?.map((innerItem, index) => (
                                <Route exact key={index.toString()} path={innerItem.url}
                                       render={props => (<Page {...props} {...innerItem} parentItem={item} isOpen={menuIsOpen}/>)}/>
                            ))
                        ))}
                        {menuItems[0].items.map(item => (
                            item.tabs?.map((innerItem, index) => (
                                <Route exact key={index.toString()} path={innerItem.url}
                                       render={props => (<Page {...props} {...item} tabItem={innerItem} isOpen={menuIsOpen}/>)}/>
                            ))
                        ))}
                    </main>
                    <TopButton/>
                </Scrollbars>
            </Box>
        </Box>
    );
}

