import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Box, useMediaQuery, useTheme} from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((theme) => ({
    container: {
        width:'calc(100vw - 120px) !important',
        height:'calc(100vh - 61px) !important',
        color:'#fff',
        [theme.breakpoints.down('md')] :{
            width:'initial !important',
            height:'calc(100vh - 52px) !important',
        }
    },
    history     : {
        display         : 'flex'
        , flex          : 1
        , height        : 'inherit'
        , flexDirection : 'column'
        , alignItems    : 'center'
        , justifyContent: 'center'
        , background    : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec7/bg.jpg) no-repeat`
    }
    , titleBox  : {
        marginBottom: 80
        , '& h2'    : {
            fontSize    : 45
            , lineHeight: '68px'
            , margin    : 0
        }
        , [theme.breakpoints.down('xs')]: {
            marginTop     : 20
            , marginBottom: 50
            , '& h2'      : {
                fontSize    : 28
                , lineHeight: '35px'
            }
        }
    }
    , descBox   : {
        position: 'relative'
        , [theme.breakpoints.down('xs')]: {
            width: '100vw'
        }
    }
    , centerLine: {
        position    : 'absolute'
        , top       : 0
        , left      : '50%'
        , width     : 1
        , height    : 'calc(100vh / 1.6)'
        , background: 'linear-gradient(#ffffff, #000000)'
        , [theme.breakpoints.down('xs')]: {
            top       : '-5px'
            ,left      : '10%'
            , height    : 'calc(100vh / 1.3)'

        }
    }
    , entries   : {
        display                    : 'flex'
        , flexDirection            : 'column'
        , width                    : 1200
        , '& > div:nth-child(even)' : {
            marginLeft: 'auto'
            , [theme.breakpoints.down('xs')]: {
                marginLeft: '15vw',
                '& > :last-child': {
                    paddingBottom: 40
                }
            }
        }
        , '& > div:nth-child(odd)': {
            textAlign: 'right'
            , [theme.breakpoints.down('xs')]: {
                marginLeft: '15vw'
                ,textAlign: 'left'
            }
        }
        , '& > div:nth-child(1)'   : {
            marginTop: '-28px'
        }
        , [theme.breakpoints.down('md')]: {
            maxWidth : 750,
            width: '95vw'
        }
        , [theme.breakpoints.down('xs')]: {
            width: '100vw'
        }
    }
    , entry     : {
        display                       : 'flex'
        , flexDirection               : 'column'
        , width                       : 'calc(50% - 40px)'
        , marginTop                   : 40
        , '& > div:nth-child(1)'      : {
            border        : '5px solid #fff'
            , borderRadius: '100%'
            , width       : '10px'
            , height      : '10px'
            , position    : 'absolute'
            , left        : 'calc(50% - 5px)'
            , background  : 'transparent'
            , marginTop   : 15
        }
        , '& > div:nth-child(3).firstContent > p:nth-child(1)' : {
            whiteSpace : 'pre-line'
        }
        , '& > div:nth-child(1).first': {
            border     : '8px solid #fff'
            , width    : '28px'
            , height   : '28px'
            , left     : 'calc(50% - 14px)'
            , marginTop: 7
        }
        , '& > div:nth-child(1).second': {
            transform: 'translate(0, -10px)'
        }
        , '& > div:nth-child(1).third': {
            transform: 'translate(0, -260px)'
        }
        , '& > div:nth-child(1).forth': {
            transform: 'translate(0, -160px)'
        }
        , '& > div:nth-child(2)'      : {
            margin      : 0
            , fontStyle : 'normal'
            , fontWeight: '900'
            , fontSize  : '24px'
            , lineHeight: '42px'
        }
        , '& > div:nth-child(3) > p'  : {
            margin         : '0'
            , fontWeight   : '400'
            , fontSize     : '15px'
            , lineHeight   : '24px'
            , letterSpacing: '-0.04em'
            , whiteSpace   : 'nowrap'
            , color        : '#E4E4E4'
        }
        , [theme.breakpoints.down('md')]: {
            '& > div:nth-child(2)'      : {
                fontSize  : '24px'
                , lineHeight: '24px'
            }
            , '& > div:nth-child(3) > p'  : {
                fontSize     : '13px'
                , lineHeight   : '24px'
                , whiteSpace   : 'normal'
            }
        }
        , [theme.breakpoints.down('sm')]: {
            '& > div:nth-child(3) > p'  : {
                fontSize: 10
                , lineHeight: '22px'
            }
        }
        , [theme.breakpoints.down('xs')]: {
            width                       : '80vw'
            , '& > div' : {
                backgroundColor: '#010204'
            }
            , '& > div:nth-child(1)'      : {
                left        : 'calc(10% - 5px)'
                , marginTop   : 8
            }
            , '& > div:nth-child(1).first': {
                width   : 20
                , height: 20
                , left  : 'calc(10% - 10px)'
                , marginTop: 3
            }
            , '& > div:nth-child(2)': {
                fontSize  : '20px'
                , lineHeight : '24px'
                , paddingBottom : 10
            }
            , '& > div:nth-child(3) > p'  : {
                fontSize     : '11px'
                , lineHeight   : '20px'
                , whiteSpace   : 'normal'
            }
        }
    }
    , lineup250 : {
        marginTop: '-250px'
        , [theme.breakpoints.down('xs')]: {
            marginTop: 10
        }
    }
    , lineup70  : {
        marginTop: '-70px'
        , [theme.breakpoints.down('xs')]: {
            marginTop: 0
        }
    }
    , pt_40 : {
        paddingTop: 40
    }
}));

function HistoryText({historyYear}) {
    const {t} = useTranslation();

    return (
        Array.from(t(historyYear)).map((content, index) => {
            return (<p key={index}>· {content}</p>)
        })
    )
}


function MobileHistoryPage({index = 1, innerFlag}) {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <section className={`${classes.history} ${innerFlag && classes.container}`} >

            <Box className={classes.titleBox}>
                <h2>{t('Fnsv.Company.History.Title')}</h2>
            </Box>

            <Box className={classes.descBox}>
                <Box className={classes.centerLine} />

                {index === 1 ?
                    <Box className={classes.entries}>
                        <Box className={classes.entry}>
                            <Box className={'first'} />
                            <Box>2023</Box>
                            <Box>
                                <HistoryText historyYear={'Fnsv.Company.History.2023'} />
                            </Box>
                        </Box>
                        <Box className={classes.entry}>
                            <Box className={'second'} />
                            <Box>2022</Box>
                            <Box>
                                <HistoryText historyYear={'Fnsv.Company.History.2022'} />
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box className={classes.entries}>
                        <Box className={classes.entry}>
                            <Box className={'first'} />
                            <Box>2020~21</Box>
                            <Box>
                                <HistoryText historyYear={'Fnsv.Company.History.2021'} />
                                <HistoryText historyYear={'Fnsv.Company.History.2020'} />
                            </Box>
                        </Box>
                        <Box className={`${classes.entry} ${classes.lineup70}`}>
                            <Box className={'second'}/>
                            <Box className={classes.pt_40}>2012~19</Box>
                            <Box>
                                <HistoryText historyYear={'Fnsv.Company.History.2012'} />
                            </Box>
                        </Box>
                    </Box>
                }
                <Box className={`${classes.entry} ${classes.lineup70}`}>
                    <Box className={'third'}/>
                </Box>
                <Box className={`${classes.entry} ${classes.lineup70}`}>
                    <Box className={'forth'} />
                </Box>
            </Box>

        </section>
    )
}

export default function HistoryPage({name, index}){
    const _innerFlag = name !== undefined;
    const classes = useStyles();
    const {t} = useTranslation();
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down('xs'));

    return (!isPhone ?
        <section className={`${classes.history} ${_innerFlag && classes.container}`}>

            <Box className={classes.titleBox}>
                <h2>{t('Fnsv.Company.History.Title')}</h2>
            </Box>

            <Box className={classes.descBox}>
                <Box className={classes.centerLine} />
                <Box className={classes.entries}>
                    <Box className={classes.entry}>
                        <Box className={'first'} />
                        <Box>2022~23</Box>
                        <Box className={'firstContent'}>
                            <HistoryText historyYear={'Fnsv.Company.History.2023'} />
                            <HistoryText historyYear={'Fnsv.Company.History.2022'} />
                        </Box>
                    </Box>

                    <Box className={`${classes.entry} ${classes.lineup70}`}>
                        <Box />
                        <Box>2020~21</Box>
                        <Box>
                            <HistoryText historyYear={'Fnsv.Company.History.2021'} />
                            <HistoryText historyYear={'Fnsv.Company.History.2020'} />
                        </Box>
                    </Box>

                    <Box className={`${classes.entry} ${classes.lineup250}`}>
                        <Box />
                        <Box>2012~19</Box>
                        <Box>
                            <HistoryText historyYear={'Fnsv.Company.History.2012'} />
                        </Box>
                    </Box>

                </Box>
            </Box>

        </section>
        :
        <MobileHistoryPage index={index} innerFlag={_innerFlag} />
    )
}