import React, {useRef} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    sideBar: {
        height: '100vh',
        '& > div': {
            display: 'flex',
            flexDirection: 'column'
        }
    },
    track: {
        position: 'absolute',
        width: '7px',
        height: '100vh',
        right: '2px',
        bottom: '2px',
        borderRadius: '3px',
        paddingRight: '2px',
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'none',
        },
    }
}));

export default function ScrollView(props) {
    const {children, isUpdate, renderTrack } = props;
    const styles = useStyles();

    const scrollbar = useRef(null);
    const fadeValue = useRef(
        {
            old: false,
            new: false
        }
    );

    const handleUpdate = (values) => {
        fadeValue.current.old = fadeValue.current.new;
        fadeValue.current.new = values.scrollTop > 200;
    }

    const renderTrackVertical = () => {
        return (<div className={styles.track}/>)
    }

    return (
        <Scrollbars className={styles.sideBar}
                    ref={scrollbar}
                    onUpdate={ isUpdate && handleUpdate }
                    autoHide
                    renderTrackVertical={ renderTrack === undefined ? renderTrackVertical : renderTrack }>
            { children }
        </Scrollbars>
    )
}
