import React from 'react';
import DashboardPage from "../pages/home/DashboardPage";
import {ReactComponent as DashboardIcon} from '../images/menu/dashboard_icon.svg';
import MyPage from "../pages/my/MyPage";
import {ReactComponent as MyPageIcon} from "../images/menu/mypage_icon.svg";
import ClientListPage from "../pages/client/ClientListPage";
import {ReactComponent as ClientIcon} from "../images/menu/client_icon.svg";
import ClientCreate from "../pages/client/ClientCreate";
import UserListPage from "../pages/user/UserListPage";
import {ReactComponent as UserIcon} from "../images/menu/user_icon.svg";
import AgreementListPage from "../pages/agreement/AgreementListPage";
import {ReactComponent as AgreementIcon} from "../images/menu/agreement_icon.svg";
import AgreementCreate from "../pages/agreement/AgreementCreate";
import LicenseListPage from "../pages/license/LicenseListPage";
import {ReactComponent as LicenseIcon} from "../images/menu/license_icon.svg";
import LicenseCreate from "../pages/license/LicenseCreate";
import ClientDetailsPage from "../pages/client/ClientDetailsPage";
import UserDetailsPage from "../pages/user/UserDetailsPage";
import AgreementDetailsPage from "../pages/agreement/AgreementDetailsPage";
import LicenseDetailPage from "../pages/license/LicenseDetailPage";
import QnaPage from "../pages/qna/QnaPage";
import {ReactComponent as QnAIcon} from "../images/menu/qna_icon.svg";
import QuestionEdit from "../pages/qna/QuestionEdit";
import SettingsPage from "../pages/settings/SettingsPage";
import {ReactComponent as SettingIcon} from "../images/menu/setting_icon.svg";
import SolutionPage from "../pages/fnsv/SolutionPage";
import NewsPage from "../pages/fnsv/NewsPage";
import VideoPage from "../pages/fnsv/VideoPage";
import PatentPage from "../pages/fnsv/PatentPage";
import HistoryPage from "../pages/fnsv/HistoryPage";
import ContactPage from "../pages/fnsv/ContactPage";
import EventHistoryPage from "../pages/events/EventHistoryPage";
import {ReactComponent as EventIcon} from "../images/menu/event_icon.svg";
import EventDetailPage from "../pages/events/EventDetailPage";
import ClientDetail from "../pages/client/detail/DetailPage";
import UserDetail from "../pages/user/detail/DetailPage";
import StatusPage from "../pages/client/detail/StatusPage";
import AgreementConfigPage from "../pages/client/detail/AgreementConfigPage";
import ManagerListPage from "../pages/client/detail/ManagerListPage";
import UserHistoryPage from "../pages/events/UserHistoryPage";
import AuthHistoryPage from "../pages/events/AuthHistoryPage";
import ClientLicenseDetailPage from "../pages/client/detail/LicenseDetailPage";
import PatchNoteListPage from "../pages/patch/PatchNotesPage";
import {ReactComponent as PatchIcon} from "../images/menu/patch_icon.svg";
import PatchDetailPage from "../pages/patch/PatchDetailPage";
import PatchNoteCreate from "../pages/patch/PatchNoteCreate";

function adminMenuFunc(user) {
    return [
        {
            items: [        // 왼쪽 메뉴 아이템 목록
                {
                    menuIndex: 0,   // 왼쪽 메뉴 position number
                    menuName: 'Menu.dashboard',
                    label: 'Menu.dashboard',
                    url: ['/dashboard', '/'],   // 해당 페이지의 경로
                    router: '/Dashboard',
                    page: DashboardPage,    // 메인 화면에 나올 페이지
                    iconImg: DashboardIcon,     // 메뉴 아이콘
                    navs: ['Menu.dashboard'],
                    navUrl: ['/'],
                    menuAuth: true,     // 사용자의 접근 권한
                },
                {
                    menuIndex: 1,
                    menuName: 'Menu.Nav.MyPage',    // 메뉴에 표시된 menu name
                    label: 'MyPage.MyInfo.Title',   // 헤더에 나오는 page title
                    url: '/mypage',
                    page: MyPage,
                    iconImg: MyPageIcon,
                    navs: ['Menu.Home', 'Menu.Nav.MyPage', 'MyPage.MyInfo.Title'],  // 헤더에 있는 내비게이션 labels (titles)
                    navUrl: ['/', '/mypage/info'],      // 내비게이션 label 클릭하면 이동할 경로
                    menuAuth: true,
                    pages: [    //  Tabs 안에 있는 페이지
                        {
                            tabIdx: 0,
                            label: 'MyPage.MyInfo.Title',
                            url: '/mypage/info',        // 탭 경로
                            page: MyPage,               // 탭 콘텐츠
                            navs: ['Menu.Home', 'Menu.Nav.MyPage', 'MyPage.MyInfo.Title'],
                            navUrl: ['/', '/mypage/info']
                        },
                        {
                            tabIdx: 1,
                            label: 'MyPage.AuthHistory.Title',
                            url: '/mypage/history',
                            page: MyPage,
                            navs: ['Menu.Home', 'Menu.Nav.MyPage', 'MyPage.AuthHistory.Title'],
                            navUrl: ['/', '/mypage/info']
                        },
                        {
                            tabIdx: 2,
                            label: 'MyPage.Event.Title',
                            url: '/mypage/event',
                            page: MyPage,
                            navs: ['Menu.Home', 'Menu.Nav.MyPage', 'MyPage.Event.Title'],
                            navUrl: ['/', '/mypage/info']
                        },
                        {
                            tabIdx: 3,
                            label: 'MyPage.Site.Title',
                            url: '/mypage/site',
                            page: MyPage,
                            navs: ['Menu.Home', 'Menu.Nav.MyPage', 'MyPage.Site.Title'],
                            navUrl: ['/', '/mypage/info']
                        }
                    ]
                },
                {
                    menuIndex: 1,   // 이 페이지가 열려있다면 같은 index를 가지고 있는 메인 메뉴가 selected 상태로 표시됩니다
                    url: '/mypage/event/detail',
                    label: 'Menu.Nav.Detail',
                    page: EventDetailPage,
                    hidden: true,         // 숨겨진 메뉴 아이템 (다른 화면을 통해서만 이 페이지로 이동할 수 있다)
                    navs: ['Menu.Home', 'Menu.Nav.MyPage', 'MyPage.Event.Title', 'Menu.Nav.Detail'],
                    navUrl: ['/', '/mypage/info', '/mypage/event'],
                    menuAuth: true
                },
                {
                    menuIndex: 2,
                    menuName: 'Menu.Nav.Client',
                    label: 'Menu.clients',
                    url: '/admin/clients',
                    page: ClientListPage,
                    iconImg: ClientIcon,
                    navs: ['Menu.Home', 'Menu.clients'],
                    navUrl: ['/', '/admin/clients'],
                    menuAuth: user.isManager(),     // 매니저 권한을 보유하는 사용자에게만 보이는 메뉴 아이템
                    searchData: true,               // 페이지 내 데이터 검색 기능 사용 여부
                    createContent: true,            // 등록 모달 다이얼로그 사용 여부
                    createAuth: user.isSuperManager(),  // 등록할 수 있는 사용자에게만 등록 버튼이 보인다
                    createPage: ClientCreate,       // 등록 다이얼로그에 들어갈 요소
                },
                {
                    url: '/admin/client',
                    label: ':clientName',
                    page: ClientDetailsPage,
                    menuIndex: 2,
                    hidden: true,
                    navs: ['Menu.Home', 'Menu.clients', 'Menu.Nav.Detail'],
                    navUrl: ['/', '/admin/clients'],
                    menuAuth: user.isManager(),
                    tabs: [
                        // sub 페이지에는 탭이 있는 경우
                        // ClientPage(main) => ClientDetailPage(sub), 상세 화면의 탭 목록 >>
                        {
                            tabIdx: 0,
                            label: 'Menu.Nav.Detail',
                            page: ClientDetail,
                            url: '/admin/client/detail',
                            navs: ['Menu.Home', 'Menu.clients', 'Menu.Nav.Detail'],
                            navUrl: ['/', '/admin/clients']
                        },
                        {
                            tabIdx: 1,
                            label: 'ClientDetail.Title.Status',
                            page: StatusPage,
                            url: '/admin/client/status',
                            navs: ['Menu.Home', 'Menu.clients', 'ClientDetail.Title.Status'],
                            navUrl: ['/', '/admin/clients']
                        },
                        {
                            tabIdx: 2,
                            label: 'ClientDetail.Title.License',
                            page: ClientLicenseDetailPage,
                            url: '/admin/client/license',
                            navs: ['Menu.Home', 'Menu.clients', 'ClientDetail.Title.License'],
                            navUrl: ['/', '/admin/clients']
                        },
                        {
                            tabIdx: 3,
                            label: 'ClientDetail.Title.Agreement',
                            page: AgreementConfigPage,
                            url: '/admin/client/agreement',
                            navs: ['Menu.Home', 'Menu.clients', 'ClientDetail.Title.Agreement'],
                            navUrl: ['/', '/admin/clients']
                        },
                        {
                            tabIdx: 4,
                            label: 'ClientDetail.Title.Manager',
                            page: ManagerListPage,
                            url: '/admin/client/manager',
                            navs: ['Menu.Home', 'Menu.clients', 'ClientDetail.Title.Manager'],
                            navUrl: ['/', '/admin/clients']
                        }
                    ]
                },
                {
                    menuIndex: 3,
                    menuName: 'Menu.users',
                    label: 'Menu.users',
                    url: '/admin/users',
                    page: UserListPage,
                    iconImg: UserIcon,
                    navs: ['Menu.Home', 'Menu.users'],
                    navUrl: ['/', '/admin/users'],
                    menuAuth: user.isManager(),
                    searchData: true
                },
                {
                    url: '/admin/user',
                    label: ':userKey',
                    page: UserDetailsPage,
                    menuIndex: 3,
                    hidden: true,
                    navs: ['Menu.Home', 'Menu.users', 'Menu.Nav.Detail'],
                    navUrl: ['/', '/admin/users'],
                    menuAuth: user.isManager(),
                    tabs: [
                        {
                            tabIdx: 0,
                            label: 'Menu.Nav.Detail',
                            page: UserDetail,
                            url: '/admin/user/detail',
                            navs: ['Menu.Home', 'Menu.users', 'Menu.Nav.Detail'],
                            navUrl: ['/', '/admin/users']
                        },
                        {
                            tabIdx: 1,
                            label: 'UserPage.Title.AuthHistory',
                            page: AuthHistoryPage,
                            url: '/admin/user/auth',
                            navs: ['Menu.Home', 'Menu.users', 'UserPage.Title.AuthHistory'],
                            navUrl: ['/', '/admin/users']
                        },
                        {
                            tabIdx: 2,
                            label: 'UserPage.Title.Event',
                            page: UserHistoryPage,
                            url: '/admin/user/event',
                            navs: ['Menu.Home', 'Menu.users', 'UserPage.Title.Event'],
                            navUrl: ['/', '/admin/users']
                        }
                    ]
                },
                {
                    menuIndex: 4,
                    menuName: 'Menu.Nav.Agreement',
                    label: 'Menu.agreements',
                    url: '/admin/agreements',
                    page: AgreementListPage,
                    iconImg: AgreementIcon,
                    navs: ['Menu.Home', 'Menu.agreements'],
                    navUrl: ['/', '/admin/agreements'],
                    menuAuth: user.isManager(),
                    searchData: true,
                    createContent: true,
                    createAuth: user.isManager(),
                    createPage: AgreementCreate,
                },
                {
                    menuIndex: 4,
                    url: '/admin/agreements/detail',
                    label: 'Menu.Nav.Detail',
                    page: AgreementDetailsPage,
                    hidden: true,
                    navs: ['Menu.Home', 'Menu.agreements', 'Menu.Nav.Detail'],
                    navUrl: ['/', '/admin/agreements'],
                    menuAuth: user.isManager(),
                },
                {
                    menuIndex: 5,
                    menuName: 'Menu.Nav.License',
                    label: 'Menu.license',
                    url: '/admin/license',
                    page: LicenseListPage,
                    iconImg: LicenseIcon,
                    navs: ['Menu.Home', 'Menu.license'],
                    navUrl: ['/', '/admin/license'],
                    menuAuth: user.isManager(),
                    createContent: true,
                    createAuth: user.isManager(),
                    createPage: LicenseCreate,
                },
                {
                    menuIndex: 5,
                    url: '/admin/license/detail',
                    label: ':clientName',
                    page: LicenseDetailPage,
                    hidden: true,
                    navs: ['Menu.Home', 'Menu.license', 'Menu.Nav.Detail'],
                    navUrl: ['/', '/admin/license'],
                    menuAuth: user.isManager(),
                },
                {
                    menuIndex: 6,
                    menuName: 'Menu.Nav.EventHistory',
                    label: 'EventHistory.ClientHistory',
                    url: '/admin/events',
                    page: EventHistoryPage,
                    iconImg: EventIcon,
                    navs: ['Menu.Home', 'Menu.eventHistory', 'EventHistory.ClientHistory'],
                    navUrl: ['/', '/admin/events/clientHistory'],
                    menuAuth: user.isManager(),
                    createContent: false,
                    pages: [
                        {
                            tabIdx: 0,
                            label: 'EventHistory.ClientHistory',
                            url: '/admin/events/clientHistory',
                            page: EventHistoryPage,
                            navs: ['Menu.Home', 'Menu.eventHistory', 'EventHistory.ClientHistory'],
                            navUrl: ['/', '/admin/events/clientHistory']
                        },
                        {
                            tabIdx: 1,
                            label: 'EventHistory.UserHistory',
                            url: '/admin/events/userHistory',
                            page: EventHistoryPage,
                            navs: ['Menu.Home', 'Menu.eventHistory', 'EventHistory.UserHistory'],
                            navUrl: ['/', '/admin/events/clientHistory']
                        },
                        {
                            tabIdx: 2,
                            label: 'EventHistory.AuthHistory',
                            url:  '/admin/events/authHistory',
                            page: EventHistoryPage,
                            navs: ['Menu.Home', 'Menu.eventHistory', 'EventHistory.AuthHistory'],
                            navUrl: ['/', '/admin/events/clientHistory']
                        },
                        {
                            tabIdx: 3,
                            label: 'EventHistory.AgreementHistory',
                            url: '/admin/events/agreementHistory',
                            page: EventHistoryPage,
                            navs: ['Menu.Home', 'Menu.eventHistory', 'EventHistory.AgreementHistory'],
                            navUrl: ['/', '/admin/events/clientHistory']
                        },
                        // {
                        //     tabIdx: 4,
                        //     label: 'EventHistory.LicenseHistory',
                        //     url: '/admin/events/licenseHistory',
                        //     page: EventHistoryPage,
                        //     navs: ['Menu.Home', 'Menu.eventHistory', 'EventHistory.LicenseHistory'],
                        //     navUrl: ['/', '/admin/events/clientHistory']
                        // },
                        {
                            tabIdx: 4,
                            label: 'EventHistory.QuestionHistory',
                            url: '/admin/events/questionHistory',
                            page: EventHistoryPage,
                            navs: ['Menu.Home', 'Menu.eventHistory', 'EventHistory.QuestionHistory'],
                            navUrl: ['/', '/admin/events/clientHistory']
                        }
                    ]
                },
                {
                    menuIndex: 6,
                    url: '/admin/event/detail',
                    label: 'Menu.Nav.Detail',
                    page: EventDetailPage,
                    hidden: true,
                    navs: ['Menu.Home', 'Menu.Nav.EventHistory', ':historyLabel', 'Menu.Nav.Detail'],
                    navUrl: ['/', '/admin/events/clientHistory', ':historyUrl'],
                    menuAuth: user.isManager(),
                },
                {
                    menuIndex: 7,
                    menuName: 'Menu.Nav.Q&A',
                    label: 'Menu.qna',
                    url: '/admin/qna',
                    page: QnaPage,
                    iconImg: QnAIcon,
                    navs: ['Menu.Home', 'Menu.qna'],
                    navUrl: ['/', '/mypage/qna'],
                    menuAuth: user.isManager(),
                    searchData: true,
                    createContent: true,
                    createAuth: user.isManager(),
                    createPage: QuestionEdit,
                },
                {
                    menuIndex: 8,
                    menuName: 'Menu.Nav.Settings',
                    label: 'UserManagement.TabTitle',
                    url: '/admin/settings',
                    page: SettingsPage,
                    iconImg: SettingIcon,
                    navs: ['Menu.Home', 'Menu.Nav.Settings', 'UserManagement.TabTitle'],
                    navUrl: ['/', '/admin/settings'],
                    menuAuth: user.isSuperManager(),
                    pages: [
                        {
                            tabIdx: 0,
                            label: 'UserManagement.TabTitle',
                            url: '/admin/settings/user',
                            page: SettingsPage,
                            navs: ['Menu.Home', 'Menu.Nav.Settings', 'UserManagement.TabTitle'],
                            navUrl: ['/', '/admin/settings']
                        },
                        {
                            tabIdx: 1,
                            label: 'Logs.TabTitle',
                            url: '/admin/settings/logs',
                            page: SettingsPage,
                            navs: ['Menu.Home', 'Menu.Nav.Settings', 'Logs.TabTitle'],
                            navUrl: ['/', '/admin/settings']
                        }
                    ]
                },
                {
                    menuIndex: 9,
                    menuName: 'PatchNotePage.PatchNote',
                    label: 'PatchNotePage.PatchNote',
                    url: '/patches',
                    page: PatchNoteListPage,
                    iconImg: PatchIcon,
                    navs: ['Menu.Home', 'PatchNotePage.PatchNote'],
                    navUrl: ['/', '/patches'],
                    menuAuth: true,
                    searchData: true,
                    createContent: true,
                    createAuth: user.isSuperManager(),
                    createPage: PatchNoteCreate,
                },
                {
                    menuIndex: 9,
                    url: '/patches/detail',
                    label: 'PatchNote.Detail.Title',
                    page: PatchDetailPage,
                    hidden: true,
                    navs: ['Menu.Home', 'PatchNotePage.PatchNote', 'PatchNote.Detail.Title'],
                    navUrl: ['/', '/patches'],
                    menuAuth: true
                },
            ]
        },
        {
            // 홈페이지 화면 목록 (상단 내비게이션 바 메뉴)
            items: [
                {
                    url: '/solution',
                    label: 'Solution',
                    name: 'Solution',
                    router: '/solution',
                    page: SolutionPage,
                    hidden: true,
                    headerHidden:true,
                },
                {
                    url: '/news',
                    label: 'News',
                    name: 'News',
                    router: '/News',
                    page: NewsPage,
                    hidden: true,
                    headerHidden:true,
                },
                {
                    url: '/video',
                    label: 'Video',
                    name: 'Video',
                    router: '/Video',
                    page: VideoPage,
                    hidden: true,
                    headerHidden:true,
                },
                {
                    url: '/patent',
                    label: 'patent',
                    name: 'Patent',
                    router: '/Patent',
                    page: PatentPage,
                    hidden: true,
                    headerHidden:true,
                },
                {
                    url: '/history',
                    label: 'History',
                    name: 'History',
                    router: '/history',
                    page: HistoryPage,
                    hidden: true,
                    headerHidden:true,
                },
                {
                    url: '/contact',
                    label: 'Contact',
                    name: 'Contact',
                    router: '/contact',
                    page: ContactPage,
                    hidden: true,
                    headerHidden:true,
                },

            ]
        }
    ]
}

export { adminMenuFunc }