import {usePagination} from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import React, {useState} from "react";
import {useMediaQuery} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Menu, MenuItem} from "@mui/material";

const useStyles = makeStyles((theme) => ({

    pageSizeMenu: {
        backgroundColor: '#fff',
        borderRadius: 5,
        color: '#8E8DAB',
        fontSize: 13,
        fontWeight: 600,
        width: 68, height: 36
    },
    menuItemStyle: {
        fontFamily: 'Poppins !important',
        fontSize: '13px !important',
        fontWeight: '600 !important',
        justifyContent: 'center !important',
        '&:hover': {
            backgroundColor: '#F2F1F8 !important',
            color: '#3C39CB'
        }
    }
}));

export default function Pagination({
                           style,
                           currentPage,
                           totalPage,
                           onChangeEvent
                       }) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    const paginationStyle = style();
    const {items} = usePagination({
        count: totalPage,
        page: currentPage,
        siblingCount: isMobile ? 0 : 1,
        boundaryCount: isMobile ? 1 : 2,
    });

    const changePage = (event, type, page) => {
        if (type === 'previous') {
            onChangeEvent(event, currentPage - 1)
        } else if (type === 'next') {
            onChangeEvent(event, currentPage + 1)
        } else if (type === "page") {
            onChangeEvent(event, page);
        }
    }

    return (
        <nav>
            <ul className={paginationStyle.root}>
                {items.map(({page, type, selected, ...item}, index) => {
                    let children;
                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = <span>···</span>;
                    } else if (type === 'page') {
                        children = (
                            <Button type="button" {...item}
                                    onClick={(e) => changePage(e, type, page)}
                                    style={{
                                        color: selected ? '#3C39CB' : '#A8A8B8',
                                        borderRadius: '50%',
                                        minWidth: !isMobile ? 38 : 15,
                                        border: 'none',
                                        boxShadow: 'none',
                                        fontWeight: selected ? 700 : 500
                                    }}>
                                {page}
                            </Button>
                        );
                    } else {
                        children = (
                            <Button type="button" {...item}
                                    onClick={(e) => changePage(e, type)}
                                    style={{
                                        width: !isMobile ? 84 : 64,
                                        height: 36,
                                        margin: !isMobile ? '0 30px' : '0 10px'
                                    }}>
                                {type === 'previous' ? 'Back' : 'Next'}
                            </Button>
                        );
                    }
                    return <li key={index}>{children}</li>;
                })}
            </ul>
        </nav>
    );
}

Pagination.defaultProps = {
    totalPage: 0,
    currentPage: 1,
    style: makeStyles((theme) => ({
            root: {
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                listStyle: 'none',
                marginTop: 50,
                '& > li > button': {
                    ...theme.typography.button,
                    textTransform: 'none',
                    color: theme.palette.text.title,
                    padding: '8px 8px 10px 8px',
                    border: '1px solid #B0B0BE',
                    borderRadius: 5,
                    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.main,
                        border: '1px solid #3C39CB',
                        borderRadius: 5
                    }
                },
                '& > li > span': {
                    color: '#A8A8B8',
                    fontWeight: 700
                },
                '& .MuiButton-root.Mui-disabled': {
                    color: '#B0B0BE',
                    border: '1 px solid #B0B0BE'
                }
            }
        })
    )
}

export function PageSizeSelector({pageSize, onPageSelect}) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const pageSizeArray = [5, 10, 20, 30, 40, 50]

    return (
        <React.Fragment>
            <Button className={classes.pageSizeMenu} onClick={handleClick} endIcon={<KeyboardArrowDownIcon />} >{pageSize}</Button>
            <Menu elevation={0}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                      style: {
                          maxHeight: 100,
                          width: 68,
                          boxShadow: '0px 2px 4px rgb(0 0 0 / 20%)',
                          border: '0.5px solid #3C39CB'
                      }
                  }}
            >
                {pageSizeArray.map((i, idx) =>
                    <MenuItem key={idx}
                              className={classes.menuItemStyle}
                              onClick={() => {
                                  onPageSelect(i);
                                  handleClose();
                              }}
                    >{i}</MenuItem>
                )}
            </Menu>
        </React.Fragment>
    )
}