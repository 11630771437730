import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Box, Select} from "@mui/material";
import {ListItemIcon} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    selectPaper: {
        width: 200,
        maxHeight: '500px !important',
        border: theme.palette.border.secondary,
        boxShadow: theme.palette.shadow.primary,
        ...theme.select
    },
    titleItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > :nth-child(2)': {
            minWidth: 'auto',
            '& > *': {
                color: theme.palette.primary.main
            }
        }
    },
    allItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > :first-child': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& > :nth-child(2)': {
            minWidth: 'auto',
            '& > *': {
                color: "transparent"
            }
        }
    }
}));

export default function SelectBox({
                                      className,
                                      title,
                                      contents,
                                      displayEmpty,
                                      onChange,
                                      addAll,
                                      defaultValue,
                                      visibility,
                                      disabled,
                                      value
                                  }) {

    const classes = useStyles();

    return (visibility === undefined || visibility === true ?
        <FormControl variant="outlined" className={className}>
            <Select
                defaultValue={defaultValue?defaultValue:"ALL"}
                onChange={onChange}
                displayEmpty={displayEmpty?displayEmpty:false}
                disabled={disabled?disabled:false}
                value={value?value:undefined}
                MenuProps={{
                    classes: {paper: classes.selectPaper},
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    }
                }}
            >
                <MenuItem value="ALL" key="all" disabled={!addAll}>
                    <Box className={classes.titleItem}>
                        <div>{title}</div>
                        <ListItemIcon>
                            <ExpandMoreIcon/>
                        </ListItemIcon>
                    </Box>
                </MenuItem>
                {
                    contents.map((content, idx) =>
                        <MenuItem key={idx}
                                  value={content.value}
                                  disabled={content.disabled ?? false}>
                            <Box className={classes.allItems}>
                                <div>{content.text}</div>
                                <ListItemIcon>
                                    <ExpandMoreIcon/>
                                </ListItemIcon>
                            </Box>
                        </MenuItem>)
                }
            </Select>
        </FormControl> : '');
}