import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {List, ListItem} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    listBox: props => ({
        display: "flex",
        marginLeft: 30,
        '& > li': {
            padding: `0 ${props.space}rem`,
            borderRight: `${props.borderWidth}px solid ${props.borderColor}`
        },
        '& > li:last-child': {
            border: 'none'
        }
    })
}))

export function HorizontalListBox({borderWidth = 1, borderColor = '#363E55', space = 0.5, className, children}) {
    const styleProps = {
        borderWidth: borderWidth,
        borderColor: borderColor,
        space: space
    }
    const classes = useStyles(styleProps);

    return (
        <List className={`${classes.listBox} ${className}`}>
            {children.map((item, idx) => (
                <ListItem key={idx}>{item}</ListItem>
            ))}
        </List>
    )
}