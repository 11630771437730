import React, {useEffect, useState} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import {Typography, useMediaQuery} from "@material-ui/core";
import Grow from '@mui/material/Grow';
import {useTranslation} from "react-i18next";
import Carousel from "react-material-ui-carousel";
import Button from "@material-ui/core/Button";
import {Fade} from "@mui/material";
import AuthChart from "./AuthChart";
import AuthTimeline from "./AuthTimeline";
import LoginChart from "./LoginChart";
import {useStores} from "../../common/store";

const useStyles = makeStyles((theme) => ({

    screen: {
        marginBottom: "-17px",
        height: 'calc(100vh - 4.063rem)',
        minHeight: 750,
        minWidth: 1130,
        overflow: 'hidden',
        backgroundColor: "#FFF",
            [theme.breakpoints.down("md")]: {
            height: 'auto',
            minWidth: '100vw'
        },
        [theme.breakpoints.down("xs")]: {
            height: 'auto',
            minWidth: '100vw',
            maxWidth: '100vw'
        }
    },

    dashboardBox: {
        display: 'flex',
        flexDirection: 'column',
        justifySelf: 'center',
        padding: 5,
        justifyContent: "center",
        margin: "auto",
        height: '100%',
        width: '100%',
        alignItems: 'stretch',
        '& > :first-child': {
            justifyContent: "center",
            maxHeight: 450,
            flex: "0 0 54%"
        },
        '& > :last-child': {
            justifyContent: "center",
            maxHeight: 395,
            flex: "0 0 46%"
        },
        [theme.breakpoints.down("md")]: {
            height: "auto",
            minWidth: '100vw',
            padding: 0,
            '& > :first-child': { // first row
                justifyContent: "center",
                height: 'auto',
                maxHeight: 1000,
                alignItems: 'center',
                flex: 1
            },
            '& > :last-child': { // second row
                justifyContent: "center",
                height: 'auto',
                maxHeight: 1000,
                alignItems: 'center',
                flex: 1
            },
        },
        [theme.breakpoints.down("xs")]: {
            height: 'auto',
            width: '100vw',
            minWidth: '100vw',
            padding: 0,
            '& > :first-child': { // first row
                justifyContent: "center",
                height: 'auto',
                alignItems: 'center',
            },
            '& > :last-child': { // second row
                justifyContent: "center",
                height: 'auto',
                alignItems: 'center',
            },
        }
    },
    blockRow: {
        alignItems: 'stretch',
        margin: '5px 10px 5px 10px',
        '& > *': {
            width: '100%',
            minHeight: 300,
            borderRadius: '20px',
        },
        '& > :first-child': { // left block
            marginRight: 10,
            flex: "0 0 52%",
            maxWidth: '700px'
        },
        '& > :last-child': { // right block
            flex: "0 0 48%",
            maxWidth: '640px'
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'center',
            height: 'auto',
            minWidth: '100vw',
            maxWidth: '100vw',
            margin: 0,
            padding: 5,
            '& > :first-child': {   // 1st and 3rd in the column
                marginRight: 0,
                maxWidth: 700,
                minHeight: 400
            },
            '& > :last-child': {    // 2nd and 4th in the column
                maxWidth: 700,
                minHeight: 400
            }
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                width: '95%',
                borderRadius: '20px',
            },
            '& > :first-child': {
                marginRight: 0,
                minHeight: 400
            },
            '& > :last-child': {
                minHeight: 300
            }
        }
    },

    // top banner
    bannerText: {
        color: '#fff',
        justifyContent: 'flex-end',
        margin: '0px 60px 70px 60px',
        '& > :first-child': {
            fontSize: '3rem',
            fontWeight: 900,
            lineHeight: '71.04px',
            paddingBottom: 10,
            [theme.breakpoints.down("xs")]: {
                fontSize: '2.5rem',
            }
        },
        '& > :nth-child(2)': {
            fontSize: '1.125rem',
            fontWeight: 350,
            lineHeight: '26.64px',
            paddingBottom: 6,
            [theme.breakpoints.down("xs")]: {
                fontSize: '0.938rem',
            }
        },
        '& > :last-child': {
            fontSize: '0.938rem',
            fontWeight: 350,
            lineHeight: '22.2px',
            opacity: 0.65,
            [theme.breakpoints.down("xs")]: {
                fontSize: '0rem'
            }
        },
        [theme.breakpoints.down("xs")]: {
            width: 250,
            margin: '0px 0px 30px 30px',
        }
    },
    banner: {
        marginTop: 10,
        transitionDuration: "700ms",
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    easy: {
        backgroundImage: `url(${require('../../images/dashboard/dashboard_easy.png')})`,
    },
    fast: {
        backgroundImage: `url(${require('../../images/dashboard/dashboard_fast.png')})`,
    },
    safe: {
        backgroundImage: `url(${require('../../images/dashboard/dashboard_safe.png')})`,
    },

    // login activity bar chart
    blockTopRight: {
        backgroundColor: '#233457',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 10,
        padding: '30px 40px',
        [theme.breakpoints.down("xs")]: {
            padding: 20
        },
        '& > :first-child': {
            alignItems: 'center',
            marginBottom: 35,
            [theme.breakpoints.down("xs")]: {
                flexDirection: 'column',
                marginBottom: 20,
            },
            '& > :first-child': {   // login activity
                color: '#fff',
                fontSize: '1.125rem',
                fontWeight: 500,
                lineHeight: '20.7px',
                [theme.breakpoints.down("xs")]: {
                    alignSelf: 'flex-start',
                    marginBottom: 20,
                    fontSize: '1rem'
                }
            }
        },
    },
    chartButtons: {
        '& > .MuiButton-root': {
            textTransform: 'none'
        },
        '& > *': {
            width: 85,
            height: 35
        },
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            '& > *': {
                width: '50%',
                height: 35,
            }
        }
    },
    activeBtn: {
        backgroundColor: '#3C39CB',
        transitionDuration: 0,
        '&:hover': {
            backgroundColor: '#3C39CB',
            "& > *": {
                color: '#fff'
            }
        },
        "& > *": {
            fontSize: '0.75rem',
            fontWeight: 700,
            lineHeight: '16.1px',
            color: '#fff'
        }
    },
    notActiveBtn: {
        backgroundColor: '#2B3F68',
        transitionDuration: 0,
        '&:hover': {
            backgroundColor: '#2B3F68',
            "& > *": {
                color: '#6E7D9D'
            }
        },
        "& > *": {
            fontSize: '0.75rem',
            fontWeight: 500,
            lineHeight: '16.1px',
            color: '#6E7D9D'
        }
    },
    chartBox: {
        minHeight: 250,
        [theme.breakpoints.down("xs")]: {
            minHeight: 170,
        }
    },
    barChartBox: {
        height: 250,
        width: '100%',
        alignSelf: 'center',
        '& > :first-child': {
            width: '100% !important',
            height: '100% !important'
        },
        [theme.breakpoints.down("xs")]: {
            height: 170,
        }
    },

    // authentication chart
    blockBottomLeft: {
        justifyContent: "center",
        marginBottom: 10,
        '& > :first-child': {
            color: theme.palette.text.title,
            fontWeight: 700,
            fontSize: '1.125rem',
            margin: '0 0 40px 10px',
            [theme.breakpoints.down("md")]: {
                maxHeight: 300,
                margin: '0 0 10px 10px'
            },
            [theme.breakpoints.down("xs")]: {
                margin: '10px 0 0 10px'
            }
        },
        [theme.breakpoints.down("md")]: {
            minHeight: "350px !important",
        }
    },

    // slider
    blockBottomRight: {
        fontWeight: 500,
        padding: 0,
        marginBottom: 10,
        backgroundColor: '#F4F7F8'
    },
    slider: {
        maxHeight: 395,
        height: '100%',
        width: '100%',
        borderRadius: '20px',
        backgroundPosition: "right bottom",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        [theme.breakpoints.down("md")]: {
            height: 400,
            maxHeight: 450,
        },
        [theme.breakpoints.down("xs")]: {
            height: 300,
        }
    },
    sliderText: {
        position: 'absolute',
        zIndex: 1,
        width: 500,
        padding: '45px 0 0 40px',
        '& > :first-child': {       // title
            width: 390,
            color: '#000',
            fontSize: '1.875rem',
            fontWeight: 500,
            marginBottom: 15,
            display: 'inline-block',
            [theme.breakpoints.down("xs")]: {
                fontSize: '1.375rem',
                width: 300
            }
        },
        '& > :nth-child(2)': {        // subtitle
            color: '#555',
            width: 300,
            fontWeight: 500,
            fontSize: '0.813rem',
            lineHeight: '21px',
            display: 'inline-block',
            [theme.breakpoints.down("xs")]: {
                fontSize: '0.688rem',
            }
        }
    },
    carousel: {
        borderRadius: '20px',
        height: '100%',
        width: '100%',
        "& > :first-child": { // carousel item
            height: '100%',
            width: '100%',
            [theme.breakpoints.down("xs")]: {
                height: 300
            },
            "& > :first-child": {
                height: '100%',
                width: '100%',
            }
        },
        "& > :last-child": {
            textAlign: 'left',
            paddingLeft: 35,
            maxHeight: 6,
            marginTop: 0
        }
    },
    indicator: {
        maxHeight: '6px',
        maxWidth: '6px',
        margin: "-200px 5px 0 5px",
        backgroundColor: '#B2B2B2',
        textAlign: 'left',
        "&:hover": {
            backgroundColor: '#0642D9',
        }
    },
    indicatorActive: {
        transitionDuration: 300,
        maxWidth: '35px',
        width: 35,
        borderRadius: 3,
        backgroundColor: '#0642D9',
    }

}));

const mobileStyles = makeStyles((theme) => ({
    screen: {
        minWidth: '100vw',
        maxWidth: '100vw',
        backgroundColor: "#FFF"
    },
    dashboard: {
        minWidth: '100vw',
        maxWidth: '100vw',
        '& > :last-child': {
            height: 'auto'
        }
    },
    btnMobile: {
        '& > .MuiButton-root:hover': {
            backgroundColor: '#3C39CB',
            "& > *": {
                color: '#fff'
            }
        }
    },
    blockBottomLeft: {
        marginTop: 10
    },
    carousel: {
        borderRadius: '20px',
        height: '100% !important',
        width: '100%',
        "& > :first-child": { // carousel item
            height: 420,
            width: '100%',
            "& > :first-child": {
                height: 420,
                width: '100%'
            }
        },
        "& > :last-child": {
            textAlign: 'left',
            paddingLeft: 35,
            maxHeight: 6,
            marginTop: 0
        }
    },
    slider: {
        height: '100%',
        width: '100%',
        borderRadius: '20px',
        backgroundPosition: "right bottom",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '85%',
    },
    sliderText: {
        position: 'absolute',
        zIndex: 1,
        width: 350,
        padding: '45px 0 0 40px',
        '& > :first-child': {       // title
            color: '#000',
            fontWeight: 500,
            marginBottom: 15,
            display: 'inline-block',
            fontSize: '1.25rem',
            width: '90%'
        },
        '& > :last-child': {        // subtitle
            color: '#555',
            width: '70%',
            fontWeight: 500,
            lineHeight: '21px',
            display: 'inline-block',
            fontSize: '0.688rem'
        }
    },
    indicator: {
        maxHeight: '6px',
        maxWidth: '6px',
        margin: "-430px 5px 0 5px",
        backgroundColor: '#B2B2B2',
        textAlign: 'left',
        "&:hover": {
            backgroundColor: '#0642D9'
        }
    },
    indicatorActive: {
        transitionDuration: 300,
        maxWidth: '35px',
        width: 35,
        borderRadius: 3,
        backgroundColor: '#0642D9'
    }
}));

function FadeBanner() {

    const classes = useStyles();
    const [bgImage, setBgImage] = React.useState(0);
    const {t} = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            setBgImage(bgImage === 0 ? 1 : bgImage === 1 ? 2 : 0);
        }, 5000);
        return () => clearInterval(interval);
    });

    return (
        <Box display='flex' flexDirection='column' justifyContent='flex-end'
             className={bgImage === 0 ? `${classes.easy} ${classes.banner}` :
                 bgImage === 1 ? `${classes.fast} ${classes.banner}` : `${classes.safe} ${classes.banner}`}
             onClick={() => setBgImage(bgImage === 0 ? 1 : bgImage === 1 ? 2 : 0)}>
            <Grow in={true} timeout={1000} style={{transformOrigin: '0 0 0'}}>
                {bgImage === 0 ?
                    <Box className={classes.bannerText}>
                        <Typography>EASY</Typography>
                        <Typography>{t("Home.BannerText.Easy")}</Typography>
                        <Typography>Easy authentication process</Typography>
                    </Box> :
                    bgImage === 1 ?
                        <Box className={classes.bannerText}>
                            <Typography>FAST</Typography>
                            <Typography>{t("Home.BannerText.Fast")}</Typography>
                            <Typography>Complete authentication in less than 2 seconds</Typography>
                        </Box>
                        :
                        <Box className={classes.bannerText}>
                            <Typography>SAFE</Typography>
                            <Typography>{t("Home.BannerText.Safe")}</Typography>
                            <Typography>Secure authentication through multiple distributed verification</Typography>
                        </Box>
                }
            </Grow>
        </Box>
    )
}

function SlideBanner() {
    const classes = useStyles();
    const mobileStyle = mobileStyles();
    const {ds} = useStores();
    const theme = useTheme();
    const isPhone = useMediaQuery(theme.breakpoints.down('xs')) && ds.isMobileDevice;

    const items = [
        {image: `url(${require('../../images/dashboard/banner_1.png')})`},
        {image: `url(${require('../../images/dashboard/banner_2.png')})`},
        {image: `url(${require('../../images/dashboard/banner_3.png')})`},
        {image: `url(${require('../../images/dashboard/banner_4.png')})`}
    ]

    const SlideIcon = ({className}) => {
        const iconStyle = {
            maxWidth: 6,
            maxHeight: 6,
            borderRadius: 3,
            margin: 5
        };
        return (
            <Box className={className} style={iconStyle}/>
        )
    };

    return (
        <Carousel animation='slide' timeout={600} className={!isPhone ? classes.carousel : mobileStyle.carousel}
                  IndicatorIcon={<SlideIcon/>}
                  indicatorIconButtonProps={{className: !isPhone ? classes.indicator : mobileStyle.indicator}}
                  activeIndicatorIconButtonProps={{className: !isPhone ? classes.indicatorActive : mobileStyle.indicatorActive}}
        >
            {
                items.map((item, i) =>
                    <Box key={i} className={!isPhone ? classes.slider : mobileStyle.slider}
                         style={{backgroundImage: item.image}}/>)
            }
        </Carousel>
    )
}

export default function DashboardPage(props) {
    const {t} = useTranslation();
    const {ds} = useStores();
    const theme = useTheme();
    const classes = useStyles();
    const mobileStyle = mobileStyles();
    const [monthly, setMonthly] = useState(true);
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setHidden(true)
        }, 500); // delay for the bar chart appearance
        return () => clearTimeout(timer);
    }, []);

    const isSmallWindow = useMediaQuery(theme.breakpoints.down('lg'));
    const isPhone = useMediaQuery(theme.breakpoints.down('xs')) && ds.isMobileDevice;

    return (
        <Box display='flex' flexDirection='row' justifyContent='space-between'
             className={!isPhone ? classes.screen : mobileStyle.screen}>
            <Box className={!isPhone ? classes.dashboardBox : mobileStyle.dashboard}>
                <Box display='flex' flexDirection='row' className={classes.blockRow}>
                    <FadeBanner/>
                    <Box className={classes.blockTopRight}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between'>
                            <Typography>{t('Home.LoginActivity')}</Typography>
                            <Box
                                className={!ds.isMobileDevice ? classes.chartButtons : `${classes.chartButtons} ${mobileStyle.btnMobile}`}>
                                <Button className={monthly ? classes.activeBtn : classes.notActiveBtn}
                                        onClick={() => setMonthly(true)}>
                                    {t('Home.Monthly')}
                                </Button>
                                <Button className={!monthly ? classes.activeBtn : classes.notActiveBtn}
                                        onClick={() => setMonthly(false)}>
                                    {t('Home.Weekly')}
                                </Button>
                            </Box>
                        </Box>
                        <Box className={classes.chartBox}>
                            {hidden &&
                            <Fade in={hidden}>
                                <Box className={classes.barChartBox}>
                                    {monthly ? <LoginChart type='monthly'/> : <LoginChart type='weekly'/>}
                                </Box>
                            </Fade>
                            }
                        </Box>
                    </Box>
                </Box>

                <Box display='flex' flexDirection='row' className={classes.blockRow}>
                    <Box display='flex' flexDirection='column'
                         className={!isPhone ? classes.blockBottomLeft : `${classes.blockBottomLeft} ${mobileStyle.blockBottomLeft}`}>
                        <Typography>{t('Home.AuthenticationChart')}</Typography>
                        <AuthChart/>
                    </Box>
                    <Box className={classes.blockBottomRight}>
                        <Box className={!isPhone ? classes.sliderText : mobileStyle.sliderText}>
                            {
                                theme.type === 'gccs'
                                    ? <Typography>Security authentication solution G-CCS</Typography>
                                    : <Typography>Security authentication solution BSA</Typography>
                            }

                            <Typography>Easy and fast authentication technology designed for your security</Typography>
                        </Box>
                        <SlideBanner/>
                    </Box>
                </Box>
            </Box>
            <AuthTimeline isOpen={!isSmallWindow && !props.sideMenuOpen}/>
        </Box>
    );
}