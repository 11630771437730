import React, {useEffect, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import LabelIcon from '@material-ui/icons/Label';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import api from "../../../common/api";
import {useStores} from "../../../common/store";
import {useTranslation} from "react-i18next";
import {Scrollbars} from "react-custom-scrollbars";
import Typography from "@material-ui/core/Typography";
import {Box, useMediaQuery} from "@material-ui/core";
import {ReactComponent as FnsvLogo} from "../../../images/fnsv_logo.svg";
import LinkIcon from '@mui/icons-material/Link';
import ConfirmDialog from "../../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        position:'relative',
        display: 'flex',
        flexDirection:'column',
        '& > *':{
            marginTop:theme.spacing(2),
            marginBottom:theme.spacing(2),
        }
    },
    cardContainer: {
        flex: 'auto',
        padding: '40px 50px',
        background: theme.palette.background.paper,
        position:'relative',
        '&.MuiPaper-elevation1':{
            boxShadow:'none',
        },
        '&.MuiPaper-rounded':{
            borderRadius:theme.spacing(1),
        },
        [theme.breakpoints.between('xs', 'md')]: {
            padding: 20,
        },
    },
    cardHeader: {
        borderBottom: '1px solid #767A8F',
        padding: '10px 0 30px 0',
        '& .MuiSvgIcon-root': {
            transform: 'rotate(-45deg)',
            width: 25,
            height: 18,
            marginBottom: '-4px'
        },
        '& .MuiCardHeader-content': {
            '& > :first-child': {
                fontSize: '1.125rem',
                color: '#5E6278'
            },
            '& > :last-child': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                '&  > p': {
                    color: '#767A8F',
                    marginLeft: '-5px',
                    '& > a': {
                        textDecoration: 'none',
                        color: '#4878E9'
                    },
                    [theme.breakpoints.between('xs', 'sm')]: {
                        marginRight: 10,
                        '& > a': {
                            wordBreak: 'break-all'
                        }
                    }
                }
            }
        }
    },
    logo: {
        width: 70,
        height: 70,
        backgroundColor: '#F5F8FA',
        borderRadius: 7
    },
    urlAndCopy:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    title: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        margin: 0,
        marginBottom: theme.spacing(2),
        padding: 0,
        color: theme.palette.text.title,
        '& span': {fontSize: '1rem'},
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection:'column',
        }
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        flex:1,
        padding: '15px 0 25px 0',
        backgroundColor: theme.palette.background.paper,
        '& > :first-child': {
            backgroundColor: theme.palette.background.paper,
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            padding:0,
        },
        '& dl > dt': {
            maxWidth: 150
        }
    },
    agreementContent: {
        '& > :first-child': {
            fontSize: '1rem',
            fontWeight: 500,
            margin: '25px 0 30px 0'
        },
        '& > .MuiTypography-colorTextPrimary': {
            fontSize: '0.813rem'
        }
    },
    buttonContainer:{
        alignSelf:'flex-end',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom:theme.spacing(1),
        '& > :first-child': {
            marginRight: theme.spacing(1),
            border: '1px solid #3C39CB',
            color: theme.palette.primary.main,
            backgroundColor: '#FFF'
        },
        '& .MuiButton-contained.Mui-disabled': {
            border: '1px solid #A3A2BD',
            color: '#A3A2BD'
        },
        '& > button': {
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
            }
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin:theme.spacing(2),
        }
    },
    listContainer:{
        display: 'flex',
        flexDirection: 'row',
        alignItems:'center',
        padding: 0,
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'column',
            alignItems:'initial',
        }
    },
    buttonContent:{
        display: 'flex',
        flexDirection: 'column',
        margin:theme.spacing(2),
        '& .MuiButton-root.Mui-disabled': {
            border: '1px solid #A3A2BD',
            color: '#A3A2BD'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'row',
            alignSelf:'center',
            margin:theme.spacing(1),
        }
    },
    button: {
        minWidth: 42,
        height: 42,
        margin: theme.spacing(0.5, 0),
        color: theme.palette.primary.main,
        border: '1px solid #A3A2BD',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            margin: theme.spacing(0, 0.5),
        }
    }
}));

const listStyles = makeStyles((theme) => ({
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
        flex:1,
        [theme.breakpoints.between('xs', 'sm')]: {
            width: 'auto',
            '& > div': {
                paddingTop: '12rem !important'
            }
        }
    },
    checkedItemLeft: {
        '& .MuiListItemText-root': {
            color: theme.palette.primary.main
        },
        '& .MuiCheckbox-root': {
            color: theme.palette.primary.main
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: theme.palette.primary.main
        },
    },
    checkedItemRight: {
        '& .MuiListItemText-root': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiCheckbox-root': {
            color: theme.palette.primary.contrastText
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: theme.palette.primary.contrastText
        }
    },
    track: {
        position: 'absolute',
        width: '7px',
        height: '100vh',
        right: '2px',
        bottom: '2px',
        borderRadius: '3px',
        paddingRight: '2px',
        [theme.breakpoints.between('xs', 'md')]: {
            display: 'none',
        },
    },

}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function AgreementConfigPage(props) {
    const classes = useStyles();
    const {clientKey} = props;
    const {ds} = useStores();
    const {t} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const [checked, setChecked] = React.useState([]);
    const [response, setResponse] = React.useState([]);
    const [saveDialog, setSaveDialog] = React.useState(false);
    const [hasChanged, setChanged] = React.useState(false);

    const agreements = useRef([]);

    const leftList = useRef([]);
    const rightList = useRef([]);
    const cloneLeftList = useRef([]);
    const cloneRightList = useRef([]);

    useEffect(() => {
        api.getClientAgreementList(clientKey)
            .then(data => {
                leftList.current = data.data;
                cloneLeftList.current = data.data;
                setResponse(data.data);
            })
            .catch(err => {
                return err;
            });

        api.getClientAgreementApplyList(clientKey)
            .then(data => {
                rightList.current = data.data;
                cloneRightList.current = data.data;
                setResponse(data.data);
            })
            .catch(err => {
                return err;
            });
    }, []);


    const saveOpenHandler = (value) => {
        setSaveDialog(value);
    }

    const saveOkClick = () => {
        if( rightList.current.length === 0 ){
            if( cloneRightList.current.length > 0) {
                api.postClientAgreement(clientKey, dataSet(clientKey, rightList.current))
                    .then(data => {
                        ds.showSuccessAlert(t('ClientDetail.AgreementConfig.SaveComplete'));
                        setResponse(data);
                    })
                    .catch(err => {
                        return err;
                    });
            } else {
                ds.showErrorAlert(t('ClientDetail.AgreementConfig.NoChange'));
            }
        } else {
            api.postClientAgreement(clientKey, dataSet(clientKey, rightList.current))
                .then(data => {
                    ds.showSuccessAlert(t('ClientDetail.AgreementConfig.SaveComplete'));
                    setResponse(data);
                })
                .catch(err => {
                    // 사용자 하위클라이언트를 잘못 수정하려고 하는지 확인
                    if (err.rtCode === 7101) {
                        ds.showErrorAlert(t('ClientDetail.AgreementConfig.NotAllowed'));
                        leftList.current = cloneLeftList.current;
                        rightList.current = cloneRightList.current;
                        setChecked([]);
                    }
                    return err;
                });
        }

        setSaveDialog(false);
    }

    const handleToggle = (item) => () => {
        const currentIndex = checked.indexOf(item);
        const newChecked = [...checked];

        if (item.customizable === 'false') {
            let exists = false;
            for (var i = 0; i < agreements.current.length; i++) {
                if (agreements.current[i].seq === item.seq) {
                    exists = true;
                    break;
                }
            }
            if (exists) {
                handleAgreement('delete', item);
            } else {
                handleAgreement('add', item);
            }
        } else {

            if (currentIndex === -1) {
                newChecked.push(item);
                handleAgreement('add',item);
            } else {
                newChecked.splice(currentIndex, 1);
                handleAgreement('delete',item);
            }
    
            setChecked(newChecked);
        }
    };

    const leftChecked = intersection(checked, leftList.current);
    const rightChecked = intersection(checked, rightList.current);

    const hasBeenModified = () => {
        if (cloneLeftList.current.length !== leftList.current.length
            || cloneRightList.current.length !== rightList.current.length) {
            return true;
        }
        // check if every object inside the cloned list still exists in the current list
        for (var i = 0; i < cloneLeftList.current.length; i++) {
            const cloneLeftSeq = cloneLeftList.current[i].seq;
            if (!leftList.current.some(item => item.seq === cloneLeftSeq)) {
                return true;
            }
        }
        return false;
    }

    const handleCheckedRight = () => {
        rightList.current = rightList.current.concat(leftChecked);
        leftList.current = not(leftList.current, leftChecked);
        setChecked(not(checked, leftChecked));
        setChanged(hasBeenModified());
    };

    const handleCheckedLeft = () => {
        leftList.current = leftList.current.concat(rightChecked);
        rightList.current = not(rightList.current, rightChecked);
        setChecked(not(checked, rightChecked));
        setChanged(hasBeenModified());
    };

    const resetAgreements = () => {
        leftList.current = cloneLeftList.current;
        rightList.current = cloneRightList.current;
        setChecked([]);
        setChanged(false);
    }

    const handleAgreement = (type, item) => {
        if( type === "add") {
            api.getAgreementDetail(item.seq)
                .then(data => {
                    const agreement = {
                        "title" : data.data.title,
                        "content" : data.data.content,
                        "seq" : item.seq,
                        "publicUrl" : api.HOST + '/api/v3/clients/' + item.clientKey + '/agreements/' + item.seq
                    };
                    agreements.current.push(agreement);
                    setResponse(data.data.seq);
                })
                .catch(err => {
                    return err;
                });
        } else if ( type === "delete") {
            for (var i = 0; i < agreements.current.length; i++) {
                if (agreements.current[i].seq === item.seq) {
                    agreements.current.splice(i, 1);
                    break;
                }
            }
            if (agreements.current.length > 0) {
                setResponse(agreements.current[agreements.current.length - 1].seq);
            } else {
                setResponse(null);
            }
        }
    };

    const agreementSaveClick = () => {
        setSaveDialog(true);
    }

    const dataSet = (clientKey ,items) => {

        const list = [];

        items.forEach((item) => {
           list.push(item.seq);
        });

        return {
            clientKey : clientKey,
            agreementSeq : list
        }

    }

    const onClickCopy = (e, value) => {
        let textField = document.createElement('textarea');
        textField.innerText = value
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    return (
        <div className={classes.root}>
            <Card className={classes.cardContainer}>
                <CardContent className={classes.title}>
                    <Typography variant={"h5"}>
                        {t('ClientDetail.AgreementConfig.Title')}
                    </Typography>

                </CardContent>
                <CardContent className={classes.cardContent}>
                    <div className={classes.listContainer}>
                        <AgreementList items={leftList.current} handleToggle={handleToggle} checked={checked} side={"left"}/>
                        <div className={classes.buttonContent}>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                { isMobile ? '∨' : '>' }
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                { isMobile ? '∧' : '<' }
                            </Button>

                        </div>
                        <AgreementList items={rightList.current} handleToggle={handleToggle} checked={checked} side={"right"}/>
                    </div>
                </CardContent>
                <div className={classes.buttonContainer}>
                    <Button
                        color={'primary'}
                        variant="contained"
                        type={"button"}
                        disabled={!hasChanged}
                        onClick={resetAgreements}
                    >{t('ClientDetail.AgreementConfig.Reset')}</Button>
                    <Button color={'primary'}
                            variant="contained"
                            type={'button'}
                            onClick={agreementSaveClick}
                            disabled={!hasChanged}
                    >
                        {t('ClientDetail.AgreementConfig.Save')}
                    </Button>
                </div>
            </Card>

            {agreements.current.length !== 0 &&
                <Card className={`${classes.cardContainer}`} >
                    <CardHeader
                        className={classes.cardHeader}
                        avatar={
                            <Box display='flex' justifyContent='center' alignItems="center" className={classes.logo}>
                                <FnsvLogo/>
                            </Box>
                        }
                        title={(<Typography variant='h5'>Fns Value Co., Ltd</Typography>)}
                        subheader={(
                            <React.Fragment>
                                <Typography>
                                    <LinkIcon/>
                                    Public URL &nbsp;&nbsp;
                                    <a href={agreements.current[agreements.current.length - 1].publicUrl}>{agreements.current[agreements.current.length - 1].publicUrl}</a>
                                </Typography>
                                <Button
                                    color={'primary'}
                                    variant="contained"
                                    type={'button'}
                                    onClick={(e) => onClickCopy(e, agreements.current[agreements.current.length - 1].publicUrl)}>
                                    {t('ClientDetail.AgreementConfig.Detail.Copy')}
                                </Button>
                            </React.Fragment>)}
                    />
                    <CardContent className={`${classes.cardContent} ${classes.agreementContent}`}>
                        <Typography>{agreements.current[agreements.current.length - 1].title}</Typography>
                        <Typography color='textPrimary'
                                    dangerouslySetInnerHTML={{__html: agreements.current[agreements.current.length - 1].content}}/>
                    </CardContent>
                </Card>
            }
            <ConfirmDialog open={saveDialog}
                           onClose={() => saveOpenHandler(false)}
                           onOk={saveOkClick}
                           title={t('ClientDetail.AgreementConfig.ConfirmTitle')}
                           content={t('ClientDetail.AgreementConfig.SaveContent')}
            />
        </div>
    )
}


function AgreementList({items, handleToggle, checked, side}) {
    const classes = listStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    items.sort(function(a, b) {
        if ((a.customizable === 'false' && b.customizable !== 'false')
                || (a.customizable === undefined && b.customizable === 'true')) {
            return -1;
        } else if ((a.customizable === 'true' && b.customizable !== 'true')
                || (a.customizable === undefined && b.customizable === 'false')) {
            return 1;
        } else {
            return 0;
        }
    });

    const renderTrackVertical = () => {
        return (<div className={classes.track}/>)
    }

    const scrollThumb = () => {
        const thumbStyle = {
            height: '100vh',
            borderRadius: '5px',
            backgroundColor: '#b8bbbeb3'
        };
        return (
            <div style={thumbStyle}/>
        )
    }

    return (
        <Paper className={classes.paper} elevation={0}
               style={side === "left" ? {backgroundColor: '#F5F8FA', border: '1px solid #EFF2F5'} : {backgroundColor: '#3B3B56'}}>
            <Scrollbars style={{height: '100%', paddingTop: '5rem'}} autoHide
                        renderTrackVertical={renderTrackVertical}
                        renderThumbVertical={scrollThumb}>
                <List dense component="div" role="list">
                    {items.map((item, index) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        const languageText = t('CommonCode.' + item.lang);
                        // vendor client 약관은 `상위 상태를 따름` 설정이 있으면 바꿀 수 없는 스타일...
                        if (item.customizable === 'false') {
                            return (
                                <ListItem  alignItems="center" key={index} role="listItem" button onClick={handleToggle(item)}>
                                    <ListItemIcon>
                                        <LabelIcon/>
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`[${languageText}] ${item.title}`} />
                                </ListItem>
                            );
                        } else {
                            return (
                                <ListItem alignItems="center" key={index} role="listItem" button onClick={handleToggle(item)} disableRipple
                                          className={checked.indexOf(item) !== -1 && side === "left" ? classes.checkedItemLeft :
                                              side === "right" ? classes.checkedItemRight : undefined}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(item) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={`[${languageText}] ${item.title}`} />
                                </ListItem>
                            );
                        }
                    })}
                    <ListItem />
                </List>
            </Scrollbars>
        </Paper>
    )
}
