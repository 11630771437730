import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import fnsvAPI from "./fnsvAPI";

import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, {Navigation} from "swiper";

import {dataFormat} from "../../common/utils/StringFormatUtils";
import ReactPlayer from "react-player";
import {ErrCode} from "../../common/types";
import {useTranslation} from "react-i18next";
import Slider from "react-slick";
import {useMediaQuery, useTheme} from "@material-ui/core";
import {Arrows, DownArrow, LeftArrow, RightArrow, SimpleArrows, UpArrow} from "./FNSVCommon";

const useStyles = makeStyles((theme) => ({
    container: {
        width:'calc(100vw - 120px) !important',
        height:'calc(100vh - 61px) !important',
        color:'#fff',
        [theme.breakpoints.down('md')] :{
            width:'initial !important',
            height:'calc(100vh - 52px) !important',
        }
    },
    root         : {
        display         : 'flex'
        , flex          : 1
        , flexDirection : 'column'
        , alignItems    : 'center'
        , justifyContent: 'center'
        , height        : 'inherit'
        , background    : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec5/bg.jpg) no-repeat`
        ,backgroundSize:'cover'

    }
    , titleBox   : {
        textAlign     : 'center'
        , marginBottom: 80
        , '& h2'      : {
            fontWeight  : 700
            , fontSize  : 45
            , lineHeight: '68px'
            , margin    : 0
        }
        , '& p'       : {
            fontWeight     : 400
            , fontSize     : 21
            , lineHeight   : '32px'
            , letterSpacing: '-0.04em'
            , margin       : 0
        }
        , [theme.breakpoints.down('md')]: {
            marginBottom: 40
            , '& h2'    : {
                fontSize    : 32
                , lineHeight: '48px'
            }
            , '& p'     : {
                fontSize    : 12
                , lineHeight: '20px'
            }
        }
    }
    , content    : {
        display: 'flex'
        , [theme.breakpoints.down('md')]: {
            width: '100vw'
            , flexDirection   : 'column-reverse'
            , justifyContent: 'center'
            , alignItems    : 'center'
        }
    }
    , ThumbBox   : {
        position  : 'relative'
        , width   : 530
        , height  : 500
        , overflow: 'hidden'
        , [theme.breakpoints.down('md')]: {
            width   : '100%'
            , height  : '100px'
        }
    }
    , videoSwiper: {
        width                  : '100%'
        , height               : '100%'
        , '& > .swiper-wrapper': {
            '& > .swiper-slide': {
                display                                  : 'flex'
                , alignItems                             : 'center'
                , marginLeft                             : 30
                , '& > p'                                : {
                    width          : 250
                    , marginLeft   : 30
                    , fontSize     : 16
                    , lineHeight   : '24px'
                    , letterSpacing: '-0.04em'
                }
                , [theme.breakpoints.down('md')]         : {
                    marginLeft: 0
                    ,justifyContent:'center'
                    , '& > p' : {
                        width       : '100px'
                        , fontSize  : '12px'
                        , margin    : '0 0 0 10px'
                        , lineHeight: '14px'
                    }
                }

            }
            , '& > .swiper-slide.swiper-slide-active': {
                '& > div > div': {
                    display: 'flex'
                }
            }
        }
    }
    , control    : {

        '& div'                     : {
            [theme.breakpoints.up('lg')]: {
                width   : 16
                , height: 10

            }

        }
        , [theme.breakpoints.up('lg')]: {
            position                      : 'absolute'
            , top                         : 'calc(50% - 10px)'
            , right: 30
            , zIndex                      : 2
        }
    }

    , dim          : {
        position    : 'absolute'
        , top       : 'calc( 50% - (195px / 2))'
        , width     : 530
        , height    : 195
        , background: 'rgba(16, 28, 39, 0.85)'
        , border    : '1px solid #395F7D'
        , boxSizing : 'border-box'
        , [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
    , activeBox  : {
        position: 'relative'
    }
    , playbg     : {
        position        : 'absolute'
        , top           : 0
        , display:'none'
        , flexDirection : 'column'
        , width         : '100%'
        , height        : '100%'
        , alignItems    : 'center'
        , justifyContent: 'center'
        , background    : '#04070a69'
        , '& > p'       : {
            paddingTop  : 22
            , background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec5/ico_play.png) no-repeat 50% 0'
        }
    }
    , videoBox   : {
        display     : 'flex'
        , marginLeft: '-15px'
        , [theme.breakpoints.down('md')]: {
            position            : 'relative'
            , width             : '80%'
            , height            : '0'
            , margin            : '0 auto 90px'
            , paddingBottom     : '56.25%'
            , '& > div > iframe': {
                position: 'absolute'
                , top   : 0
                , left  : 0
            }
        }
    }
    , p_upArrow: {
        background    : 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec5/ico_arrow_up.png) no-repeat 50% 50%'
        , marginBottom: 20
    }
    , p_leftArrow: {
        left        : 20
        , background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec4/ico_arrow_left.png) no-repeat 0 50%'
    }
    , p_downArrow : {
        background : 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec5/ico_arrow_down.png) no-repeat 50% 50%'
        , marginTop: 20
    }
    , p_rightArrow: {
        right       : 20
        , background: 'url(https://resources.fnsvalue.co.kr/fnsv/images/sec4/ico_arrow_right.png) no-repeat 0 50%'
    }
}));

function videoIdHandler(url) {
    let _id = '';

    if (url !== null && url !== undefined && (url.includes("www.youtube.com") || url.includes("youtu.be"))) {
        let match = url.match(/.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/);
        if (match && match[1].length==11) _id = match[1];
    }

    return _id;
}

function ThumbBox(props) {
    const classes = useStyles();
    const {item} = props;
    const videoId = item?.url;

    const _id = videoIdHandler(videoId);

    return (
        <React.Fragment>
            <Box className={classes.activeBox}>
                <img src={`https://img.youtube.com/vi/${_id}/0.jpg`} alt={item.title} onClick={()=> onPlay(_id)} width={140} data-item={_id}/>
                <Box className={classes.playbg}>
                    <p>Play Now</p>
                </Box>
            </Box>
            <p>{ item.title }</p>
        </React.Fragment>
    )
}

function VideoBox(props) {
    const classes = useStyles();
    const { _id } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    return (
        <ReactPlayer url={`https://www.youtube.com/embed/${_id}`}
                     className={classes.videoBox}
                     width={ isMobile ? '90%' : 900}
                     height={ isMobile ? 'auto' : 530}
        />
    )
}




export default function VideoPage({name}){

    const _innerFlag = name !== undefined;

    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    const [data, setData] = useState();
    const [video, setVideo] = useState('');

    SwiperCore.use([Navigation]);

    const swiperRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const slickRef = useRef(null);

    useEffect( () => {
        let language = i18n.language === 'ko' ? "CMMLNG001" : "CMMLNG002";
        fnsvAPI.getHomepageData(96, language)
            .then(data => {
                setData(data.data);
                if( data.rtCode === ErrCode.RT_SUCCESS) {
                    swiperRef.current.swiper.slideTo(data.data.length);
                    setVideo( swiperRef.current.swiper.slides[swiperRef.current.swiper.activeIndex].dataset.videoid );
                }
            })
            .catch(err => {
                return err;
            });
    },[i18n.language]);

    return (
        <section className={`${classes.root} ${_innerFlag && classes.container}`}>

            <Box className={classes.titleBox}>
                <h2>{t('Fnsv.Video.Title')}</h2>
                <p>{t('Fnsv.Video.Description')}</p>
            </Box>

            <Box className={classes.content}>
                <Box className={classes.ThumbBox}>

                    <Swiper
                        ref={swiperRef}
                        className={classes.videoSwiper}
                        slidesPerView={(isMobile ? '1' : '3')}
                        centeredSlides={true}
                        direction={'vertical'}
                        loop={true}
                        navigation={{
                            prevEl: prevRef.current ? prevRef.current : undefined,
                            nextEl: nextRef.current ? nextRef.current : undefined,
                        }}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.navigation.update();
                        }}
                        onActiveIndexChange={ (swiper) => {
                            setVideo( swiper.slides[swiper.activeIndex].dataset.videoid );
                        }}
                    >
                        {
                            data?.map( (item, index) => (
                                <SwiperSlide key={index} data-videoid={videoIdHandler(item.url)}>
                                    <ThumbBox item={item} />
                                </SwiperSlide>
                            ))
                        }


                        <Box className={classes.control}>
                            <SimpleArrows forwardRef={prevRef}  clazz={ isMobile ? classes.p_leftArrow : classes.p_upArrow }  />
                            <SimpleArrows forwardRef={nextRef} clazz={ isMobile ? classes.p_rightArrow : classes.p_downArrow } />
                        </Box>


                        <Box className={classes.dim}></Box>
                    </Swiper>



                </Box>

                <VideoBox _id={video}/>
            </Box>

        </section>
    )
}
