import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../../components/DataTable';
import api from "../../common/api";
import {Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {CommonCodeValue, UserStatus} from "../../common/types";
import queryString from 'query-string';
import {useStores} from "../../common/store";
import moment from "moment";
import _ from "lodash";
import AccessClientSelectBox from "../../components/AccessClientSelectBox";
import Box from "@material-ui/core/Box";
import SelectBox from "../../components/SelectBox";

const useStyles = makeStyles((theme) => ({
    pageContent: {
        margin: '40px 170px 60px 170px',
        padding: '35px 40px',
        backgroundColor: '#FFF',
        borderRadius: 6,
        [theme.breakpoints.between('xs', 'md')]: {
            margin: 20
        }
    },
    conditionDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            '& > *, & .client-select': {
                width: '100% !important',
                maxWidth: '100% !important'
            }
        }
    },
    selectBox: {
        ...theme.select,
        minWidth: 200
    },
    button: {
        minWidth: 140,
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    table: {
        '& .MuiTableRow-root': {
            '& > *': {
                wordBreak: 'break-all',
                [theme.breakpoints.between('xs', 'md')]: {
                    wordBreak: 'keep-all'
                }
            },
            '& > :nth-child(1)': {
                width: 100
            },
            '& > :nth-child(2)': {
                width: 300
            },
            '& > :nth-child(3)': {
                width: 200
            },
            '& > :nth-child(4)': {
                width: 300
            },
            '& > :nth-child(n+5)': {
                width: 160
            }
        }
    }
}));

export default function UserListPage(props) {
    const pageSize = 5;
    const {location, setSearch} = props;
    const {ds} = useStores();
    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const query = queryString.parse(location.search);
    let page = query['page'] !== undefined ? Number(query['page']) : 1;
    let keyword = query['keyword'] !== undefined ? query['keyword'] : null;
    let clientKey = query['clientKey'] !== undefined ? query['clientKey'] : 'ALL';
    let userStatus = query['userStatus'] !== undefined ? query['userStatus'] : 'ALL';

    const selectClient = useRef(clientKey);
    const selectStatus = useRef(userStatus);

    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);

    const changedSearchKeywordEvent = (value) => {
        keyword = value;
    }

    const onChangeSelect = (type, value) => {
        if (type === 'client') {
            selectClient.current = value;
        } else if (type === 'status') {
            selectStatus.current = value;
        }
        search();
    }

    const rowClickEvent = (row) => (event) => {
        setEvent({type: 'detail', clientName: row[1]?.content, clientKey: row[7]?.content, userKey: row[8]?.content});
    }

    const search = (event, page = 1) => {
        const query = {
            clientKey: selectClient.current,
            userStatus: selectStatus.current,
            keyword: keyword,
            page: page
        };
        props.history.push('/admin/users?' + api.encodeParams(query, true));
    }

    const fetchData = (query) => {
        const status = query.userStatus !== 'ALL' ? query.userStatus : null;
        api.getUserList(query.clientKey, query.keyword, status, query.page - 1, pageSize, 'REG_DT,DESC')
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                setResponse(null);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    useEffect(() => {
        fetchData({clientKey, userStatus, keyword, page});
        if (setSearch) {
            setSearch(
                {
                    placeholder: t('UserPage.SearchPlaceholder'),
                    doQuery: search,
                    onChange: changedSearchKeywordEvent
                }
            );
        }
    }, [location.search, i18n.language]);


    if (event?.type === 'detail') {
        return (
            <Redirect push to={{
                pathname: '/admin/user',
                search: "?" + api.encodeParams({clientKey: event.clientKey, userKey: event.userKey}),
                state: api.encodeParams({clientName: event.clientName})
            }}/>
        );
    }
    return (
        <Box className={classes.pageContent}>
            <ConditionPanel onChangeSelect={onChangeSelect} />
            <ResultTable response={response}
                         search={search}
                         currentPage={page}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
        </Box>
    );
}

function ConditionPanel({onChangeSelect}) {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.conditionDiv}>
            <AccessClientSelectBox className={`${classes.selectBox} ${"client-select"}`}
                             title={t('UserPage.Client')}
                             onChangeSelect={onChangeSelect}
                             addAll/>
            <StatusSelectBox className={classes.selectBox}
                             title={t('UserPage.UserStatus')}
                             onChangeSelect={onChangeSelect}
                             addAll/>
        </div>
    );
}

function StatusSelectBox({className, title, onChangeSelect}) {
    const {t} = useTranslation();
    const statusArray = [];

    if (statusArray.length === 0) {
        Object.keys(UserStatus).map((key, index) => {
            statusArray[index] = {key: key, value: UserStatus[key], text: t(CommonCodeValue.translateKey + '.' + UserStatus[key])};
        })
    }

    const onChangeStatus = (event) => {
        const statusCode = event.target.value;

        if (onChangeSelect) {
            onChangeSelect('status', statusCode);
        }
    }

    return (
        <SelectBox className={className}
                   title={title}
                   displayEmpty={false}
                   contents={statusArray}
                   onChange={onChangeStatus}
                   addAll
        />
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage, pageSize}) {
    const {t} = useTranslation();
    const style = useStyles();
    const body = [];
    const head = [
        [
            {content: ''},
            {content: t('UserPage.Client')},
            {content: t('UserPage.UserID')},
            {content: t('UserPage.Email')},
            {content: t('UserPage.PhoneNumber')},
            {content: t('UserPage.Status')},
            {content: t('UserPage.Date')}
        ]
    ];

    if (response !== null) {
        let num = response.page.currentPage * pageSize;

        response.data.map((data, idx) => {
            let clientName = data.clientName ? data.clientName : '-';
            let userId = data.userKey ? data.userKey : '-';
            let userEmail = data.email ? data.email : '-';
            let userPhone = data.phoneNum ? data.phoneNum : '-';
            let userStatus = data.userStatus ? t(CommonCodeValue.translateKey + '.' + data.userStatus) : '-';
            let userDate = data.regDt ? _dataFormat(data.regDt, 'date') : '-';

            body[idx] = [
                {id: 'index', content: ++num},
                {id: 'clientName', content: clientName},
                {id: 'userId', content: userId},
                {id: 'userEmail', content: userEmail},
                {id: 'userPhone', content: userPhone},
                {id: 'status', content: userStatus},
                {id: 'date', content: userDate},
                {id: 'clientKey', content: data.clientKey},
                {id: 'userKey', content: data.userKey}
            ];

        });
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   style={style}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}


function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}

