import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box , useMediaQuery} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        color: '#fff',
        [theme.breakpoints.down('md')]: {
            width: 'initial !important',
            height: 'calc(100vh - 52px) !important',
        },
    },
    contact: {
        display: 'flex',
        flex: 1,
        height: 'inherit',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#0F1014',
        backgroundSize: 'cover',
    },
    securityPolicyBox: {
        width: '100%',
        height: '100%',
        textAlign: 'left',
        '& > h2': {
            fontWeight: '700',
            fontSize: '32px',
            lineHeight: '68px',
            marginBottom: 20,
        },
        '& > img': {
            display: 'block',
            margin: '0 auto', // Center align the image
            maxWidth: '100%', // Set a maximum width to fill the container
            maxHeight: '100%', // Set a maximum height to fill the container
            width: 'auto',
            height: 'auto',
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center', // Center align content on mobile
            width: 'calc(100% - 40px)', // Adjust width to center align on mobile
            height: 'calc(80% - 73px)',
            '& > h2': {
                fontSize: '30px',
                lineHeight: '48px',
            },
            '& > img': {
                display: 'block',
                margin: '0 auto', // Center align the image
                maxWidth: '100%', // Set a maximum width to fill the container
                maxHeight: '70%', // Set a maximum height to maintain aspect ratio
                width: '100%',
                height: 'auto',
            },
        },
    },
}));

const SecurityPolicyForm = () => {
    const classes = useStyles();

    return (
        <div className={`${classes.contact} ${classes.container}`}>
            <Box className={classes.securityPolicyBox}>
                <h2>정보보안방침</h2>
                <img
                    src={`${require('../../images/fnsv/privacyPolicy/privacy_main.jpg')}`}
                    alt="정보보안방침"
                />
            </Box>
        </div>
    );
};

export default SecurityPolicyForm;

