import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Box, useMediaQuery, useTheme} from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fnsvAPI from "./fnsvAPI";
import {useTranslation} from "react-i18next";
import {LeftArrow, RightArrow} from "./FNSVCommon";


const useStyles = makeStyles((theme) => ({
    container     : {
        width                         : 'calc(100vw - 120px) !important',
        height                        : 'calc(100vh - 61px) !important',
        color                         : '#fff',
        [theme.breakpoints.down('md')]: {
            width : 'initial !important',
            height: 'calc(100vh - 52px) !important',
        }
    },
    patent        : {
        display         : 'flex'
        , flex          : 1
        , height        : 'inherit'
        , flexDirection : 'column'
        , alignItems    : 'center'
        , justifyContent: 'center'
        , background    : `url(https://resources.fnsvalue.co.kr/fnsv/images/sec6/bg.jpg) no-repeat`
        , backgroundSize: 'cover'
    }
    , titleBox    : {
        '& h2': {
            fontSize                        : 45
            , lineHeight                    : '68px'
            , margin                        : 0
            , [theme.breakpoints.down('md')]: {
                fontSize    : 32
                , lineHeight: '40px'
            }
        }
    }
    , descBox     : {
        display                         : 'flex'
        , '& > div'                     : {
            width                 : 600
            , '& > p:nth-child(1)': {
                marginTop      : 50
                , marginBottom : 15
                , fontSize     : 21
                , lineHeight   : '32px'
                , letterSpacing: '-0.04em'
            }
            , '& > p:nth-child(2)': {
                fontSize       : 15
                , lineHeight   : '30px'
                , letterSpacing: '-0.04em'
                , color        : '#93A5C8'
                , margin       : 0
                , whiteSpace   : 'pre-wrap'
            }
        }
        , '& > div:nth-child(2)'        : {
            width       : 'auto'
            , marginLeft: 150
        }
        , [theme.breakpoints.down('md')]: {
            display: 'none'
        }

    }
    , contentBox  : {
        width                           : 1000
        , marginTop                     : 80
        , [theme.breakpoints.down('md')]: {
            width      : '100vw'
            , marginTop: 40
        },
        '& .slick-slider .slick-track' :{
            display:'flex',
            alignItems:'center'
        }

    }
    , itemBox     : {
        display         : 'flex !important'
        , justifyContent: 'center'
        , width         : 300
        , margin        : 0
        , padding       : 0
        , cursor        : 'pointer'
        , '& > img'     : {
            maxWidth   : 217
            , maxHeight: 277
        }
    }
    , p_leftArrow : {
        left: 20
    }
    , p_rightArrow: {
        right: 20
    }
}));


export default function PatentPage({name}) {
    const _innerFlag = name !== undefined;
    const classes = useStyles();
    const [patentData, setPatentData] = useState();
    const patentSliderRef = useRef();
    const {t, i18n} = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    useEffect(() => {
        let language = i18n.language === 'ko' ? "CMMLNG001" : "CMMLNG002";
        fnsvAPI.getHomepageData(97, language)
            .then(data => {
                setPatentData(data.data[0].images);
            })
            .catch(err => {
                return err;
            });
    }, [i18n.language]);

    const settings = {
        dots            : false
        , arrows        : true
        , infinite      : true
        , lazyLoad      : true
        , draggable     : true
        , autoPlay      : true
        , autoPlaySpeed : 2500
        , slidesToShow  : isMobile ? 1 : 4
        , slidesToScroll: isMobile ? 1 : 4
        , prevArrow     : <LeftArrow clazz={isMobile && classes.p_leftArrow}/>
        , nextArrow     : <RightArrow clazz={isMobile && classes.p_rightArrow}/>
    };

    return (
        <section className={`${classes.patent} ${_innerFlag && classes.container}`}>

            <Box className={classes.titleBox}>
                <h2>{t('Fnsv.Company.Patent.Title')}</h2>
            </Box>

            <Box className={classes.descBox}>
                <Box>
                    <p>{t('Fnsv.Company.Patent.Patent.Title')}</p>
                    <p>
                        {
                            Array.from(t('Fnsv.Company.Patent.Patent.Contents')).map((content, index) => {
                                return (<React.Fragment key={index}>{content}<br/></React.Fragment>)
                            })
                        }
                    </p>
                </Box>
                <Box>
                    <p>{t('Fnsv.Company.Patent.Certification.Title')}</p>
                    <p>
                        {
                            Array.from(t('Fnsv.Company.Patent.Certification.Contents')).map((content, index) => {
                                return (<React.Fragment key={index}>{content}<br/></React.Fragment>)
                            })
                        }
                    </p>
                </Box>
            </Box>

            <Box className={classes.contentBox}>
                <Slider ref={patentSliderRef} {...settings}>
                    {
                        patentData?.map((item, index) => (
                            <Box key={index} className={classes.itemBox}>
                                <img src={item.encodingSrc} alt={item.originalName}/>

                            </Box>
                        ))
                    }
                </Slider>

            </Box>

        </section>
    )
}