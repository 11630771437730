import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../../../components/DataTable';
import api from "../../../common/api";
import {Redirect} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {CommonCodeValue, UserType, UserStatus} from "../../../common/types";
import {useStores} from "../../../common/store";
import moment from "moment";
import _ from "lodash";
import SearchBox from "../../../components/SearchBox";
import SelectBox from "../../../components/SelectBox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        padding: '35px 40px',
        borderRadius: 6
    },
    conditionDiv: {
        ...theme.typography.body1,
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    selectDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
        [theme.breakpoints.between('xs', 'md')]: {
            width: '100%',
            '& > *': {
                flex: 1,
                maxWidth: '100% !important'
            }
        }
    },
    selectBox: {
        ...theme.typography.body1,
        ...theme.select,
        minWidth: '200px'
    },
    searchBox: {
        height: 40,
        minWidth: '20rem',
        [theme.breakpoints.between('xs', 'md')]: {
            minWidth: 200,
            flex: 1
        }
    },
    table: {
        '& .MuiTableRow-root': {
            '& > :nth-child(1)': {
                width: 200,
                minWidth: 150,
                whiteSpace: 'nowrap'
            },
            '& > :nth-child(2)': {
                width: 300
            },
            '& > :nth-child(3)': {
                width: 300
            },
            '& > :nth-child(4)': {
                width: 250
            },
            '& > :nth-child(5)': {
                width: 250
            }
        }
    },
    typeTag: {
        display: "flex",
        justifyContent: "center",
        '& > p': {
            fontWeight: 500,
            height: 34,
            padding: '0 20px',
            lineHeight: '34px',
            borderRadius: '17px'
        }
    },
    type1: {
        color: theme.palette.tag.blue,
        backgroundColor: theme.palette.tag.lightBlue,
    },
    type2: {
        color: theme.palette.tag.green,
        backgroundColor: theme.palette.tag.lightGreen,
    },
    type3: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.tag.purple,
    },
}));


export default function ManagerListPage(props) {
    const pageSize = 5;
    const {clientKey, clientName} = props;
    const classes = useStyles();
    const {ds} = useStores();

    let page = 1;
    const [keyword, setKeyword] = useState(null);
    let userType = 'ALL';
    let userStatus = 'ALL';

    const selectType = useRef(userType);
    const selectStatus = useRef(userStatus);

    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);

    const changedSearchKeywordEvent = (value) => {
        setKeyword(value);
    }

    const onChangeSelect = (type, value) => {
        if (type === 'type') {
            selectType.current = value;
        } else if (type === 'status') {
            selectStatus.current = value;
        }
        search();
    }

    const rowClickEvent = (row) => (event) => {
        setEvent({type: 'detail', clientName: clientName, clientKey: row[6]?.content, userKey: row[7]?.content});
    }

    const search = (event, page = 1) => {
        const type = selectType.current !== 'ALL' ? selectType.current : null;
        const status = selectStatus.current !== 'ALL' ? selectStatus.current : null;
        api.getManagerList(clientKey, keyword, type, status, page - 1, pageSize, 'REG_DT,DESC')
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                setResponse(null);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    const fetchData = (query) => {
        const type = query.userType !== 'ALL' ? query.userType : null;
        const status = query.userStatus !== 'ALL' ? query.userStatus : null;
        api.getManagerList(clientKey, query.keyword, type, status, query.page - 1, pageSize, 'REG_DT,DESC')
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                setResponse(null);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    useEffect(() => {
        fetchData({clientKey, userType, userStatus, keyword, page});
    }, [location.search]);


    if (event?.type === 'detail') {
        return (
            <Redirect push to={{
                pathname: '/admin/user/detail',
                search: "?" + api.encodeParams({clientKey: event.clientKey, userKey: event.userKey}),
                state: api.encodeParams({clientName: event.clientName})
            }}/>
        );
    }
    
    return (
        <div className={classes.root}>
            <ConditionPanel keyword={keyword}
                            defaultClientKey={clientKey}
                            defaultType={userType}
                            defaultStatus={userStatus}
                            search={search}
                            onChangeSelect={onChangeSelect}
                            changedSearchKeywordEvent={changedSearchKeywordEvent}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={response !== null ? response.page.currentPage + 1 : 1}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
        </div>
    );
}

function ConditionPanel({keyword, defaultType, defaultStatus, search, onChangeSelect, changedSearchKeywordEvent}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {ds} = useStores();
    const currentUserType = ds.user.userType;

    return (
        <div className={classes.conditionDiv}>
            <div className={classes.selectDiv}>
                {UserType.isClientManager(currentUserType) ? null :
                    <TypeSelectBox className={classes.selectBox}
                    title={t('UserPage.UserType')}
                    defaultType={defaultType}
                    onChangeSelect={onChangeSelect}
                    currentUserType={currentUserType}/>
                }
                <StatusSelectBox className={classes.selectBox}
                                 title={t('UserPage.UserStatus')}
                                 defaultStatus={defaultStatus}
                                 onChangeSelect={onChangeSelect}
                                 addAll/>
            </div>
            <SearchBox
                className={classes.searchBox}
                value={keyword}
                placeholder={t('UserPage.SearchPlaceholder')}
                doQuery={search}
                onChange={changedSearchKeywordEvent}
            />
        </div>
    );
}

function TypeSelectBox({className, title, defaultType, onChangeSelect, currentUserType}) {
    const {t} = useTranslation();
    const typeArray = [];

    if (typeArray.length === 0) {
        Object.keys(UserType).map((key, index) => {
            if (UserType.isManager(UserType[key])) {
                if (UserType[key] >= currentUserType) {
                    typeArray[index] = {key: key, value: UserType[key], text: t(CommonCodeValue.translateKey + '.' + UserType[key])};
                }
            }
        })
    }

    const onChangeType = (event) => {
        const typeCode = event.target.value;

        if (onChangeSelect) {
            onChangeSelect('type', typeCode);
        }
    }

    return (
        <SelectBox className={className}
                   title={title}
                   displayEmpty={false}
                   defaultValue={defaultType}
                   contents={typeArray}
                   onChange={onChangeType}
                   addAll
        />
    );
}

function StatusSelectBox({className, title, defaultStatus, onChangeSelect}) {
    const {t} = useTranslation();
    const statusArray = [];

    if (statusArray.length === 0) {
        Object.keys(UserStatus).map((key, index) => {
            statusArray[index] = {key: key, value: UserStatus[key], text: t(CommonCodeValue.translateKey + '.' + UserStatus[key])};
        })
    }

    const onChangeStatus = (event) => {
        const statusCode = event.target.value;

        if (onChangeSelect) {
            onChangeSelect('status', statusCode);
        }
    }

    return (
        <SelectBox className={className}
                   title={title}
                   displayEmpty={false}
                   defaultValue={defaultStatus}
                   contents={statusArray}
                   onChange={onChangeStatus}
                   addAll
        />
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage, pageSize}) {
    const {t} = useTranslation();
    const style = useStyles();
    const body = [];
    const head = [
        [
            {content: t('UserPage.UserType')},
            {content: t('UserPage.UserID')},
            {content: t('UserPage.Email')},
            {content: t('UserPage.PhoneNumber')},
            {content: t('UserPage.UserStatus')},
            {content: t('UserPage.Date')}
        ]
    ];

    if (response !== null) {
        let num = response.page.currentPage * pageSize;

        response.data.map((data, idx) => {
            let userId = data.userKey ? data.userKey : '-';
            let userType = data.userType ? t(CommonCodeValue.translateKey + '.' + data.userType) : '-';
            let userEmail = data.email ? data.email : '-';
            let userPhone = data.phoneNum ? data.phoneNum : '-';
            let userStatus = data.userStatus ? t(CommonCodeValue.translateKey + '.' + data.userStatus) : '-';
            let uptDt = data.uptDt ? _dataFormat(data.uptDt, 'date') : '-';

            body[idx] = [
                {id: 'type', content: (
                    <Box className={style.typeTag}>
                        <Typography
                            className={
                                userType === t("CommonCode.CMMMCL001") ? style.type1 :
                                    userType === t("CommonCode.CMMMCL002") ? style.type2 : style.type3
                            }
                            variant="body1">
                            {userType}
                        </Typography>
                    </Box>
                    )},
                {id: 'userId', content: userId},
                {id: 'userEmail', content: userEmail},
                {id: 'userPhone', content: userPhone},
                {id: 'status', content: userStatus},
                {id: 'date', content: uptDt},
                {id: 'clientKey', content: data.clientKey},
                {id: 'userKey', content: data.userKey}
            ];
        });
    }

    const pageChangeEvent = (event, pageIndex, content) => {
        search(null, pageIndex);
    };

    return (
        <DataTable headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   style={style}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}


function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(new Date(data)).format(dateFormat)
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}