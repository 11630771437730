import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DataTable from '../../components/DataTable';
import api from "../../common/api";
import {useTranslation} from "react-i18next"
import {CommonCodeValue, ErrCode, UserStatus} from "../../common/types";
import queryString from 'query-string';
import {useStores} from "../../common/store";
import moment from "moment";
import _ from "lodash";
import Box from "@mui/material/Box";
import SelectBox from "../../components/SelectBox";
import SearchBox from "../../components/SearchBox";
import {useObserver} from "mobx-react-lite";
import {Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup} from "@mui/material";
import Typography from "@material-ui/core/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@material-ui/core/Button";
import storage, {StorageKey as SK} from "../../common/storage";
import ConfirmDialog from "../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    conditionDiv: {
        display: 'flex',
        height: 40,
        marginBottom: theme.spacing(2),
        gap: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            height: 'auto',
            '& > *': {
                flex: 1,
                width: '100% !important',
                maxWidth: '100% !important'
            }
        }
    },
    selectBox: {
        ...theme.select,
        minWidth: 200
    },
    table: {
        '& .MuiTableRow-root': {
            '& > :nth-child(-n+4)': {
                width: 250,
                wordBreak: 'break-all',
                [theme.breakpoints.between('xs', 'md')]: {
                    wordBreak: 'keep-all'
                }
            },
            '& > :nth-child(5)': {
                width: 200
            },
            '& > :nth-child(6)': {
                width: 150
            }
        }
    },
}));

const dialogStyle = makeStyles((theme) => ({
    dialog: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        '& .css-1jh78f5-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody': {
            backgroundColor: theme.palette.secondary.main,
            border: '1px solid #171E31',
            borderRadius: 5,
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.35)'
        },
        [theme.breakpoints.between('xs', 'md')]: {
            '& .MuiDialog-paper' : {
                maxWidth: '95vw'
            }
        }
    },
    dialogHeader: {
        backgroundColor: theme.palette.secondary.main,
        height: 60,
        '& .css-i4bv87-MuiSvgIcon-root': {
            width: 27,
            height: 25
        }
    },
    dialog_content: {
        padding: "15px 30px 30px 30px !important",
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    title: {
        ...theme.typography.h5,
        color: theme.palette.text.white
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        "& > *": {
            color: theme.palette.text.primary,
        },
        '& > div > *': {
            minWidth: 130
        },
        [theme.breakpoints.down('xs')]: {
            overflowX: 'auto'
        }
    },

    infoBox: {
        display: 'flex',
        width: '100%',
        flex: 1,
        '& > :first-child': {
            color: '#555',
            fontSize: '0.875rem',
            margin: '10px 0'
        },
        '& div': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            minHeight: 40,
            '& > dl': {
                display: 'flex',
                alignItems: 'center',
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                '& > *': {
                    fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
                    fontSize: '0.688rem',
                    [theme.breakpoints.between('xs', 'md')]: {
                        fontSize: '0.588rem',
                    }
                },
                '& > dt': {
                    color: theme.palette.primary.main,
                    fontWeight: 700
                },
                '& > dd': {
                    color: '#555',
                    wordBreak: 'break-word',
                    marginRight: theme.spacing(1),
                    marginInlineStart: theme.spacing(1),
                }
            },
            [theme.breakpoints.down('sm')]: {
                height: 'fit-content'
            }
        }
    },
    deviceDetail: {
        display: 'flex',
        width: '100%',
        flex: 1,
        overflowX: 'scroll',
        '& > :first-child': {
            color: '#555',
            fontSize: 14,
            margin: '10px 0'
        },
        '& > div > :last-child': {
            marginRight: 0
        },
        [theme.breakpoints.down('sm')]: {
            overflowX: 'unset',
        }
    },
    deviceInfo: {
        flexDirection: 'column !important',
        padding: '15px !important',
        borderRadius: 5,
        width: 300,
        marginRight: 10,
        backgroundColor: '#F2F1F8',
        '& > :first-child > dd': {
            maxWidth: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
            whiteSpace: 'nowrap'
        }
    },
    clientDetail: {
        '& > table': {
            borderSpacing: 0
        },
        '& > table > * > tr': {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            minHeight: 40,
            borderBottom: '1px solid #DBDADA',
            backgroundColor: '#F2F1F8',
            padding: '0 10px',
            '& > th': {
                color: theme.palette.primary.main,
                fontSize: '0.688rem',
                fontWeight: 700,
                [theme.breakpoints.between('xs', 'md')]: {
                    fontSize: '0.588rem',
                }
            },
            '& > td': {
                fontSize: '0.688rem',
                color: '#555',
                wordBreak: 'break-word',
                marginRight: theme.spacing(1),
                [theme.breakpoints.between('xs', 'md')]: {
                    '& > p': {
                        fontSize: '0.588rem'
                    }
                }
            },
            '& > *': {
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
                '& > *': {
                    fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif']
                }
            }
        },
        '& > table > tbody': {
            display: 'grid',
            maxHeight: 120,
            overflowY: 'overlay',
            '& > :last-child': {
                borderBottom: 'none'
            }
        }
    },
    userDetail: {
        '& > div': {
            borderBottom: '1px solid #DBDADA',
            backgroundColor: '#F2F1F8',
            padding: '0 10px',
            '& > dl': {
                minWidth: 295,
                flex: 1,
                [theme.breakpoints.down('sm')]: {
                    minWidth: 200
                },
                [theme.breakpoints.down('xs')]: {
                    minWidth: 150
                }
            }
        },
        '& > :last-child': {
            borderBottom: 'none',
        }
    },

    checkBoxes: {
        display: "flex",
        flexDirection: 'row !important',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
        margin: '15px 0',
        '& > *': {
            justifyContent: 'center',
            '& > :last-child': {
                width: 100
            }
        },
        '& span': {
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif'],
            fontWeight: 400,
            fontSize: '0.75rem',
        },
        '& .MuiCheckbox-root.Mui-checked': {
            color: theme.palette.secondary.main
        }
    },
    actionBtn: {
        '& > *': {
            width: 120,
            height: 40
        },
        '& > :first-child': {
            ...theme.buttons.secondary,
            marginRight: 6
        },
        '& > :last-child': {
            ...theme.buttons.primary
        },
        '& .MuiButton-root.Mui-disabled': {
            backgroundColor: 'rgba(170, 170, 170, 0.3)',
            color: '#7E7E88',
        }
    }
}));

export default function UserManagementPage() {
    const pageSize = 5;
    const {ds} = useStores();
    const {t, i18n} = useTranslation();
    const currentUser = ds.user.userKey;

    const query = queryString.parse(location.search);
    let page = query['page'] !== undefined ? Number(query['page']) : 1;
    let userStatus = query['userStatus'] !== undefined ? query['userStatus'] : 'ALL';
    const [keyword, setKeyword] = useState(null);
    const selectStatus = useRef(userStatus);
    const [response, setResponse] = useState(null);

    const [openDialog, setOpenDialog] = useState(false);
    let dialogContent = useRef(null);

    const [deleteUser, setDeleteUser] = useState(true);
    const [deleteDevice, setDeleteDevice] = useState(true);

    let confirmMessage = deleteUser ? t('UserManagement.ConfirmUser') : t('UserManagement.ConfirmDevice');

    const onChangeValue = (event, type) => {
        const value = event.target.checked;
        if (type === 'user') {
            if (value) {
                setDeleteUser(value);
                setDeleteDevice(value);
            } else {
                setDeleteUser(value);
            }
        } else if (type === 'device') {
            setDeleteDevice(value);
            if (!value && deleteUser) {
                setDeleteUser(false)
            }
        }
    }

    const onCloseDialog = () => {
        setOpenDialog(false);
        setDeleteUser(true);
        setDeleteDevice(true);
    }

    const changedSearchKeywordEvent = (value) => {
        setKeyword(value);
    }

    const onChangeSelect = (value) => {
        selectStatus.current = value;
        search();
    }

    const rowClickEvent = (row) => () => {
        setOpenDialog(true);
        if (row[8] !== undefined) {
            dialogContent.current = row[8].content;
        }
    }

    const logoutHandler = () => {
        storage.removeSession(SK.ACCESS_TOKEN);
        window.location.reload(false);
    }

    const search = (event, page = 1) => {
        const query = {
            userStatus: selectStatus.current,
            keyword: keyword,
            page: page
        };
        fetchData(query);
    }

    const fetchData = (query) => {
        const status = query.userStatus !== 'ALL' ? query.userStatus : null;
        api.getUserManagementPage(query.keyword, status, query.page - 1, pageSize, 'REG_DT,DESC')
            .then(data => {
                setResponse(data);
            })
            .catch(err => {
                setResponse(null);
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    useEffect(() => {
        fetchData({userStatus, keyword, page});
    }, [location.search, i18n.language]);

    const [openConfirm, setOpenConfirm] = useState(false);

    const onOpenConfirm = () => {
        setOpenConfirm(true)
    }

    function onCloseConfirm() {
        setOpenConfirm(false)
    }

    const doReload = () => {
        const query = {
            userStatus: selectStatus.current,
            keyword: keyword,
            page: page
        };
        fetchData(query);
    }

    function onDeleteConfirm() {

        api.deleteUser(dialogContent.current?.userKey, deleteUser, deleteDevice)
            .then((data) => {
                if (data.rtCode === ErrCode.RT_SUCCESS) {
                    if (dialogContent.current?.userKey === currentUser) {
                        logoutHandler();
                    }
                    doReload();
                    ds.showInfoAlert(t('UserManagement.DeleteSuccess'));
                }
            })
            .catch((err) => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });

        onCloseConfirm();
        onCloseDialog();
    }

    return (
        <Box width={'100%'}>
            <ConditionPanel onChangeSelect={onChangeSelect}
                            keyword={keyword}
                            search={search}
                            changedSearchKeywordEvent={changedSearchKeywordEvent}
            />
            <ResultTable response={response}
                         search={search}
                         currentPage={response !== null ? response.page.currentPage + 1 : 1}
                         totalPage={response !== null ? response.page?.totalPages : 0}
                         pageSize={pageSize}
                         rowClickEvent={rowClickEvent}
            />
            <UserManagementDialog open={openDialog}
                                  onCloseEvent={onCloseDialog}
                                  onConfirm={onOpenConfirm}
                                  onChange={onChangeValue}
                                  content={dialogContent}
                                  userCheck={deleteUser}
                                  deviceCheck={deleteDevice}
            />
            <ConfirmDialog open={openConfirm}
                           onClose={onCloseConfirm}
                           onOk={onDeleteConfirm}
                           title={t('UserManagement.ConfirmTitle')}
                           content={confirmMessage}
            />
        </Box>
    );
}

function ConditionPanel({onChangeSelect, keyword, search, changedSearchKeywordEvent}) {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.conditionDiv}>
            <StatusSelectBox className={classes.selectBox}
                             title={t('UserManagement.UserStatus')}
                             onChangeSelect={onChangeSelect}
                             addAll/>
            <SearchBox
                value={keyword}
                placeholder={t('UserManagement.SearchPlaceholder')}
                doQuery={search}
                onChange={changedSearchKeywordEvent}
            />
        </div>
    );
}

function StatusSelectBox({className, title, onChangeSelect}) {
    const {t} = useTranslation();
    const statusArray = [];

    if (statusArray.length === 0) {
        Object.keys(UserStatus).map((key, index) => {
            statusArray[index] = {
                key: key,
                value: UserStatus[key],
                text: t(CommonCodeValue.translateKey + '.' + UserStatus[key])
            };
        })
    }

    const onChangeStatus = (event) => {
        if (onChangeSelect) {
            onChangeSelect(event.target.value);
        }
    }

    return (
        <SelectBox className={className}
                   title={title}
                   displayEmpty={false}
                   contents={statusArray}
                   onChange={onChangeStatus}
                   addAll
        />
    );
}

function ResultTable({response, search, rowClickEvent, currentPage, totalPage}) {
    const {t} = useTranslation();
    const style = useStyles();
    const body = [];
    const head = [
        [
            {content: t('UserManagement.UserID')},
            {content: t('UserManagement.UserName')},
            {content: t('UserManagement.Email')},
            {content: t('UserManagement.PhoneNumber')},
            {content: t('UserManagement.Status')},
            {content: t('UserManagement.Date')}
        ]
    ];

    if (response !== null) {

        response.data.map((data, idx) => {
            let userName = data.name ? data.name : '-';
            let userId = data.userKey ? data.userKey : '-';
            let userEmail = data.email ? data.email : '-';
            let userPhone = data.phoneNum ? data.phoneNum : '-';
            let userStatus = data.userStatus ? t(CommonCodeValue.translateKey + '.' + data.userStatus) : '-';
            let userDate = data.regDt ? _dataFormat(data.regDt, 'date') : '-';

            body[idx] = [
                {id: 'userId', content: userId},
                {id: 'userName', content: userName},
                {id: 'userEmail', content: userEmail},
                {id: 'userPhone', content: userPhone},
                {id: 'status', content: userStatus},
                {id: 'date', content: userDate},
                {id: 'clientKey', content: data.clientKey},
                {id: 'userKey', content: data.userKey},
                {id: 'data', content: data}
            ];

        });
    }

    const pageChangeEvent = (event, pageIndex) => {
        search(null, pageIndex);
    };

    return (
        <DataTable headContent={head}
                   bodyContent={body}
                   currentPage={currentPage}
                   totalPage={totalPage}
                   emptyText={t('Error.NoData')}
                   onChangePageEvent={pageChangeEvent}
                   oncClickRowEvent={rowClickEvent}
                   style={style}
        />
    );
}

ResultTable.defaultProps = {
    currentPage: 1,
    totalPage: 0
}

function UserManagementDialog(props) {

    const {open, onCloseEvent, onChange, onConfirm, content, userCheck, deviceCheck} = props;

    if (!open) return null;

    const {t} = useTranslation();
    const {ds} = useStores();
    const classes = dialogStyle();
    const userKey = content.current.userKey;
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        api.getUserInfo(userKey)
            .then(data => {
                setUserInfo(data.data);
            })
            .catch(err => {
                ds.showErrorAlert(api.getErrMsg(err.rtCode));
            });
    }

    return useObserver(() =>
        <Dialog className={classes.dialog} open={open} onClose={onCloseEvent} scroll='paper' maxWidth='lg'>
            {userInfo !== null ?
                <React.Fragment>
                    <DialogTitle className={classes.dialogHeader}>
                        <Typography className={classes.title}>{t('UserManagement.Settings')}</Typography>
                        {onCloseEvent ? (
                            <IconButton
                                aria-label="close"
                                onClick={onCloseEvent}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: '#fff'
                                }}
                            >
                                <CloseIcon/>
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent className={classes.dialog_content}>
                        <Box className={classes.container}>
                            <Box className={`${classes.infoBox} ${classes.userDetail}`} flexDirection='column'>
                                <Typography>{t('UserManagement.UserInfo')}</Typography>
                                <div>
                                    <dl>
                                        <dt>ID</dt>
                                        <dd><span>{userInfo.userDetail.userKey}</span></dd>
                                    </dl>
                                    <dl>
                                        <dt>{t('UserManagement.UserName')}</dt>
                                        <dd><span>{userInfo.userDetail.name ? userInfo.userDetail.name : '-'}</span></dd>
                                    </dl>
                                </div>
                                <div>
                                    <dl>
                                        <dt>{t('UserManagement.Status')}</dt>
                                        <dd>
                                            <span>{userInfo.userDetail.userStatus ? t('CommonCode.' + userInfo.userDetail.userStatus) : '-'}</span>
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>{t('UserManagement.RegDt')}</dt>
                                        <dd>
                                            <Typography>
                                                {userInfo.userDetail.regDt ? _dataFormat(userInfo.userDetail.regDt, 'date', 'YYYY-MM-DD HH:mm') : '-'}
                                            </Typography>
                                        </dd>
                                    </dl>
                                </div>
                                <div>
                                    <dl>
                                        <dt>{t('UserManagement.Email')}</dt>
                                        <dd><span>{userInfo.userDetail.email ? userInfo.userDetail.email : '-'}</span></dd>
                                    </dl>
                                    <dl>
                                        <dt>{t('UserManagement.PhoneNumber')}</dt>
                                        <dd><span>{userInfo.userDetail.phoneNum ? userInfo.userDetail.phoneNum : '-'}</span></dd>
                                    </dl>
                                </div>
                            </Box>
                            <Box className={`${classes.infoBox} ${classes.clientDetail}`} flexDirection='column'>
                                <Typography>{t('UserManagement.ClientInfo')}</Typography>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{t('UserManagement.UserName')}</th>
                                            <th>{t('UserManagement.UserType')}</th>
                                            <th>{t('UserManagement.RegDt')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    { userInfo.clientUserList?.map((client, idx) => (
                                        <tr key={idx}>
                                            <td><span>{client.clientName}</span></td>
                                            <td><span>{t("CommonCode." + client.userType)}</span></td>
                                            <td>
                                                <Typography>
                                                    {client.regDt ? _dataFormat(client.regDt, 'date', 'YYYY-MM-DD HH:mm') : '-'}
                                                </Typography>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Box>
                            <Box className={`${classes.infoBox} ${classes.deviceDetail}` } flexDirection='column'>
                                <Typography>{t('UserManagement.DeviceInfo')}</Typography>
                                <Box display='flex' flexDirection='row'>
                                { userInfo.deviceList.length !== 0 ?
                                    userInfo.deviceList?.map((device, idx) => (
                                        <Box display='flex' flexDirection='column' className={classes.deviceInfo} key={idx}>
                                            <dl>
                                                <dt>ID</dt>
                                                <dd>{device.deviceId}</dd>
                                            </dl>
                                            <dl>
                                                <dt>{t('UserManagement.Status')}</dt>
                                                <dd>{t("CommonCode." + device.deviceStatus)}</dd>
                                            </dl>
                                            <dl>
                                                <dt>OS</dt>
                                                <dd>{t("CommonCode." + device.deviceOS)}</dd>
                                            </dl>
                                            <dl>
                                                <dt>{t('UserManagement.RegDt')}</dt>
                                                <dd>{device.deviceRegDt ? _dataFormat(device.deviceRegDt, 'date', 'YYYY-MM-DD HH:mm') : '-'}</dd>
                                            </dl>
                                        </Box>
                                    )) : <Box display='flex' flexDirection='column' className={classes.deviceInfo}><span>-</span></Box>
                                }
                                </Box>
                            </Box>
                            <Box className={`${classes.infoBox} ${classes.userDetail}`} flexDirection='column'>
                                <Typography>{t('UserManagement.Activity')}</Typography>
                                <div>
                                    <dl>
                                        <dt>{t('UserManagement.AuthCount')}</dt>
                                        <dd><span>{userInfo.authHistoryCount}</span></dd>
                                    </dl>
                                    <dl>
                                        <dt>{t('UserManagement.EventCount')}</dt>
                                        <dd><span>{userInfo.userEventCount}</span></dd>
                                    </dl>
                                </div>
                            </Box>
                        </Box>
                        <FormGroup className={classes.checkBoxes}>
                            <FormControlLabel control={<Checkbox checked={userCheck}/>}
                                              label={t('UserManagement.DeleteUser')} onChange={(e) => onChange(e, 'user')}/>
                            <FormControlLabel control={<Checkbox checked={deviceCheck}/>}
                                              label={t('UserManagement.DeleteDevice')} onChange={(e) => onChange(e, 'device')}/>
                        </FormGroup>
                        <Box display='flex' justifyContent='center' className={classes.actionBtn}>
                            <Button onClick={onCloseEvent}>{t('UserManagement.Cancel')}</Button>
                            <Button onClick={onConfirm} disabled={!(userCheck || deviceCheck)}>{t('UserManagement.Delete')}</Button>
                        </Box>
                    </DialogContent>
                </React.Fragment> : <React.Fragment/>
            }
        </Dialog>
    );
}

function _dataFormat(data, dataType, format) {
    if (data === undefined || data === null) {
        return null;
    }
    if (dataType === 'date') {
        let dateFormat = 'YYYY-MM-DD';
        if (format !== undefined) {
            dateFormat = format;
        }
        return moment(data, 'YYYY-MM-DD hh:mm:ss Z').format(dateFormat);
    } else if (dataType === 'number') {
        if (_.isNumber(data)) {
            return new Intl.NumberFormat().format(data);
        }
    }
    return data;
}
