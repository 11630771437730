import React, {useRef, useState} from 'react';

import * as PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Select from '@mui/material/Select';
import MenuItem from "@material-ui/core/MenuItem";
import {UserType} from "../common/types";
import {useStores} from "../common/store";
import {useObserver} from "mobx-react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {ListItemIcon} from "@mui/material";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    selectBox: {
        ...theme.select,
        minWidth: 200,
        marginRight: 10
    },
    selectPaper: {
        width: 200,
        border: theme.palette.border.secondary,
        boxShadow: theme.palette.shadow.primary,
        ...theme.select,
        '& > :first-child': {
            '& .MuiListItem-root.Mui-disabled': {
                opacity: 1
            },
        }
    },
    titleItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > :nth-child(2)': {
            minWidth: 'auto',
            '& > *': {
                color: theme.palette.primary.main
            }
        }
    },
    allItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '& > :first-child': {
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '& > :nth-child(2)': {
            minWidth: 'auto',
            '& > *': {
                color: "transparent"
            }
        }
    }
}));

AccessClientSelectBox.propTypes = {
    className: PropTypes.any,
    title:PropTypes.string,
    contents: PropTypes.any,
    onChange: PropTypes.func,
    addAll: PropTypes.bool,
    displayEmpty: PropTypes.bool

};

export default function AccessClientSelectBox({
                                                  className,
                                                  title,
                                                  defaultClientKey,
                                                  defaultClientName,
                                                  onChangeSelect,
                                                  addAll,
                                                  readOnly = false,
                                                  field = null,
                                                  required = false}) {
    const {ds} = useStores();
    const clientsArray = useRef([]);
    const classes = useStyles();

    const onChangeClient = (event) => {
        if (onChangeSelect) {
            onChangeSelect('client', event.target.value);
        }
    }
    if( ds.user.accessibleClient !== undefined && ds.user.accessibleClient !== null ) {
        if( clientsArray.current.length === 0 ) {
            let clientIndex = 0;
            for( let key in ds.user.accessibleClient ) {
                if ( UserType.isManager( ds.user.accessibleClient[key].userType ) ) {
                    clientsArray.current[clientIndex++] = {value: ds.user.accessibleClient[key].clientKey, text: ds.user.accessibleClient[key].clientName};
                }
            }
        }
    }

    return useObserver( () => (
        <Box className={classes.selectBox}>
            { readOnly
                ? (
                    <span>{defaultClientName ? defaultClientName : '-'}</span>
                )
                : (
                    <FormControl variant="outlined" className={className} {...(field?.error ? {error: true} : null)}>
                        <Select
                            displayEmpty={false}
                            onChange={onChangeClient}
                            defaultValue={defaultClientKey ? defaultClientKey : 'ALL'}
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                                classes: { paper: classes.selectPaper },
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                }
                            }}
                        >
                            <MenuItem value="ALL" key="all" disabled={!addAll}>
                                <Box className={classes.titleItem}>
                                    <div>{title}</div>
                                    <ListItemIcon>
                                        <ExpandMoreIcon/>
                                    </ListItemIcon>
                                </Box>
                            </MenuItem>
                            {
                                clientsArray.current.map((content, idx) =>
                                    <MenuItem key={idx} value={content.value}>
                                        <Box className={classes.allItems}>
                                            <div>{content.text}</div>
                                            <ListItemIcon>
                                                <ExpandMoreIcon/>
                                            </ListItemIcon>
                                        </Box>
                                    </MenuItem>)
                            }
                        </Select>
                        {required && field &&
                            <FormHelperText>{field.error ? field.error : " "} </FormHelperText>
                        }
                    </FormControl>
                ) }
        </Box>
    ));
}
