import React, {useEffect} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Box, useMediaQuery} from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('md')]: {
            display       : 'flex',
            flexDirection : 'column',
            flex          : 1,
            alignItems    : 'center',
            justifyContent: 'center',
            textAlign     : 'center',
            width         : '100vw',
            height        : '100vh',
        }
    },
    infoBox  : {
        marginBottom                  : 80,
        [theme.breakpoints.down('md')]: {
            marginBottom: 40
        }
    },
    title    : {
        fontWeight                    : '300',
        fontSize                      : '1.563rem',
        lineHeight                    : '2.25rem',
        marginTop                     : 10,
        marginBottom                  : 40,
        '& strong'                    : {
            fontWeight: '600',
        },
        [theme.breakpoints.down('md')]: {
            fontSize  : '16px',
            lineHeight: '20px',
        }
    },
    subTitle : {
        fontWeight                    : '500',
        fontSize                      : '1rem',
        lineHeight                    : '1.5rem',
        margin                        : 0,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    recuit   : {
        border        : '1.5px solid rgba(255, 255, 255, 0.3)',
        borderRadius  : '20px',
        boxSizing     : 'border-box',
        padding       : '10px 50px',
        textDecoration: 'none',
        color         : '#fff',
    },
    mapBox   : {
        // marginTop:'80px !important',
        // marginBottom:'30px '
        [theme.breakpoints.down('md')]: {
            width          : '90% !important',
            margin         : '0 auto !important',
            '& > .wrap_map': {
                height: '250px !important'
            }
        }
    },
    address  : {
        fontSize                      : '0.875rem',
        lineHeight                    : '1.313rem',
        color                         : '#82858D',
        marginTop                     : 30,
        [theme.breakpoints.down('md')]: {
            fontSize  : '0.688rem',
            lineHeight: '0.938rem',
            margin    : 15
        }
    },
}));


export default function ContactForm(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {daum} = window;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

    useEffect(() => {
        new daum.roughmap.Lander({
            "timestamp": "1647481093178",
            "key"      : "29hmo",
            "mapWidth" : "640",
            "mapHeight": "360"
        }).render();

    }, []);

    return (
        <Box className={classes.container}>
            <Box className={classes.infoBox}>
                <p className={classes.subTitle}>
                    {t('Fnsv.Company.ContactUs.Recruit.SubTitle')}
                </p>
                <p className={classes.title}>
                    <strong>{t('Fnsv.Company.ContactUs.Recruit.TitleKeyword')}</strong>
                    <br/>
                    {t('Fnsv.Company.ContactUs.Recruit.TitleDescription')}
                </p>

                <a className={classes.recuit} href="https://www.jobkorea.co.kr/company/45465194" target={"_blank"}>
                    <span>{t('Fnsv.Company.ContactUs.Recruit.RecruitBtn')}</span>
                </a>
            </Box>

            <div id="daumRoughmapContainer1647481093178"
                 className={`root_daum_roughmap root_daum_roughmap_landing ${classes.mapBox}`}></div>

            <p className={classes.address}>
                {t('Fnsv.Company.ContactUs.Address')}
                <br/>
                TEL : 02-303-3885 / FAX : 02-304-3885 / fnsvalue@fnsvalue.co.kr
            </p>
        </Box>
    )
}
