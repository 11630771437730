import React, {useRef} from "react";
import ReactQuill from "react-quill";
import {useObserver} from "mobx-react-lite";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import 'react-quill/dist/quill.snow.css';
import {Scrollbars} from "react-custom-scrollbars";
import {useStores} from "../common/store";
import * as Quill from "quill";


const useStyles = makeStyles((theme) => ({
    editor: {
        '& .ql-toolbar.ql-snow': {
            borderColor: theme.palette.border.primary,
            backgroundColor: theme.palette.background.contrast,
            borderRadius: "5px 5px 0 0"
        },
        '& .ql-container.ql-snow': {
            borderColor: theme.palette.border.primary,
            borderRadius: "0 0 5px 5px",
            height: 250,
            fontFamily: ['Gmarket Sans', 'Noto Sans KR', 'sans-serif']
        },
        '& .ql-stroke': {
            stroke: theme.palette.text.gray,
        },
        '& .ql-fill': {
            fill: theme.palette.text.gray,
        },
        '& .ql-picker': {
            color: theme.palette.text.gray,
            fontStyle: 'italic',
            fontWeight: 700
        },
        '& .ql-editor.ql-blank::before': {
            color: theme.palette.text.hint
        },
        [theme.breakpoints.down("md")]: {
            height: 'auto !important'
        }
    },
    editorError: {
        '& .ql-toolbar.ql-snow': {
            borderColor: '#f44336',
        },
        '& .ql-container.ql-snow': {
            borderColor: '#f44336',
        }
    },
    helpText: {
        color: '#f44336',
        fontSize: '0.438rem',
        // marginTop: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },

}));

export default function TextEditor(props) {
    const {field,height} = props;
    const classes = useStyles();

    const modules = {
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{size: ["small", false, "large", "huge"]}, {color: []}],
                [
                    {list: "ordered"},
                    {list: "bullet"},
                    {indent: "-1"},
                    {indent: "+1"},
                    {align: []}
                ],
                ["clean"]
            ]
        },
        clipboard: {matchVisual: false}
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "size",
        "color",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align"
    ];

    let contentHeight = undefined;
    if( height !== undefined ){
        contentHeight = {
            height:height
        }
    }

    /*
    Content 내용을 작성하고 지웠을떄
    화면은 빈 화면으로 보이지만
    <p><br></p> 태그가 존재하여
    필수 체크가 허용되어 제어가는 메소드 추가
    */
    const handleBlur = () => {
        if( field !== undefined) {
            if( field.value === "<p><br></p>") {
                field.value = "";
            }
        }
    }


    return useObserver(() =>
        <Box width='100%' height='100%' onBlur={handleBlur}>
            <ReactQuill className={`${classes.editor} ` + (field.error ? ` ${classes.editorError}` : '') }
                        theme='snow'
                        modules={modules}
                        formats={formats}
                        placeholder={props.placeholder}
                        style={contentHeight}
                        {...field?.bind()}
            />
            {field ? <FormHelperText className={classes.helpText}>{field.error ? field.error : " "}</FormHelperText> : null}
        </Box>
    );
}
